.logos {
    background-color: $middle-tone;
    position: relative;
    z-index: 100;
    .logo-item {
        background-size: cover;
        background-position: center;
        margin: 5.5625rem 0;
        display: block;
        height: 14rem;
        .logo-container {
            padding: 1rem;
            background-color: $white;
            width: 100%;
            height: 100%;
            img {
                max-width: 100%;
                max-height: 100%;
                margin-top: auto;
                margin-bottom: auto;
            }
        }
        

    }
    @include media-breakpoint-down(sm) {
        padding: 5.625rem 0;
        .logo-item {
            margin-top: 0;
            margin-bottom: $gap-spacing;
        }
        .col-12 {
            &:last-of-type {
                .service-item {
                    margin-bottom: 0;
                }
            }
        }
    }
}
