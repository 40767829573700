footer {
    background: rgb(13, 22, 37);
    background: linear-gradient(
        90deg,
        rgba(13, 22, 37, 1) 0%,
        rgba(18, 45, 63, 1) 100%
    );
    color: $secondary;

    z-index: 2;
    position: relative;
    .footer-image {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        img {
            display: block;
            margin-left: auto;
            max-height: 100%;
            margin-right: 0;
        }
    }

    a,
    p {
        color: $secondary;
        line-height: 1.875rem;
    }
    h6 {
        font-weight: 300;
        text-transform: uppercase;
        color: $success;
        margin-bottom: 1.5625rem;
    }
    .footer-nav {
        padding-top: 4rem;
        padding-bottom: 3rem;

        .routeplanner-link {
            text-decoration: underline;
        }
        .adres-data {
            p,
            a {
                font-size: 1rem;
            }
            a {
                text-decoration: underline;
            }
        }
    }
    .horizontal-line {
        border-bottom: 1px solid $secondary;
    }
    .footer-credentials {
        padding: 1.5rem 0;

        a,
        p {
            font-size: 0.8rem;
            font-weight: 300;
        }
        ul {
            list-style: none;
            padding-left: 0;
            margin-bottom: 0;
            display: flex;

            li {
                padding-right: 1rem;
            }
        }
        .social-link {
            display: flex;
            margin-left: 1rem;
        }
    }
    @include media-breakpoint-between(md, lg) {
        .footer-credentials {
            p {
                margin-top: 1rem;
            }
        }
    }
    @include media-breakpoint-down(md) {
        .footer-credentials {
            ul {
                margin-bottom: 1rem;
            }
        }
    }
    @include media-breakpoint-down(sm) {
        .footer-image {
            display: none;
        }
        h6 {
            margin-top: 1.5625rem;
            margin-bottom: 0;
        }
        .col-12 {
            &:first-of-type {
                h6 {
                    margin-top: 0;
                }
            }
        }
        .footer-credentials {
            ul {
                flex-direction: column;
                margin-bottom: 1rem;
            }
            .social-link {
                &:first-of-type {
                    margin-left: 0;
                }
            }
        }
    }
}
