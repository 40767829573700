/* main content for projects */
main.projects-page {
    background-color: rgba(18, 45, 63, 0.03);
    background-color: #fff;

    .breadcrumb-container .step-back-link {
        display: none;
    }

    .project-introduction {
        text-align: center;
        h1 {
            margin-bottom: 3rem;
        }
        p {
            margin-bottom: 5.1875rem;
        }
    }

    .call-part-bottom {
        // padding-top: 10.6rem;
    }
}
.projects-overview,
.related-projects-overview {
    position: relative;
    background-color: rgba(18, 45, 63, 0.03);
    padding-bottom: 6.9375rem;

    .clipped-background {
        @include clipped-bg-top-white();
    }
    .filter-wrapper {
        background-color: $secondary;
    }
    .search-and-filter {
        display: block;
        width: max-content;
        margin: 0 auto;
        z-index: 2;
        position: relative;
        padding-bottom: 3rem;

        .filter {
            font-size: 1.25rem;
            opacity: 1;
            transition: all 1s ease-out;
            img.filter-icon {
                margin-right: 0.8875rem;
            }
            svg {
                margin-right: 0.8875rem;
                line,
                g {
                    transition: all 0.5s ease-out;
                }
            }

            &.open {
                opacity: 0.5;
            }
            &:hover {
                opacity: 0.5;
                svg {
                    line,
                    g {
                        stroke: $warning;
                    }
                }
            }
        }
    }

    .checkboxes {
        display: block;
        width: max-content;
        max-width: 100%;
        margin-left: auto;
        margin-right: auto;
        overflow: hidden;
        max-height: 0;
        opacity: 0;
        transition: 0.5s all ease-in-out;
        input[type="checkbox"] {
            width: 30px;
            height: 30px;
        }
        label {
            margin-left: 1rem;
            font-size: 1.375rem;
            margin-right: 3rem;
            @include media-breakpoint-down(sm){
                margin-left: 0.5rem;
                font-size: 1.25rem;
                margin-right: 1.5rem;
            }
        }
        .form-check {
            width: max-content;
        }
        
        &.show {
            max-height: 10rem;
            opacity: 1;
            @include media-breakpoint-down(sm) {
                max-height: 15rem;
            }
        }
        @include media-breakpoint-down(sm) {
            .form-check {
                width: 100%;
            }
            input[type="checkbox"] {
                width: 18px;
                height: 18px;
            }
        }
    }

    .projects {
        position: relative;
        z-index: 2;

        .grid-layout {
            display: grid;
            grid-template-columns: repeat(auto-fill, minmax(13%, 1fr));
            grid-gap: 1.875rem;
            grid-auto-rows: repeat(auto-fill, minmax(1.2345rem, 17fr));
            grid-auto-flow: dense;
            padding-bottom: 1.875rem;

            @include media-breakpoint-down(md) {
                grid-template-columns: repeat(auto-fill, minmax(18%, 1fr));
            }
        }

        .project-item {
            background-color: $primary;
            background-size: cover;
            background-repeat: no-repeat;
            background-position: center;
            display: flex;
            align-items: flex-end;
            // grid-row for big height span 17 vor small height span 11
            grid-row-end: span 17;
            // grid-column: for 3 items in a row span 2, for 2 items span 3, for 1 item span 6
            grid-column-end: span 2;

            @include basis-zooming-usage-before();

            .title {
                background-color: #fff;
                padding: 1.125rem 1.375rem;
                flex-grow: 1;
                position: relative;
                z-index: 1000;
                h3 {
                    font-weight: 700;
                    line-height: $line-height-medium;
                }
            }
            .arrow-box {
                padding: 0.875rem;
                padding-top: 1rem;
                background-color: $warning;
                transition: all 0.2s ease-out;
                position: relative;
                z-index: 1000;
            }
            &:hover {
                &::before {
                    transform: scale(1.1);
                }
                .arrow-box {
                    background-color: $primary;
                }
            }
        }
        .grid1items {
            .project-item {
                grid-column-end: span 6;
            }
        }

        .grid3items {
            .project-item {
                grid-row-end: span 11;
                grid-column-end: span 2;
            }
        }
        .grid4items {
            .project-item {
                grid-row-end: span 11;
                &:nth-child(4) {
                    grid-row-end: span 17;
                    grid-column-end: span 6;
                }
            }
        }

        .grid2items,
        .grid5items,
        .grid6items,
        .grid7items,
        .grid8items,
        .grid9items,
        .grid10items {
            .project-item {
                grid-column-end: span 3;
                &:nth-child(3),
                &:nth-child(4),
                &:nth-child(5) {
                    grid-row-end: span 11;
                    grid-column-end: span 2;
                }
                &:nth-child(6),
                &:nth-child(10) {
                    grid-column-end: span 6;
                }
                &:nth-child(7),
                &:nth-child(8),
                &:nth-child(9) {
                    grid-row-end: span 11;
                    grid-column-end: span 2;
                }
            }
        }
        .grid7items {
            .project-item {
                &:nth-child(6),
                &:nth-child(7) {
                    grid-row-end: span 17;
                    grid-column-end: span 3;
                }
            }
        }
        .grid8items {
            .project-item {
                &:nth-child(n + 7) {
                    grid-row-end: span 17;
                    grid-column-end: span 3;
                }
            }
        }

        @include media-breakpoint-down(md) {
            .grid1items,
            .grid2items,
            .grid3items,
            .grid4items,
            .grid5items,
            .grid6items,
            .grid7items,
            .grid8items,
            .grid9items,
            .grid10items {
                .project-item {
                    &:nth-child(n) {
                        grid-column-end: span 4;
                        grid-row-end: span 11;
                    }
                }
            }
        }
    }
    @include media-breakpoint-down(sm) {
        .search-and-filter {
            .search-bar {
                margin-right: 1rem;
                max-width: 17rem;
            }
        }
    }
}
