@mixin solid-label($background-color) {
    background-color: $background-color;
    padding: 0.5rem 1rem;
    p {
        margin-bottom: 0;
        text-transform: uppercase;
        font-weight: 500;
        font-size: 0.8125rem;
        color: $secondary;
    }
}

/* full gradient */
@mixin gradient($gradient-height) {
    background: rgb(18, 45, 63);
    background: linear-gradient(
        180deg,
        rgba(18, 45, 63, 1) 0%,
        rgba(255, 255, 255, 0) $gradient-height
    );
}

/* gradient with a solid color on top */
@mixin gradient-solid-color-top($gradient-height) {
    background: rgb(18, 45, 63);
    background: linear-gradient(
        180deg,
        rgba(18, 45, 63, 1) 0%,
        rgba(18, 45, 63, 1) 15%,
        rgba(18, 45, 63, 0) $gradient-height
    );
}

@mixin search-bar() {
    display: flex;
    flex-direction: row;
    background-color: #fff;
    padding: 0;
    border-radius: 2rem;
    border: 1px solid $primary;
    margin-bottom: 0;
    input[type="text"] {
        flex-grow: 1;
        background-color: #fff;
        color: $primary;
        font-size: 1rem;
        border: none;
        border-radius: 1.1rem 0px 0px 1.1rem;
        padding: 0.2rem 1rem;
        outline: none;
    }
    button {
        background-color: #fff;
        border: none;
        border-radius: 0px 1.5rem 1.5rem 0px;
        padding: 0.2rem 0.5rem;
    }
    ::placeholder {
        opacity: 1;
    }
    &.selected {
        display: flex;
    }
}

/* grid container, if no need for grid-auto-rows? value is "unset" */
@mixin grid-container($template-columns, $template-rows, $auto-rows) {
    display: grid;
    height: auto;
    grid-template-columns: $template-columns;
    grid-template-rows: $template-rows;
    grid-auto-rows: $auto-rows;
    column-gap: $gap-spacing;
    row-gap: $gap-spacing;
    grid-row-gap: $gap-spacing;
    -moz-column-gap: $gap-spacing;
}

/* diagonal background on top from element where it is in with white background color  */
@mixin clipped-bg-top-white() {
    position: absolute;
    top: 0;
    width: 100%;
    background-color: $secondary;
    -webkit-clip-path: polygon(0 100%, 0 0, 100% 0);
    clip-path: polygon(0 100%, 0 0, 100% 0);
    min-height: 26vw;
}

/* diagonal background on top from element where it is in with variable background color  */
@mixin diagonal-background-top($background-color) {
    position: absolute;
    top: 0;
    width: 100%;
    background-color: $background-color;
    -webkit-clip-path: polygon(0% 0%, 100% 0%, 100% 25vw, 0 52vw);
    clip-path: polygon(0% 0%, 100% 0%, 100% 25vw, 0 52vw);
    height: 100%;
}

/* transparent labels */
@mixin transparent-label($padding, $position) {
    padding: $padding;
    position: $position;
    bottom: 0;
    width: max-content;
    overflow: hidden;

    .label-text {
        background: rgba(246, 246, 246, 0.8);
        overflow: hidden;
        background-position: bottom left;
        padding: 0.34375rem 1rem;
        transition: all 0.4s ease-in-out;
        p {
            color: $primary;
            font-weight: 500;
            transition: color 0.4s ease-in-out;
        }
    }
}

/* for animation .svg in background */
@mixin animation-background($image-url, $position-x, $position-y) {
    background-image: $image-url;
    background-position-x: $position-x;
    background-position-y: $position-y;
    background-size: initial;
    background-repeat: no-repeat;
    position: absolute;
    width: 100%;
    left: 0;
}

/* specification for element with animation as background image, height en position of element */
@mixin animation-container-element(
    $height-content,
    $position-top,
    $position-left
) {
    min-height: $height-content;
    top: $position-top;
    left: $position-left;
}

/* for ul lists */
@mixin list($ul-padding-left, $li-line-height, $li-margin-bottom) {
    list-style: none;
    padding-left: $ul-padding-left;
    li {
        font-size: 1.25rem;
        line-height: $li-line-height;
        padding-left: 1.65rem;
        position: relative;
        margin-bottom: $li-margin-bottom;
        &:before {
            content: url("/static/images/icons/triangle-li-dark.svg");
            position: absolute;
            top: 0;
            left: 0;
        }
    }
}

/* form styling */
@mixin form(
    $text-color,
    $background-color,
    $border-color,
    $button-margin-left,
    $button-margin-right
) {
    ::placeholder {
        color: $text-color;
        opacity: 1;
    }

    input[type="text"],
    input[type="email"],
    input[type="number"],
    textarea {
        width: 100%;
        background-color: $background-color;
        color: $text-color;
        border: 1px solid $border-color;
        border-radius: 1rem;
        padding-left: 1rem;
        margin-bottom: 1.75rem;
        line-height: $line-height-big;
        resize: none;
        outline: none;
        appearance: none;
        -webkit-appearance: none;
    }
    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }
    input[type="number"] {
        -moz-appearance: textfield;
    }
    .form-check {
        margin-left: 1rem;
        margin-bottom: 3.5rem;
        label,
        input {
            display: inline-block;
            vertical-align: middle;
        }
        label {
            margin-left: 1.3125rem;
            line-height: 1.5625rem;
            font-size: 1rem;
        }
        .form-check-input {
            float: unset;
            vertical-align: middle;
        }
        a {
            color: $secondary;
        }
    }
    // label {
    //     display: inline;
    // }
    a.button-basic {
        display: block;
        width: max-content;
        margin-left: $button-margin-left;
        margin-right: $button-margin-right;
    }
}

/* filter basic */
@mixin filter($justify-content) {
    display: flex;
    justify-content: $justify-content;
    margin-bottom: 1.875rem;
    margin-bottom: 2.125rem;
    align-items: baseline;
    p.small {
        margin-right: 1rem;
    }
    label {
        width: 100%;
        max-width: fit-content;
        margin-right: 1.25rem;
    }
    select {
        text-transform: uppercase;
        max-width: fit-content;
        option {
            padding: 1rem 2rem;
            margin-right: 1rem;
        }
    }
    @include media-breakpoint-down(sm) {
        flex-direction: column;
        h5 {
            margin-bottom: 2rem;
        }
    }
}

/* filter menu divider */
@mixin divider-filter-menu($color) {
    ul li {
        border-bottom: 1px solid $color;
        &:first-of-type {
            border-top: 1px solid $color;
        }
        &:last-of-type {
            border-bottom: none;
        }
    }
}

/* filter menu form-select  */
@mixin filter-form-select($background-color, $color, $arrow-url) {
    background-color: $background-color;
    color: $color;
    background-image: none;
    border: 1px solid $background-color;
    position: relative;
    font-size: 1rem;
    transition: none;
    &:after {
        content: $arrow-url;
        position: absolute;
        top: 0.24rem;
        right: 0.56rem;
        width: 2rem;
        transform: rotate(0deg);
        transition: all 0.2s ease-out;
    }
    &:hover {
        color: $warning;
    }
}

/* filter menu arrow transition on click  */
@mixin filter-arrow-transistion() {
    .form-select {
        &:after {
            top: 0rem;
            right: -0.5rem;
            right: 0.5rem;
            width: 2rem;
            transform: rotate(-45deg);
        }
    }
}

/* basis for zooming background image on mouse over */
@mixin basis-zooming-usage-before() {
    position: relative;
    transition: all 0.4s ease-in-out;
    overflow: hidden;
    &::before {
        content: "";
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background: inherit;
        transition: inherit;
    }
}

@mixin carousel-indicator() {
    // width: 1rem;
    // height: 1rem;
    padding: 0.5rem;
    border-radius: 1rem;
    border: none;
    background-color: rgba(255, 255, 255, 0.65);
    margin-left: 1.6875rem;
    box-shadow: 0px 0px 5px rgba(13, 22, 37, 0.5);
}
