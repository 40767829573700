main.search-results {
    .search-result-list {
        margin: 2rem 0;
        .search-result-item {
            background-color: $secondary;
            padding: 1.5rem;
            h3 {
            }
            a {
                font-size: 1.25rem;
                display: block;
            }
            &:nth-child(even) {
                background-color: $dark;
            }
        }
    }
}

.easy-autocomplete {
    flex-grow: 1;
    text-align: left;
    input {
        width: 100%;
        box-shadow: none;
    }
}


