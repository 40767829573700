main.home-page {
    .introduction {
        background-color: rgba(18, 45, 63, 0.03);
        position: relative;
        overflow: hidden;
        #animation-home-introduction-one {
            @include animation-background(
                url("/static/images/icons/triangle-white-left-bottom.svg"),
                calc(100% -236.7px),
                0px
            );
            // initial height animation
            min-height: 236.7px;
            top: 10px;
            left: 10%;
            margin-left: auto;
            margin-right: auto;
            background-size: 236.7px;
            // transform: rotate(1deg);
            transition: all ease;
        }
        #animation-home-introduction-two {
            @include animation-background(
                url("/static/images/icons/triangle-white-left-top.svg"),
                calc(100%),
                0px
            );
            // initial height animation
            min-height: 236.7px;
            top: 246.7px;
            left: 10%;
            background-size: 236.7px;
            // transform: rotate(1deg);
            transition: all ease;
        }
        #animation-home-project-three {
            @include animation-background(
                url("/static/images/icons/trinagle-current-project.svg"),
                calc(0),
                0px
            );
            // initial height animation
            min-height: 393px;
            bottom: 470px;
            left: 7%;
            background-size: 393px;
            // transform: rotate(1deg);
            transition: all ease;

            @include media-breakpoint-down(md) {
                bottom: 300px;
            }
            @include media-breakpoint-down(sm) {
                bottom: 350px;
            }
            @include media-breakpoint-up(xxl) {
                background-size: 593px;
            }
        }

        .introduction-content {
            // compensation to overlay conent over animated background
            padding-top: 8rem;
            padding-bottom: 8.5625rem;

            h1 {
                margin-bottom: 2.8125rem;
                font-weight: 800;
            }
        }
        .core-values {
            background-image: url("/static/images/background-core-values.svg");
            background-color: #fff;
            background-repeat: no-repeat;
            background-size: 100%;
            padding-bottom: 4.48125rem;
            a {
                color: $secondary;
            }
            h1 {
                margin-bottom: 2.8125rem;
                font-weight: 800;
            }
            .grid-container {
                @include grid-container(1fr 1fr, 1fr 1fr 1fr, unset);
                @include media-breakpoint-down(md) {
                }

                .item-one,
                .item-two,
                .item-three,
                .item-four {
                    display: grid;
                    min-height: 250px;

                    // option for image background
                    .image-background {
                        background-size: cover;
                        background-position: center;
                        .gradient-background-layer {
                            @include gradient-solid-color-top(80%);
                            display: flex;
                            flex-direction: column;
                            justify-content: space-between;
                            align-items: flex-start;
                            height: 100%;
                            padding: 1.5rem 1.75rem;
                            position: absolute;
                            top: 0;
                            width: 100%;
                        }
                    }

                    // option for solid color background
                    .color-background {
                        background-size: cover;
                        background-position: 100% 100%;
                        padding: 1.5rem 1.75rem;
                        display: flex;
                        flex-direction: column;
                        justify-content: space-between;
                        align-items: flex-start;
                        position: relative;
                        img {
                            position: absolute;
                            bottom: 0;
                            right: 0;
                        }
                    }
                    h2 {
                        line-height: $line-height-big;
                        font-weight: 700;
                    }
                    .image-header {
                        flex: 1;
                        min-height: 7rem;
                        // default background if images doesn't load
                        background-color: $triple-tone;
                        background-size: cover;
                        background-repeat: no-repeat;
                        background-position: center;
    
                        @include basis-zooming-usage-before();
    
                        img {
                            width: 100%;
                            height: 100%;
                            object-fit: cover;
                            opacity: 0;
                        }
                    }

                    &:hover {
                        .image-header {
                            &::before {
                                transform: scale(1.1);
                            }
                        }
                    }
    
                    .button-basic.dark.forward,
                    .button-basic.red.forward {
                        &:hover {
                            border: 1px solid $secondary;
                        }
                    }
                }

                .item-one {
                    grid-column: 1 / span 1;
                    grid-row: 1 / span 2;

                    .image-background {
                        background-color: $warning;
                        background-image: url('/data/images/home/imagesmallhome_block_left_upper2.jpg');
                        @include media-breakpoint-up(xl) {
                            background-image: url('/data/images/home/imagehome_block_left_upper2.jpg');
                        }
                    }

                    .color-background {
                        background-color: $warning;
                    }
                }
                .item-two {
                    grid-column: 2 / span 1;
                    grid-row: 1 / span 1;

                    .color-background {
                        background-color: $warning;
                    }
                }

                .item-three {
                    grid-column: 1 / span 1;
                    grid-row: 3 / span 1;

                    .color-background {
                        background-color: $primary;
                    }
                }
                .item-four {
                    grid-column: 2 / span 1;
                    grid-row: 2 / span 2;
                    .image-background {
                        background-color: $warning;
                        background-image: url('/data/images/home/imagesmallhome_block_right_lower2.jpg');
                        @include media-breakpoint-up(xl) {
                            background-image: url('/data/images/home/imagehome_block_right_lower2.jpg');
                        }
                    }

                    .color-background {
                        background-color: $warning;
                    }
                }
            }
        }
        .current-project {
            background-color: #fff;
            // position: relative;
            // clip-path: inset(0% 0% 2rem 0%);
            .background-basic {
                position: relative;
                margin-bottom: -2rem;
                img.background-image {
                    width: 130%;
                    margin-left: -15%;
                    clip-path: polygon(100% 0%, 100% 100%, 0% 100%, 0% 33.5vw);
                    max-height: 40rem;

                }
                overflow: hidden;
            }

            

            .content {
                &.image-title {
                    bottom: 8rem;
                    @include media-breakpoint-up(md){
                        bottom: 8.5rem;
                    }

                    @include media-breakpoint-up(lg){
                        bottom: 9rem;
                    }

                    @include media-breakpoint-up(xl){
                        bottom: 9.5rem;
                    }
                    @include media-breakpoint-up(xxl){
                        bottom: 10rem;
                    }

                    h1 {
                        text-shadow: 0 0 8px rgba(0,0,0,.2);
                    }
                }
                position: absolute;
                bottom: 0;

                overflow: hidden;
                width: 100%;
                padding-top: 1.5rem;
                padding-bottom: 2.5rem;
                color: #fff;

                .background-blurred {
                    position: absolute;
                    bottom: 0;
                    width: 100%;
                    height: 100%;
                    img {
                        filter: blur(20px);
                        width: 130%;
                        margin-left: -15%;
                        position: absolute;
                        bottom: 0;
                    }
                    overflow: hidden;
                }

                .row {
                    h1,
                    .col-8,
                    .button-basic {
                        z-index: 9999;
                    }
                }

                h1 {
                    margin-bottom: 0;
                    font-weight: 700;
                }
                .text {
                    padding-top: 2.1875rem;
                    p {
                        font-size: 1rem;
                        line-height: $line-height-medium;
                    }
                }
                .buttons {
                    display: flex;
                    flex-direction: column;
                    justify-content: flex-start;
                    align-items: flex-end;
                    .button-basic:first-of-type {
                        margin-bottom: 1rem;
                    }
                    .button-basic.dark.forward {
                        &:hover {
                            border: 1px solid $secondary;
                        }
                    }
                }
            }
        }
    }

    .news {
        h1 {
            padding-bottom: 2rem;
        }
    }

    .current-projects-container {
        background-color: rgba(18, 45, 63, 0.03);
        .introduction-current-projects {
            padding-top: 5.34rem;
            padding-bottom: 3.26rem;
            h1,
            p {
                text-align: center;
            }
            h1 {
                margin-bottom: 1.6875rem;
            }
        }
        .map-with-locations {
            background-color: transparent;
            padding-bottom: 7.3rem;
            @include media-breakpoint-down(sm)
            {
                padding-top: 5rem;
            }
        }
    }

    .call-part-multiple-triangles {
        padding-top: 8.3rem;
        @include media-breakpoint-down(sm)
        {
            padding-top: 4rem;
            padding-bottom: 6rem;

        }
    }

    @include media-breakpoint-up(lg) {
        .introduction .current-project .background-basic {
            img.background-image {
                max-height: 45rem;
                object-fit: cover;
            }
        }
    }

    @include media-breakpoint-up(xl) {
        .introduction .current-project .background-basic {
            img.background-image {
                max-height: 50rem;
                object-fit: cover;
            }
        }
    }

    @include media-breakpoint-up(xxl) {
        .introduction .current-project .background-basic {
            img.background-image {
                max-height: 60rem;
                object-fit: cover;
            }
        }
    }

    @media (min-width: 2500px) {
        .introduction .current-project .background-basic {
            img.background-image {
                max-height: 75rem;
                object-fit: cover;
            }
        }
    }

    @include media-breakpoint-down(lg) {
        .core-values {
            .grid-container {
                .item-one,
                .item-two,
                .item-three,
                .item-four {
                    min-height: 200px;
                }
            }
        }

        .introduction .current-project .content .buttons {
            flex-direction: row;
            flex-wrap: wrap;

        }
    }
    @include media-breakpoint-down(md) {
        .introduction .core-values {
            // TODO achteraf kijken of deze in de mixin voor grid-container kan
            .grid-container {
                grid-template-columns: 1fr;
                grid-template-rows: auto;
                grid-auto-rows: 1fr;

                .item-one,
                .item-two,
                .item-three,
                .item-four {
                    grid-column: 1 / span 1;
                }
                .item-one {
                    grid-row: 1 / span 3;
                }
                .item-two {
                    grid-row: 4 / span 1;
                }
                .item-three {
                    grid-row: 7 / span 1;
                }
                .item-four {
                    grid-row: 5 / span 2;
                }
            }
        }
    }

    @include media-breakpoint-down(sm) {
        .introduction .core-values {
            .grid-container {
               
                .item-one,
                .item-two,
                .item-three,
                .item-four {
                    grid-column: 1 / span 1;
                }
                .item-one {
                    grid-row: 1 / span 1;
                }
                .item-two {
                    grid-row: 2 / span 1;
                }
                .item-three {
                    grid-row: 4 / span 1;
                }
                .item-four {
                    grid-row: 3 / span 1;
                }
            }
        }
    }
    @include media-breakpoint-down(md) {
        .introduction .current-project .content {
            .background-buttons-mobile {
                z-index: 2;
                height: calc(100% -2rem);
                width: 100%;
                background: rgb(255, 255, 255);
                background: linear-gradient(
                    180deg,
                    rgba(255, 255, 255, 0) 0%,
                    rgba(255, 255, 255, 0) 50%,
                    rgba(255, 255, 255, 1) 88%,
                    rgba(255, 255, 255, 1) 100%
                );
            }
        }
    }

    @include media-breakpoint-down(sm) {
        .introduction .current-project .background-basic {
            img.background-image {
                min-height: 35rem;
                object-fit: cover;
            }
        }
        .introduction .current-project .content {
            .background-buttons-mobile {
                z-index: 2;
                height: calc(100% -2rem);
                width: 100%;
                background: rgb(255, 255, 255);
                background: linear-gradient(
                    180deg,
                    rgba(255, 255, 255, 0) 0%,
                    rgba(255, 255, 255, 0) 50%,
                    rgba(255, 255, 255, 1) 88%,
                    rgba(255, 255, 255, 1) 100%
                );
            }
        }
    }
    @media (max-width: 440px) {
        .introduction .current-project .content {
            padding-bottom: 2.375rem;
        }
    }
}
