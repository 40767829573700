main.current-projects-page,
main.home-page {
    .search-bar {
        @include search-bar;
        max-width: 43rem;
        margin-bottom: 3.89rem;
        margin-left: auto;
        margin-right: auto;
    }
    .map-with-locations {
        position: relative;
        background-color: rgba(18, 45, 63, 0.03);
        padding-bottom: 10rem;
        .clipped-background {
            @include clipped-bg-top-white();
        }

        @include media-breakpoint-down(sm){
            padding-bottom: 5rem;
        } 

        .google-maps-container {
            position: relative;
            box-shadow: 0px 0px 24px 7px rgba(18, 45, 63, 0.24);
            // instead of img google maps
            img {
                object-fit: cover;
            }
            @include media-breakpoint-down(sm){
                img[src*="/static/images/icons/location-flag-red.svg"] {
                    height: 45px !important;
                    object-fit: contain;
                }
                .ratio-16x9 {
                    @include media-breakpoint-down(sm) {
                      --aspect-ratio: calc(100%*16/9); 
                    }
                  }
                  
            } 
            
            iframe {
                width: 100%;
                margin-left: 0%;
            }
            .popup-location {
                position: absolute;
                top: 0;
                bottom: 0;
                left: 0;
                width: 37%;
                opacity: 0;
                background-color: $primary;
                padding-top: 1.1825rem;
                color: $secondary;
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                transition: all 0.4s ease-out;
                @include media-breakpoint-up(md) {
                    margin-left: -37%;
                }
                @include media-breakpoint-down(md) {
                }
                .content {
                    padding: 0 2.25rem;
                    h2 {
                        padding-bottom: 1.375rem;
                    }
                    .details {
                        p,
                        span {
                            line-height: $line-height-medium;
                            font-weight: 300;
                            &:before {
                                padding-right: 0.6375rem;
                            }
                        }
                        p {
                            margin-bottom: 0.6375rem;
                            &.location {

                                &:before {
                                    content: url("/static/images/icons/location-marker-icon.svg");
                                }
                            }
                            &.date {
                                &:before {
                                    content: url("/static/images/icons/location-time-icon.svg");
                                }
                            }
                        }
                    }
                }
                a {
                    display: block;
                    width: 100%;
                    text-align: end;
                    color: $secondary;
                    position: relative;

                    img {
                        width: 3rem;
                        background-color: $warning;
                        padding: 1rem;
                        margin-left: 1.625rem;
                    }
                }
            }
            &.popup-open {
                .popup-location {
                    @include media-breakpoint-up(md) {
                        margin-left: 0;
                        opacity: 1;
                    }
                    @include media-breakpoint-down(md) {
                        height: 100%;
                        padding-top: 1.825rem;
                        opacity: 1;
                    }
                }
            }
        }
    }
    @include media-breakpoint-down(md) {
        .map-with-locations .google-maps-container {
            .popup-location {
                position: relative;
                width: 100%;
                height: 0;
                padding-top: 0;
                opacity: 0;
            }
        }
    }
    @include media-breakpoint-down(sm) {
        .search-bar {
            margin-left: 1.5rem;
            margin-right: 1.5rem;
        }
    }
}

main.current-projects-page {
    .call-part-triangle-background {
        h4 {
            margin-top: 3.2rem;
        }
    }
}
