.breadcrumb-container {
    .breadcrumbs {
        list-style: none;
        display: flex;
        font-size: 1rem;
        padding-top: 2.8125rem;
        padding-bottom: 6rem;
        padding-left: 0;
        li {
            padding-right: 0.5rem;
        }
        a {
            font-weight: 300;
            &:after {
                content: "/";
                padding-left: 0.5rem;
            }
        }
    }
    .step-back-link {
        display: block;
        padding: 2.8125rem 0;
        text-align: end;
        &:before {
            content: url("/static/images/icons/arrow-dark-back.svg");
            padding-right: 0.375rem;
        }
    }
    @include media-breakpoint-down(sm) {
        .breadcrumbs {
            padding-bottom: 1rem;
        }
        .step-back-link {
            padding-top: 0;
            text-align: start;
        }
    }
}
