body {
    font-family: "Manrope", sans-serif;
    color: $primary;
}

a {
    color: $primary;
    text-decoration: none;
    transition: color 0.2s ease-in;
    &:hover {
        color: $warning;
    }
}

h1 {
    font-size: 2.875rem;
    line-height: $line-height-XXL;
    &.large {
        font-size: 3.25rem;
        line-height: 3.75rem;
    }
    &.xlarge {
        font-size: 4.75rem;
        line-height: 5.25rem;
        @include media-breakpoint-down(xl) {
            font-size: 4rem;
            line-height: 4.5rem;
        }
        @include media-breakpoint-down(lg) {
            font-size: 3.5rem;
            line-height: 4rem;
        }
        @include media-breakpoint-down(md) {
            font-size: 3rem;
            line-height: 3.5rem;        }
    }
}
h2 {
    font-size: 1.625rem;
    line-height: $line-height-big;
}
h3 {
    font-size: 1.25rem;
    line-height: $line-height-medium;
}
h4 {
    font-size: 2.375rem;
    line-height: $line-height-XXL;
}
h5 {
    font-size: 2rem;
    line-height: $line-height-XXL;
}
h6 {
    font-size: 1rem;
    line-height: $line-height-big;
}
h1,
h2,
h3,
h4,
h5,
h6 {
    font-weight: 700;
}

p {
    /* main p font size */
    font-size: 1.25rem;
    line-height: $line-height-big;
    font-weight: 400;
    margin-bottom: 0;
    /* styling for p in "card" */
    &.card-quote {
        font-weight: 700;
        line-height: $line-height-medium;
    }
    /* styling for p used in introduction text */
    &.introduction-text {
        font-size: 1.375rem;
        font-weight: 600;
    }
    /* small p fonts */
    &.small {
        font-size: 1rem;
    }
    /* extra small p fonts */
    &.extra-small {
        font-size: 0.8125rem;
    }
}

/* responsive font size */
html {
    @media (max-width: 992px) {
        font-size: 90%;
    }
    @media (max-width: 768px) {
        font-size: 85%;
    }
    @media (max-width: 576px) {
        font-size: 80%;
    }
}

//buttons blue, white and red
.button-basic {
    display: inline-block;
    background-color: $primary;
    font-size: 1rem;
    font-weight: 700;
    color: $secondary;
    line-height: $line-height-medium;
    border-radius: 1.875rem;
    padding: 0.59375rem 1.875rem;
    transition: all 0.2s ease-out;
    /* .dark, .light or .red button */
    &.dark,
    &.light,
    &.red {
        &.forward {
            &:after {
                padding-left: 1.15rem;
            }
        }
        &.back {
            &:before {
                padding-right: 1.15rem;
            }
        }
        &.down {
            &:after {
                padding-left: 1.15rem;
            }
        }
    }
    &.dark {
        background-color: $primary;
        color: $secondary;
        border: 1px solid $primary;
        &.forward {
            &:after {
                content: url("/static/images/icons/arrow-light.svg");
            }
            &:hover {
                &:after {
                    content: url("/static/images/icons/arrow-dark.svg");
                }
            }
        }
        &.back {
            &:before {
                content: url("/static/images/icons/arrow-light-back.svg");
            }
            &:hover {
                &:before {
                    content: url("/static/images/icons/arrow-dark-back.svg");
                }
            }
        }
        &.down {
            &:after {
                content: url("/static/images/icons/arrow-button-down-light.svg");
            }
        }
        &:hover {
            background-color: $secondary;
            color: $primary;
            border: 1px solid $primary;
        }
    }
    &.light {
        background-color: $secondary;
        color: $primary;
        border: 1px solid $secondary;

        &.forward {
            &:after {
                content: url("/static/images/icons/arrow-dark.svg");
            }
            &:hover {
                &:after {
                    content: url("/static/images/icons/arrow-light.svg");
                }
            }
        }
        &.back {
            &:before {
                content: url("/static/images/icons/arrow-dark-back.svg");
            }
            &:hover {
                &:before {
                    content: url("/static/images/icons/arrow-light-back.svg");
                }
            }
        }
        &:hover {
            background-color: $primary;
            color: $secondary;
            border: 1px solid $secondary;
        }
    }
    &.red {
        background-color: $warning;
        color: $secondary;
        border: 1px solid $warning;
        &.forward {
            &:after {
                content: url("/static/images/icons/arrow-light.svg");
            }
            &:hover {
                &:after {
                    content: url("/static/images/icons/arrow-red.svg");
                }
            }
        }
        &.back {
            &:before {
                content: url("/static/images/icons/arrow-light-back.svg");
            }
            &:hover {
                &:before {
                    content: url("/static/images/icons/arrow-red-back.svg");
                }
            }
        }
        &.down {
            &:after {
                content: url("/static/images/icons/arrow-button-down-light.svg");
            }
            &:hover {
                &:after {
                    content: url("/static/images/icons/arrow-down-red.svg");
                }
            }
        }
        &:hover {
            background-color: $secondary;
            color: $warning;
            border: 1px solid $warning;
        }
    }
}
/* labels in header */
.header-labels {
    padding-bottom: 1.375rem;
    display: flex;
    justify-content: center;
    .project-label {
        @include solid-label($primary);
        margin-right: 1rem;
        &:last-child {
            margin-right: 0;
        }
    }
}

/* last section in bottom of content */
.call-part-bottom {
    padding-bottom: 7.75rem;
    background-size: initial;
    background-repeat: no-repeat;
    h4 {
        margin-bottom: 1.875rem;
        font-weight: 700;
    }
    p {
        margin-bottom: 2.6875rem;
    }
    .button-basic:first-of-type {
        margin-right: 2.875rem;
    }

    &.call-part-multiple-triangles {
        background-image: url("/static/images/background-vacancies.svg");
        background-position: 30vw 0;
        @include media-breakpoint-up(xxl) {
            background-position: 40vw 0;
        }
        @include media-breakpoint-down(xl) {
            background-position: 20vw 0;
        }
    }
    &.call-part-triangle-background {
        position: relative;

        h4 {
            margin-top: 10.6875rem;
        }

        .animation-vacancy-one {
            @include animation-background(
                url("/static/images/icons/triangle-grey-left-bottom.svg"),
                right,
                top
            );
            // height top left
            @include animation-container-element(271px, unset, calc(-271px));
            bottom: 271px;
        }
        .animation-vacancy-two {
            @include animation-background(
                url("/static/images/icons/triangle-grey-left-top.svg"),
                right,
                top
            );
            // height top left
            @include animation-container-element(271px, unset, calc(0));
            bottom: 0;
        }
    }
    @include media-breakpoint-down(sm) {
        .button-basic {
            margin-bottom: 1.5rem;
            &:last-of-type {
                margin-bottom: 0;
            }
        }
    }
}

/* add to items that needs to be layed over the background animations */
.z-index-layer {
    position: relative;
    z-index: 100;
}

.vacancy-item {
    padding: 1.8125rem 3.125rem 2.5rem 2.3125rem;
    background: rgb(247, 247, 247);
    background: linear-gradient(
        0deg,
        rgba(247, 247, 247, 1) 0%,
        rgba(235, 235, 235, 1) 50%,
        rgba(247, 247, 247, 1) 100%
    );
    margin-bottom: 2.75rem;
    display: block;
    position: relative;
    &:not(.open) {
        cursor: pointer;
    }
    // transition: all 1s ease-in;

    .red-triangle {
        position: absolute;
        top: 0;
        right: 0;
        display: none;
    }
    .vacancy-header {
        display: flex;
        align-items: center;
        h3 {
            font-size: 1.375rem;
        }
        span {
            font-size: 0.875rem;
            text-transform: uppercase;
            vertical-align: center;
            &:last-of-type {
                padding-left: 0.7375rem;
                opacity: 0.36;
            }
            .vacancy-label {
                display: inline;
            }
            .vacancy-label-hover {
                display: none;
            }
        }
        .vacancy-add-icon {
            display: block;
            padding: 1rem 0 1rem 2rem;
            transition: all 0.5s ease-out;
            img {
                display: block;
                &:last-of-type {
                    display: none;
                }
            }
        }
    }
    .vacancy-content {
        display: none;
        .horizontal-line {
            padding-top: 2.5rem;
            margin-bottom: 2.1875rem;
            border-bottom: 1px solid $primary;
            opacity: 0.2;
        }
        p {
            margin-bottom: 2.1875rem;
        }
        .button-basic {
            &.light {
                &:hover {
                    border: 1px solid $primary;
                }
            }
        }
    }
    &:last-of-type {
        margin-bottom: 7.9125rem;
    }

    &.open {
        .red-triangle {
            display: block;
        }
        .vacancy-header {
            .vacancy-add-icon {
                display: none;
            }
        }
        .vacancy-content {
            display: block;
        }
    }
    &:not(.open):hover {
        color: $warning;
        .vacancy-header {
            span {
                .vacancy-label {
                    display: none;
                }
                .vacancy-label-hover {
                    display: inline;
                }
            }
            .vacancy-add-icon {
                img {
                    display: block;
                    &:first-of-type {
                        display: none;
                    }
                }
            }
        }
    }
}

.background-on-scroll {
    nav {
        background-color: transparent;
        box-shadow: none;
        p {
            text-shadow: 0px 2px 7px rgba(18, 45, 63, 0.5);
        }
        .menu-hamburger {
            .menu-icon {
                .line {
                    box-shadow: 0px 0px 7px rgba(35, 71, 95, 0.5);
                }
            }
        }
    }
}

.background-animation {
    transition: all 0.1s ease;
    &.animation-tablet-display {
        display: none;
    }
    &.animation-desktop-display {
        display: block;
    }
    @include media-breakpoint-down(md) {
        &.animation-tablet-display {
            display: block;
        }
        &.animation-desktop-display {
            display: none;
        }
    }
}

/*--- Filters ---*/

.drop-down-container {
    height: 3rem;
    min-width: 15rem;
    .scrollable {
        overflow-y: auto;
        overflow-x: hidden;
    }
    .full-height {
        height: calc(100vh - 6.2rem);
    }
    .drop-down-start {
        display: block;
        .form-select {
            padding-left: 1.5rem;
            padding-top: 0.2rem;
        }
    }
 
    .drop-down-open {
        display: none;
    }

    .drop-down-selected {
        display: none;
    }

    // general styling filter list
    .drop-down-list {
        transition: max-height 0.3s;
        overflow: hidden;
        position: relative;
        z-index: 2;
        border-bottom-left-radius: 2rem;
        border-bottom-right-radius: 2rem;
        width: 100%;
        max-height: 0;
        @media (max-height: 500px) {
            height: unset;
        }
        ul {
            list-style-type: none;
            padding: 0 0.7rem;
            padding: 0 1.5rem;

            li {
                line-height: 1.7rem;
                text-transform: uppercase;
                a {
                    color: $primary;
                    line-height: 2rem;
                    transition: color 0.15s ease-in;

                    &:hover {
                        color: $warning;
                    }
                }
            }
            .active {
                display: inline-block;
                color: $warning;
            }

            .explain-list {
                text-transform: uppercase;
                font-weight: 100;
                color: $warning;
                font-size: 0.8rem;
                margin-bottom: 0.2rem;
            }
        }

        // specific styling filter list
        &.news-list {
            background-color: $dark;
            @include divider-filter-menu($primary);

            ul {
                li {
                    a:not(.active) {
                        color: $primary;

                        &:hover {
                            color: $warning;
                        }
                    }
                }
            }
        }

        &.jobs-list {
            background-color: $primary;
            @include divider-filter-menu($secondary);
            ul {
                li {
                    a:not(.active) {
                        color: $secondary;

                        &:hover {
                            color: $warning;
                        }
                    }
                }
            }
        }

        @include media-breakpoint-down(md) {
            ul li {
                line-height: 2.5rem;
            }
        }
    }

    a {
        text-decoration: none;
        color: $primary;
    }

    &.filter-menu-open {
        .drop-down-list {
            min-height: auto;
            max-height: max-content;
            @media (max-height: 500px) {
                max-height: max-content;
            }
        }
        .arrow {
            transform: rotate(180deg);
        }

        .drop-down-list {
            display: block;
        }
        .drop-down-start {
            .form-select {
                border-bottom-left-radius: 0;
                border-bottom-right-radius: 0;
            }
        }
    }

    &.filter-menu-selected {
        .arrow {
            transform: rotate(270deg);
        }
    }
    @include media-breakpoint-down(md) {
        a {
            font-size: 1.3rem;
        }
    }
}

.drop-down-list-selected {
    .drop-down-selected {
        display: block;
    }
    .drop-down-start {
        display: none;
    }
    .drop-down-open {
        display: none;
    }
    .drop-down-list {
        display: none;
    }
}

.noscroll {
    overflow: hidden;
}
