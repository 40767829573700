@keyframes open-menu-list {
    0% {
        clip-path: polygon(0% 0%, 100% 0%, 100% 0%);
    }
    30% {
        clip-path: polygon(0% 0%, 100% 0%, 100% 60%);
    }
    100% {
        clip-path: polygon(0% 0%, 100% 0%, 100% 130%);
    }
}
@keyframes open-menu-list-tablet {
    0% {
        clip-path: polygon(0% 0%, 100% 0%, 100% 0%, 100% 0%);
    }
    30% {
        clip-path: polygon(0% 0%, 100% 0%, 100% 60%, 100% 60%);
    }
    100% {
        clip-path: polygon(0% 0%, 100% 0%, 100% 110%, 70% 110%);
    }
}
@keyframes open-menu-list-mobile {
    0% {
        clip-path: polygon(0% 0%, 100% 0%, 100% 0%, 0% 0%);
    }
    30% {
        clip-path: polygon(0% 0%, 100% 0%, 100% 60%, 0% 60%);
    }
    100% {
        clip-path: polygon(0% 0%, 100% 0%, 100% 100%, 0% 100%);
    }
}
@keyframes close-menu-list {
    0% {
        clip-path: polygon(0% 0%, 100% 0%, 100% 130%);
    }
    30% {
        clip-path: polygon(0% 0%, 100% 0%, 100% 40%);
    }
    100% {
        clip-path: polygon(0% 0%, 100% 0%, 100% 0%);
    }
}
@keyframes close-menu-list-mobile {
    0% {
        clip-path: polygon(0% 0%, 100% 0%, 100% 100%, 0% 100%);
    }
    30% {
        clip-path: polygon(0% 0%, 100% 0%, 100% 40%, 0% 40%);
    }
    100% {
        clip-path: polygon(0% 0%, 100% 0%, 100% 0%, 0% 0%);
    }
}

nav {
    background-color: $primary;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1rem 2rem;
    position: fixed;
    // position: absolute;
    top: 0;
    color: $secondary;
    width: 100%;
    z-index: 100000;
    box-shadow: 0px 10px 20px rgba(13, 22, 37, 0.16);
    transition: all 0.3s ease-out;
    // when .navbar-menu-open is removed from the body a transition-delay of 0.5s is applied
    transition-delay: 0.5s;

    &.background-dark {
        background-color: $primary !important;
        transition-delay: 0s;
    }
    a {
        color: $secondary;
        text-decoration: none;
    }
    p {
        // margin-bottom: 0rem;
    }
    .logo-header {
        img {
            max-height: 2rem;
            max-height: 2.4rem;
        }
    }
    .logo-header-white {
        display: none;
        img {
            max-height: 2rem;
            max-height: 2.4rem;
        }
    }

    .search-bar {
        @include search-bar();
        display: none;
    }

    .search-bar[style*='display: block']{
        display: flex !important;
    }

    .easy-autocomplete-container
    {
        color: $primary;
    }

    .search {
        display: flex;
        align-items: center;
        p {
            display: none;
        }

        .magnify-icon {
            margin-left: 1.25rem;
            rect {
                fill: $secondary;
                transition: all 0.2s ease-out;
            }
            g {
                stroke: $secondary;
                transition: all 0.2s ease-out;
            }
            -webkit-filter: drop-shadow(0px 0px 7px rgba(13, 22, 37, 0.5));
            filter: drop-shadow(0px 0px 7px rgba(13, 22, 37, 0.5));
        }
        &:hover {
            .magnify-icon {
                rect {
                    fill: $warning;
                }
                g {
                    stroke: $warning;
                }
            }
        }
    }
    .menu-hamburger {
        margin-left: 1.5rem;
        cursor: pointer;
        transition: color 0.2s ease-out;

        p.open {
            display: block;
        }
        p.close {
            display: none;
        }
        .menu-icon {
            display: flex;
            width: 21px;
            height: 15px;
            position: relative;
            cursor: pointer;
            margin-left: 1rem;

            -webkit-transform: rotate(0deg);
            -moz-transform: rotate(0deg);
            -o-transform: rotate(0deg);
            transform: rotate(0deg);
            -webkit-transition: 0.5s ease-in-out;
            -moz-transition: 0.5s ease-in-out;
            -o-transition: 0.5s ease-in-out;
            transition: 0.5s ease-in-out;

            .line {
                display: block;
                position: absolute;
                height: 2px;
                width: 100%;
                background: #fff;
                background-color: rgb(255, 255, 255);
                border-radius: 8px;
                opacity: 1;
                left: 0;
                &:nth-child(1) {
                    top: 0px;
                }
                &:nth-child(2) {
                    top: 6.5px;
                }
                &:nth-child(3) {
                    top: 13px;
                }

                -webkit-transform: rotate(0deg);
                -moz-transform: rotate(0deg);
                -o-transform: rotate(0deg);
                transform: rotate(0deg);
                -webkit-transition: 0.25s ease-in-out;
                -moz-transition: 0.25s ease-in-out;
                -o-transition: 0.25s ease-in-out;
                transition: 0.25s ease-in-out;
            }
        }

        &:hover {
            color: $warning;
            .menu-icon .line {
                background: $warning;
                background-color: $warning;
            }
        }

        &.open {
            p.open {
                display: none;
            }
            p.close {
                display: block;
            }

            .menu-icon .line {
                &:nth-child(1) {
                    top: 18px;
                    width: 0%;
                    left: 50%;
                }
                &:nth-child(2) {
                    -webkit-transform: rotate(45deg);
                    -moz-transform: rotate(45deg);
                    -o-transform: rotate(45deg);
                    transform: rotate(45deg);
                    height: 3px;
                }
                &:nth-child(3) {
                    top: 6.5px;
                    -webkit-transform: rotate(-45deg);
                    -moz-transform: rotate(-45deg);
                    -o-transform: rotate(-45deg);
                    transform: rotate(-45deg);
                    height: 3px;
                }
            }
        }
    }
    @include media-breakpoint-down(sm) {
        // flex-direction: column;
        // align-items: flex-start;
        .navbar-right-side {
            width: calc(100% - 4rem);
            // justify-content: flex-end;
            position: absolute;
            // right: 2rem;
            left: 2rem;
            justify-content: flex-end;
            .search-bar {
                width: calc(100%);
                input[type="text"] {
                    width: calc(100% - 31px);
                }
            }
            .search,
            .menu-hamburger {
                p {
                    display: none;
                }
                &.open {
                    p.close {
                        display: none;
                    }
                }
            }
        }

        .logo-header,
        .logo-header-white {
            // margin-bottom: 1rem;
            img {
                max-height: 2rem;
            }
        }
    }
}


/* menu list when opened */
.menu-items {
    position: fixed;
    top: 4.3rem;
    color: #fff;
    background-color: $primary;
    width: 100%;
    text-align: end;
    height: 121vh;
    clip-path: polygon(30% 0%, 100% 0%, 100% 0%);
    padding-top: 3.3125rem;
    padding-right: 2rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    z-index: 10000;
    max-height: 100vh;

    a {
        color: #fff;
    }
    .menu-list {
        ul {
            list-style: none;
            font-size: 1.375rem;
            line-height: $line-height-big;
            padding-top: 0;

            // clickable to open .submenu-list items
            li.submenu-title {
                cursor: pointer;
                position: relative;
                p {
                    font-size: 1.375rem;
                }

                padding-right: 1.55625rem;
                &:after {
                    background-image: url("/static/images/icons/triangle-menu-list.svg");
                    background-size: 15px 15px;
                    content: "";
                    width: 15px;
                    height: 15px;
                    display: inline-block;
                    position: absolute;
                    top: 0.6rem;
                    right: 0.2rem;
                    transform: rotate(-0deg);
                    transition: all 0.2s ease-out;
                }
                &.open {
                    &:after {
                        top: 0.4rem;
                        transform: rotate(45deg);
                    }

                    ul {
                        display: block;
                    }
                }

                &:hover {
                    color: $warning;
                }
                &.open {
                    &:after {
                        top: 0.4rem;
                        transform: rotate(45deg);
                    }
                }
            }
            li {
                &.menu-item {
                    padding-right: 1.55625rem;
                }
                ul {
                    &.submenu-list {
                        display: none;
                        animation-duration: 0.6s;

                        li {
                            a {
                                font-size: 1rem;
                            }
                        }
                        &.open {
                            display: block;
                        }
                    }
                }
            }
        }
        a {
            &:hover {
                color: $warning;
            }
        }
    }
    .menu-links {
        display: flex;
        flex-direction: column;
        font-size: 1.25rem;
        line-height: $line-height-medium;
        padding-bottom: calc(15vh + 7rem);
        a:last-of-type {
            padding-bottom: 2.1875rem;
        }
    }
}
.menu-close-animation {
    animation: close-menu-list 0.6s ease-out;
    @include media-breakpoint-down(sm) {
        animation: close-menu-list-mobile 0.6s ease-out;
    }
}
.menu-open {
    animation: open-menu-list 0.6s ease-out;
    animation-fill-mode: forwards;
}
@include media-breakpoint-down(lg) {
    .menu-items .menu-list ul li.submenu-title {
        &:after {
            width: 14px;
            top: 0.5rem;
        }
        &.open {
            &:after {
                top: 0.3rem;
                transform: rotate(45deg);
            }
        }
    }
    .menu-open {
        animation: open-menu-list-tablet 0.6s ease-out;
        animation-fill-mode: forwards;
    }
    .menu-items {
        .menu-links {
            padding-bottom: calc(8vh + 7rem);
        }
    }
}
@include media-breakpoint-down(md) {
    .menu-items .menu-list ul li.submenu-title {
        &:after {
            width: 14px;
            top: 0.45rem;
        }
        &.open {
            &:after {
                top: 0.2rem;
                transform: rotate(45deg);
            }
        }
    }
}
@include media-breakpoint-down(sm) {
    .menu-items {
        top: 4rem;
        top: 0;
        overflow: hidden;
        padding-top: 7.3125rem;
        .menu-list {
            ul {
                li.submenu-title {
                    padding-bottom: 0.3rem;
                    &:after {
                        width: 13px;
                        top: 0.4rem;
                    }

                    &.open {
                        &:after {
                            top: 0.18rem;
                            transform: rotate(45deg);
                        }
                    }
                    ul {
                        padding-top: 0.3rem;
                        .submenu-item {
                            padding-bottom: 0.3rem;
                        }
                    }
                }
                li.menu-item {
                    padding-bottom: 0.3rem;
                }
            }
        }
        .menu-links {
            padding-bottom: calc(6vh + 7rem);
        }
    }
    .menu-open {
        animation: open-menu-list-mobile 0.8s ease-out;
        animation-fill-mode: forwards;
    }
}
@media all and (-ms-high-contrast: active), (-ms-high-contrast: none) {
    .menu-items {
        display: none !important;
        text-align: right;
        &.menu-open {
            display: flex !important;
        }
    }

 }
 

/* when menu icon is clicked and nav menu is displayed */
body.overflow-hidden {
    overflow: hidden;
}

/* this class is add when click on hamburger menu with transparent background */
body.navbar-open-menu {
    nav {
        background-color: $primary;
        box-shadow: none;
        // when navbar-op-menu is added a transition delay of 0 is applied.
        transition-delay: 0s;
        .logo-header {
            display: block !important;
        }
        .logo-header-white {
            display: none !important;
        }
    }
}
