/* main content project */
main.project-page {
    background-color: $dark;
    .breadcrumb-background {
        background-color: #fff;
        .breadcrumb-container {
            margin-top: 3rem;
        }
    }

    .project-introduction {
        padding-bottom: 4.5rem;
        background-color: #fff;
        p {
            text-align: center;
        }
    }

    .project-content {
        background-color: #fff;
        position: relative;
        overflow: hidden;
        #animation-project-one {
            @include animation-background(
                url("/static/images/icons/triangle-grey-left-bottom.svg"),
                calc(105% - 271px),
                0px
            );
            min-height: 271px;
            top: 70px;
        }

        #animation-project-two {
            @include animation-background(
                url("/static/images/icons/triangle-grey-left-top.svg"),
                105%,
                0px
            );
            min-height: 271px;
            top: calc(70px + 271px);
        }

        #animation-project-three {
            @include animation-background(
                url("/static/images/icons/triangle-grey-top.svg"),
                0,
                0px
            );
            min-height: 191.7px;
            bottom: calc(20% + 383.3px);
        }
        #animation-project-four {
            @include animation-background(
                url("/static/images/icons/triangle-grey-left.svg"),
                0,
                0px
            );
            min-height: 383.3px;
            bottom: 20%;
            left: 191.6px;
        }
        .project-description {
            position: relative;
            z-index: 2;
            h5 {
                font-weight: 700;
                margin-bottom: 1.5rem;
            }
            .text-container {
                margin-bottom: 5.5rem;
            }
            .image-container {
                padding-bottom: 5.5rem;
                img {
                    width: 100%;
                }
            }
            &:nth-child(2) {
                background-position: 0% 150%;
            }
            img {
                max-width: 100% !important;
            }
        }
        .clipped-background {
            position: absolute;
            bottom: -1px;
            width: 100%;
            background-color: $dark;
            -webkit-clip-path: polygon(100% 0%, 100% 100%, 0 100%);
            clip-path: polygon(100% 0, 100% 100%, 0% 100%);
            min-height: 40.7vw;
        }
        .related-projects-title {
            position: relative;
            z-index: 2;
            text-align: center;
            font-weight: 700;
            margin-bottom: 0;
            margin-top: 5.125rem;
        }
    }
    .related-projects-overview {
        padding-top: 3.6875rem;
        padding-bottom: 2.375rem;
        background-color: $dark;
    }
    a.button-basic.dark.back {
        margin-bottom: 5rem;
    }
}
