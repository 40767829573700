.services {
    background-color: $middle-tone;
    position: relative;
    z-index: 100;
    .service-item {
        background-color: $primary;
        background-size: cover;
        background-position: center;
        margin: 5.5625rem 0;
        display: block;

        @include basis-zooming-usage-before();
        &:hover {
            &::before {
                transform: scale(1.1);
            }
        }
        .mouse-over-surface {
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            height: 100%;
            width: 100%;
            background-color: $primary;
            opacity: 0;
            transition: all 0.5s ease-out;
        }

        &:hover {
            .mouse-over-surface {
                height: 100%;
                opacity: 0.7;
            }
        }

        .gradient-background-layer {
            @include gradient(100%);
            position: relative;

            // &:before {
            //     content: "";
            //     position: absolute;
            //     // top: 0;
            //     bottom: 0;
            //     left: 0;
            //     width: 100%;
            //     height: 0%;
            //     background-color: $primary;
            //     transition: all 0.5s ease-out;
            // }
            // &:hover {
            //     &:before {
            //         height: 100%;
            //     }
            // }
        }
        h5 {
            padding: 2rem 0;
            margin-bottom: 0;
            text-align: center;
            color: $secondary;
            line-height: $line-height-big;
        }
    }
    @include media-breakpoint-down(sm) {
        padding: 5.625rem 0;
        .service-item {
            margin-top: 0;
            margin-bottom: $gap-spacing;
        }
        .col-12 {
            &:last-of-type {
                .service-item {
                    margin-bottom: 0;
                }
            }
        }
    }
}
