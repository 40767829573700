main.current-project-page {
    .current-project-image {
        position: relative;
        margin-bottom: 4.5rem;
        overflow: hidden;
        img {
            position: relative;
            width: 100%;
            z-index: 2;
        }
        #animation-current-project-one {
            @include animation-background(
                url("/static/images/icons/triangle-grey-left-bottom.svg"),
                right,
                top
            );
            bottom: calc(-271px);
            min-height: 271px;
            left: unset;
            right: 261px;
        }
        #animation-current-project-two {
            @include animation-background(
                url("/static/images/icons/triangle-grey-left-top.svg"),
                right,
                top
            );
            bottom: calc(-542px);
            min-height: 271px;
            left: unset;
            right: -10px;
        }
    }
    article {
        position: relative;
        z-index: 2;
        margin-bottom: 10.5rem;
        h5 {
            font-weight: 700;
            margin-bottom: 1.4365rem;
        }
        p {
            line-height: $line-height-big;
            margin-bottom: 3.75rem;
            &:first-of-type {
                margin-bottom: 6.6525rem;
            }
            &:last-of-type {
                margin-bottom: 0;
            }
        }
        ul {
            @include list(3.3875rem, 2.5rem, 0);
            margin: 3.75rem 0;
        }
    }
}
