.certificates-header {
    overflow: hidden;
    .breadcrumb-container .step-back-link {
        display: none;
    }
}

main.certificate-page {
    position: relative;
    overflow: hidden;
    #animation-certificates-three {
        @include animation-background(
            url("/static/images/icons/triangle-grey-small-right-bottom.svg"),
            right,
            top
        );
        @include animation-container-element(236.7px, calc(250px), calc(5%));
    }
    .certificate {
        margin-bottom: 5.19375rem;
        h1 {
            text-align: center;
            font-weight: 700;
            margin-bottom: 2.7rem;
        }
        .introduction-text {
            margin-bottom: 4.1875rem;
            padding: 0 4.375rem;
            text-align: center;
        }
    }
    .sustainability {
        margin-bottom: 8rem;
        h5 {
            text-align: center;
            font-weight: 700;
            margin-bottom: 2.5rem;
        }
        .introduction-text {
            text-align: center;
            margin-bottom: 3.925rem;
        }
    }
    .download-bar {
        background: rgb(247, 247, 247);
        background: linear-gradient(
            0deg,
            rgba(247, 247, 247, 1) 0%,
            rgba(235, 235, 235, 1) 50%,
            rgba(247, 247, 247, 1) 100%
        );

        padding: 2.875rem 2.5rem 3.375rem 2.5rem;
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 0.875rem;

        .download-title {
            // flex-grow: 1;
            padding-right: 5rem;

            h3 {
                font-size: 1.375rem;
                font-weight: 700;
                margin-bottom: 0;
                line-height: $line-height-big;
                word-break: break-word;
            }
            p.introduction-text {
                margin-bottom: 0;
                font-weight: 400;
                text-align: start;
            }
        }
        .icon-signs {
            display: flex;
            align-items: flex-end;
            // margin-left: 8.25rem;

            span {
                margin-right: 0.675rem;
                &:last-of-type {
                    text-transform: uppercase;
                    font-size: 1.375rem;
                    font-weight: 700;
                    opacity: 0.2;
                    margin-right: 0;
                }
            }
        }
        &:last-of-type {
            margin-bottom: 0;
        }
    }
    @include media-breakpoint-down(md) {
        .certificate {
            .introduction-text {
                padding: 0;
            }
        }
        // .sustainability {
        //     .introduction-text {
        //         padding: 0;
        //     }
        // }
    }
    @include media-breakpoint-down(sm) {
        .download-bar {
            .download-title {
                padding-right: 2rem;
            }
        }
    }
}
