.news-item-header {
    .breadcrumb-container .breadcrumbs {
        display: none;
    }
}

main.news-item-page {
    position: relative;
    .clipped-diagonal-background {
        @include diagonal-background-top($dark);
    }
    h1 {
        margin-bottom: 2.8125rem;
    }
    img {
        width: 100%;
        margin-bottom: 4.75rem;
    }
    .labels-wrapper {
        display: flex;
        flex-wrap: wrap;
        margin-bottom: 2.375rem;
        .news-label {
            @include solid-label($primary);
            width: max-content;
            margin-right: 1rem;
            margin-bottom: 1rem;
        }
    }
    .news-article {
        p {
            margin-bottom: 3.75rem;
            &:last-of-type {
                margin-bottom: 6.25rem;
            }
        }
    }
    .more-news {
        margin-bottom: 7.5rem;
        h1 {
            text-align: center;
            margin-bottom: 6rem;
        }
    }
}
