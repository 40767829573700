main.jobs-page {
    margin-top: -6.75rem;
    position: relative;
    overflow: hidden;
    // background-color: red;

    #animation-jobs-one {
        @include animation-background(
            url("/static/images/icons/triangle-grey-top.svg"),
            right,
            top
        );
        @include animation-container-element(192px, 281.5px, calc(5% - 192px));
    }
    #animation-jobs-two {
        @include animation-background(
            url("/static/images/icons/triangle-grey-right.svg"),
            right,
            top
        );
        @include animation-container-element(383px, 90px, 5%);
    }
    #animation-jobs-three {
        @include animation-background(
            url("/static/images/icons/triangle-grey-left-bottom.svg"),
            left,
            top
        );
        @include animation-container-element(271px, unset, -10%);
        bottom: 580px;
    }
    #animation-jobs-four {
        @include animation-background(
            url("/static/images/icons/triangle-grey-left-top.svg"),
            left,
            top
        );
        @include animation-container-element(271px, unset, calc(-10% + 271px));
        bottom: 309px;
    }

    .introduction-quote {
        position: relative;
        background-color: $primary;
        color: $secondary;
        padding: 2.4375rem 2.75rem;
    }
    .breadcrumbs {
        padding-bottom: 3rem;
    }
    .breadcrumb-container .step-back-link {
        display: none;
    }
    .title-and-filter {
        @include filter(space-between);
    }

    &.filter-menu-open {
        .drop-down-list {
            min-height: calc(100vh - 8.15rem);
            @media (max-height: 500px) {
                max-height: max-content;
            }
        }
        .arrow {
            transform: rotate(180deg);
        }

        .drop-down-list {
            display: block;
        }
    }

    &.filter-menu-selected {
        .arrow {
            transform: rotate(270deg);
        }
    }

    .job-filter {
        @include filter(flex-end);

        .form-select {
            @include filter-form-select(
                $primary,
                $secondary,
                url("/static/images/icons/triangle-light-down.svg")
            );
        }
        .container {
            padding-right: 0;
        }
    }
    @include media-breakpoint-down(md) {
        .job-filter .form-select {
            font-size: 1.3rem;
        }
    }

    @include media-breakpoint-down(sm) {
        .job-filter {
            flex-direction: row;
        }
    }
    .filter-menu-open {
        @include filter-arrow-transistion();
    }
}

aside {
    position: relative;
    .van-baarsen-image {
        object-fit: cover;
    }
    .general-job-information {
        margin-bottom: 20rem;
    }
    .clipped-background {
        position: absolute;
        top: 0;
        width: 100%;
        background-color: $dark;
        -webkit-clip-path: polygon(0% 52vw, 100% 12vw, 100% 100%, 0 100%);
        clip-path: polygon(0% 52vw, 100% 12vw, 100% 100%, 0 100%);
        height: 100%;
    }
}
