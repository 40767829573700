.news-navigation {
    margin-top: 4rem;
    @include media-breakpoint-down(sm) {
        margin-top: 0;
    }
    z-index: 200;
    position: relative;
    .news-tabs::-webkit-scrollbar {
        width: 6px;
        height: 6px;
    }

    .news-tabs::-webkit-scrollbar-track {
        background-color: $dark;
    }
    .news-tabs::-webkit-scrollbar-thumb {
        background-color: $primary;
    }
    .news-tabs::-webkit-scrollbar-corner {
        border-radius: none;
    }

    .news-tabs {
        list-style: none;
        display: flex;
        width: 100%;
        padding: 0;
        overflow-x: scroll;
        scrollbar-color: $primary $dark;
        scrollbar-width: auto;

        .news-category {
            display: block;
            // display: flex;
            background-color: $primary;
            color: $secondary;
            line-height: 1.5rem;
            height: 100%;
            padding: 1.25rem 1.68rem;
            transition: all 0.5s ease-out;
            border-right: 1px solid $dark;
            &.active {
                background-color: $dark;
                color: $primary;
            }

            li {
                display: block;
                width: 100%;
            }
            &:hover {
                background-color: $dark;
                color: $primary;
            }
        }
    }
    .breadcrumbs {
        padding-bottom: 1.5625rem;
    }
    .breadcrumb-container .step-back-link {
        display: none;
    }
    .news-filter {
        display: flex;
        justify-content: flex-end;
        margin-bottom: 1.875rem;
        label {
            width: 100%;
        }
        select {
            text-transform: uppercase;
            option {
                padding: 1rem 2rem;
                margin-right: 1rem;
            }
        }
    }
}
main.news-overview-page {
    margin-bottom: 7.4375rem;
    position: relative;
    overflow: hidden;
    #animation-news-one {
        @include animation-background(
            url("/static/images/animation-background-news-one.svg"),
            left,
            top
        );
        @include animation-container-element(237px, 450px, -10%);
    }
    #animation-news-two {
        @include animation-background(
            url("/static/images/icons/triangle-grey-top.svg"),
            right,
            bottom
        );
        @include animation-container-element(192px, unset, calc(10% - 192px));
        bottom: 150px;
    }
    #animation-news-three {
        @include animation-background(
            url("/static/images/icons/triangle-grey-right.svg"),
            right,
            bottom
        );
        @include animation-container-element(383px, unset, 10%);
        bottom: 150px;
    }
    h1 {
        text-align: center;
        margin-bottom: 1.8125rem;
    }
    .news-filter {
        @include filter(flex-end);

        .form-select {
            @include filter-form-select(
                $dark,
                $primary,
                url("/static/images/icons/triangle-dark-down.svg")
            );
        }
    }

    a.button-basic.dark.down {
        display: block;
        width: max-content;
        margin-left: auto;
        margin-right: auto;
    }
    @include media-breakpoint-down(md) {
        .news-filter .form-select {
            font-size: 1.3rem;
        }
    }

    @include media-breakpoint-down(sm) {
        .news-filter {
            flex-direction: row;
        }
    }

    .filter-menu-open {
        @include filter-arrow-transistion();
    }
}
