main.seo-page {
    padding-top: 5.4375rem;
    position: relative;
    overflow: hidden;

    .clipped-diagonal-background {
        @include diagonal-background-top($dark);
    }

    #animation-service-one {
        @include animation-background(
            url("/static/images/icons/triangle-grey-xxl-top-left.svg"),
            right,
            top
        );
        // height, top-value, left-value
        @include animation-container-element(393.4px, calc(1400px), -5%);
    }
    #animation-service-two {
        @include animation-background(
            url("/static/images/icons/triangle-grey-xxl-top-left.svg"),
            left,
            top
        );
        @include animation-container-element(393.4px, calc(1800px), 0);
    }
    #animation-service-three {
        @include animation-background(
            url("/static/images/icons/triangle-grey-xxl-bottom-right.svg"),
            right,
            top
        );
        @include animation-container-element(393.4px, unset, -5%);
        bottom: 1300px;
    }
    #animation-service-four {
        @include animation-background(
            url("/static/images/icons/triangle-grey-xxl-bottom-left.svg"),
            left,
            top
        );
        @include animation-container-element(393.4px, unset, 10%);
        bottom: 100px;
    }

    p {
        margin-bottom: 3.125rem;
        &:first-of-type {
            font-size: 1.375rem;
            font-weight: 700;
        }
    }

    .image-and-text,
    .image-only {
        margin-bottom: 3.125rem;
        img {
            width: 100%;
        }
        p {
            &:first-of-type {
                font-size: 1.25rem;
                font-weight: 400;
            }
        }
    }

    .innovative-projects {
        position: relative;
        overflow: hidden;
        // .animation-background-top {
        //     @include animation-background(
        //         url("/static/images/animation-background-seo.svg"),
        //         left,
        //         top
        //     );
        //     @include animation-container-element(
        //         393px,
        //         calc(-393px / 2),
        //         calc(90% - 393px)
        //     );
        // }
        h5 {
            text-align: center;
            font-weight: 700;
            margin-bottom: 4.1875rem;
        }
        .grid-container {
            @include grid-container(1fr 1fr, auto, 1fr);
            margin-bottom: 14.26rem;
            .innovative-item {
                display: grid;
                grid-column: auto;
                grid-row: auto;
                background-color: $warning;
                background-size: cover;
                background-repeat: no-repeat;
                position: relative;
                color: $secondary;
                padding: 1.5rem 2.5rem;
                min-height: 25rem;
                .background-gradient {
                    @include gradient-solid-color-top(80%);
                    position: absolute;
                    top: 0;
                    width: 100%;
                    height: 100%;
                }
                .item-content {
                    display: flex;
                    flex-direction: column;
                    justify-content: space-between;
                    height: 100%;
                    h2 {
                        margin-bottom: 2rem;
                    }
                    .item-label {
                        @include solid-label($primary);
                        width: max-content;
                    }
                }
            }
        }
        @include media-breakpoint-down(md) {
            .grid-container {
                .innovative-item {
                    padding: 0.5rem 1.5rem;
                    min-height: 21rem;
                }
            }
        }
        @include media-breakpoint-down(sm) {
            .grid-container {
                @include grid-container(1fr, auto, 1fr);
                .innovative-item {
                    min-height: 18rem;
                }
            }
        }
    }
}
