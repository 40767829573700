.news {
    padding-bottom: 6.3125rem;
    h1 {
        text-align: center;
        padding-top: 7.125rem;
        padding-bottom: 6.3125rem;
    }
    .news-items {
        .fill-height-controller {
            display: flex;
            flex-direction: column;
            height: 100%;

            &.padding-right {
                padding-right: 3px;
            }
            &.padding-left {
                padding-left: 3px;
            }

            .news-item {
                background-color: $dark;
                color: $primary;
                flex: 1;
                display: flex;
                flex-direction: column;
                margin-bottom: $gap-spacing;

                p {
                    text-transform: uppercase;
                    font-weight: 500;
                }

                .image-header {
                    flex: 1;
                    min-height: 7rem;
                    // default background if images doesn't load
                    background-color: $triple-tone;
                    background-size: cover;
                    background-repeat: no-repeat;
                    background-position: center;

                    @include basis-zooming-usage-before();

                    img {
                        width: 100%;
                        height: 100%;
                        object-fit: cover;
                        opacity: 0;
                    }

                    .news-label {
                        @include transparent-label(1.5rem 1.75rem, absolute);
                        width: auto;
                    }
                }

                .news-content {
                    padding: 1.5rem 1.75rem;

                    h3 {
                        font-weight: 700;
                        line-height: $line-height-medium;
                        img {
                            margin-bottom: 0;
                            width: max-content;
                        }
                    }
                }
                &:hover {
                    .image-header {
                        &::before {
                            transform: scale(1.1);
                        }
                        .news-label {
                            .label-text {
                                background-color: rgba(246, 246, 246, 1);
                            }
                        }
                    }
                }
            }
            .news-item:last-of-type {
                margin-bottom: 0;
            }

            // second item on left side with "meer nieuws title
            .more-news-box {
                background-color: $primary;
                background-size: cover;
                background-position: center;
                min-height: 18rem;
                flex: 1;
                display: flex;
                flex-direction: column;
                position: relative;
                transition: all 0.4s ease-in-out;
                overflow: hidden;

                &::before {
                    content: "";
                    position: absolute;
                    top: 0;
                    right: 0;
                    bottom: 0;
                    left: 0;
                    background: inherit;
                    transition: inherit;
                }
                .title-header {
                    position: relative;
                    background-color: $primary;
                    padding: 1.5rem;
                    h2 {
                        color: #fff;
                        font-weight: 700;
                    }
                }
                .gradient-overlay {
                    position: relative;
                    background: linear-gradient(
                        180deg,
                        #122d3f,
                        hsla(0, 0%, 100%, 0) 50%
                    );
                    flex: 1;
                    .triangle-icon {
                        position: absolute;
                        bottom: 0;
                        right: 0;
                    }
                }
                &:hover {
                    &::before {
                        transform: scale(1.1);
                    }
                }
            }
        }
    }
    @include media-breakpoint-down(sm) {
        .news-items {
            .fill-height-controller {
                &.padding-right {
                    padding-right: 0;
                }
                &.padding-left {
                    padding-left: 0;
                }
                .news-item:last-of-type {
                    margin-bottom: $gap-spacing;
                }
            }
        }
    }
}
