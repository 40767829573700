// Configuration
@import "../../node_modules/bootstrap/scss/functions";
@import "../../node_modules/bootstrap/scss/variables";

// custom bootsrap  gutters
$gutters: (
    0: 0,
    1: $spacer * 0.25,
    2: $spacer * 0.5,
    3: $spacer,
    4: $spacer * 1.5,
    5: $spacer * 4.75,
);

// custom ratio bootstrap values
$aspect-ratios: (
    "1x1": 100%,
    "4x3": calc(3 / 4 * 100%),
    "16x9": calc(9 / 16 * 100%),
    "21x9": calc(9 / 21 * 100%),
    "2x1": calc(1 / 2 * 100%),
    "1x2": calc(2 / 1 * 100%),
    "32x30": calc(30 / 32 * 100%),
    // custom ratio for project items in proects.html
    "391x316": calc(316 / 391 * 100%),
    // custom ratio for member profile image in team.html in .team-members-overview
    "65x37": calc(37 / 65 * 100%),
    // custom ratio for header-image in newsitem
    "21x10": calc(10 / 21 * 100%),
);

// colors
$primary: #122d3f;
/* dark blue */
$primary2: #11283a;
/* dark blue version 2 */
$secondary: #ffffff;
/* white */
$success: #74aac2;
/* light blue */
$info: #f1f3f4;
/* broken white */
$light: #f7f8f9;
/* light grey */
$warning: #fc0039;
/* red */
$danger: #a8d2e3;
/* grey */
$dark: #f6f6f6;
/* mid grey */
$middle-tone: #f5f6f7;
/* mid grey 2 */
$triple-tone: #6f6f6f;
/* mid grey 3 */
$quarter-tone: #707070;
/* mid grey 4 */
$quintet-tone: #d8d8d8;
/* mid grey 5 */

$aspect-ratios: (
    "1x1": 100%,
    "4x3": calc(3 / 4 * 100%),
    "16x9": calc(9 / 16 * 100%),
    "21x9": calc(9 / 21 * 100%),
    "3x2": calc(2 / 3 * 100%),
);

// shared values
$gap-spacing: 1.875rem;

$line-height-small: 1.125rem;
$line-height-medium: 1.75rem;
$line-height-big: 2.125rem;
$line-height-XXL: 3.125rem;

// select filter button
$form-select-bg: $primary;
$form-select-color: $secondary;
$form-select-indicator-color: $secondary;
$form-select-indicator: url("/static/images/icons/triangle-light-down.svg");
$form-select-font-size: 0.875rem;
$form-select-line-height: 2rem;
$form-select-border-radius: 2rem;
$form-select-border-color: $primary;
$form-select-focus-box-shadow: rgba(0, 0, 0, 0);
$form-select-focus-border-color: rgba(0, 0, 0, 0);
$form-check-input-width: 1.2rem;
$form-check-input-focus-box-shadow: none;
$form-check-input-active-filter: brightness(90%);
$form-check-input-bg: $secondary;
$form-check-input-border: 1px solid $quarter-tone;
$form-check-input-border-radius: 0;
$form-check-input-checked-bg-color: $warning;
$form-check-input-checked-border-color: $secondary;

// carousel transition values, used in projects_overview,html and in news_overview.html
$carousel-transition-duration: 0.5s;
$carousel-transition: transform $carousel-transition-duration ease-in-out;

@import "../../node_modules/bootstrap/scss/mixins";
@import "../../node_modules/bootstrap/scss/utilities";

@import "../../node_modules/bootstrap/scss/root";
@import "../../node_modules/bootstrap/scss/reboot";
@import "../../node_modules/bootstrap/scss/type";
@import "../../node_modules/bootstrap/scss/images";
@import "../../node_modules/bootstrap/scss/containers";
@import "../../node_modules/bootstrap/scss/grid";
@import "../../node_modules/bootstrap/scss/tables";
@import "../../node_modules/bootstrap/scss/forms";
@import "../../node_modules/bootstrap/scss/buttons";
@import "../../node_modules/bootstrap/scss/transitions";
@import "../../node_modules/bootstrap/scss/transitions";
@import "../../node_modules/bootstrap/scss/dropdown";
@import "../../node_modules/bootstrap/scss/button-group";
@import "../../node_modules/bootstrap/scss/nav";
@import "../../node_modules/bootstrap/scss/navbar";
@import "../../node_modules/bootstrap/scss/card";
@import "../../node_modules/bootstrap/scss/breadcrumb";
@import "../../node_modules/bootstrap/scss/pagination";
@import "../../node_modules/bootstrap/scss/badge";
@import "../../node_modules/bootstrap/scss/alert";
@import "../../node_modules/bootstrap/scss/progress";
@import "../../node_modules/bootstrap/scss/list-group";
@import "../../node_modules/bootstrap/scss/close";
@import "../../node_modules/bootstrap/scss/toasts";
@import "../../node_modules/bootstrap/scss/modal";
@import "../../node_modules/bootstrap/scss/tooltip";
@import "../../node_modules/bootstrap/scss/popover";
@import "../../node_modules/bootstrap/scss/carousel";
@import "../../node_modules/bootstrap/scss/spinners";

// // Helpers
@import "../../node_modules/bootstrap/scss/helpers";

// // Utilities
@import "../../node_modules/bootstrap/scss/utilities/api";

// // animations

// TODO op een juiste manier de import regelen van animations
// @import "../../node_modules/animate.css/animate.compat.css";
// @import "../../node_modules/animate.css/animate.css";
// @import "../../node_modules/animate.css/animate.min.css";
// @import "../../node_modules/animate.css/source/_base.css";
// @import "../../node_modules/animate.css/source/_vars.css";
// @import "../../node_modules/animate.css/source/animate.css";
// @import "../../node_modules/animate.css/source/fading_entrances/fadeIn.css";

@import "./base.scss";
@import "./mixins.scss";
@import "./shared.scss";
@import "./services.scss";
@import "./navbar.scss";
@import "./header.scss";
@import "./breadcrumb.scss";
@import "./footer.scss";
@import "./home.scss";
@import "./projects.scss";
@import "./project.scss";
@import "./about.scss";
@import "./current_projects.scss";
@import "./current_project.scss";
@import "./contact.scss";
@import "./jobs.scss";
@import "./job.scss";
@import "./news.scss";
@import "./news-overview.scss";
@import "./news_item.scss";
@import "./seo.scss";
@import "./certificates.scss";
@import "./search_results.scss";
@import "./logos.scss";


// font family
@import url("https://fonts.googleapis.com/css2?family=Manrope:wght@200;300;400;500;600;700;800&display=swap");
