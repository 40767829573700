header {
    position: relative;
    z-index: 100;
    padding-top: 4rem;
    &.no-image {
        padding-top: 0;
        @include media-breakpoint-down(sm){
            margin-top: -0.5rem;
        }
    }
    .header-content {
        max-height: fit-content;
        display: flex;
        align-items: center;
        width: 100%;
        background-size: cover;
        background-position: center;

        &.projects-header {
            align-items: flex-start;
        }

        /* image slider in header (used in projects.html) */
        .carousel {
            width: 100%;
            margin-top: 0;
            min-height: calc(100vh - 5rem);
            .carousel-indicators {
                /* padding is 2.4375rem - 1rem padding from button .carousel-control-next en prev */
                padding-bottom: 1.4375rem;
                align-items: center;
                -webkit-appearance: none;

                button.indicator {
                    @include carousel-indicator();
                    -webkit-appearance: none;
                    -moz-appearance: none;
                    appearance: none;
                }

                .indicator.active {
                    background-color: $warning;
                }
                .carousel-control-prev,
                .carousel-control-next {
                    position: relative;
                    width: auto;
                    background-color: transparent;
                    opacity: 1;
                    border: none;
                    padding: 1rem;
                    .nav-arrow {
                        height: 1rem;
                        width: auto;
                    }
                    svg {
                        path,
                        line {
                            stroke: $secondary;
                            transition: all 0.2s ease-out;
                            // box-shadow: 0px 0px 5px rgba(13, 22, 37, 0.5);
                        }
                        -webkit-filter: drop-shadow(
                            0px 0px 7px rgba(13, 22, 37, 0.5)
                        );
                        filter: drop-shadow(0px 0px 7px rgba(13, 22, 37, 0.5));
                    }
                    &:hover {
                        svg {
                            path,
                            line {
                                stroke: $warning;
                            }
                        }
                    }
                }
                .carousel-control-next {
                    margin-left: 1.6875rem;
                }
            }
            .carousel-inner {
                min-height: calc(100vh -5rem);
                max-height: calc(100vh -5rem);
                display: flex;
                .carousel-item {
                    min-height: calc(100vh -5rem);
                    max-height: calc(100vh -5rem);
                    display: flex;
                    background-color: $primary;
                    .carousel-caption {
                        text-align: start;
                        top: 0;
                        bottom: 0;
                        flex-direction: column;
                        align-items: flex-start;
                        justify-content: center;
                        left: 0;
                        width: 100%;

                        h1 {
                            text-shadow: 0px 0px 8px rgba(0, 0, 0, 0.2);
                            margin-bottom: 3.5625rem;
                            padding-right: 20rem;
                        }

                        .button-basic.red {
                            &:hover {
                                border: 1px solid $secondary;
                            }
                        }
                    }
                    .background-image {
                        object-fit: cover;
                        width: 100%;
                        min-width: 100%;
                        width: 100%;
                    }
                }
            }
        }
        &.background-image{
            @include media-breakpoint-down(sm) {
                height: 100vh;
            }
            background: url('/data/images/home/image_mediumhome_header2.jpg');
            @include media-breakpoint-up(lg) {
                background: url('/data/images/home/image_largehome_header2.jpg');
                background-size: cover;
                background-position: center;
            }
        } 

        /* used in home.html template: header size hull height of browser window */
        &.header-full-height {
            h1 {
                color: $secondary;
                text-align: center;
                text-shadow: 0 0 8px rgba(0, 0, 0, 0.2);
            }
           
            /* video in header home */
            .iframe-container {
                width: 100vw;
                height: 100%;
                overflow: hidden;
                position: relative;
                video {
                    position: relative;
                    width: 100vw;
                    display: block;
                    height: auto;
                    z-index: 0;
                    .ytp-chrome-top,
                    .ytp-show-cards-title {
                        display: none;
                    }
                }
            }

            .overlay {
                position:absolute;
                top: 50%;
                -webkit-transform: translateY(-50%);
                transform: translateY(-50%);
                z-index:1;
                min-width: 100%;
            }
        }

        /* only a quote in the header with background (used in about.html and contact.html) */
        &.quote-in-header {
            padding: 4.4rem;
            display: flex;
            flex-direction: column;
            .breadcrumbs {
                padding-bottom: 2.8125rem;
            }
            .breadcrumb-container .step-back-link {
                display: none;
            }
            .quote {
                display: flex;
                align-items: center;
                flex: 1;
                H1 {
                    text-align: center;
                }
            }
        }

        /* header with title and labels ( used in project.html) */
        &.project-item-header {
            align-items: flex-end;
            margin-bottom: 0;
            min-height: calc(100vh - 10rem);
            .project-specifics {
                margin-bottom: -3.0625rem;
                color: #fff;
                h1 {
                    text-align: center;
                    margin-bottom: 2.3125rem;
                }

                .project-properties {
                    background-color: $primary;
                    .property-item {
                        display: flex;
                        align-items: flex-end;
                        justify-content: center;
                        padding-top: 1.625rem;
                        padding-bottom: 2rem;
                        img {
                            margin-right: 1rem;
                        }
                        p {
                            font-size: 1.25rem;
                            line-height: $line-height-medium;
                            margin-bottom: 0;
                            font-weight: 700;
                            &:last-of-type {
                                font-weight: 300;
                            }
                        }
                    }
                }
                .header-labels {
                    .project-label {
                        background-color: rgba(246, 246, 246, 0.8);
                        p {
                            color: $primary;
                        }
                    }
                }
            }
        }
        /* custom header used in current-projects.html */
        &.current-projects-header {
            display: flex;
            flex-direction: column;
            @include media-breakpoint-down(sm){
                margin-top: 0;
            }

            .breadcrumbs {
                padding-bottom: 5.3125rem;
            }
            .breadcrumb-container .step-back-link {
                display: none;
            }
            .quote {
                text-align: center;
                margin-bottom: 3.8125rem;
                h1 {
                    margin-bottom: 3rem;
                }
            }
            min-height: unset;
            max-height: unset;
        }
        &.search-results-page {
            background-image: url("/static/images/background-about.svg");
            background-size: cover;
            .quote {
                h1 {
                    margin-top: 5.3125rem;
                }
                .search-bar {
                    @include search-bar;
                    max-width: 43rem;
                    margin-bottom: 3.89rem;
                    margin-left: auto;
                    margin-right: auto;
                    border-color: $secondary;
                }
            }
        }

        /* custom header used in current-project.html */
        &.current-project-header {
            display: flex;
            flex-direction: column;

            .breadcrumbs,
            .step-back-link {
                padding-bottom: 5.3125rem;
               
            }
            @include media-breakpoint-down(sm) {
                .breadcrumbs {
                    padding-bottom: 0.5rem;
                }
                .step-back-link {
                    padding-bottom: 2rem;
                    margin-bottom: 1rem;
                }
            }

            .c-project-header-content {
                width: 100%;

                h1 {
                    text-align: center;
                    margin-bottom: 3rem;
                }
                .header-labels {
                    margin-bottom: 0rem;
                }
            }
            #timeline-caption {
                opacity: 0;
                transition: all 2s ease-out;
                display: block;
                text-align: center;
                // TODO make functional that this item gets the correct width value
                /* 0% width is (0% - 0rem), 50% width is (50% - 3rem), 100% width is (100% - 6rem) */
                margin-bottom: 0.9rem;
                p {
                    margin-bottom: 0;
                    line-height: 1.25rem;
                    &:last-of-type {
                        margin-bottom: 0.8625rem;
                    }
                }
                &.start {
                    p {
                        text-align: left;
                        margin-left: 3rem;
                    }
                }

                &.end {
                    p {
                        text-align: right;
                        margin-left: -3rem;
                    }
                }
                div {
                    width: 6rem;

                    margin-left: -3rem;
                }
            }
            .timeline-container {
                position: relative;
                padding-top: 1rem;
                display: flex;
                margin-bottom: 6.875rem;

                .timeline-item {
                    position: relative;
                    .timeline-bar-container {
                        width: 100%;
                        border-left: 1px solid $quarter-tone;
                        height: 2.8rem;
                        position: relative;
                        z-index: 2;
                        .timeline-bar {
                            border-radius: 1rem;
                            position: absolute;
                            top: -1rem;
                            left: -0.5px;
                            width: calc(100% + 1px);
                            z-index: 4;

                            p {
                                padding: 0.4375rem 0.4375rem;
                                line-height: 1.25rem;
                            }
                            &.preparation {
                                color: $secondary;
                                background-color: $warning;
                            }
                            &.performance {
                                color: $secondary;
                                background-color: $primary;
                            }
                            &.completion {
                                color: $primary;
                                background-color: $quintet-tone;
                            }
                        }

                        .time-line-date {
                            position: absolute;
                            bottom: -1.5rem;
                            width: 7rem;
                            @include media-breakpoint-down(md) {
                                width: 15rem;
                            }

                            p {
                                line-height: 1.25rem;
                                text-align: center;
                            }
                            &.start-date {
                                left: -3.5rem;
                            }
                            &.end-date {
                                right: -3.5rem;
                                left: auto;
                            }
                        }
                    }
                    &:nth-child(1) {
                        .timeline-bar-container {
                            .timeline-bar {
                                width: calc(100% + 1.5px);
                                left: -1px;
                            }
                        }
                    }

                    &:nth-child(3) {
                        .timeline-bar-container {
                            border-right: 1px solid rgba(112, 112, 112, 0.29);
                            &.finished {
                                border-right: 1px solid rgba(112, 112, 112, 1);
                            }
                            .timeline-bar {
                                width: calc(100% + 1.5px);
                            }
                        }
                    }
                    .vertical-time-lines {
                        position: absolute;
                        top: 0;
                        left: 0px;
                        height: 2.8rem;
                        width: 100%;
                        display: flex;
                        justify-content: space-evenly;

                        span {
                            opacity: 1;
                            display: inline-block;
                            height: 100%;
                            width: 1px;
                            background-color: $quarter-tone;
                            opacity: 0.29;
                        }
                    }
                }
            }
            @include media-breakpoint-down(lg) {
                .timeline-container {
                    .timeline-item {
                        .timeline-bar-container {
                            .timeline-bar {
                                p {
                                    padding-left: 0.4375rem;
                                    line-height: 1.25rem;
                                    font-size: 0.9rem;
                                }
                            }
                        }
                    }
                }
            }

            @include media-breakpoint-down(md) {
                #timeline-caption {
                    display: none;
                }
                .timeline-container {
                    padding-top: 0;
                    flex-direction: column;
                    .timeline-item {
                        .timeline-bar-container {
                            border: none;
                            margin-top: 2.5rem;
                            height: 2.5rem;
                            .timeline-bar {
                                top: 0;
                                border-radius: 0;
                                p {
                                    color: $primary;
                                    padding-left: 0;
                                    font-size: 1.25rem;
                                }
                                &.preparation,
                                &.performance,
                                &.completion {
                                    height: 100%;
                                    background-color: transparent;
                                }
                                &.preparation {
                                    border-bottom: 4px solid $warning;
                                }
                                &.performance {
                                    border-bottom: 4px solid $primary;
                                }
                                &.completion {
                                    border-bottom: 4px solid $quintet-tone;
                                }
                            }
                            .time-line-date {
                                bottom: -1.5rem;
                                p {
                                    text-align: start;
                                }
                                &.start-date {
                                    left: 0;
                                }
                                &.end-date {
                                    right: -7rem;
                                    p {
                                        text-align: start;
                                    }
                                }
                            }
                        }
                        &:nth-child(3) {
                            .timeline-bar-container {
                                border: none;
                                &.finished {
                                    border: none;
                                }
                            }
                        }
                        .vertical-time-lines {
                            display: none;
                        }
                    }
                }
            }
            @include media-breakpoint-down(sm) {
                .timeline-container
                    .timeline-item
                    .timeline-bar-container
                    .timeline-bar
                    p {
                    width: 90vw;
                }
            }
        }
    }
    @include media-breakpoint-down(lg) {
        .header-content {
            .carousel {
                .carousel-inner {
                    .carousel-item {
                        .carousel-caption {
                            h1 {
                                padding-right: 10rem;
                            }
                        }
                    }
                }
            }
        }
    }
    @include media-breakpoint-down(md) {
        .header-content {
            .carousel {
                .carousel-inner {
                    .carousel-item {
                        .carousel-caption {
                            h1 {
                                padding-right: 0;
                            }
                        }
                    }
                }
            }
            &.project-item-header {
                .project-specifics {
                    .project-properties {
                        .property-item {
                            justify-content: flex-start;
                            img {
                                margin-left: 1rem;
                            }
                        }
                    }
                }
            }
            &.current-project-header {
                .breadcrumbs {
                    padding-bottom: 2.8125rem;
                }
            }
        }
    }
    @include media-breakpoint-down(sm) {
        .header-content {
            min-height: calc(100vh-5rem);
            .carousel {
                min-height: calc(100vh-5rem);
                .carousel-inner {
                    min-height: calc(100vh-5rem);
                    max-height: calc(100vh-5rem);
                    .carousel-item {
                        min-height: calc(100vh-5rem);
                        max-height: calc(100vh-5rem);
                        .carousel-caption {
                            h1 {
                                word-wrap: anywhere;
                            }
                        }
                    }
                }
            }
            &.current-projects-header {
                .breadcrumbs {
                    padding-bottom: 1rem;
                }
            }
            &.quote-in-header {
                padding: 4.4rem 0;
            }
            &.current-project-header {
                min-height: auto;
                .step-back-link {
                    margin-top: 0;
                }
                .breadcrumbs {
                    padding-bottom: 0.5rem;
                }
            }
        }
    }
    /* custom header used in jobs.html and seo.html */
    .header-with-blurred-image {
        position: relative;
        clip-path: inset(0% 0% 2rem 0%);
        margin-bottom: -7.5rem;

        .background-basic {
            position: relative;

            img.background-image {
                width: 130%;
                margin-left: -15%;
                max-height: calc(100vh -5.5rem);
                min-height: calc(100vh -5.5rem);
                object-fit: cover;
            }
            overflow: hidden;
        }
        &.full-height-header {
            margin-bottom: -2rem;
            .background-basic {
                img.background-image {
                    max-height: calc(100vh - 5rem);
                    min-height: calc(100vh -5rem);
                }
                
            }
        }

        .content {
            position: absolute;
            bottom: 0;
            overflow: hidden;
            width: 100%;
            padding-top: 3rem;
            padding-bottom: 3rem;
            color: #fff;
            @include media-breakpoint-down(sm) {
                padding-bottom: 2rem;
                padding-top: 1rem;
                h1 {
                    font-size: 2.25rem;
                    line-height: 2.5rem;
                  }
            }

            .background-blurred {
                position: absolute;
                bottom: 0;
                width: 100%;
                height: 100%;
                img {
                    filter: blur(20px);
                    width: 130%;
                    margin-left: -15%;
                    position: absolute;
                    bottom: 0;
                }
                overflow: hidden;
            }
            .title-text-and-button {
                display: flex;
                align-items: flex-end;
                margin-bottom: 1.96rem;
                h1 {
                    color: $secondary;
                    text-shadow: 0 0 8px rgba(0, 0, 0, 0.2);
                    word-break: break-word;
                }
            }

            .news-labels {
                display: flex;
                flex-wrap: wrap;
                .label-item {
                    @include transparent-label(0 0, relative);
                    padding-right: 1rem;
                    padding-bottom: 1rem;
                }
            }

            h1 {
                margin-bottom: 0;
                &.jobs-title {
                    margin-bottom: 6rem;
                }
            }

            p {
                text-transform: uppercase;
            }
        }
    }
    /* header with blurred part in bottom of image */
    .header-with-blurred-image-carousel {
        clip-path: inset(0% 0% 2rem 0%);
        min-height: calc(100vh - 5rem);
        margin-bottom: -2rem;

        .content {
            position: absolute;
            position: relative;
            overflow: hidden;
            width: 100%;
            color: #fff;
            .title-text-and-button {
                display: flex;
                align-items: flex-end;
                margin-bottom: 1.96rem;
            }

            .news-labels {
                display: flex;
                flex-wrap: wrap;
                .label-item {
                    @include transparent-label(0 0, relative);
                    padding-right: 1rem;
                    padding-bottom: 1rem;
                }
            }

            h1 {
                margin-bottom: 0;
            }
            p {
                text-transform: uppercase;
            }
            .carousel {
                width: 100%;
                margin-top: 0;
                min-height: calc(100vh - 5rem);

                /* content inside the carousel, text and labels */
                .carousel-inner {
                    min-height: calc(100vh - 5rem);
                    max-height: calc(100vh - 5rem);

                    .carousel-item {
                        min-height: calc(100vh - 5rem);
                        max-height: calc(100vh - 5rem);

                        display: flex;
                        background-color: $primary;
                        .background-basic {
                            position: relative;
                            width: 100%;
                            margin-bottom: -2rem;

                            img.background-image {
                                width: 130%;
                                margin-left: -15%;
                                min-height: calc(100vh -5rem);
                                max-height: calc(100vh -5rem);

                                object-fit: cover;
                            }
                            overflow: hidden;
                        }
                        .carousel-caption {
                            text-align: start;

                            padding-right: 0;
                            padding-left: 0;
                            padding-bottom: 5.625rem;
                            padding-bottom: 4.58125rem;
                            bottom: 0;
                            min-width: 100%;
                            position: absolute;
                            overflow: hidden;
                            left: 0;
                            .background-blurred {
                                position: absolute;
                                bottom: 0;
                                width: 100%;
                                height: 100%;
                                margin-bottom: -2rem;
                                img {
                                    filter: blur(20px);
                                    width: 130%;
                                    height: calc(100vh -5rem);
                                    margin-left: -15%;
                                    position: absolute;
                                    bottom: 0;
                                }
                                overflow: hidden;
                            }
                            p.date-content {
                                padding-top: 2.18125rem;
                            }

                            h1,
                            .caption-title {
                                display: block;
                                font-size: 3.25rem;
                                font-weight: 700;
                                text-shadow: 0px 0px 8px rgba(0, 0, 0, 0.2);
                                margin-bottom: 2rem;
                                line-height: 3.75rem;
                                overflow-wrap: break-word;
                                color: $secondary;
                                @include media-breakpoint-down(sm) {
                                    margin-bottom: 0;
                                    font-size: 2.25rem;
                                    line-height: 2.5rem;
                                }
                            }
                            .news-labels {
                                display: flex;
                                flex-wrap: wrap;
                                .label-item {
                                    @include transparent-label(0 0, relative);
                                    padding-right: 1rem;
                                    padding-bottom: 1rem;
                                }
                            }
                        }
                        .background-image {
                            object-fit: cover;
                            min-width: 130%;
                        }
                    }
                }
                /* 4 tabs in the bottom of the carousel */
                .carousel-indicators {
                    padding-bottom: 1.96875rem;
                    margin: 0;
                    position: relative;
                    margin-top: -3.78125rem;

                    .col-md-3 {
                        &:nth-child(5) {
                            .indicator {
                                border-right: none;
                            }
                        }
                    }

                    // .col-6 {
                    //     padding: 0;
                    //     &:nth-child(1),
                    //     &:nth-child(3) {
                    //         padding-left: calc(var(--bs-gutter-x) / 2);
                    //     }
                    //     &:nth-child(2),
                    //     &:nth-child(4) {
                    //         padding-right: calc(var(--bs-gutter-x) / 2);
                    //     }
                    //     @include media-breakpoint-up(md) {
                    //         &:nth-child(2),
                    //         &:nth-child(3) {
                    //             padding: 0;
                    //         }
                    //     }
                    // }
                    @include media-breakpoint-up(md) {
                        button.indicator {
                            width: 100%;
                            height: 100%;
                            border: none;
                            border-right: 1px solid $dark;
                            background-color: $primary;
                            color: $dark;
                            display: flex;
                            padding: 1rem;
                            padding-top: 1.1875rem;
                            padding-bottom: 1.5625rem;
                            min-height: 6.8125rem;
                            position: relative;
                            transition: all 0.5s ease-out;
                            transition-delay: 0.1s;
                            text-align: start;

                            &:before {
                                content: "";
                                position: absolute;
                                top: 0;
                                bottom: 0;
                                left: 0;
                                width: 0%;
                                height: 100%;
                                border-bottom: 0.25rem solid $primary;
                                transition: all 0.5s ease-out;
                            }
                            &:hover {
                                background-color: $dark;
                                color: $primary;
                                &:before {
                                    width: 100%;
                                }
                            }
                        }

                        .indicator.active {
                            background-color: $dark;
                            color: $primary;
                            @include media-breakpoint-up(md) {
                                &:before {
                                    width: 100%;
                                    border-bottom: 0.25rem solid $primary;
                                }
                            }
                        }
                    }
                    .carousel-control-prev,
                    .carousel-control-next {
                        position: relative;
                        width: auto;
                        background-color: transparent;
                        opacity: 1;
                        border: none;
                        padding: 1rem;
                        .nav-arrow {
                            height: 1rem;
                            width: auto;
                        }
                        svg {
                            path,
                            line {
                                stroke: $secondary;
                                transition: all 0.2s ease-out;
                            }
                            -webkit-filter: drop-shadow(
                                0px 0px 7px rgba(13, 22, 37, 0.5)
                            );
                            filter: drop-shadow(
                                0px 0px 7px rgba(13, 22, 37, 0.5)
                            );
                        }
                        &:hover {
                            svg {
                                path,
                                line {
                                    stroke: $warning;
                                }
                            }
                        }
                        @include media-breakpoint-up(md) {
                            display: none;
                        }
                    }
                    .carousel-control-next {
                        margin-left: 1.6875rem;
                    }

                    @include media-breakpoint-down(md) {
                        button.indicator {
                            @include carousel-indicator();
                            span {
                                display: none;
                            }
                        }

                        .indicator.active {
                            background-color: $warning;
                        }
                    }
                }
                @include media-breakpoint-up(lg) {
                    min-height: calc(100vh - 5rem);
                    .carousel-inner {
                        min-height: calc(100vh - 5rem);
                        max-height: calc(100vh - 5rem);
                        .carousel-item {
                            min-height: calc(100vh - 5rem);
                            max-height: calc(100vh - 5rem);
                        }
                    }
                }
                @include media-breakpoint-down(md) {
                    .mobile-display-indicators {
                        align-items: center;
                        justify-content: center;
                        .col-md-3 {
                            display: flex;
                            align-items: center;
                        }
                    }
                    .carousel-inner {
                        min-height: calc(100vh-5rem);
                        min-height: calc(var(--vh, 1vh) * 100 -5rem);

                        .carousel-item {
                            min-height: calc(100vh-5rem);
                            min-height: calc(var(--vh, 1vh) * 100-5rem);

                            .background-basic {
                                img.background-image {
                                    min-height: calc(100vh -5rem);
                                    max-height: calc(100vh -5rem);
                                }
                            }
                        }
                    }
                    .carousel-indicators {
                        padding-bottom: 2.969rem;
                    }
                }

                @include media-breakpoint-down(sm) {
                   
                    .carousel-inner {
                        min-height: calc(100vh-5rem);
                        min-height: calc(var(--vh, 1vh) * 100 -5rem);

                        .carousel-item {
                            min-height: calc(100vh-5rem);
                            min-height: calc(var(--vh, 1vh) * 100-5rem);

                            .background-basic {
                                img.background-image {
                                    min-height: calc(100vh -5rem);
                                    max-height: calc(100vh -5rem);
                                }
                            }
                        }
                    }
                    .carousel-indicators {
                        padding-bottom: 2.969rem;
                    }
                }
            }
        }
    }

    /* header with only breadcrumb and/or title/text */
    .text-header {
        margin-top: 4.4rem;
        background-color: $dark;
        text-align: center;
        display: flex;
        flex-direction: column;
        &.light-background-with-margin {
            background-color: $secondary;
            position: relative;
            #animation-certificates-one {
                @include animation-background(
                    url("/static/images/icons/triangle-grey-small-left-bottom.svg"),
                    left,
                    top
                );
                @include animation-container-element(236.7px, calc(-4rem), -5%);
            }
            #animation-certificates-two {
                @include animation-background(
                    url("/static/images/icons/triangle-grey-small-left-top.svg"),
                    left,
                    top
                );
                @include animation-container-element(
                    236.7px,
                    calc(-4rem + 236.7px),
                    calc(-5% + 236.7px)
                );
            }
        }

        &.full-height {
            min-height: calc(100vh - 4.4rem);
        }

        .title {
            flex: 1;

            h1 {
                margin-bottom: 2.5rem;
            }
            span {
                display: inline-block;
                margin-bottom: 4.4rem;
                vertical-align: middle;
                &:last-of-type {
                    padding-left: 0.8rem;
                    opacity: 0.36;
                    text-transform: uppercase;
                }
            }
        }
        .introduction-paragraph {
            background-color: $secondary;
            text-align: start;
            a.button-basic {
                display: block;
                width: max-content;
                margin-top: -1.5rem;
                margin-left: auto;
                margin-right: auto;
                margin-bottom: 5.3125rem;
            }
            p {
                margin-bottom: 2.75rem;
            }
        }
        &.news-item-header {
            .step-back-link {
                padding-top: 2.6875rem;
                padding-bottom: 2.0625rem;
            }
        }
    }
}

.project_list {
    color: blue;
}

.menu_open {
    .project_list {
        color: red;
    }
}
