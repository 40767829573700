.main-job {
    position: relative;
    overflow: hidden;
    #animation-job-item-one {
        @include animation-background(
            url("/static/images/icons/triangle-grey-left-bottom.svg"),
            left,
            top
        );
        @include animation-container-element(542px, 300px, -10%);
    }
    #animation-job-item-two {
        @include animation-background(
            url("/static/images/icons/triangle-grey-left-top.svg"),
            left,
            top
        );
        @include animation-container-element(542px, 571px, calc(-10% +271px));
    }
    #animation-job-item-three {
        @include animation-background(
            url("/static/images/icons/triangle-grey-left-bottom.svg"),
            right,
            bottom
        );
        @include animation-container-element(271px, unset, -271px);
        bottom: 500px;
    }
    #animation-job-item-four {
        @include animation-background(
            url("/static/images/icons/triangle-grey-left-top.svg"),
            right,
            bottom
        );
        @include animation-container-element(271px, unset, 0);
        bottom: 229px;
    }
    .job-description {
        margin-top: 2.75rem;
        margin-bottom: 5.6125rem;
        h5 {
            margin-bottom: 1.4375rem;
            font-weight: 700;
        }
        p {
            margin-bottom: 1.7rem;
        }
        .list-with-background {
            background-color: $dark;
            padding: 2.25rem 2.625rem;
            margin-bottom: 3.75rem;
            ul {
                @include list(0, $line-height-big, 2rem);
                margin-bottom: 0;
            }
        }
        ul {
            @include list(0, $line-height-big, 1.25rem);
            margin-bottom: 3.75rem;
        }
    }
}
.apply-for-job {
    background-color: $primary;
    color: $secondary;
    padding-top: 4.875rem;
    padding-bottom: 6.75rem;
    margin-bottom: 6rem;
    h5 {
        margin-bottom: 1.4375rem;
        font-weight: 700;
    }
    .apply-form {
        @include form($secondary, $primary, $secondary, 0, auto);
        margin-top: 3.125rem;
        p {
            padding-left: 1rem;
            margin-bottom: 1.25rem;
        }
        h3 {
            padding-left: 1rem;
            font-weight: 700;
            margin-bottom: 0;
            line-height: $line-height-big;
        }
        a.button-basic {
            margin-bottom: 2.4375rem;
            &:last-of-type {
                margin-bottom: 0;
            }
        }

        .messages {
            margin-top: -1.75rem;
            height: 1.75rem;
            p.help-block {
                height: 100%;
                font-weight: 500;
                font-size: x-small;
                line-height: 1rem;
                &.error {
                    color: $white;
                }
            }
            &.checkbox {
                margin-top: 0;

            }
        }
    }
    .hidden {
        display: none;
    }
}

.more-vacancies {
    margin-bottom: 0;
    h5 {
        font-weight: 700;
        margin-bottom: 2.625rem;
    }
    .vacancy-item {
        &:last-of-type {
            margin-bottom: 3.5625rem;
        }
    }
    a.button-basic.dark.forward {
        margin-bottom: 4.75rem;
    }

}


