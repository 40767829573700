/* main content for about */
main.about-page {
    position: relative;
    // background-color: grey;
    overflow: hidden;
    .flag-icon {
        text-align: center;
        margin-top: 12rem;
        margin-bottom: -1px;
    }
    #animation-about-one {
        @include animation-background(
            url("/static/images/icons/triangle-grey-top.svg"),
            left,
            top
        );
        @include animation-container-element(575px, 800px, 5%);
    }
    #animation-about-two {
        @include animation-background(
            url("/static/images/icons/triangle-grey-left.svg"),
            left,
            top
        );
        @include animation-container-element(384px, 992px, calc(5% + 191px));
    }
    #animation-about-three {
        @include animation-background(
            url("/static/images/icons/triangle-grey-right.svg"),
            right,
            top
        );
        @include animation-container-element(384px, 3000px, -5%);
    }
    #animation-about-four {
        @include animation-background(
            url("/static/images/icons/triangle-grey-top.svg"),
            right,
            top
        );
        @include animation-container-element(
            384px,
            3191.5px,
            calc(-5% - 192px)
        );
    }
    .content-about {
        position: relative;
        padding-top: 6.375rem;
        z-index: 2;
        .image-and-article {
            position: relative;
            padding-bottom: 13rem;
            img {
                object-fit: cover;
                z-index: 2;
            }
            p {
                font-size: 1.25rem;
                line-height: $line-height-big;
                margin-bottom: 0;
                position: relative;
                z-index: 2;
                &:first-of-type {
                    color: $triple-tone;
                }
            }

            h3 {
                font-weight: 700;
                margin-bottom: 0;
                line-height: $line-height-big;
            }
        }

        .subject-promotion {
            position: relative;
            z-index: 2;
            padding-bottom: 13rem;
            .subject-background {
                background-size: cover;
                width: 100%;
                min-height: calc((100vw) / 1.7);
                max-height: auto;

                .subject-content {
                    padding: 2rem 3.125rem;
                    @include gradient-solid-color-top(80%);
                    color: $secondary;
                }
            }
        }

        .vertical-line {
            position: absolute;
            top: 0;
            height: 100%;
            left: calc(50% - 4px);
            .line-blue {
                display: block;
                position: absolute;
                height: 100%;
                width: 1px;
                background-color: $primary;
                opacity: 1;
                left: 0;
            }
        }
    }
    .dotted-vertical {
        position: relative;
        height: 9.5rem;

        .dotted-line {
            position: absolute;
            top: 0;
            left: calc(50% - 4px);
            background-image: linear-gradient(
                $quarter-tone 50%,
                rgba(255, 255, 255, 0) 0%
            );

            background-size: 1px 10px;
            height: 100%;
            width: 1px;
            background-position: top;
        }
    }
    .item-box {
        display: flex;
        flex-direction: column;
        background-color: $primary;
        background-size: cover;

        background-position: top;
        margin-top: 5.53125rem;
        margin-bottom: 1.4rem;

        @include basis-zooming-usage-before();

        h2 {
            background-color: $primary;
            color: $secondary;
            padding: 1.375rem 2.3125rem;
            margin-bottom: 0;
            &:after {
                content: url("/static/images/icons/arrow-light.svg");
                padding-left: 0.5rem;
            }
        }
        .triangle {
            @include gradient(100%);
            display: flex;
            align-items: flex-end;
            justify-content: flex-end;
            background-position: bottom;
            flex: 1;
        }
        &:hover {
            &::before {
                transform: scale(1.1);
            }
        }
    }
    @include media-breakpoint-down(md) {
        .content-about {
            .subject-promotion {
                .subject-background {
                    .subject-content {
                        a.button-basic.light.forward {
                            margin-top: 2rem;
                        }
                        .button-basic.light {
                            &:hover {
                                border: 1px solid $primary;
                            }
                        }
                    }
                }
            }
        }
    }
    @include media-breakpoint-down(sm) {
        .item-box {
            &:last-of-type {
                margin-top: 0;
            }
        }
        .content-about {
            .subject-promotion {
                .subject-background {
                    .subject-content {
                        padding: 2rem 2rem;
                    }
                }
            }
            .image-and-article {
                position: relative;
                z-index: 5;
                p,
                h3 {
                    background-color: $secondary;
                }
            }
        }
    }
}
