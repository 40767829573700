/* dark blue */
/* dark blue version 2 */
/* white */
/* light blue */
/* broken white */
/* light grey */
/* red */
/* grey */
/* mid grey */
/* mid grey 2 */
/* mid grey 3 */
/* mid grey 4 */
/* mid grey 5 */
@import url("https://fonts.googleapis.com/css2?family=Manrope:wght@200;300;400;500;600;700;800&display=swap");
:root {
  --bs-blue: #0d6efd;
  --bs-indigo: #6610f2;
  --bs-purple: #6f42c1;
  --bs-pink: #d63384;
  --bs-red: #dc3545;
  --bs-orange: #fd7e14;
  --bs-yellow: #ffc107;
  --bs-green: #198754;
  --bs-teal: #20c997;
  --bs-cyan: #0dcaf0;
  --bs-white: #fff;
  --bs-gray: #6c757d;
  --bs-gray-dark: #343a40;
  --bs-primary: #0d6efd;
  --bs-secondary: #6c757d;
  --bs-success: #198754;
  --bs-info: #0dcaf0;
  --bs-warning: #ffc107;
  --bs-danger: #dc3545;
  --bs-light: #f8f9fa;
  --bs-dark: #343a40;
  --bs-font-sans-serif: system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  --bs-font-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  --bs-gradient: linear-gradient(180deg, rgba(255, 255, 255, 0.15), rgba(255, 255, 255, 0)); }

*,
*::before,
*::after {
  box-sizing: border-box; }

body {
  margin: 0;
  font-family: var(--bs-font-sans-serif);
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  background-color: #fff;
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0); }

[tabindex="-1"]:focus:not(:focus-visible) {
  outline: 0 !important; }

hr {
  margin: 1rem 0;
  color: inherit;
  background-color: currentColor;
  border: 0;
  opacity: 0.25; }

hr:not([size]) {
  height: 1px; }

h1, .h1, h2, .h2, h3, .h3, h4, .h4, h5, .h5, h6, .h6 {
  margin-top: 0;
  margin-bottom: 0.5rem;
  font-weight: 500;
  line-height: 1.2; }

h1, .h1 {
  font-size: calc(1.375rem + 1.5vw); }
  @media (min-width: 1200px) {
    h1, .h1 {
      font-size: 2.5rem; } }

h2, .h2 {
  font-size: calc(1.325rem + 0.9vw); }
  @media (min-width: 1200px) {
    h2, .h2 {
      font-size: 2rem; } }

h3, .h3 {
  font-size: calc(1.3rem + 0.6vw); }
  @media (min-width: 1200px) {
    h3, .h3 {
      font-size: 1.75rem; } }

h4, .h4 {
  font-size: calc(1.275rem + 0.3vw); }
  @media (min-width: 1200px) {
    h4, .h4 {
      font-size: 1.5rem; } }

h5, .h5 {
  font-size: 1.25rem; }

h6, .h6 {
  font-size: 1rem; }

p {
  margin-top: 0;
  margin-bottom: 1rem; }

abbr[title],
abbr[data-original-title] {
  text-decoration: underline;
  text-decoration: underline dotted;
  cursor: help;
  text-decoration-skip-ink: none; }

address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit; }

ol,
ul {
  padding-left: 2rem; }

ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1rem; }

ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0; }

dt {
  font-weight: 700; }

dd {
  margin-bottom: .5rem;
  margin-left: 0; }

blockquote {
  margin: 0 0 1rem; }

b,
strong {
  font-weight: bolder; }

small, .small {
  font-size: 0.875em; }

mark, .mark {
  padding: 0.2em;
  background-color: #fcf8e3; }

sub,
sup {
  position: relative;
  font-size: 0.75em;
  line-height: 0;
  vertical-align: baseline; }

sub {
  bottom: -.25em; }

sup {
  top: -.5em; }

a {
  color: #0d6efd;
  text-decoration: underline; }
  a:hover {
    color: #024dbc; }

a:not([href]):not([class]), a:not([href]):not([class]):hover {
  color: inherit;
  text-decoration: none; }

pre,
code,
kbd,
samp {
  font-family: var(--bs-font-monospace);
  font-size: 1em; }

pre {
  display: block;
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto;
  font-size: 0.875em;
  -ms-overflow-style: scrollbar; }
  pre code {
    font-size: inherit;
    color: inherit;
    word-break: normal; }

code {
  font-size: 0.875em;
  color: #d63384;
  word-wrap: break-word; }
  a > code {
    color: inherit; }

kbd {
  padding: 0.2rem 0.4rem;
  font-size: 0.875em;
  color: #fff;
  background-color: #212529;
  border-radius: 0.2rem; }
  kbd kbd {
    padding: 0;
    font-size: 1em;
    font-weight: 700; }

figure {
  margin: 0 0 1rem; }

img,
svg {
  vertical-align: middle; }

table {
  caption-side: bottom;
  border-collapse: collapse; }

caption {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  color: #6c757d;
  text-align: left; }

th {
  text-align: inherit;
  text-align: -webkit-match-parent; }

thead,
tbody,
tfoot,
tr,
td,
th {
  border-color: inherit;
  border-style: solid;
  border-width: 0; }

label {
  display: inline-block; }

button {
  border-radius: 0; }

button:focus {
  outline: 1px dotted;
  outline: 5px auto -webkit-focus-ring-color; }

input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit; }

button,
input {
  overflow: visible; }

button,
select {
  text-transform: none; }

[role="button"] {
  cursor: pointer; }

select {
  word-wrap: normal; }

[list]::-webkit-calendar-picker-indicator {
  display: none; }

button,
[type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button; }
  button:not(:disabled),
  [type="button"]:not(:disabled),
  [type="reset"]:not(:disabled),
  [type="submit"]:not(:disabled) {
    cursor: pointer; }

::-moz-focus-inner {
  padding: 0;
  border-style: none; }

textarea {
  resize: vertical; }

fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0; }

legend {
  float: left;
  width: 100%;
  padding: 0;
  margin-bottom: 0.5rem;
  font-size: calc(1.275rem + 0.3vw);
  line-height: inherit;
  white-space: normal; }
  @media (min-width: 1200px) {
    legend {
      font-size: 1.5rem; } }
  legend + * {
    clear: left; }

::-webkit-datetime-edit-fields-wrapper,
::-webkit-datetime-edit-text,
::-webkit-datetime-edit-minute,
::-webkit-datetime-edit-hour-field,
::-webkit-datetime-edit-day-field,
::-webkit-datetime-edit-month-field,
::-webkit-datetime-edit-year-field {
  padding: 0; }

::-webkit-inner-spin-button {
  height: auto; }

[type="search"] {
  outline-offset: -2px;
  -webkit-appearance: textfield; }

::-webkit-search-decoration {
  -webkit-appearance: none; }

::-webkit-color-swatch-wrapper {
  padding: 0; }

::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button; }

output {
  display: inline-block; }

iframe {
  border: 0; }

summary {
  display: list-item;
  cursor: pointer; }

progress {
  vertical-align: baseline; }

[hidden] {
  display: none !important; }

.lead {
  font-size: 1.25rem;
  font-weight: 300; }

.display-1 {
  font-size: calc(1.625rem + 4.5vw);
  font-weight: 300;
  line-height: 1.2; }
  @media (min-width: 1200px) {
    .display-1 {
      font-size: 5rem; } }

.display-2 {
  font-size: calc(1.575rem + 3.9vw);
  font-weight: 300;
  line-height: 1.2; }
  @media (min-width: 1200px) {
    .display-2 {
      font-size: 4.5rem; } }

.display-3 {
  font-size: calc(1.525rem + 3.3vw);
  font-weight: 300;
  line-height: 1.2; }
  @media (min-width: 1200px) {
    .display-3 {
      font-size: 4rem; } }

.display-4 {
  font-size: calc(1.475rem + 2.7vw);
  font-weight: 300;
  line-height: 1.2; }
  @media (min-width: 1200px) {
    .display-4 {
      font-size: 3.5rem; } }

.display-5 {
  font-size: calc(1.425rem + 2.1vw);
  font-weight: 300;
  line-height: 1.2; }
  @media (min-width: 1200px) {
    .display-5 {
      font-size: 3rem; } }

.display-6 {
  font-size: calc(1.375rem + 1.5vw);
  font-weight: 300;
  line-height: 1.2; }
  @media (min-width: 1200px) {
    .display-6 {
      font-size: 2.5rem; } }

.list-unstyled {
  padding-left: 0;
  list-style: none; }

.list-inline {
  padding-left: 0;
  list-style: none; }

.list-inline-item {
  display: inline-block; }
  .list-inline-item:not(:last-child) {
    margin-right: 0.5rem; }

.initialism {
  font-size: 0.875em;
  text-transform: uppercase; }

.blockquote {
  margin-bottom: 1rem;
  font-size: 1.25rem; }
  .blockquote > :last-child {
    margin-bottom: 0; }

.blockquote-footer {
  margin-top: -1rem;
  margin-bottom: 1rem;
  font-size: 0.875em;
  color: #6c757d; }
  .blockquote-footer::before {
    content: "\2014\00A0"; }

.img-fluid {
  max-width: 100%;
  height: auto; }

.img-thumbnail {
  padding: 0.25rem;
  background-color: #fff;
  border: 1px solid #dee2e6;
  border-radius: 0.25rem;
  max-width: 100%;
  height: auto; }

.figure {
  display: inline-block; }

.figure-img {
  margin-bottom: 0.5rem;
  line-height: 1; }

.figure-caption {
  font-size: 0.875em;
  color: #6c757d; }

.container,
.container-fluid,
.container-sm,
.container-md,
.container-lg,
.container-xl,
.container-xxl {
  --bs-gutter-x: 1.5rem;
  width: 100%;
  padding-right: calc(var(--bs-gutter-x) / 2);
  padding-left: calc(var(--bs-gutter-x) / 2);
  margin-right: auto;
  margin-left: auto; }

@media (min-width: 576px) {
  .container, .container-sm {
    max-width: 540px; } }

@media (min-width: 768px) {
  .container, .container-sm, .container-md {
    max-width: 720px; } }

@media (min-width: 992px) {
  .container, .container-sm, .container-md, .container-lg {
    max-width: 960px; } }

@media (min-width: 1200px) {
  .container, .container-sm, .container-md, .container-lg, .container-xl {
    max-width: 1140px; } }

@media (min-width: 1400px) {
  .container, .container-sm, .container-md, .container-lg, .container-xl, .container-xxl {
    max-width: 1320px; } }

.row {
  --bs-gutter-x: 1.5rem;
  --bs-gutter-y: 0;
  display: flex;
  flex-wrap: wrap;
  margin-top: calc(var(--bs-gutter-y) * -1);
  margin-right: calc(var(--bs-gutter-x) / -2);
  margin-left: calc(var(--bs-gutter-x) / -2); }
  .row > * {
    flex-shrink: 0;
    width: 100%;
    max-width: 100%;
    padding-right: calc(var(--bs-gutter-x) / 2);
    padding-left: calc(var(--bs-gutter-x) / 2);
    margin-top: var(--bs-gutter-y); }

.col {
  flex: 1 0 0%; }

.row-cols-auto > * {
  flex: 0 0 auto;
  width: auto; }

.row-cols-1 > * {
  flex: 0 0 auto;
  width: 100%; }

.row-cols-2 > * {
  flex: 0 0 auto;
  width: 50%; }

.row-cols-3 > * {
  flex: 0 0 auto;
  width: 33.33333%; }

.row-cols-4 > * {
  flex: 0 0 auto;
  width: 25%; }

.row-cols-5 > * {
  flex: 0 0 auto;
  width: 20%; }

.row-cols-6 > * {
  flex: 0 0 auto;
  width: 16.66667%; }

.col-auto {
  flex: 0 0 auto;
  width: auto; }

.col-1 {
  flex: 0 0 auto;
  width: 8.33333%; }

.col-2 {
  flex: 0 0 auto;
  width: 16.66667%; }

.col-3 {
  flex: 0 0 auto;
  width: 25%; }

.col-4 {
  flex: 0 0 auto;
  width: 33.33333%; }

.col-5 {
  flex: 0 0 auto;
  width: 41.66667%; }

.col-6 {
  flex: 0 0 auto;
  width: 50%; }

.col-7 {
  flex: 0 0 auto;
  width: 58.33333%; }

.col-8 {
  flex: 0 0 auto;
  width: 66.66667%; }

.col-9 {
  flex: 0 0 auto;
  width: 75%; }

.col-10 {
  flex: 0 0 auto;
  width: 83.33333%; }

.col-11 {
  flex: 0 0 auto;
  width: 91.66667%; }

.col-12 {
  flex: 0 0 auto;
  width: 100%; }

.offset-1 {
  margin-left: 8.33333%; }

.offset-2 {
  margin-left: 16.66667%; }

.offset-3 {
  margin-left: 25%; }

.offset-4 {
  margin-left: 33.33333%; }

.offset-5 {
  margin-left: 41.66667%; }

.offset-6 {
  margin-left: 50%; }

.offset-7 {
  margin-left: 58.33333%; }

.offset-8 {
  margin-left: 66.66667%; }

.offset-9 {
  margin-left: 75%; }

.offset-10 {
  margin-left: 83.33333%; }

.offset-11 {
  margin-left: 91.66667%; }

.g-0,
.gx-0 {
  --bs-gutter-x: 0; }

.g-0,
.gy-0 {
  --bs-gutter-y: 0; }

.g-1,
.gx-1 {
  --bs-gutter-x: 0.25rem; }

.g-1,
.gy-1 {
  --bs-gutter-y: 0.25rem; }

.g-2,
.gx-2 {
  --bs-gutter-x: 0.5rem; }

.g-2,
.gy-2 {
  --bs-gutter-y: 0.5rem; }

.g-3,
.gx-3 {
  --bs-gutter-x: 1rem; }

.g-3,
.gy-3 {
  --bs-gutter-y: 1rem; }

.g-4,
.gx-4 {
  --bs-gutter-x: 1.5rem; }

.g-4,
.gy-4 {
  --bs-gutter-y: 1.5rem; }

.g-5,
.gx-5 {
  --bs-gutter-x: 4.75rem; }

.g-5,
.gy-5 {
  --bs-gutter-y: 4.75rem; }

@media (min-width: 576px) {
  .col-sm {
    flex: 1 0 0%; }
  .row-cols-sm-auto > * {
    flex: 0 0 auto;
    width: auto; }
  .row-cols-sm-1 > * {
    flex: 0 0 auto;
    width: 100%; }
  .row-cols-sm-2 > * {
    flex: 0 0 auto;
    width: 50%; }
  .row-cols-sm-3 > * {
    flex: 0 0 auto;
    width: 33.33333%; }
  .row-cols-sm-4 > * {
    flex: 0 0 auto;
    width: 25%; }
  .row-cols-sm-5 > * {
    flex: 0 0 auto;
    width: 20%; }
  .row-cols-sm-6 > * {
    flex: 0 0 auto;
    width: 16.66667%; }
  .col-sm-auto {
    flex: 0 0 auto;
    width: auto; }
  .col-sm-1 {
    flex: 0 0 auto;
    width: 8.33333%; }
  .col-sm-2 {
    flex: 0 0 auto;
    width: 16.66667%; }
  .col-sm-3 {
    flex: 0 0 auto;
    width: 25%; }
  .col-sm-4 {
    flex: 0 0 auto;
    width: 33.33333%; }
  .col-sm-5 {
    flex: 0 0 auto;
    width: 41.66667%; }
  .col-sm-6 {
    flex: 0 0 auto;
    width: 50%; }
  .col-sm-7 {
    flex: 0 0 auto;
    width: 58.33333%; }
  .col-sm-8 {
    flex: 0 0 auto;
    width: 66.66667%; }
  .col-sm-9 {
    flex: 0 0 auto;
    width: 75%; }
  .col-sm-10 {
    flex: 0 0 auto;
    width: 83.33333%; }
  .col-sm-11 {
    flex: 0 0 auto;
    width: 91.66667%; }
  .col-sm-12 {
    flex: 0 0 auto;
    width: 100%; }
  .offset-sm-0 {
    margin-left: 0; }
  .offset-sm-1 {
    margin-left: 8.33333%; }
  .offset-sm-2 {
    margin-left: 16.66667%; }
  .offset-sm-3 {
    margin-left: 25%; }
  .offset-sm-4 {
    margin-left: 33.33333%; }
  .offset-sm-5 {
    margin-left: 41.66667%; }
  .offset-sm-6 {
    margin-left: 50%; }
  .offset-sm-7 {
    margin-left: 58.33333%; }
  .offset-sm-8 {
    margin-left: 66.66667%; }
  .offset-sm-9 {
    margin-left: 75%; }
  .offset-sm-10 {
    margin-left: 83.33333%; }
  .offset-sm-11 {
    margin-left: 91.66667%; }
  .g-sm-0,
  .gx-sm-0 {
    --bs-gutter-x: 0; }
  .g-sm-0,
  .gy-sm-0 {
    --bs-gutter-y: 0; }
  .g-sm-1,
  .gx-sm-1 {
    --bs-gutter-x: 0.25rem; }
  .g-sm-1,
  .gy-sm-1 {
    --bs-gutter-y: 0.25rem; }
  .g-sm-2,
  .gx-sm-2 {
    --bs-gutter-x: 0.5rem; }
  .g-sm-2,
  .gy-sm-2 {
    --bs-gutter-y: 0.5rem; }
  .g-sm-3,
  .gx-sm-3 {
    --bs-gutter-x: 1rem; }
  .g-sm-3,
  .gy-sm-3 {
    --bs-gutter-y: 1rem; }
  .g-sm-4,
  .gx-sm-4 {
    --bs-gutter-x: 1.5rem; }
  .g-sm-4,
  .gy-sm-4 {
    --bs-gutter-y: 1.5rem; }
  .g-sm-5,
  .gx-sm-5 {
    --bs-gutter-x: 4.75rem; }
  .g-sm-5,
  .gy-sm-5 {
    --bs-gutter-y: 4.75rem; } }

@media (min-width: 768px) {
  .col-md {
    flex: 1 0 0%; }
  .row-cols-md-auto > * {
    flex: 0 0 auto;
    width: auto; }
  .row-cols-md-1 > * {
    flex: 0 0 auto;
    width: 100%; }
  .row-cols-md-2 > * {
    flex: 0 0 auto;
    width: 50%; }
  .row-cols-md-3 > * {
    flex: 0 0 auto;
    width: 33.33333%; }
  .row-cols-md-4 > * {
    flex: 0 0 auto;
    width: 25%; }
  .row-cols-md-5 > * {
    flex: 0 0 auto;
    width: 20%; }
  .row-cols-md-6 > * {
    flex: 0 0 auto;
    width: 16.66667%; }
  .col-md-auto {
    flex: 0 0 auto;
    width: auto; }
  .col-md-1 {
    flex: 0 0 auto;
    width: 8.33333%; }
  .col-md-2 {
    flex: 0 0 auto;
    width: 16.66667%; }
  .col-md-3 {
    flex: 0 0 auto;
    width: 25%; }
  .col-md-4 {
    flex: 0 0 auto;
    width: 33.33333%; }
  .col-md-5 {
    flex: 0 0 auto;
    width: 41.66667%; }
  .col-md-6 {
    flex: 0 0 auto;
    width: 50%; }
  .col-md-7 {
    flex: 0 0 auto;
    width: 58.33333%; }
  .col-md-8 {
    flex: 0 0 auto;
    width: 66.66667%; }
  .col-md-9 {
    flex: 0 0 auto;
    width: 75%; }
  .col-md-10 {
    flex: 0 0 auto;
    width: 83.33333%; }
  .col-md-11 {
    flex: 0 0 auto;
    width: 91.66667%; }
  .col-md-12 {
    flex: 0 0 auto;
    width: 100%; }
  .offset-md-0 {
    margin-left: 0; }
  .offset-md-1 {
    margin-left: 8.33333%; }
  .offset-md-2 {
    margin-left: 16.66667%; }
  .offset-md-3 {
    margin-left: 25%; }
  .offset-md-4 {
    margin-left: 33.33333%; }
  .offset-md-5 {
    margin-left: 41.66667%; }
  .offset-md-6 {
    margin-left: 50%; }
  .offset-md-7 {
    margin-left: 58.33333%; }
  .offset-md-8 {
    margin-left: 66.66667%; }
  .offset-md-9 {
    margin-left: 75%; }
  .offset-md-10 {
    margin-left: 83.33333%; }
  .offset-md-11 {
    margin-left: 91.66667%; }
  .g-md-0,
  .gx-md-0 {
    --bs-gutter-x: 0; }
  .g-md-0,
  .gy-md-0 {
    --bs-gutter-y: 0; }
  .g-md-1,
  .gx-md-1 {
    --bs-gutter-x: 0.25rem; }
  .g-md-1,
  .gy-md-1 {
    --bs-gutter-y: 0.25rem; }
  .g-md-2,
  .gx-md-2 {
    --bs-gutter-x: 0.5rem; }
  .g-md-2,
  .gy-md-2 {
    --bs-gutter-y: 0.5rem; }
  .g-md-3,
  .gx-md-3 {
    --bs-gutter-x: 1rem; }
  .g-md-3,
  .gy-md-3 {
    --bs-gutter-y: 1rem; }
  .g-md-4,
  .gx-md-4 {
    --bs-gutter-x: 1.5rem; }
  .g-md-4,
  .gy-md-4 {
    --bs-gutter-y: 1.5rem; }
  .g-md-5,
  .gx-md-5 {
    --bs-gutter-x: 4.75rem; }
  .g-md-5,
  .gy-md-5 {
    --bs-gutter-y: 4.75rem; } }

@media (min-width: 992px) {
  .col-lg {
    flex: 1 0 0%; }
  .row-cols-lg-auto > * {
    flex: 0 0 auto;
    width: auto; }
  .row-cols-lg-1 > * {
    flex: 0 0 auto;
    width: 100%; }
  .row-cols-lg-2 > * {
    flex: 0 0 auto;
    width: 50%; }
  .row-cols-lg-3 > * {
    flex: 0 0 auto;
    width: 33.33333%; }
  .row-cols-lg-4 > * {
    flex: 0 0 auto;
    width: 25%; }
  .row-cols-lg-5 > * {
    flex: 0 0 auto;
    width: 20%; }
  .row-cols-lg-6 > * {
    flex: 0 0 auto;
    width: 16.66667%; }
  .col-lg-auto {
    flex: 0 0 auto;
    width: auto; }
  .col-lg-1 {
    flex: 0 0 auto;
    width: 8.33333%; }
  .col-lg-2 {
    flex: 0 0 auto;
    width: 16.66667%; }
  .col-lg-3 {
    flex: 0 0 auto;
    width: 25%; }
  .col-lg-4 {
    flex: 0 0 auto;
    width: 33.33333%; }
  .col-lg-5 {
    flex: 0 0 auto;
    width: 41.66667%; }
  .col-lg-6 {
    flex: 0 0 auto;
    width: 50%; }
  .col-lg-7 {
    flex: 0 0 auto;
    width: 58.33333%; }
  .col-lg-8 {
    flex: 0 0 auto;
    width: 66.66667%; }
  .col-lg-9 {
    flex: 0 0 auto;
    width: 75%; }
  .col-lg-10 {
    flex: 0 0 auto;
    width: 83.33333%; }
  .col-lg-11 {
    flex: 0 0 auto;
    width: 91.66667%; }
  .col-lg-12 {
    flex: 0 0 auto;
    width: 100%; }
  .offset-lg-0 {
    margin-left: 0; }
  .offset-lg-1 {
    margin-left: 8.33333%; }
  .offset-lg-2 {
    margin-left: 16.66667%; }
  .offset-lg-3 {
    margin-left: 25%; }
  .offset-lg-4 {
    margin-left: 33.33333%; }
  .offset-lg-5 {
    margin-left: 41.66667%; }
  .offset-lg-6 {
    margin-left: 50%; }
  .offset-lg-7 {
    margin-left: 58.33333%; }
  .offset-lg-8 {
    margin-left: 66.66667%; }
  .offset-lg-9 {
    margin-left: 75%; }
  .offset-lg-10 {
    margin-left: 83.33333%; }
  .offset-lg-11 {
    margin-left: 91.66667%; }
  .g-lg-0,
  .gx-lg-0 {
    --bs-gutter-x: 0; }
  .g-lg-0,
  .gy-lg-0 {
    --bs-gutter-y: 0; }
  .g-lg-1,
  .gx-lg-1 {
    --bs-gutter-x: 0.25rem; }
  .g-lg-1,
  .gy-lg-1 {
    --bs-gutter-y: 0.25rem; }
  .g-lg-2,
  .gx-lg-2 {
    --bs-gutter-x: 0.5rem; }
  .g-lg-2,
  .gy-lg-2 {
    --bs-gutter-y: 0.5rem; }
  .g-lg-3,
  .gx-lg-3 {
    --bs-gutter-x: 1rem; }
  .g-lg-3,
  .gy-lg-3 {
    --bs-gutter-y: 1rem; }
  .g-lg-4,
  .gx-lg-4 {
    --bs-gutter-x: 1.5rem; }
  .g-lg-4,
  .gy-lg-4 {
    --bs-gutter-y: 1.5rem; }
  .g-lg-5,
  .gx-lg-5 {
    --bs-gutter-x: 4.75rem; }
  .g-lg-5,
  .gy-lg-5 {
    --bs-gutter-y: 4.75rem; } }

@media (min-width: 1200px) {
  .col-xl {
    flex: 1 0 0%; }
  .row-cols-xl-auto > * {
    flex: 0 0 auto;
    width: auto; }
  .row-cols-xl-1 > * {
    flex: 0 0 auto;
    width: 100%; }
  .row-cols-xl-2 > * {
    flex: 0 0 auto;
    width: 50%; }
  .row-cols-xl-3 > * {
    flex: 0 0 auto;
    width: 33.33333%; }
  .row-cols-xl-4 > * {
    flex: 0 0 auto;
    width: 25%; }
  .row-cols-xl-5 > * {
    flex: 0 0 auto;
    width: 20%; }
  .row-cols-xl-6 > * {
    flex: 0 0 auto;
    width: 16.66667%; }
  .col-xl-auto {
    flex: 0 0 auto;
    width: auto; }
  .col-xl-1 {
    flex: 0 0 auto;
    width: 8.33333%; }
  .col-xl-2 {
    flex: 0 0 auto;
    width: 16.66667%; }
  .col-xl-3 {
    flex: 0 0 auto;
    width: 25%; }
  .col-xl-4 {
    flex: 0 0 auto;
    width: 33.33333%; }
  .col-xl-5 {
    flex: 0 0 auto;
    width: 41.66667%; }
  .col-xl-6 {
    flex: 0 0 auto;
    width: 50%; }
  .col-xl-7 {
    flex: 0 0 auto;
    width: 58.33333%; }
  .col-xl-8 {
    flex: 0 0 auto;
    width: 66.66667%; }
  .col-xl-9 {
    flex: 0 0 auto;
    width: 75%; }
  .col-xl-10 {
    flex: 0 0 auto;
    width: 83.33333%; }
  .col-xl-11 {
    flex: 0 0 auto;
    width: 91.66667%; }
  .col-xl-12 {
    flex: 0 0 auto;
    width: 100%; }
  .offset-xl-0 {
    margin-left: 0; }
  .offset-xl-1 {
    margin-left: 8.33333%; }
  .offset-xl-2 {
    margin-left: 16.66667%; }
  .offset-xl-3 {
    margin-left: 25%; }
  .offset-xl-4 {
    margin-left: 33.33333%; }
  .offset-xl-5 {
    margin-left: 41.66667%; }
  .offset-xl-6 {
    margin-left: 50%; }
  .offset-xl-7 {
    margin-left: 58.33333%; }
  .offset-xl-8 {
    margin-left: 66.66667%; }
  .offset-xl-9 {
    margin-left: 75%; }
  .offset-xl-10 {
    margin-left: 83.33333%; }
  .offset-xl-11 {
    margin-left: 91.66667%; }
  .g-xl-0,
  .gx-xl-0 {
    --bs-gutter-x: 0; }
  .g-xl-0,
  .gy-xl-0 {
    --bs-gutter-y: 0; }
  .g-xl-1,
  .gx-xl-1 {
    --bs-gutter-x: 0.25rem; }
  .g-xl-1,
  .gy-xl-1 {
    --bs-gutter-y: 0.25rem; }
  .g-xl-2,
  .gx-xl-2 {
    --bs-gutter-x: 0.5rem; }
  .g-xl-2,
  .gy-xl-2 {
    --bs-gutter-y: 0.5rem; }
  .g-xl-3,
  .gx-xl-3 {
    --bs-gutter-x: 1rem; }
  .g-xl-3,
  .gy-xl-3 {
    --bs-gutter-y: 1rem; }
  .g-xl-4,
  .gx-xl-4 {
    --bs-gutter-x: 1.5rem; }
  .g-xl-4,
  .gy-xl-4 {
    --bs-gutter-y: 1.5rem; }
  .g-xl-5,
  .gx-xl-5 {
    --bs-gutter-x: 4.75rem; }
  .g-xl-5,
  .gy-xl-5 {
    --bs-gutter-y: 4.75rem; } }

@media (min-width: 1400px) {
  .col-xxl {
    flex: 1 0 0%; }
  .row-cols-xxl-auto > * {
    flex: 0 0 auto;
    width: auto; }
  .row-cols-xxl-1 > * {
    flex: 0 0 auto;
    width: 100%; }
  .row-cols-xxl-2 > * {
    flex: 0 0 auto;
    width: 50%; }
  .row-cols-xxl-3 > * {
    flex: 0 0 auto;
    width: 33.33333%; }
  .row-cols-xxl-4 > * {
    flex: 0 0 auto;
    width: 25%; }
  .row-cols-xxl-5 > * {
    flex: 0 0 auto;
    width: 20%; }
  .row-cols-xxl-6 > * {
    flex: 0 0 auto;
    width: 16.66667%; }
  .col-xxl-auto {
    flex: 0 0 auto;
    width: auto; }
  .col-xxl-1 {
    flex: 0 0 auto;
    width: 8.33333%; }
  .col-xxl-2 {
    flex: 0 0 auto;
    width: 16.66667%; }
  .col-xxl-3 {
    flex: 0 0 auto;
    width: 25%; }
  .col-xxl-4 {
    flex: 0 0 auto;
    width: 33.33333%; }
  .col-xxl-5 {
    flex: 0 0 auto;
    width: 41.66667%; }
  .col-xxl-6 {
    flex: 0 0 auto;
    width: 50%; }
  .col-xxl-7 {
    flex: 0 0 auto;
    width: 58.33333%; }
  .col-xxl-8 {
    flex: 0 0 auto;
    width: 66.66667%; }
  .col-xxl-9 {
    flex: 0 0 auto;
    width: 75%; }
  .col-xxl-10 {
    flex: 0 0 auto;
    width: 83.33333%; }
  .col-xxl-11 {
    flex: 0 0 auto;
    width: 91.66667%; }
  .col-xxl-12 {
    flex: 0 0 auto;
    width: 100%; }
  .offset-xxl-0 {
    margin-left: 0; }
  .offset-xxl-1 {
    margin-left: 8.33333%; }
  .offset-xxl-2 {
    margin-left: 16.66667%; }
  .offset-xxl-3 {
    margin-left: 25%; }
  .offset-xxl-4 {
    margin-left: 33.33333%; }
  .offset-xxl-5 {
    margin-left: 41.66667%; }
  .offset-xxl-6 {
    margin-left: 50%; }
  .offset-xxl-7 {
    margin-left: 58.33333%; }
  .offset-xxl-8 {
    margin-left: 66.66667%; }
  .offset-xxl-9 {
    margin-left: 75%; }
  .offset-xxl-10 {
    margin-left: 83.33333%; }
  .offset-xxl-11 {
    margin-left: 91.66667%; }
  .g-xxl-0,
  .gx-xxl-0 {
    --bs-gutter-x: 0; }
  .g-xxl-0,
  .gy-xxl-0 {
    --bs-gutter-y: 0; }
  .g-xxl-1,
  .gx-xxl-1 {
    --bs-gutter-x: 0.25rem; }
  .g-xxl-1,
  .gy-xxl-1 {
    --bs-gutter-y: 0.25rem; }
  .g-xxl-2,
  .gx-xxl-2 {
    --bs-gutter-x: 0.5rem; }
  .g-xxl-2,
  .gy-xxl-2 {
    --bs-gutter-y: 0.5rem; }
  .g-xxl-3,
  .gx-xxl-3 {
    --bs-gutter-x: 1rem; }
  .g-xxl-3,
  .gy-xxl-3 {
    --bs-gutter-y: 1rem; }
  .g-xxl-4,
  .gx-xxl-4 {
    --bs-gutter-x: 1.5rem; }
  .g-xxl-4,
  .gy-xxl-4 {
    --bs-gutter-y: 1.5rem; }
  .g-xxl-5,
  .gx-xxl-5 {
    --bs-gutter-x: 4.75rem; }
  .g-xxl-5,
  .gy-xxl-5 {
    --bs-gutter-y: 4.75rem; } }

.table {
  --bs-table-bg: transparent;
  --bs-table-accent-bg: transparent;
  --bs-table-striped-color: #212529;
  --bs-table-striped-bg: rgba(0, 0, 0, 0.05);
  --bs-table-active-color: #212529;
  --bs-table-active-bg: rgba(0, 0, 0, 0.1);
  --bs-table-hover-color: #212529;
  --bs-table-hover-bg: rgba(0, 0, 0, 0.075);
  width: 100%;
  margin-bottom: 1rem;
  color: #212529;
  vertical-align: top;
  border-color: #dee2e6; }
  .table > :not(caption) > * > * {
    padding: 0.5rem 0.5rem;
    background-color: var(--bs-table-bg);
    background-image: linear-gradient(var(--bs-table-accent-bg), var(--bs-table-accent-bg));
    border-bottom-width: 1px; }
  .table > tbody {
    vertical-align: inherit; }
  .table > thead {
    vertical-align: bottom; }
  .table > :not(:last-child) > :last-child > * {
    border-bottom-color: currentColor; }

.caption-top {
  caption-side: top; }

.table-sm > :not(caption) > * > * {
  padding: 0.25rem 0.25rem; }

.table-bordered > :not(caption) > * {
  border-width: 1px 0; }
  .table-bordered > :not(caption) > * > * {
    border-width: 0 1px; }

.table-borderless > :not(caption) > * > * {
  border-bottom-width: 0; }

.table-striped > tbody > tr:nth-of-type(odd) {
  --bs-table-accent-bg: var(--bs-table-striped-bg);
  color: var(--bs-table-striped-color); }

.table-active {
  --bs-table-accent-bg: var(--bs-table-active-bg);
  color: var(--bs-table-active-color); }

.table-hover > tbody > tr:hover {
  --bs-table-accent-bg: var(--bs-table-hover-bg);
  color: var(--bs-table-hover-color); }

.table-primary {
  --bs-table-bg: #bbd6fe;
  --bs-table-striped-bg: #b2cbf1;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #a8c1e5;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #adc6eb;
  --bs-table-hover-color: #000;
  color: #000;
  border-color: #a8c1e5; }

.table-secondary {
  --bs-table-bg: #d6d8db;
  --bs-table-striped-bg: #cbcdd0;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #c1c2c5;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #c6c8cb;
  --bs-table-hover-color: #000;
  color: #000;
  border-color: #c1c2c5; }

.table-success {
  --bs-table-bg: #bfddcf;
  --bs-table-striped-bg: #b5d2c5;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #acc7ba;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #b1ccbf;
  --bs-table-hover-color: #000;
  color: #000;
  border-color: #acc7ba; }

.table-info {
  --bs-table-bg: #bbf0fb;
  --bs-table-striped-bg: #b2e4ee;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #a8d8e2;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #addee8;
  --bs-table-hover-color: #000;
  color: #000;
  border-color: #a8d8e2; }

.table-warning {
  --bs-table-bg: #ffeeba;
  --bs-table-striped-bg: #f2e2b1;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #e6d6a7;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #ecdcac;
  --bs-table-hover-color: #000;
  color: #000;
  border-color: #e6d6a7; }

.table-danger {
  --bs-table-bg: #f5c6cb;
  --bs-table-striped-bg: #e9bcc1;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #ddb2b7;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #e3b7bc;
  --bs-table-hover-color: #000;
  color: #000;
  border-color: #ddb2b7; }

.table-light {
  --bs-table-bg: #f8f9fa;
  --bs-table-striped-bg: #ecedee;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #dfe0e1;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #e5e6e7;
  --bs-table-hover-color: #000;
  color: #000;
  border-color: #dfe0e1; }

.table-dark {
  --bs-table-bg: #343a40;
  --bs-table-striped-bg: #3e444a;
  --bs-table-striped-color: #fff;
  --bs-table-active-bg: #484e53;
  --bs-table-active-color: #fff;
  --bs-table-hover-bg: #43494e;
  --bs-table-hover-color: #fff;
  color: #fff;
  border-color: #484e53; }

.table-responsive {
  overflow-x: auto;
  -webkit-overflow-scrolling: touch; }

@media (max-width: 575.98px) {
  .table-responsive-sm {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch; } }

@media (max-width: 767.98px) {
  .table-responsive-md {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch; } }

@media (max-width: 991.98px) {
  .table-responsive-lg {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch; } }

@media (max-width: 1199.98px) {
  .table-responsive-xl {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch; } }

@media (max-width: 1399.98px) {
  .table-responsive-xxl {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch; } }

.form-label {
  margin-bottom: 0.5rem; }

.col-form-label {
  padding-top: calc(0.375rem + 1px);
  padding-bottom: calc(0.375rem + 1px);
  margin-bottom: 0;
  font-size: inherit;
  line-height: 1.5; }

.col-form-label-lg {
  padding-top: calc(0.5rem + 1px);
  padding-bottom: calc(0.5rem + 1px);
  font-size: 1.25rem; }

.col-form-label-sm {
  padding-top: calc(0.25rem + 1px);
  padding-bottom: calc(0.25rem + 1px);
  font-size: 0.875rem; }

.form-text {
  margin-top: 0.25rem;
  font-size: 0.875em;
  color: #6c757d; }

.form-control {
  display: block;
  width: 100%;
  min-height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  appearance: none;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    .form-control {
      transition: none; } }
  .form-control:focus {
    color: #495057;
    background-color: #fff;
    border-color: #8bbafe;
    outline: 0;
    box-shadow: 0 0 0 0.25rem rgba(13, 110, 253, 0.25); }
  .form-control::placeholder {
    color: #6c757d;
    opacity: 1; }
  .form-control:disabled, .form-control[readonly] {
    background-color: #e9ecef;
    opacity: 1; }

.form-control-plaintext {
  display: block;
  width: 100%;
  padding: 0.375rem 0;
  margin-bottom: 0;
  line-height: 1.5;
  color: #212529;
  background-color: transparent;
  border: solid transparent;
  border-width: 1px 0; }
  .form-control-plaintext.form-control-sm, .form-control-plaintext.form-control-lg {
    padding-right: 0;
    padding-left: 0; }

.form-control-sm {
  min-height: calc(1.5em + 0.5rem + 2px);
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  border-radius: 0.2rem; }

.form-control-lg {
  min-height: calc(1.5em + 1rem + 2px);
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  border-radius: 0.3rem; }

.form-control-color {
  max-width: 3rem;
  padding: 0.375rem; }

.form-control-color::-moz-color-swatch {
  border-radius: 0.25rem; }

.form-control-color::-webkit-color-swatch {
  border-radius: 0.25rem; }

.form-select {
  display: block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 1.75rem 0.375rem 0.75rem;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 2rem;
  color: #ffffff;
  vertical-align: middle;
  background-color: #122d3f;
  background-image: url("/static/images/icons/triangle-light-down.svg");
  background-repeat: no-repeat;
  background-position: right 0.75rem center;
  background-size: 16px 12px;
  border: 1px solid #122d3f;
  border-radius: 2rem;
  appearance: none; }
  .form-select:focus {
    border-color: rgba(0, 0, 0, 0);
    outline: 0;
    box-shadow: rgba(0, 0, 0, 0); }
    .form-select:focus::-ms-value {
      color: #495057;
      background-color: #fff; }
  .form-select[multiple], .form-select[size]:not([size="1"]) {
    height: auto;
    padding-right: 0.75rem;
    background-image: none; }
  .form-select:disabled {
    color: #6c757d;
    background-color: #e9ecef; }
  .form-select:-moz-focusring {
    color: transparent;
    text-shadow: 0 0 0 #ffffff; }

.form-select-sm {
  height: calc(1.5em + 0.5rem + 2px);
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  padding-left: 0.5rem;
  font-size: 0.875rem; }

.form-select-lg {
  height: calc(1.5em + 1rem + 2px);
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  padding-left: 1rem;
  font-size: 1.25rem; }

.form-check {
  display: block;
  min-height: 1.5rem;
  padding-left: 1.5em;
  margin-bottom: 0.125rem; }
  .form-check .form-check-input {
    float: left;
    margin-left: -1.5em; }

.form-check-input {
  width: 1.2rem;
  height: 1.2rem;
  margin-top: 0.15rem;
  vertical-align: top;
  background-color: #ffffff;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  border: 1px solid #707070;
  appearance: none;
  color-adjust: exact;
  transition: background-color 0.15s ease-in-out, background-position 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    .form-check-input {
      transition: none; } }
  .form-check-input[type="checkbox"] {
    border-radius: 0; }
  .form-check-input[type="radio"] {
    border-radius: 50%; }
  .form-check-input:active {
    filter: brightness(90%); }
  .form-check-input:focus {
    border-color: #8bbafe;
    outline: 0;
    box-shadow: none; }
  .form-check-input:checked {
    background-color: #fc0039;
    border-color: #ffffff; }
    .form-check-input:checked[type="checkbox"] {
      background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10l3 3l6-6'/%3e%3c/svg%3e"); }
    .form-check-input:checked[type="radio"] {
      background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='2' fill='%23fff'/%3e%3c/svg%3e"); }
  .form-check-input[type="checkbox"]:indeterminate {
    background-color: #0d6efd;
    border-color: #0d6efd;
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10h8'/%3e%3c/svg%3e"); }
  .form-check-input:disabled {
    pointer-events: none;
    filter: none;
    opacity: .5; }
  .form-check-input[disabled] ~ .form-check-label, .form-check-input:disabled ~ .form-check-label {
    opacity: .5; }

.form-switch {
  padding-left: 2.5em; }
  .form-switch .form-check-input {
    width: 2em;
    margin-left: -2.5em;
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='rgba%280, 0, 0, 0.25%29'/%3e%3c/svg%3e");
    background-position: left center;
    border-radius: 2em; }
    .form-switch .form-check-input:focus {
      background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%238bbafe'/%3e%3c/svg%3e"); }
    .form-switch .form-check-input:checked {
      background-position: right center;
      background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23fff'/%3e%3c/svg%3e"); }

.form-check-inline {
  display: inline-block;
  margin-right: 1rem; }

.btn-check {
  position: absolute;
  clip: rect(0, 0, 0, 0);
  pointer-events: none; }

.form-file {
  --bs-form-file-height: calc(1.5em + 0.75rem + 2px);
  position: relative; }

.form-file-input {
  position: relative;
  z-index: 2;
  width: 100%;
  height: var(--bs-form-file-height);
  margin: 0;
  opacity: 0; }
  .form-file-input:focus-within ~ .form-file-label {
    border-color: #8bbafe;
    box-shadow: 0 0 0 0.25rem rgba(13, 110, 253, 0.25); }
  .form-file-input[disabled] ~ .form-file-label .form-file-text,
  .form-file-input:disabled ~ .form-file-label .form-file-text {
    background-color: #e9ecef; }

.form-file-label {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1;
  display: flex;
  height: var(--bs-form-file-height);
  border-color: #ced4da;
  border-radius: 0.25rem; }

.form-file-text {
  display: block;
  flex-grow: 1;
  padding: 0.375rem 0.75rem;
  overflow: hidden;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  text-overflow: ellipsis;
  white-space: nowrap;
  background-color: #fff;
  border-color: inherit;
  border-style: solid;
  border-width: 1px;
  border-top-left-radius: inherit;
  border-bottom-left-radius: inherit; }

.form-file-button {
  display: block;
  flex-shrink: 0;
  padding: 0.375rem 0.75rem;
  margin-left: -1px;
  line-height: 1.5;
  color: #495057;
  background-color: #e9ecef;
  border-color: inherit;
  border-style: solid;
  border-width: 1px;
  border-top-right-radius: inherit;
  border-bottom-right-radius: inherit; }

.form-file-sm {
  --bs-form-file-height: calc(1.5em + 0.5rem + 2px);
  font-size: 0.875rem; }
  .form-file-sm .form-file-text,
  .form-file-sm .form-file-button {
    padding: 0.25rem 0.5rem; }

.form-file-lg {
  --bs-form-file-height: calc(1.5em + 1rem + 2px);
  font-size: 1.25rem; }
  .form-file-lg .form-file-text,
  .form-file-lg .form-file-button {
    padding: 0.5rem 1rem; }

.form-range {
  width: 100%;
  height: 1.5rem;
  padding: 0;
  background-color: transparent;
  appearance: none; }
  .form-range:focus {
    outline: none; }
    .form-range:focus::-webkit-slider-thumb {
      box-shadow: 0 0 0 1px #fff, 0 0 0 0.25rem rgba(13, 110, 253, 0.25); }
    .form-range:focus::-moz-range-thumb {
      box-shadow: 0 0 0 1px #fff, 0 0 0 0.25rem rgba(13, 110, 253, 0.25); }
    .form-range:focus::-ms-thumb {
      box-shadow: 0 0 0 1px #fff, 0 0 0 0.25rem rgba(13, 110, 253, 0.25); }
  .form-range::-moz-focus-outer {
    border: 0; }
  .form-range::-webkit-slider-thumb {
    width: 1rem;
    height: 1rem;
    margin-top: -0.25rem;
    background-color: #0d6efd;
    border: 0;
    border-radius: 1rem;
    transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    appearance: none; }
    @media (prefers-reduced-motion: reduce) {
      .form-range::-webkit-slider-thumb {
        transition: none; } }
    .form-range::-webkit-slider-thumb:active {
      background-color: #bed8fe; }
  .form-range::-webkit-slider-runnable-track {
    width: 100%;
    height: 0.5rem;
    color: transparent;
    cursor: pointer;
    background-color: #dee2e6;
    border-color: transparent;
    border-radius: 1rem; }
  .form-range::-moz-range-thumb {
    width: 1rem;
    height: 1rem;
    background-color: #0d6efd;
    border: 0;
    border-radius: 1rem;
    transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    appearance: none; }
    @media (prefers-reduced-motion: reduce) {
      .form-range::-moz-range-thumb {
        transition: none; } }
    .form-range::-moz-range-thumb:active {
      background-color: #bed8fe; }
  .form-range::-moz-range-track {
    width: 100%;
    height: 0.5rem;
    color: transparent;
    cursor: pointer;
    background-color: #dee2e6;
    border-color: transparent;
    border-radius: 1rem; }
  .form-range::-ms-thumb {
    width: 1rem;
    height: 1rem;
    margin-top: 0;
    margin-right: 0.25rem;
    margin-left: 0.25rem;
    background-color: #0d6efd;
    border: 0;
    border-radius: 1rem;
    transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    appearance: none; }
    @media (prefers-reduced-motion: reduce) {
      .form-range::-ms-thumb {
        transition: none; } }
    .form-range::-ms-thumb:active {
      background-color: #bed8fe; }
  .form-range::-ms-track {
    width: 100%;
    height: 0.5rem;
    color: transparent;
    cursor: pointer;
    background-color: transparent;
    border-color: transparent;
    border-width: 0.5rem; }
  .form-range::-ms-fill-lower {
    background-color: #dee2e6;
    border-radius: 1rem; }
  .form-range::-ms-fill-upper {
    margin-right: 15px;
    background-color: #dee2e6;
    border-radius: 1rem; }
  .form-range:disabled {
    pointer-events: none; }
    .form-range:disabled::-webkit-slider-thumb {
      background-color: #adb5bd; }
    .form-range:disabled::-moz-range-thumb {
      background-color: #adb5bd; }
    .form-range:disabled::-ms-thumb {
      background-color: #adb5bd; }

.input-group {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  width: 100%; }
  .input-group > .form-control,
  .input-group > .form-select,
  .input-group > .form-file {
    position: relative;
    flex: 1 1 auto;
    width: 1%;
    min-width: 0; }
  .input-group > .form-control:focus,
  .input-group > .form-select:focus,
  .input-group > .form-file .form-file-input:focus ~ .form-file-label {
    z-index: 3; }
  .input-group > .form-file > .form-file-input:focus {
    z-index: 4; }
  .input-group > .form-file:not(:last-child) > .form-file-label {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0; }
  .input-group > .form-file:not(:first-child) > .form-file-label {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0; }
  .input-group .btn {
    position: relative;
    z-index: 2; }
    .input-group .btn:focus {
      z-index: 3; }

.input-group-text {
  display: flex;
  align-items: center;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  text-align: center;
  white-space: nowrap;
  background-color: #e9ecef;
  border: 1px solid #ced4da;
  border-radius: 0.25rem; }

.input-group-lg > .form-control {
  min-height: calc(1.5em + 1rem + 2px); }

.input-group-lg > .form-select {
  height: calc(1.5em + 1rem + 2px); }

.input-group-lg > .form-control,
.input-group-lg > .form-select,
.input-group-lg > .input-group-text,
.input-group-lg > .btn {
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  border-radius: 0.3rem; }

.input-group-sm > .form-control {
  min-height: calc(1.5em + 0.5rem + 2px); }

.input-group-sm > .form-select {
  height: calc(1.5em + 0.5rem + 2px); }

.input-group-sm > .form-control,
.input-group-sm > .form-select,
.input-group-sm > .input-group-text,
.input-group-sm > .btn {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  border-radius: 0.2rem; }

.input-group-lg > .form-select,
.input-group-sm > .form-select {
  padding-right: 1.75rem; }

.input-group > :not(:last-child):not(.dropdown-toggle):not(.dropdown-menu),
.input-group > .dropdown-toggle:nth-last-child(n + 3) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0; }

.input-group > :not(:first-child):not(.dropdown-menu) {
  margin-left: -1px;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0; }

.valid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 0.875em;
  color: #198754; }

.valid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: .1rem;
  font-size: 0.875rem;
  color: #fff;
  background-color: rgba(25, 135, 84, 0.9);
  border-radius: 0.25rem; }

.was-validated :valid ~ .valid-feedback,
.was-validated :valid ~ .valid-tooltip,
.is-valid ~ .valid-feedback,
.is-valid ~ .valid-tooltip {
  display: block; }

.was-validated .form-control:valid, .form-control.is-valid {
  border-color: #198754;
  padding-right: calc(1.5em + 0.75rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%23198754' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.1875rem) center;
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem); }
  .was-validated .form-control:valid:focus, .form-control.is-valid:focus {
    border-color: #198754;
    box-shadow: 0 0 0 0.25rem rgba(25, 135, 84, 0.25); }

.was-validated textarea.form-control:valid, textarea.form-control.is-valid {
  padding-right: calc(1.5em + 0.75rem);
  background-position: top calc(0.375em + 0.1875rem) right calc(0.375em + 0.1875rem); }

.was-validated .form-select:valid, .form-select.is-valid {
  border-color: #198754;
  padding-right: calc(0.75em + 2.3125rem);
  background-image: url("/static/images/icons/triangle-light-down.svg"), url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%23198754' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
  background-position: right 0.75rem center, center right 1.75rem;
  background-size: 16px 12px, calc(0.75em + 0.375rem) calc(0.75em + 0.375rem); }
  .was-validated .form-select:valid:focus, .form-select.is-valid:focus {
    border-color: #198754;
    box-shadow: 0 0 0 0.25rem rgba(25, 135, 84, 0.25); }

.was-validated .form-check-input:valid, .form-check-input.is-valid {
  border-color: #198754; }
  .was-validated .form-check-input:valid:checked, .form-check-input.is-valid:checked {
    background-color: #198754; }
  .was-validated .form-check-input:valid:focus, .form-check-input.is-valid:focus {
    box-shadow: 0 0 0 0.25rem rgba(25, 135, 84, 0.25); }
  .was-validated .form-check-input:valid ~ .form-check-label, .form-check-input.is-valid ~ .form-check-label {
    color: #198754; }

.form-check-inline .form-check-input ~ .valid-feedback {
  margin-left: .5em; }

.was-validated .form-file-input:valid ~ .form-file-label, .form-file-input.is-valid ~ .form-file-label {
  border-color: #198754; }

.was-validated .form-file-input:valid:focus ~ .form-file-label, .form-file-input.is-valid:focus ~ .form-file-label {
  border-color: #198754;
  box-shadow: 0 0 0 0.25rem rgba(25, 135, 84, 0.25); }

.invalid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 0.875em;
  color: #dc3545; }

.invalid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: .1rem;
  font-size: 0.875rem;
  color: #fff;
  background-color: rgba(220, 53, 69, 0.9);
  border-radius: 0.25rem; }

.was-validated :invalid ~ .invalid-feedback,
.was-validated :invalid ~ .invalid-tooltip,
.is-invalid ~ .invalid-feedback,
.is-invalid ~ .invalid-tooltip {
  display: block; }

.was-validated .form-control:invalid, .form-control.is-invalid {
  border-color: #dc3545;
  padding-right: calc(1.5em + 0.75rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23dc3545' viewBox='0 0 12 12'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.1875rem) center;
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem); }
  .was-validated .form-control:invalid:focus, .form-control.is-invalid:focus {
    border-color: #dc3545;
    box-shadow: 0 0 0 0.25rem rgba(220, 53, 69, 0.25); }

.was-validated textarea.form-control:invalid, textarea.form-control.is-invalid {
  padding-right: calc(1.5em + 0.75rem);
  background-position: top calc(0.375em + 0.1875rem) right calc(0.375em + 0.1875rem); }

.was-validated .form-select:invalid, .form-select.is-invalid {
  border-color: #dc3545;
  padding-right: calc(0.75em + 2.3125rem);
  background-image: url("/static/images/icons/triangle-light-down.svg"), url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23dc3545' viewBox='0 0 12 12'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e");
  background-position: right 0.75rem center, center right 1.75rem;
  background-size: 16px 12px, calc(0.75em + 0.375rem) calc(0.75em + 0.375rem); }
  .was-validated .form-select:invalid:focus, .form-select.is-invalid:focus {
    border-color: #dc3545;
    box-shadow: 0 0 0 0.25rem rgba(220, 53, 69, 0.25); }

.was-validated .form-check-input:invalid, .form-check-input.is-invalid {
  border-color: #dc3545; }
  .was-validated .form-check-input:invalid:checked, .form-check-input.is-invalid:checked {
    background-color: #dc3545; }
  .was-validated .form-check-input:invalid:focus, .form-check-input.is-invalid:focus {
    box-shadow: 0 0 0 0.25rem rgba(220, 53, 69, 0.25); }
  .was-validated .form-check-input:invalid ~ .form-check-label, .form-check-input.is-invalid ~ .form-check-label {
    color: #dc3545; }

.form-check-inline .form-check-input ~ .invalid-feedback {
  margin-left: .5em; }

.was-validated .form-file-input:invalid ~ .form-file-label, .form-file-input.is-invalid ~ .form-file-label {
  border-color: #dc3545; }

.was-validated .form-file-input:invalid:focus ~ .form-file-label, .form-file-input.is-invalid:focus ~ .form-file-label {
  border-color: #dc3545;
  box-shadow: 0 0 0 0.25rem rgba(220, 53, 69, 0.25); }

.btn {
  display: inline-block;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  text-align: center;
  text-decoration: none;
  vertical-align: middle;
  cursor: pointer;
  user-select: none;
  background-color: transparent;
  border: 1px solid transparent;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    .btn {
      transition: none; } }
  .btn:hover {
    color: #212529; }
  .btn-check:focus + .btn, .btn:focus {
    outline: 0;
    box-shadow: 0 0 0 0.25rem rgba(13, 110, 253, 0.25); }
  .btn:disabled, .btn.disabled,
  fieldset:disabled .btn {
    pointer-events: none;
    opacity: 0.65; }

.btn-primary {
  color: #fff;
  background-color: #0d6efd;
  border-color: #0d6efd; }
  .btn-primary:hover {
    color: #fff;
    background-color: #025ce2;
    border-color: #0257d5; }
  .btn-check:focus + .btn-primary, .btn-primary:focus {
    color: #fff;
    background-color: #025ce2;
    border-color: #0257d5;
    box-shadow: 0 0 0 0.25rem rgba(49, 132, 253, 0.5); }
  .btn-check:checked + .btn-primary,
  .btn-check:active + .btn-primary, .btn-primary:active, .btn-primary.active,
  .show > .btn-primary.dropdown-toggle {
    color: #fff;
    background-color: #0257d5;
    border-color: #0252c9; }
    .btn-check:checked + .btn-primary:focus,
    .btn-check:active + .btn-primary:focus, .btn-primary:active:focus, .btn-primary.active:focus,
    .show > .btn-primary.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.25rem rgba(49, 132, 253, 0.5); }
  .btn-primary:disabled, .btn-primary.disabled {
    color: #fff;
    background-color: #0d6efd;
    border-color: #0d6efd; }

.btn-secondary {
  color: #fff;
  background-color: #6c757d;
  border-color: #6c757d; }
  .btn-secondary:hover {
    color: #fff;
    background-color: #5a6268;
    border-color: #545b62; }
  .btn-check:focus + .btn-secondary, .btn-secondary:focus {
    color: #fff;
    background-color: #5a6268;
    border-color: #545b62;
    box-shadow: 0 0 0 0.25rem rgba(130, 138, 145, 0.5); }
  .btn-check:checked + .btn-secondary,
  .btn-check:active + .btn-secondary, .btn-secondary:active, .btn-secondary.active,
  .show > .btn-secondary.dropdown-toggle {
    color: #fff;
    background-color: #545b62;
    border-color: #4e555b; }
    .btn-check:checked + .btn-secondary:focus,
    .btn-check:active + .btn-secondary:focus, .btn-secondary:active:focus, .btn-secondary.active:focus,
    .show > .btn-secondary.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.25rem rgba(130, 138, 145, 0.5); }
  .btn-secondary:disabled, .btn-secondary.disabled {
    color: #fff;
    background-color: #6c757d;
    border-color: #6c757d; }

.btn-success {
  color: #fff;
  background-color: #198754;
  border-color: #198754; }
  .btn-success:hover {
    color: #fff;
    background-color: #136740;
    border-color: #115c39; }
  .btn-check:focus + .btn-success, .btn-success:focus {
    color: #fff;
    background-color: #136740;
    border-color: #115c39;
    box-shadow: 0 0 0 0.25rem rgba(60, 153, 110, 0.5); }
  .btn-check:checked + .btn-success,
  .btn-check:active + .btn-success, .btn-success:active, .btn-success.active,
  .show > .btn-success.dropdown-toggle {
    color: #fff;
    background-color: #115c39;
    border-color: #0f5133; }
    .btn-check:checked + .btn-success:focus,
    .btn-check:active + .btn-success:focus, .btn-success:active:focus, .btn-success.active:focus,
    .show > .btn-success.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.25rem rgba(60, 153, 110, 0.5); }
  .btn-success:disabled, .btn-success.disabled {
    color: #fff;
    background-color: #198754;
    border-color: #198754; }

.btn-info {
  color: #000;
  background-color: #0dcaf0;
  border-color: #0dcaf0; }
  .btn-info:hover {
    color: #000;
    background-color: #2fd3f4;
    border-color: #23d0f3; }
  .btn-check:focus + .btn-info, .btn-info:focus {
    color: #000;
    background-color: #2fd3f4;
    border-color: #23d0f3;
    box-shadow: 0 0 0 0.25rem rgba(11, 172, 204, 0.5); }
  .btn-check:checked + .btn-info,
  .btn-check:active + .btn-info, .btn-info:active, .btn-info.active,
  .show > .btn-info.dropdown-toggle {
    color: #000;
    background-color: #3cd5f4;
    border-color: #23d0f3; }
    .btn-check:checked + .btn-info:focus,
    .btn-check:active + .btn-info:focus, .btn-info:active:focus, .btn-info.active:focus,
    .show > .btn-info.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.25rem rgba(11, 172, 204, 0.5); }
  .btn-info:disabled, .btn-info.disabled {
    color: #000;
    background-color: #0dcaf0;
    border-color: #0dcaf0; }

.btn-warning {
  color: #000;
  background-color: #ffc107;
  border-color: #ffc107; }
  .btn-warning:hover {
    color: #000;
    background-color: #ffcb2d;
    border-color: #ffc721; }
  .btn-check:focus + .btn-warning, .btn-warning:focus {
    color: #000;
    background-color: #ffcb2d;
    border-color: #ffc721;
    box-shadow: 0 0 0 0.25rem rgba(217, 164, 6, 0.5); }
  .btn-check:checked + .btn-warning,
  .btn-check:active + .btn-warning, .btn-warning:active, .btn-warning.active,
  .show > .btn-warning.dropdown-toggle {
    color: #000;
    background-color: #ffce3a;
    border-color: #ffc721; }
    .btn-check:checked + .btn-warning:focus,
    .btn-check:active + .btn-warning:focus, .btn-warning:active:focus, .btn-warning.active:focus,
    .show > .btn-warning.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.25rem rgba(217, 164, 6, 0.5); }
  .btn-warning:disabled, .btn-warning.disabled {
    color: #000;
    background-color: #ffc107;
    border-color: #ffc107; }

.btn-danger {
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545; }
  .btn-danger:hover {
    color: #fff;
    background-color: #c82333;
    border-color: #bd2130; }
  .btn-check:focus + .btn-danger, .btn-danger:focus {
    color: #fff;
    background-color: #c82333;
    border-color: #bd2130;
    box-shadow: 0 0 0 0.25rem rgba(225, 83, 97, 0.5); }
  .btn-check:checked + .btn-danger,
  .btn-check:active + .btn-danger, .btn-danger:active, .btn-danger.active,
  .show > .btn-danger.dropdown-toggle {
    color: #fff;
    background-color: #bd2130;
    border-color: #b21f2d; }
    .btn-check:checked + .btn-danger:focus,
    .btn-check:active + .btn-danger:focus, .btn-danger:active:focus, .btn-danger.active:focus,
    .show > .btn-danger.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.25rem rgba(225, 83, 97, 0.5); }
  .btn-danger:disabled, .btn-danger.disabled {
    color: #fff;
    background-color: #dc3545;
    border-color: #dc3545; }

.btn-light {
  color: #000;
  background-color: #f8f9fa;
  border-color: #f8f9fa; }
  .btn-light:hover {
    color: #000;
    background-color: white;
    border-color: white; }
  .btn-check:focus + .btn-light, .btn-light:focus {
    color: #000;
    background-color: white;
    border-color: white;
    box-shadow: 0 0 0 0.25rem rgba(211, 212, 213, 0.5); }
  .btn-check:checked + .btn-light,
  .btn-check:active + .btn-light, .btn-light:active, .btn-light.active,
  .show > .btn-light.dropdown-toggle {
    color: #000;
    background-color: white;
    border-color: white; }
    .btn-check:checked + .btn-light:focus,
    .btn-check:active + .btn-light:focus, .btn-light:active:focus, .btn-light.active:focus,
    .show > .btn-light.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.25rem rgba(211, 212, 213, 0.5); }
  .btn-light:disabled, .btn-light.disabled {
    color: #000;
    background-color: #f8f9fa;
    border-color: #f8f9fa; }

.btn-dark {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40; }
  .btn-dark:hover {
    color: #fff;
    background-color: #23272b;
    border-color: #1d2124; }
  .btn-check:focus + .btn-dark, .btn-dark:focus {
    color: #fff;
    background-color: #23272b;
    border-color: #1d2124;
    box-shadow: 0 0 0 0.25rem rgba(82, 88, 93, 0.5); }
  .btn-check:checked + .btn-dark,
  .btn-check:active + .btn-dark, .btn-dark:active, .btn-dark.active,
  .show > .btn-dark.dropdown-toggle {
    color: #fff;
    background-color: #1d2124;
    border-color: #171a1d; }
    .btn-check:checked + .btn-dark:focus,
    .btn-check:active + .btn-dark:focus, .btn-dark:active:focus, .btn-dark.active:focus,
    .show > .btn-dark.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.25rem rgba(82, 88, 93, 0.5); }
  .btn-dark:disabled, .btn-dark.disabled {
    color: #fff;
    background-color: #343a40;
    border-color: #343a40; }

.btn-outline-primary {
  color: #0d6efd;
  border-color: #0d6efd; }
  .btn-outline-primary:hover {
    color: #fff;
    background-color: #0d6efd;
    border-color: #0d6efd; }
  .btn-check:focus + .btn-outline-primary, .btn-outline-primary:focus {
    box-shadow: 0 0 0 0.25rem rgba(13, 110, 253, 0.5); }
  .btn-check:checked + .btn-outline-primary,
  .btn-check:active + .btn-outline-primary, .btn-outline-primary:active, .btn-outline-primary.active, .btn-outline-primary.dropdown-toggle.show {
    color: #fff;
    background-color: #0d6efd;
    border-color: #0d6efd; }
    .btn-check:checked + .btn-outline-primary:focus,
    .btn-check:active + .btn-outline-primary:focus, .btn-outline-primary:active:focus, .btn-outline-primary.active:focus, .btn-outline-primary.dropdown-toggle.show:focus {
      box-shadow: 0 0 0 0.25rem rgba(13, 110, 253, 0.5); }
  .btn-outline-primary:disabled, .btn-outline-primary.disabled {
    color: #0d6efd;
    background-color: transparent; }

.btn-outline-secondary {
  color: #6c757d;
  border-color: #6c757d; }
  .btn-outline-secondary:hover {
    color: #fff;
    background-color: #6c757d;
    border-color: #6c757d; }
  .btn-check:focus + .btn-outline-secondary, .btn-outline-secondary:focus {
    box-shadow: 0 0 0 0.25rem rgba(108, 117, 125, 0.5); }
  .btn-check:checked + .btn-outline-secondary,
  .btn-check:active + .btn-outline-secondary, .btn-outline-secondary:active, .btn-outline-secondary.active, .btn-outline-secondary.dropdown-toggle.show {
    color: #fff;
    background-color: #6c757d;
    border-color: #6c757d; }
    .btn-check:checked + .btn-outline-secondary:focus,
    .btn-check:active + .btn-outline-secondary:focus, .btn-outline-secondary:active:focus, .btn-outline-secondary.active:focus, .btn-outline-secondary.dropdown-toggle.show:focus {
      box-shadow: 0 0 0 0.25rem rgba(108, 117, 125, 0.5); }
  .btn-outline-secondary:disabled, .btn-outline-secondary.disabled {
    color: #6c757d;
    background-color: transparent; }

.btn-outline-success {
  color: #198754;
  border-color: #198754; }
  .btn-outline-success:hover {
    color: #fff;
    background-color: #198754;
    border-color: #198754; }
  .btn-check:focus + .btn-outline-success, .btn-outline-success:focus {
    box-shadow: 0 0 0 0.25rem rgba(25, 135, 84, 0.5); }
  .btn-check:checked + .btn-outline-success,
  .btn-check:active + .btn-outline-success, .btn-outline-success:active, .btn-outline-success.active, .btn-outline-success.dropdown-toggle.show {
    color: #fff;
    background-color: #198754;
    border-color: #198754; }
    .btn-check:checked + .btn-outline-success:focus,
    .btn-check:active + .btn-outline-success:focus, .btn-outline-success:active:focus, .btn-outline-success.active:focus, .btn-outline-success.dropdown-toggle.show:focus {
      box-shadow: 0 0 0 0.25rem rgba(25, 135, 84, 0.5); }
  .btn-outline-success:disabled, .btn-outline-success.disabled {
    color: #198754;
    background-color: transparent; }

.btn-outline-info {
  color: #0dcaf0;
  border-color: #0dcaf0; }
  .btn-outline-info:hover {
    color: #000;
    background-color: #0dcaf0;
    border-color: #0dcaf0; }
  .btn-check:focus + .btn-outline-info, .btn-outline-info:focus {
    box-shadow: 0 0 0 0.25rem rgba(13, 202, 240, 0.5); }
  .btn-check:checked + .btn-outline-info,
  .btn-check:active + .btn-outline-info, .btn-outline-info:active, .btn-outline-info.active, .btn-outline-info.dropdown-toggle.show {
    color: #000;
    background-color: #0dcaf0;
    border-color: #0dcaf0; }
    .btn-check:checked + .btn-outline-info:focus,
    .btn-check:active + .btn-outline-info:focus, .btn-outline-info:active:focus, .btn-outline-info.active:focus, .btn-outline-info.dropdown-toggle.show:focus {
      box-shadow: 0 0 0 0.25rem rgba(13, 202, 240, 0.5); }
  .btn-outline-info:disabled, .btn-outline-info.disabled {
    color: #0dcaf0;
    background-color: transparent; }

.btn-outline-warning {
  color: #ffc107;
  border-color: #ffc107; }
  .btn-outline-warning:hover {
    color: #000;
    background-color: #ffc107;
    border-color: #ffc107; }
  .btn-check:focus + .btn-outline-warning, .btn-outline-warning:focus {
    box-shadow: 0 0 0 0.25rem rgba(255, 193, 7, 0.5); }
  .btn-check:checked + .btn-outline-warning,
  .btn-check:active + .btn-outline-warning, .btn-outline-warning:active, .btn-outline-warning.active, .btn-outline-warning.dropdown-toggle.show {
    color: #000;
    background-color: #ffc107;
    border-color: #ffc107; }
    .btn-check:checked + .btn-outline-warning:focus,
    .btn-check:active + .btn-outline-warning:focus, .btn-outline-warning:active:focus, .btn-outline-warning.active:focus, .btn-outline-warning.dropdown-toggle.show:focus {
      box-shadow: 0 0 0 0.25rem rgba(255, 193, 7, 0.5); }
  .btn-outline-warning:disabled, .btn-outline-warning.disabled {
    color: #ffc107;
    background-color: transparent; }

.btn-outline-danger {
  color: #dc3545;
  border-color: #dc3545; }
  .btn-outline-danger:hover {
    color: #fff;
    background-color: #dc3545;
    border-color: #dc3545; }
  .btn-check:focus + .btn-outline-danger, .btn-outline-danger:focus {
    box-shadow: 0 0 0 0.25rem rgba(220, 53, 69, 0.5); }
  .btn-check:checked + .btn-outline-danger,
  .btn-check:active + .btn-outline-danger, .btn-outline-danger:active, .btn-outline-danger.active, .btn-outline-danger.dropdown-toggle.show {
    color: #fff;
    background-color: #dc3545;
    border-color: #dc3545; }
    .btn-check:checked + .btn-outline-danger:focus,
    .btn-check:active + .btn-outline-danger:focus, .btn-outline-danger:active:focus, .btn-outline-danger.active:focus, .btn-outline-danger.dropdown-toggle.show:focus {
      box-shadow: 0 0 0 0.25rem rgba(220, 53, 69, 0.5); }
  .btn-outline-danger:disabled, .btn-outline-danger.disabled {
    color: #dc3545;
    background-color: transparent; }

.btn-outline-light {
  color: #f8f9fa;
  border-color: #f8f9fa; }
  .btn-outline-light:hover {
    color: #000;
    background-color: #f8f9fa;
    border-color: #f8f9fa; }
  .btn-check:focus + .btn-outline-light, .btn-outline-light:focus {
    box-shadow: 0 0 0 0.25rem rgba(248, 249, 250, 0.5); }
  .btn-check:checked + .btn-outline-light,
  .btn-check:active + .btn-outline-light, .btn-outline-light:active, .btn-outline-light.active, .btn-outline-light.dropdown-toggle.show {
    color: #000;
    background-color: #f8f9fa;
    border-color: #f8f9fa; }
    .btn-check:checked + .btn-outline-light:focus,
    .btn-check:active + .btn-outline-light:focus, .btn-outline-light:active:focus, .btn-outline-light.active:focus, .btn-outline-light.dropdown-toggle.show:focus {
      box-shadow: 0 0 0 0.25rem rgba(248, 249, 250, 0.5); }
  .btn-outline-light:disabled, .btn-outline-light.disabled {
    color: #f8f9fa;
    background-color: transparent; }

.btn-outline-dark {
  color: #343a40;
  border-color: #343a40; }
  .btn-outline-dark:hover {
    color: #fff;
    background-color: #343a40;
    border-color: #343a40; }
  .btn-check:focus + .btn-outline-dark, .btn-outline-dark:focus {
    box-shadow: 0 0 0 0.25rem rgba(52, 58, 64, 0.5); }
  .btn-check:checked + .btn-outline-dark,
  .btn-check:active + .btn-outline-dark, .btn-outline-dark:active, .btn-outline-dark.active, .btn-outline-dark.dropdown-toggle.show {
    color: #fff;
    background-color: #343a40;
    border-color: #343a40; }
    .btn-check:checked + .btn-outline-dark:focus,
    .btn-check:active + .btn-outline-dark:focus, .btn-outline-dark:active:focus, .btn-outline-dark.active:focus, .btn-outline-dark.dropdown-toggle.show:focus {
      box-shadow: 0 0 0 0.25rem rgba(52, 58, 64, 0.5); }
  .btn-outline-dark:disabled, .btn-outline-dark.disabled {
    color: #343a40;
    background-color: transparent; }

.btn-link {
  font-weight: 400;
  color: #0d6efd;
  text-decoration: underline; }
  .btn-link:hover {
    color: #024dbc; }
  .btn-link:disabled, .btn-link.disabled {
    color: #6c757d; }

.btn-lg, .btn-group-lg > .btn {
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  border-radius: 0.3rem; }

.btn-sm, .btn-group-sm > .btn {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  border-radius: 0.2rem; }

.btn-block {
  display: block;
  width: 100%; }
  .btn-block + .btn-block {
    margin-top: 0.5rem; }

.fade {
  transition: opacity 0.15s linear; }
  @media (prefers-reduced-motion: reduce) {
    .fade {
      transition: none; } }
  .fade:not(.show) {
    opacity: 0; }

.collapse:not(.show) {
  display: none; }

.collapsing {
  height: 0;
  overflow: hidden;
  transition: height 0.35s ease; }
  @media (prefers-reduced-motion: reduce) {
    .collapsing {
      transition: none; } }

.fade {
  transition: opacity 0.15s linear; }
  @media (prefers-reduced-motion: reduce) {
    .fade {
      transition: none; } }
  .fade:not(.show) {
    opacity: 0; }

.collapse:not(.show) {
  display: none; }

.collapsing {
  height: 0;
  overflow: hidden;
  transition: height 0.35s ease; }
  @media (prefers-reduced-motion: reduce) {
    .collapsing {
      transition: none; } }

.dropup,
.dropright,
.dropdown,
.dropleft {
  position: relative; }

.dropdown-toggle {
  white-space: nowrap; }
  .dropdown-toggle::after {
    display: inline-block;
    margin-left: 0.255em;
    vertical-align: 0.255em;
    content: "";
    border-top: 0.3em solid;
    border-right: 0.3em solid transparent;
    border-bottom: 0;
    border-left: 0.3em solid transparent; }
  .dropdown-toggle:empty::after {
    margin-left: 0; }

.dropdown-menu {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1000;
  display: none;
  min-width: 10rem;
  padding: 0.5rem 0;
  margin: 0.125rem 0 0;
  font-size: 1rem;
  color: #212529;
  text-align: left;
  list-style: none;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 0.25rem; }

.dropdown-menu-left {
  right: auto;
  left: 0; }

.dropdown-menu-right {
  right: 0;
  left: auto; }

@media (min-width: 576px) {
  .dropdown-menu-sm-left {
    right: auto;
    left: 0; }
  .dropdown-menu-sm-right {
    right: 0;
    left: auto; } }

@media (min-width: 768px) {
  .dropdown-menu-md-left {
    right: auto;
    left: 0; }
  .dropdown-menu-md-right {
    right: 0;
    left: auto; } }

@media (min-width: 992px) {
  .dropdown-menu-lg-left {
    right: auto;
    left: 0; }
  .dropdown-menu-lg-right {
    right: 0;
    left: auto; } }

@media (min-width: 1200px) {
  .dropdown-menu-xl-left {
    right: auto;
    left: 0; }
  .dropdown-menu-xl-right {
    right: 0;
    left: auto; } }

@media (min-width: 1400px) {
  .dropdown-menu-xxl-left {
    right: auto;
    left: 0; }
  .dropdown-menu-xxl-right {
    right: 0;
    left: auto; } }

.dropup .dropdown-menu {
  top: auto;
  bottom: 100%;
  margin-top: 0;
  margin-bottom: 0.125rem; }

.dropup .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0;
  border-right: 0.3em solid transparent;
  border-bottom: 0.3em solid;
  border-left: 0.3em solid transparent; }

.dropup .dropdown-toggle:empty::after {
  margin-left: 0; }

.dropright .dropdown-menu {
  top: 0;
  right: auto;
  left: 100%;
  margin-top: 0;
  margin-left: 0.125rem; }

.dropright .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0;
  border-bottom: 0.3em solid transparent;
  border-left: 0.3em solid; }

.dropright .dropdown-toggle:empty::after {
  margin-left: 0; }

.dropright .dropdown-toggle::after {
  vertical-align: 0; }

.dropleft .dropdown-menu {
  top: 0;
  right: 100%;
  left: auto;
  margin-top: 0;
  margin-right: 0.125rem; }

.dropleft .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: ""; }

.dropleft .dropdown-toggle::after {
  display: none; }

.dropleft .dropdown-toggle::before {
  display: inline-block;
  margin-right: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0.3em solid;
  border-bottom: 0.3em solid transparent; }

.dropleft .dropdown-toggle:empty::after {
  margin-left: 0; }

.dropleft .dropdown-toggle::before {
  vertical-align: 0; }

.dropdown-menu[x-placement^="top"], .dropdown-menu[x-placement^="right"], .dropdown-menu[x-placement^="bottom"], .dropdown-menu[x-placement^="left"] {
  right: auto;
  bottom: auto; }

.dropdown-divider {
  height: 0;
  margin: 0.5rem 0;
  overflow: hidden;
  border-top: 1px solid rgba(0, 0, 0, 0.15); }

.dropdown-item {
  display: block;
  width: 100%;
  padding: 0.25rem 1rem;
  clear: both;
  font-weight: 400;
  color: #212529;
  text-align: inherit;
  text-decoration: none;
  white-space: nowrap;
  background-color: transparent;
  border: 0; }
  .dropdown-item:hover, .dropdown-item:focus {
    color: #16181b;
    background-color: #f8f9fa; }
  .dropdown-item.active, .dropdown-item:active {
    color: #fff;
    text-decoration: none;
    background-color: #0d6efd; }
  .dropdown-item.disabled, .dropdown-item:disabled {
    color: #6c757d;
    pointer-events: none;
    background-color: transparent; }

.dropdown-menu.show {
  display: block; }

.dropdown-header {
  display: block;
  padding: 0.5rem 1rem;
  margin-bottom: 0;
  font-size: 0.875rem;
  color: #6c757d;
  white-space: nowrap; }

.dropdown-item-text {
  display: block;
  padding: 0.25rem 1rem;
  color: #212529; }

.dropdown-menu-dark {
  color: #dee2e6;
  background-color: #343a40;
  border-color: rgba(0, 0, 0, 0.15); }
  .dropdown-menu-dark .dropdown-item {
    color: #dee2e6; }
    .dropdown-menu-dark .dropdown-item:hover, .dropdown-menu-dark .dropdown-item:focus {
      color: #fff;
      background-color: rgba(255, 255, 255, 0.15); }
    .dropdown-menu-dark .dropdown-item.active, .dropdown-menu-dark .dropdown-item:active {
      color: #fff;
      background-color: #0d6efd; }
    .dropdown-menu-dark .dropdown-item.disabled, .dropdown-menu-dark .dropdown-item:disabled {
      color: #adb5bd; }
  .dropdown-menu-dark .dropdown-divider {
    border-color: rgba(0, 0, 0, 0.15); }
  .dropdown-menu-dark .dropdown-item-text {
    color: #dee2e6; }
  .dropdown-menu-dark .dropdown-header {
    color: #adb5bd; }

.btn-group,
.btn-group-vertical {
  position: relative;
  display: inline-flex;
  vertical-align: middle; }
  .btn-group > .btn,
  .btn-group-vertical > .btn {
    position: relative;
    flex: 1 1 auto; }
  .btn-group > .btn-check:checked + .btn,
  .btn-group > .btn-check:focus + .btn,
  .btn-group > .btn:hover,
  .btn-group > .btn:focus,
  .btn-group > .btn:active,
  .btn-group > .btn.active,
  .btn-group-vertical > .btn-check:checked + .btn,
  .btn-group-vertical > .btn-check:focus + .btn,
  .btn-group-vertical > .btn:hover,
  .btn-group-vertical > .btn:focus,
  .btn-group-vertical > .btn:active,
  .btn-group-vertical > .btn.active {
    z-index: 1; }

.btn-toolbar {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start; }
  .btn-toolbar .input-group {
    width: auto; }

.btn-group > .btn:not(:first-child),
.btn-group > .btn-group:not(:first-child) {
  margin-left: -1px; }

.btn-group > .btn:not(:last-child):not(.dropdown-toggle),
.btn-group > .btn-group:not(:last-child) > .btn {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0; }

.btn-group > .btn:nth-child(n + 3),
.btn-group > :not(.btn-check) + .btn,
.btn-group > .btn-group:not(:first-child) > .btn {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0; }

.dropdown-toggle-split {
  padding-right: 0.5625rem;
  padding-left: 0.5625rem; }
  .dropdown-toggle-split::after,
  .dropup .dropdown-toggle-split::after,
  .dropright .dropdown-toggle-split::after {
    margin-left: 0; }
  .dropleft .dropdown-toggle-split::before {
    margin-right: 0; }

.btn-sm + .dropdown-toggle-split, .btn-group-sm > .btn + .dropdown-toggle-split {
  padding-right: 0.375rem;
  padding-left: 0.375rem; }

.btn-lg + .dropdown-toggle-split, .btn-group-lg > .btn + .dropdown-toggle-split {
  padding-right: 0.75rem;
  padding-left: 0.75rem; }

.btn-group-vertical {
  flex-direction: column;
  align-items: flex-start;
  justify-content: center; }
  .btn-group-vertical > .btn,
  .btn-group-vertical > .btn-group {
    width: 100%; }
  .btn-group-vertical > .btn:not(:first-child),
  .btn-group-vertical > .btn-group:not(:first-child) {
    margin-top: -1px; }
  .btn-group-vertical > .btn:not(:last-child):not(.dropdown-toggle),
  .btn-group-vertical > .btn-group:not(:last-child) > .btn {
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0; }
  .btn-group-vertical > .btn:not(:first-child),
  .btn-group-vertical > .btn-group:not(:first-child) > .btn {
    border-top-left-radius: 0;
    border-top-right-radius: 0; }

.nav {
  display: flex;
  flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none; }

.nav-link {
  display: block;
  padding: 0.5rem 1rem;
  text-decoration: none;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    .nav-link {
      transition: none; } }
  .nav-link.disabled {
    color: #6c757d;
    pointer-events: none;
    cursor: default; }

.nav-tabs {
  border-bottom: 1px solid #dee2e6; }
  .nav-tabs .nav-link {
    margin-bottom: -1px;
    border: 1px solid transparent;
    border-top-left-radius: 0.25rem;
    border-top-right-radius: 0.25rem; }
    .nav-tabs .nav-link:hover, .nav-tabs .nav-link:focus {
      border-color: #e9ecef #e9ecef #dee2e6; }
    .nav-tabs .nav-link.disabled {
      color: #6c757d;
      background-color: transparent;
      border-color: transparent; }
  .nav-tabs .nav-link.active,
  .nav-tabs .nav-item.show .nav-link {
    color: #495057;
    background-color: #fff;
    border-color: #dee2e6 #dee2e6 #fff; }
  .nav-tabs .dropdown-menu {
    margin-top: -1px;
    border-top-left-radius: 0;
    border-top-right-radius: 0; }

.nav-pills .nav-link {
  border-radius: 0.25rem; }

.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  color: #fff;
  background-color: #0d6efd; }

.nav-fill > .nav-link,
.nav-fill .nav-item {
  flex: 1 1 auto;
  text-align: center; }

.nav-justified > .nav-link,
.nav-justified .nav-item {
  flex-basis: 0;
  flex-grow: 1;
  text-align: center; }

.tab-content > .tab-pane {
  display: none; }

.tab-content > .active {
  display: block; }

.navbar {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem; }
  .navbar > .container,
  .navbar > .container-fluid, .navbar > .container-sm, .navbar > .container-md, .navbar > .container-lg, .navbar > .container-xl, .navbar > .container-xxl {
    display: flex;
    flex-wrap: inherit;
    align-items: center;
    justify-content: space-between; }

.navbar-brand {
  padding-top: 0.3125rem;
  padding-bottom: 0.3125rem;
  margin-right: 1rem;
  font-size: 1.25rem;
  text-decoration: none;
  white-space: nowrap; }

.navbar-nav {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none; }
  .navbar-nav .nav-link {
    padding-right: 0;
    padding-left: 0; }
  .navbar-nav .dropdown-menu {
    position: static; }

.navbar-text {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem; }

.navbar-collapse {
  align-items: center;
  width: 100%; }

.navbar-toggler {
  padding: 0.25rem 0.75rem;
  font-size: 1.25rem;
  line-height: 1;
  background-color: transparent;
  border: 1px solid transparent;
  border-radius: 0.25rem;
  transition: box-shadow 0.15s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    .navbar-toggler {
      transition: none; } }
  .navbar-toggler:hover {
    text-decoration: none; }
  .navbar-toggler:focus {
    text-decoration: none;
    outline: 0;
    box-shadow: 0 0 0 0.25rem; }

.navbar-toggler-icon {
  display: inline-block;
  width: 1.5em;
  height: 1.5em;
  vertical-align: middle;
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100%; }

@media (min-width: 576px) {
  .navbar-expand-sm {
    flex-wrap: nowrap;
    justify-content: flex-start; }
    .navbar-expand-sm .navbar-nav {
      flex-direction: row; }
      .navbar-expand-sm .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-sm .navbar-nav .nav-link {
        padding-right: 0.5rem;
        padding-left: 0.5rem; }
    .navbar-expand-sm .navbar-collapse {
      display: flex !important; }
    .navbar-expand-sm .navbar-toggler {
      display: none; } }

@media (min-width: 768px) {
  .navbar-expand-md {
    flex-wrap: nowrap;
    justify-content: flex-start; }
    .navbar-expand-md .navbar-nav {
      flex-direction: row; }
      .navbar-expand-md .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-md .navbar-nav .nav-link {
        padding-right: 0.5rem;
        padding-left: 0.5rem; }
    .navbar-expand-md .navbar-collapse {
      display: flex !important; }
    .navbar-expand-md .navbar-toggler {
      display: none; } }

@media (min-width: 992px) {
  .navbar-expand-lg {
    flex-wrap: nowrap;
    justify-content: flex-start; }
    .navbar-expand-lg .navbar-nav {
      flex-direction: row; }
      .navbar-expand-lg .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-lg .navbar-nav .nav-link {
        padding-right: 0.5rem;
        padding-left: 0.5rem; }
    .navbar-expand-lg .navbar-collapse {
      display: flex !important; }
    .navbar-expand-lg .navbar-toggler {
      display: none; } }

@media (min-width: 1200px) {
  .navbar-expand-xl {
    flex-wrap: nowrap;
    justify-content: flex-start; }
    .navbar-expand-xl .navbar-nav {
      flex-direction: row; }
      .navbar-expand-xl .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-xl .navbar-nav .nav-link {
        padding-right: 0.5rem;
        padding-left: 0.5rem; }
    .navbar-expand-xl .navbar-collapse {
      display: flex !important; }
    .navbar-expand-xl .navbar-toggler {
      display: none; } }

@media (min-width: 1400px) {
  .navbar-expand-xxl {
    flex-wrap: nowrap;
    justify-content: flex-start; }
    .navbar-expand-xxl .navbar-nav {
      flex-direction: row; }
      .navbar-expand-xxl .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-xxl .navbar-nav .nav-link {
        padding-right: 0.5rem;
        padding-left: 0.5rem; }
    .navbar-expand-xxl .navbar-collapse {
      display: flex !important; }
    .navbar-expand-xxl .navbar-toggler {
      display: none; } }

.navbar-expand {
  flex-wrap: nowrap;
  justify-content: flex-start; }
  .navbar-expand .navbar-nav {
    flex-direction: row; }
    .navbar-expand .navbar-nav .dropdown-menu {
      position: absolute; }
    .navbar-expand .navbar-nav .nav-link {
      padding-right: 0.5rem;
      padding-left: 0.5rem; }
  .navbar-expand .navbar-collapse {
    display: flex !important; }
  .navbar-expand .navbar-toggler {
    display: none; }

.navbar-light .navbar-brand {
  color: rgba(0, 0, 0, 0.9); }
  .navbar-light .navbar-brand:hover, .navbar-light .navbar-brand:focus {
    color: rgba(0, 0, 0, 0.9); }

.navbar-light .navbar-nav .nav-link {
  color: rgba(0, 0, 0, 0.55); }
  .navbar-light .navbar-nav .nav-link:hover, .navbar-light .navbar-nav .nav-link:focus {
    color: rgba(0, 0, 0, 0.7); }
  .navbar-light .navbar-nav .nav-link.disabled {
    color: rgba(0, 0, 0, 0.3); }

.navbar-light .navbar-nav .show > .nav-link,
.navbar-light .navbar-nav .nav-link.active {
  color: rgba(0, 0, 0, 0.9); }

.navbar-light .navbar-toggler {
  color: rgba(0, 0, 0, 0.55);
  border-color: rgba(0, 0, 0, 0.1); }

.navbar-light .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%280, 0, 0, 0.55%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e"); }

.navbar-light .navbar-text {
  color: rgba(0, 0, 0, 0.55); }
  .navbar-light .navbar-text a,
  .navbar-light .navbar-text a:hover,
  .navbar-light .navbar-text a:focus {
    color: rgba(0, 0, 0, 0.9); }

.navbar-dark .navbar-brand {
  color: #fff; }
  .navbar-dark .navbar-brand:hover, .navbar-dark .navbar-brand:focus {
    color: #fff; }

.navbar-dark .navbar-nav .nav-link {
  color: rgba(255, 255, 255, 0.55); }
  .navbar-dark .navbar-nav .nav-link:hover, .navbar-dark .navbar-nav .nav-link:focus {
    color: rgba(255, 255, 255, 0.75); }
  .navbar-dark .navbar-nav .nav-link.disabled {
    color: rgba(255, 255, 255, 0.25); }

.navbar-dark .navbar-nav .show > .nav-link,
.navbar-dark .navbar-nav .nav-link.active {
  color: #fff; }

.navbar-dark .navbar-toggler {
  color: rgba(255, 255, 255, 0.55);
  border-color: rgba(255, 255, 255, 0.1); }

.navbar-dark .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%28255, 255, 255, 0.55%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e"); }

.navbar-dark .navbar-text {
  color: rgba(255, 255, 255, 0.55); }
  .navbar-dark .navbar-text a,
  .navbar-dark .navbar-text a:hover,
  .navbar-dark .navbar-text a:focus {
    color: #fff; }

.card {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 0.25rem; }
  .card > hr {
    margin-right: 0;
    margin-left: 0; }
  .card > .list-group {
    border-top: inherit;
    border-bottom: inherit; }
    .card > .list-group:first-child {
      border-top-width: 0;
      border-top-left-radius: calc(0.25rem - 1px);
      border-top-right-radius: calc(0.25rem - 1px); }
    .card > .list-group:last-child {
      border-bottom-width: 0;
      border-bottom-right-radius: calc(0.25rem - 1px);
      border-bottom-left-radius: calc(0.25rem - 1px); }
  .card > .card-header + .list-group,
  .card > .list-group + .card-footer {
    border-top: 0; }

.card-body {
  flex: 1 1 auto;
  padding: 1rem 1rem; }

.card-title {
  margin-bottom: 0.5rem; }

.card-subtitle {
  margin-top: -0.25rem;
  margin-bottom: 0; }

.card-text:last-child {
  margin-bottom: 0; }

.card-link:hover {
  text-decoration: none; }

.card-link + .card-link {
  margin-left: 1rem; }

.card-header {
  padding: 0.5rem 1rem;
  margin-bottom: 0;
  background-color: rgba(0, 0, 0, 0.03);
  border-bottom: 1px solid rgba(0, 0, 0, 0.125); }
  .card-header:first-child {
    border-radius: calc(0.25rem - 1px) calc(0.25rem - 1px) 0 0; }

.card-footer {
  padding: 0.5rem 1rem;
  background-color: rgba(0, 0, 0, 0.03);
  border-top: 1px solid rgba(0, 0, 0, 0.125); }
  .card-footer:last-child {
    border-radius: 0 0 calc(0.25rem - 1px) calc(0.25rem - 1px); }

.card-header-tabs {
  margin-right: -0.5rem;
  margin-bottom: -0.5rem;
  margin-left: -0.5rem;
  border-bottom: 0; }

.card-header-pills {
  margin-right: -0.5rem;
  margin-left: -0.5rem; }

.card-img-overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 1rem;
  border-radius: calc(0.25rem - 1px); }

.card-img,
.card-img-top,
.card-img-bottom {
  width: 100%; }

.card-img,
.card-img-top {
  border-top-left-radius: calc(0.25rem - 1px);
  border-top-right-radius: calc(0.25rem - 1px); }

.card-img,
.card-img-bottom {
  border-bottom-right-radius: calc(0.25rem - 1px);
  border-bottom-left-radius: calc(0.25rem - 1px); }

.card-group > .card {
  margin-bottom: 0.75rem; }

@media (min-width: 576px) {
  .card-group {
    display: flex;
    flex-flow: row wrap; }
    .card-group > .card {
      flex: 1 0 0%;
      margin-bottom: 0; }
      .card-group > .card + .card {
        margin-left: 0;
        border-left: 0; }
      .card-group > .card:not(:last-child) {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0; }
        .card-group > .card:not(:last-child) .card-img-top,
        .card-group > .card:not(:last-child) .card-header {
          border-top-right-radius: 0; }
        .card-group > .card:not(:last-child) .card-img-bottom,
        .card-group > .card:not(:last-child) .card-footer {
          border-bottom-right-radius: 0; }
      .card-group > .card:not(:first-child) {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0; }
        .card-group > .card:not(:first-child) .card-img-top,
        .card-group > .card:not(:first-child) .card-header {
          border-top-left-radius: 0; }
        .card-group > .card:not(:first-child) .card-img-bottom,
        .card-group > .card:not(:first-child) .card-footer {
          border-bottom-left-radius: 0; } }

.accordion {
  overflow-anchor: none; }
  .accordion > .card {
    overflow: hidden; }
    .accordion > .card:not(:last-of-type) {
      border-bottom: 0;
      border-bottom-right-radius: 0;
      border-bottom-left-radius: 0; }
    .accordion > .card:not(:first-of-type) {
      border-top-left-radius: 0;
      border-top-right-radius: 0; }
    .accordion > .card > .card-header {
      border-radius: 0;
      margin-bottom: -1px; }

.breadcrumb {
  display: flex;
  flex-wrap: wrap;
  padding: 0.5rem 1rem;
  margin-bottom: 1rem;
  list-style: none;
  background-color: #e9ecef;
  border-radius: 0.25rem; }

.breadcrumb-item {
  display: flex; }
  .breadcrumb-item + .breadcrumb-item {
    padding-left: 0.5rem; }
    .breadcrumb-item + .breadcrumb-item::before {
      display: inline-block;
      padding-right: 0.5rem;
      color: #6c757d;
      content: "/"; }
  .breadcrumb-item.active {
    color: #6c757d; }

.pagination {
  display: flex;
  padding-left: 0;
  list-style: none; }

.page-link {
  position: relative;
  display: block;
  color: #0d6efd;
  text-decoration: none;
  background-color: #fff;
  border: 1px solid #dee2e6;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    .page-link {
      transition: none; } }
  .page-link:hover {
    z-index: 2;
    color: #024dbc;
    background-color: #e9ecef;
    border-color: #dee2e6; }
  .page-link:focus {
    z-index: 3;
    color: #024dbc;
    background-color: #e9ecef;
    outline: 0;
    box-shadow: 0 0 0 0.25rem rgba(13, 110, 253, 0.25); }

.page-item:not(:first-child) .page-link {
  margin-left: -1px; }

.page-item.active .page-link {
  z-index: 3;
  color: #fff;
  background-color: #0d6efd;
  border-color: #0d6efd; }

.page-item.disabled .page-link {
  color: #6c757d;
  pointer-events: none;
  background-color: #fff;
  border-color: #dee2e6; }

.page-link {
  padding: 0.375rem 0.75rem; }

.page-item:first-child .page-link {
  border-top-left-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem; }

.page-item:last-child .page-link {
  border-top-right-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem; }

.pagination-lg .page-link {
  padding: 0.75rem 1.5rem;
  font-size: 1.25rem; }

.pagination-lg .page-item:first-child .page-link {
  border-top-left-radius: 0.3rem;
  border-bottom-left-radius: 0.3rem; }

.pagination-lg .page-item:last-child .page-link {
  border-top-right-radius: 0.3rem;
  border-bottom-right-radius: 0.3rem; }

.pagination-sm .page-link {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem; }

.pagination-sm .page-item:first-child .page-link {
  border-top-left-radius: 0.2rem;
  border-bottom-left-radius: 0.2rem; }

.pagination-sm .page-item:last-child .page-link {
  border-top-right-radius: 0.2rem;
  border-bottom-right-radius: 0.2rem; }

.badge {
  display: inline-block;
  padding: 0.35em 0.65em;
  font-size: 0.75em;
  font-weight: 700;
  line-height: 1;
  color: #fff;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 0.25rem; }
  .badge:empty {
    display: none; }

.btn .badge {
  position: relative;
  top: -1px; }

.alert {
  position: relative;
  padding: 1rem 1rem;
  margin-bottom: 1rem;
  border: 1px solid transparent;
  border-radius: 0.25rem; }

.alert-heading {
  color: inherit; }

.alert-link {
  font-weight: 700; }

.alert-dismissible {
  padding-right: 3rem; }
  .alert-dismissible .btn-close {
    position: absolute;
    top: 0;
    right: 0;
    padding: 1.25rem 1rem; }

.alert-primary {
  color: #073984;
  background-color: #cfe2ff;
  border-color: #bbd6fe; }
  .alert-primary .alert-link {
    color: #042454; }

.alert-secondary {
  color: #383d41;
  background-color: #e2e3e5;
  border-color: #d6d8db; }
  .alert-secondary .alert-link {
    color: #202326; }

.alert-success {
  color: #0d462c;
  background-color: #d1e7dd;
  border-color: #bfddcf; }
  .alert-success .alert-link {
    color: #051b11; }

.alert-info {
  color: #07697d;
  background-color: #cff4fc;
  border-color: #bbf0fb; }
  .alert-info .alert-link {
    color: #04404d; }

.alert-warning {
  color: #856404;
  background-color: #fff3cd;
  border-color: #ffeeba; }
  .alert-warning .alert-link {
    color: #533f03; }

.alert-danger {
  color: #721c24;
  background-color: #f8d7da;
  border-color: #f5c6cb; }
  .alert-danger .alert-link {
    color: #491217; }

.alert-light {
  color: #818182;
  background-color: #fefefe;
  border-color: #fdfdfe; }
  .alert-light .alert-link {
    color: #686868; }

.alert-dark {
  color: #1b1e21;
  background-color: #d6d8d9;
  border-color: #c6c8ca; }
  .alert-dark .alert-link {
    color: #040505; }

@keyframes progress-bar-stripes {
  0% {
    background-position-x: 1rem; } }

.progress {
  display: flex;
  height: 1rem;
  overflow: hidden;
  font-size: 0.75rem;
  background-color: #e9ecef;
  border-radius: 0.25rem; }

.progress-bar {
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow: hidden;
  color: #fff;
  text-align: center;
  white-space: nowrap;
  background-color: #0d6efd;
  transition: width 0.6s ease; }
  @media (prefers-reduced-motion: reduce) {
    .progress-bar {
      transition: none; } }

.progress-bar-striped {
  background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
  background-size: 1rem 1rem; }

.progress-bar-animated {
  animation: progress-bar-stripes 1s linear infinite; }
  @media (prefers-reduced-motion: reduce) {
    .progress-bar-animated {
      animation: none; } }

.list-group {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  border-radius: 0.25rem; }

.list-group-item-action {
  width: 100%;
  color: #495057;
  text-align: inherit; }
  .list-group-item-action:hover, .list-group-item-action:focus {
    z-index: 1;
    color: #495057;
    text-decoration: none;
    background-color: #f8f9fa; }
  .list-group-item-action:active {
    color: #212529;
    background-color: #e9ecef; }

.list-group-item {
  position: relative;
  display: block;
  padding: 0.5rem 1rem;
  text-decoration: none;
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.125); }
  .list-group-item:first-child {
    border-top-left-radius: inherit;
    border-top-right-radius: inherit; }
  .list-group-item:last-child {
    border-bottom-right-radius: inherit;
    border-bottom-left-radius: inherit; }
  .list-group-item.disabled, .list-group-item:disabled {
    color: #6c757d;
    pointer-events: none;
    background-color: #fff; }
  .list-group-item.active {
    z-index: 2;
    color: #fff;
    background-color: #0d6efd;
    border-color: #0d6efd; }
  .list-group-item + .list-group-item {
    border-top-width: 0; }
    .list-group-item + .list-group-item.active {
      margin-top: -1px;
      border-top-width: 1px; }

.list-group-horizontal {
  flex-direction: row; }
  .list-group-horizontal > .list-group-item:first-child {
    border-bottom-left-radius: 0.25rem;
    border-top-right-radius: 0; }
  .list-group-horizontal > .list-group-item:last-child {
    border-top-right-radius: 0.25rem;
    border-bottom-left-radius: 0; }
  .list-group-horizontal > .list-group-item.active {
    margin-top: 0; }
  .list-group-horizontal > .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0; }
    .list-group-horizontal > .list-group-item + .list-group-item.active {
      margin-left: -1px;
      border-left-width: 1px; }

@media (min-width: 576px) {
  .list-group-horizontal-sm {
    flex-direction: row; }
    .list-group-horizontal-sm > .list-group-item:first-child {
      border-bottom-left-radius: 0.25rem;
      border-top-right-radius: 0; }
    .list-group-horizontal-sm > .list-group-item:last-child {
      border-top-right-radius: 0.25rem;
      border-bottom-left-radius: 0; }
    .list-group-horizontal-sm > .list-group-item.active {
      margin-top: 0; }
    .list-group-horizontal-sm > .list-group-item + .list-group-item {
      border-top-width: 1px;
      border-left-width: 0; }
      .list-group-horizontal-sm > .list-group-item + .list-group-item.active {
        margin-left: -1px;
        border-left-width: 1px; } }

@media (min-width: 768px) {
  .list-group-horizontal-md {
    flex-direction: row; }
    .list-group-horizontal-md > .list-group-item:first-child {
      border-bottom-left-radius: 0.25rem;
      border-top-right-radius: 0; }
    .list-group-horizontal-md > .list-group-item:last-child {
      border-top-right-radius: 0.25rem;
      border-bottom-left-radius: 0; }
    .list-group-horizontal-md > .list-group-item.active {
      margin-top: 0; }
    .list-group-horizontal-md > .list-group-item + .list-group-item {
      border-top-width: 1px;
      border-left-width: 0; }
      .list-group-horizontal-md > .list-group-item + .list-group-item.active {
        margin-left: -1px;
        border-left-width: 1px; } }

@media (min-width: 992px) {
  .list-group-horizontal-lg {
    flex-direction: row; }
    .list-group-horizontal-lg > .list-group-item:first-child {
      border-bottom-left-radius: 0.25rem;
      border-top-right-radius: 0; }
    .list-group-horizontal-lg > .list-group-item:last-child {
      border-top-right-radius: 0.25rem;
      border-bottom-left-radius: 0; }
    .list-group-horizontal-lg > .list-group-item.active {
      margin-top: 0; }
    .list-group-horizontal-lg > .list-group-item + .list-group-item {
      border-top-width: 1px;
      border-left-width: 0; }
      .list-group-horizontal-lg > .list-group-item + .list-group-item.active {
        margin-left: -1px;
        border-left-width: 1px; } }

@media (min-width: 1200px) {
  .list-group-horizontal-xl {
    flex-direction: row; }
    .list-group-horizontal-xl > .list-group-item:first-child {
      border-bottom-left-radius: 0.25rem;
      border-top-right-radius: 0; }
    .list-group-horizontal-xl > .list-group-item:last-child {
      border-top-right-radius: 0.25rem;
      border-bottom-left-radius: 0; }
    .list-group-horizontal-xl > .list-group-item.active {
      margin-top: 0; }
    .list-group-horizontal-xl > .list-group-item + .list-group-item {
      border-top-width: 1px;
      border-left-width: 0; }
      .list-group-horizontal-xl > .list-group-item + .list-group-item.active {
        margin-left: -1px;
        border-left-width: 1px; } }

@media (min-width: 1400px) {
  .list-group-horizontal-xxl {
    flex-direction: row; }
    .list-group-horizontal-xxl > .list-group-item:first-child {
      border-bottom-left-radius: 0.25rem;
      border-top-right-radius: 0; }
    .list-group-horizontal-xxl > .list-group-item:last-child {
      border-top-right-radius: 0.25rem;
      border-bottom-left-radius: 0; }
    .list-group-horizontal-xxl > .list-group-item.active {
      margin-top: 0; }
    .list-group-horizontal-xxl > .list-group-item + .list-group-item {
      border-top-width: 1px;
      border-left-width: 0; }
      .list-group-horizontal-xxl > .list-group-item + .list-group-item.active {
        margin-left: -1px;
        border-left-width: 1px; } }

.list-group-flush {
  border-radius: 0; }
  .list-group-flush > .list-group-item {
    border-width: 0 0 1px; }
    .list-group-flush > .list-group-item:last-child {
      border-bottom-width: 0; }

.list-group-item-primary {
  color: #073984;
  background-color: #bbd6fe; }
  .list-group-item-primary.list-group-item-action:hover, .list-group-item-primary.list-group-item-action:focus {
    color: #073984;
    background-color: #a2c7fe; }
  .list-group-item-primary.list-group-item-action.active {
    color: #fff;
    background-color: #073984;
    border-color: #073984; }

.list-group-item-secondary {
  color: #383d41;
  background-color: #d6d8db; }
  .list-group-item-secondary.list-group-item-action:hover, .list-group-item-secondary.list-group-item-action:focus {
    color: #383d41;
    background-color: #c8cbcf; }
  .list-group-item-secondary.list-group-item-action.active {
    color: #fff;
    background-color: #383d41;
    border-color: #383d41; }

.list-group-item-success {
  color: #0d462c;
  background-color: #bfddcf; }
  .list-group-item-success.list-group-item-action:hover, .list-group-item-success.list-group-item-action:focus {
    color: #0d462c;
    background-color: #aed4c3; }
  .list-group-item-success.list-group-item-action.active {
    color: #fff;
    background-color: #0d462c;
    border-color: #0d462c; }

.list-group-item-info {
  color: #07697d;
  background-color: #bbf0fb; }
  .list-group-item-info.list-group-item-action:hover, .list-group-item-info.list-group-item-action:focus {
    color: #07697d;
    background-color: #a3ebfa; }
  .list-group-item-info.list-group-item-action.active {
    color: #fff;
    background-color: #07697d;
    border-color: #07697d; }

.list-group-item-warning {
  color: #856404;
  background-color: #ffeeba; }
  .list-group-item-warning.list-group-item-action:hover, .list-group-item-warning.list-group-item-action:focus {
    color: #856404;
    background-color: #ffe8a1; }
  .list-group-item-warning.list-group-item-action.active {
    color: #fff;
    background-color: #856404;
    border-color: #856404; }

.list-group-item-danger {
  color: #721c24;
  background-color: #f5c6cb; }
  .list-group-item-danger.list-group-item-action:hover, .list-group-item-danger.list-group-item-action:focus {
    color: #721c24;
    background-color: #f1b0b7; }
  .list-group-item-danger.list-group-item-action.active {
    color: #fff;
    background-color: #721c24;
    border-color: #721c24; }

.list-group-item-light {
  color: #818182;
  background-color: #fdfdfe; }
  .list-group-item-light.list-group-item-action:hover, .list-group-item-light.list-group-item-action:focus {
    color: #818182;
    background-color: #ececf6; }
  .list-group-item-light.list-group-item-action.active {
    color: #fff;
    background-color: #818182;
    border-color: #818182; }

.list-group-item-dark {
  color: #1b1e21;
  background-color: #c6c8ca; }
  .list-group-item-dark.list-group-item-action:hover, .list-group-item-dark.list-group-item-action:focus {
    color: #1b1e21;
    background-color: #b9bbbe; }
  .list-group-item-dark.list-group-item-action.active {
    color: #fff;
    background-color: #1b1e21;
    border-color: #1b1e21; }

.btn-close {
  box-sizing: content-box;
  width: 1em;
  height: 1em;
  padding: 0.25em 0.25em;
  color: #000;
  background: transparent url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23000' viewBox='0 0 16 16'%3e%3cpath d='M.293.293a1 1 0 011.414 0L8 6.586 14.293.293a1 1 0 111.414 1.414L9.414 8l6.293 6.293a1 1 0 01-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 01-1.414-1.414L6.586 8 .293 1.707a1 1 0 010-1.414z'/%3e%3c/svg%3e") no-repeat center center/1em auto;
  background-clip: content-box;
  border: 0;
  border-radius: 0.25rem;
  opacity: 0.5; }
  .btn-close:hover {
    color: #000;
    text-decoration: none;
    opacity: 0.75; }
  .btn-close:focus {
    outline: none;
    box-shadow: 0 0 0 0.25rem rgba(13, 110, 253, 0.25);
    opacity: 1; }
  .btn-close:disabled, .btn-close.disabled {
    pointer-events: none;
    user-select: none;
    opacity: 0.25; }

.btn-close-white {
  filter: invert(1) grayscale(100%) brightness(200%); }

.toast {
  max-width: 350px;
  font-size: 0.875rem;
  background-color: rgba(255, 255, 255, 0.85);
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.1);
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
  opacity: 0;
  border-radius: 0.25rem; }
  .toast:not(:last-child) {
    margin-bottom: 0.75rem; }
  .toast.showing {
    opacity: 1; }
  .toast.show {
    display: block;
    opacity: 1; }
  .toast.hide {
    display: none; }

.toast-header {
  display: flex;
  align-items: center;
  padding: 0.5rem 0.75rem;
  color: #6c757d;
  background-color: rgba(255, 255, 255, 0.85);
  background-clip: padding-box;
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
  border-top-left-radius: calc(0.25rem - 1px);
  border-top-right-radius: calc(0.25rem - 1px); }
  .toast-header .btn-close {
    margin-right: -0.375rem;
    margin-left: 0.75rem; }

.toast-body {
  padding: 0.75rem; }

.modal-open {
  overflow: hidden; }
  .modal-open .modal {
    overflow-x: hidden;
    overflow-y: auto; }

.modal {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1050;
  display: none;
  width: 100%;
  height: 100%;
  overflow: hidden;
  outline: 0; }

.modal-dialog {
  position: relative;
  width: auto;
  margin: 0.5rem;
  pointer-events: none; }
  .modal.fade .modal-dialog {
    transition: transform 0.3s ease-out;
    transform: translate(0, -50px); }
    @media (prefers-reduced-motion: reduce) {
      .modal.fade .modal-dialog {
        transition: none; } }
  .modal.show .modal-dialog {
    transform: none; }
  .modal.modal-static .modal-dialog {
    transform: scale(1.02); }

.modal-dialog-scrollable {
  height: calc(100% - 1rem); }
  .modal-dialog-scrollable .modal-content {
    max-height: 100%;
    overflow: hidden; }
  .modal-dialog-scrollable .modal-body {
    overflow-y: auto; }

.modal-dialog-centered {
  display: flex;
  align-items: center;
  min-height: calc(100% - 1rem); }

.modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  pointer-events: auto;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.3rem;
  outline: 0; }

.modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1040;
  width: 100vw;
  height: 100vh;
  background-color: #000; }
  .modal-backdrop.fade {
    opacity: 0; }
  .modal-backdrop.show {
    opacity: 0.5; }

.modal-header {
  display: flex;
  flex-shrink: 0;
  align-items: center;
  justify-content: space-between;
  padding: 1rem 1rem;
  border-bottom: 1px solid #dee2e6;
  border-top-left-radius: calc(0.3rem - 1px);
  border-top-right-radius: calc(0.3rem - 1px); }
  .modal-header .btn-close {
    padding: 0.5rem 0.5rem;
    margin: -0.5rem -0.5rem -0.5rem auto; }

.modal-title {
  margin-bottom: 0;
  line-height: 1.5; }

.modal-body {
  position: relative;
  flex: 1 1 auto;
  padding: 1rem; }

.modal-footer {
  display: flex;
  flex-wrap: wrap;
  flex-shrink: 0;
  align-items: center;
  justify-content: flex-end;
  padding: 0.75rem;
  border-top: 1px solid #dee2e6;
  border-bottom-right-radius: calc(0.3rem - 1px);
  border-bottom-left-radius: calc(0.3rem - 1px); }
  .modal-footer > * {
    margin: 0.25rem; }

.modal-scrollbar-measure {
  position: absolute;
  top: -9999px;
  width: 50px;
  height: 50px;
  overflow: scroll; }

@media (min-width: 576px) {
  .modal-dialog {
    max-width: 500px;
    margin: 1.75rem auto; }
  .modal-dialog-scrollable {
    height: calc(100% - 3.5rem); }
  .modal-dialog-centered {
    min-height: calc(100% - 3.5rem); }
  .modal-sm {
    max-width: 300px; } }

@media (min-width: 992px) {
  .modal-lg,
  .modal-xl {
    max-width: 800px; } }

@media (min-width: 1200px) {
  .modal-xl {
    max-width: 1140px; } }

.modal-fullscreen {
  width: 100vw;
  max-width: none;
  height: 100%;
  margin: 0; }
  .modal-fullscreen .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0; }
  .modal-fullscreen .modal-header {
    border-radius: 0; }
  .modal-fullscreen .modal-body {
    overflow-y: auto; }
  .modal-fullscreen .modal-footer {
    border-radius: 0; }

@media (max-width: 575.98px) {
  .modal-fullscreen-sm-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0; }
    .modal-fullscreen-sm-down .modal-content {
      height: 100%;
      border: 0;
      border-radius: 0; }
    .modal-fullscreen-sm-down .modal-header {
      border-radius: 0; }
    .modal-fullscreen-sm-down .modal-body {
      overflow-y: auto; }
    .modal-fullscreen-sm-down .modal-footer {
      border-radius: 0; } }

@media (max-width: 767.98px) {
  .modal-fullscreen-md-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0; }
    .modal-fullscreen-md-down .modal-content {
      height: 100%;
      border: 0;
      border-radius: 0; }
    .modal-fullscreen-md-down .modal-header {
      border-radius: 0; }
    .modal-fullscreen-md-down .modal-body {
      overflow-y: auto; }
    .modal-fullscreen-md-down .modal-footer {
      border-radius: 0; } }

@media (max-width: 991.98px) {
  .modal-fullscreen-lg-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0; }
    .modal-fullscreen-lg-down .modal-content {
      height: 100%;
      border: 0;
      border-radius: 0; }
    .modal-fullscreen-lg-down .modal-header {
      border-radius: 0; }
    .modal-fullscreen-lg-down .modal-body {
      overflow-y: auto; }
    .modal-fullscreen-lg-down .modal-footer {
      border-radius: 0; } }

@media (max-width: 1199.98px) {
  .modal-fullscreen-xl-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0; }
    .modal-fullscreen-xl-down .modal-content {
      height: 100%;
      border: 0;
      border-radius: 0; }
    .modal-fullscreen-xl-down .modal-header {
      border-radius: 0; }
    .modal-fullscreen-xl-down .modal-body {
      overflow-y: auto; }
    .modal-fullscreen-xl-down .modal-footer {
      border-radius: 0; } }

@media (max-width: 1399.98px) {
  .modal-fullscreen-xxl-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0; }
    .modal-fullscreen-xxl-down .modal-content {
      height: 100%;
      border: 0;
      border-radius: 0; }
    .modal-fullscreen-xxl-down .modal-header {
      border-radius: 0; }
    .modal-fullscreen-xxl-down .modal-body {
      overflow-y: auto; }
    .modal-fullscreen-xxl-down .modal-footer {
      border-radius: 0; } }

.tooltip {
  position: absolute;
  z-index: 1070;
  display: block;
  margin: 0;
  font-family: var(--bs-font-sans-serif);
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  font-size: 0.875rem;
  word-wrap: break-word;
  opacity: 0; }
  .tooltip.show {
    opacity: 0.9; }
  .tooltip .tooltip-arrow {
    position: absolute;
    display: block;
    width: 0.8rem;
    height: 0.4rem; }
    .tooltip .tooltip-arrow::before {
      position: absolute;
      content: "";
      border-color: transparent;
      border-style: solid; }

.bs-tooltip-top, .bs-tooltip-auto[x-placement^="top"] {
  padding: 0.4rem 0; }
  .bs-tooltip-top .tooltip-arrow, .bs-tooltip-auto[x-placement^="top"] .tooltip-arrow {
    bottom: 0; }
    .bs-tooltip-top .tooltip-arrow::before, .bs-tooltip-auto[x-placement^="top"] .tooltip-arrow::before {
      top: 0;
      border-width: 0.4rem 0.4rem 0;
      border-top-color: #000; }

.bs-tooltip-right, .bs-tooltip-auto[x-placement^="right"] {
  padding: 0 0.4rem; }
  .bs-tooltip-right .tooltip-arrow, .bs-tooltip-auto[x-placement^="right"] .tooltip-arrow {
    left: 0;
    width: 0.4rem;
    height: 0.8rem; }
    .bs-tooltip-right .tooltip-arrow::before, .bs-tooltip-auto[x-placement^="right"] .tooltip-arrow::before {
      right: 0;
      border-width: 0.4rem 0.4rem 0.4rem 0;
      border-right-color: #000; }

.bs-tooltip-bottom, .bs-tooltip-auto[x-placement^="bottom"] {
  padding: 0.4rem 0; }
  .bs-tooltip-bottom .tooltip-arrow, .bs-tooltip-auto[x-placement^="bottom"] .tooltip-arrow {
    top: 0; }
    .bs-tooltip-bottom .tooltip-arrow::before, .bs-tooltip-auto[x-placement^="bottom"] .tooltip-arrow::before {
      bottom: 0;
      border-width: 0 0.4rem 0.4rem;
      border-bottom-color: #000; }

.bs-tooltip-left, .bs-tooltip-auto[x-placement^="left"] {
  padding: 0 0.4rem; }
  .bs-tooltip-left .tooltip-arrow, .bs-tooltip-auto[x-placement^="left"] .tooltip-arrow {
    right: 0;
    width: 0.4rem;
    height: 0.8rem; }
    .bs-tooltip-left .tooltip-arrow::before, .bs-tooltip-auto[x-placement^="left"] .tooltip-arrow::before {
      left: 0;
      border-width: 0.4rem 0 0.4rem 0.4rem;
      border-left-color: #000; }

.tooltip-inner {
  max-width: 200px;
  padding: 0.25rem 0.5rem;
  color: #fff;
  text-align: center;
  background-color: #000;
  border-radius: 0.25rem; }

.popover {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1060;
  display: block;
  max-width: 276px;
  font-family: var(--bs-font-sans-serif);
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  font-size: 0.875rem;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.3rem; }
  .popover .popover-arrow {
    position: absolute;
    display: block;
    width: 1rem;
    height: 0.5rem;
    margin: 0 0.3rem; }
    .popover .popover-arrow::before, .popover .popover-arrow::after {
      position: absolute;
      display: block;
      content: "";
      border-color: transparent;
      border-style: solid; }

.bs-popover-top, .bs-popover-auto[x-placement^="top"] {
  margin-bottom: 0.5rem; }
  .bs-popover-top > .popover-arrow, .bs-popover-auto[x-placement^="top"] > .popover-arrow {
    bottom: calc(-0.5rem - 1px); }
    .bs-popover-top > .popover-arrow::before, .bs-popover-auto[x-placement^="top"] > .popover-arrow::before {
      bottom: 0;
      border-width: 0.5rem 0.5rem 0;
      border-top-color: rgba(0, 0, 0, 0.25); }
    .bs-popover-top > .popover-arrow::after, .bs-popover-auto[x-placement^="top"] > .popover-arrow::after {
      bottom: 1px;
      border-width: 0.5rem 0.5rem 0;
      border-top-color: #fff; }

.bs-popover-right, .bs-popover-auto[x-placement^="right"] {
  margin-left: 0.5rem; }
  .bs-popover-right > .popover-arrow, .bs-popover-auto[x-placement^="right"] > .popover-arrow {
    left: calc(-0.5rem - 1px);
    width: 0.5rem;
    height: 1rem;
    margin: 0.3rem 0; }
    .bs-popover-right > .popover-arrow::before, .bs-popover-auto[x-placement^="right"] > .popover-arrow::before {
      left: 0;
      border-width: 0.5rem 0.5rem 0.5rem 0;
      border-right-color: rgba(0, 0, 0, 0.25); }
    .bs-popover-right > .popover-arrow::after, .bs-popover-auto[x-placement^="right"] > .popover-arrow::after {
      left: 1px;
      border-width: 0.5rem 0.5rem 0.5rem 0;
      border-right-color: #fff; }

.bs-popover-bottom, .bs-popover-auto[x-placement^="bottom"] {
  margin-top: 0.5rem; }
  .bs-popover-bottom > .popover-arrow, .bs-popover-auto[x-placement^="bottom"] > .popover-arrow {
    top: calc(-0.5rem - 1px); }
    .bs-popover-bottom > .popover-arrow::before, .bs-popover-auto[x-placement^="bottom"] > .popover-arrow::before {
      top: 0;
      border-width: 0 0.5rem 0.5rem 0.5rem;
      border-bottom-color: rgba(0, 0, 0, 0.25); }
    .bs-popover-bottom > .popover-arrow::after, .bs-popover-auto[x-placement^="bottom"] > .popover-arrow::after {
      top: 1px;
      border-width: 0 0.5rem 0.5rem 0.5rem;
      border-bottom-color: #fff; }
  .bs-popover-bottom .popover-header::before, .bs-popover-auto[x-placement^="bottom"] .popover-header::before {
    position: absolute;
    top: 0;
    left: 50%;
    display: block;
    width: 1rem;
    margin-left: -0.5rem;
    content: "";
    border-bottom: 1px solid #f7f7f7; }

.bs-popover-left, .bs-popover-auto[x-placement^="left"] {
  margin-right: 0.5rem; }
  .bs-popover-left > .popover-arrow, .bs-popover-auto[x-placement^="left"] > .popover-arrow {
    right: calc(-0.5rem - 1px);
    width: 0.5rem;
    height: 1rem;
    margin: 0.3rem 0; }
    .bs-popover-left > .popover-arrow::before, .bs-popover-auto[x-placement^="left"] > .popover-arrow::before {
      right: 0;
      border-width: 0.5rem 0 0.5rem 0.5rem;
      border-left-color: rgba(0, 0, 0, 0.25); }
    .bs-popover-left > .popover-arrow::after, .bs-popover-auto[x-placement^="left"] > .popover-arrow::after {
      right: 1px;
      border-width: 0.5rem 0 0.5rem 0.5rem;
      border-left-color: #fff; }

.popover-header {
  padding: 0.5rem 1rem;
  margin-bottom: 0;
  font-size: 1rem;
  background-color: #f7f7f7;
  border-bottom: 1px solid #ebebeb;
  border-top-left-radius: calc(0.3rem - 1px);
  border-top-right-radius: calc(0.3rem - 1px); }
  .popover-header:empty {
    display: none; }

.popover-body {
  padding: 1rem 1rem;
  color: #212529; }

.carousel {
  position: relative; }

.carousel.pointer-event {
  touch-action: pan-y; }

.carousel-inner {
  position: relative;
  width: 100%;
  overflow: hidden; }
  .carousel-inner::after {
    display: block;
    clear: both;
    content: ""; }

.carousel-item {
  position: relative;
  display: none;
  float: left;
  width: 100%;
  margin-right: -100%;
  backface-visibility: hidden;
  transition: transform 0.5s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    .carousel-item {
      transition: none; } }

.carousel-item.active,
.carousel-item-next,
.carousel-item-prev {
  display: block; }

.carousel-item-next:not(.carousel-item-left),
.active.carousel-item-right {
  transform: translateX(100%); }

.carousel-item-prev:not(.carousel-item-right),
.active.carousel-item-left {
  transform: translateX(-100%); }

.carousel-fade .carousel-item {
  opacity: 0;
  transition-property: opacity;
  transform: none; }

.carousel-fade .carousel-item.active,
.carousel-fade .carousel-item-next.carousel-item-left,
.carousel-fade .carousel-item-prev.carousel-item-right {
  z-index: 1;
  opacity: 1; }

.carousel-fade .active.carousel-item-left,
.carousel-fade .active.carousel-item-right {
  z-index: 0;
  opacity: 0;
  transition: opacity 0s 0.5s; }
  @media (prefers-reduced-motion: reduce) {
    .carousel-fade .active.carousel-item-left,
    .carousel-fade .active.carousel-item-right {
      transition: none; } }

.carousel-control-prev,
.carousel-control-next {
  position: absolute;
  top: 0;
  bottom: 0;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 15%;
  color: #fff;
  text-align: center;
  opacity: 0.5;
  transition: opacity 0.15s ease; }
  @media (prefers-reduced-motion: reduce) {
    .carousel-control-prev,
    .carousel-control-next {
      transition: none; } }
  .carousel-control-prev:hover, .carousel-control-prev:focus,
  .carousel-control-next:hover,
  .carousel-control-next:focus {
    color: #fff;
    text-decoration: none;
    outline: 0;
    opacity: 0.9; }

.carousel-control-prev {
  left: 0; }

.carousel-control-next {
  right: 0; }

.carousel-control-prev-icon,
.carousel-control-next-icon {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  background-repeat: no-repeat;
  background-position: 50%;
  background-size: 100% 100%; }

.carousel-control-prev-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' viewBox='0 0 16 16'%3e%3cpath d='M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z'/%3e%3c/svg%3e"); }

.carousel-control-next-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' viewBox='0 0 16 16'%3e%3cpath d='M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e"); }

.carousel-indicators {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 2;
  display: flex;
  justify-content: center;
  padding-left: 0;
  margin-right: 15%;
  margin-left: 15%;
  list-style: none; }
  .carousel-indicators li {
    box-sizing: content-box;
    flex: 0 1 auto;
    width: 30px;
    height: 3px;
    margin-right: 3px;
    margin-left: 3px;
    text-indent: -999px;
    cursor: pointer;
    background-color: #fff;
    background-clip: padding-box;
    border-top: 10px solid transparent;
    border-bottom: 10px solid transparent;
    opacity: 0.5;
    transition: opacity 0.6s ease; }
    @media (prefers-reduced-motion: reduce) {
      .carousel-indicators li {
        transition: none; } }
  .carousel-indicators .active {
    opacity: 1; }

.carousel-caption {
  position: absolute;
  right: 15%;
  bottom: 1.25rem;
  left: 15%;
  padding-top: 1.25rem;
  padding-bottom: 1.25rem;
  color: #fff;
  text-align: center; }

.carousel-dark .carousel-control-prev-icon,
.carousel-dark .carousel-control-next-icon {
  filter: invert(1) grayscale(100); }

.carousel-dark .carousel-indicators li {
  background-color: #000; }

.carousel-dark .carousel-caption {
  color: #000; }

@keyframes spinner-border {
  to {
    transform: rotate(360deg); } }

.spinner-border {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  vertical-align: text-bottom;
  border: 0.25em solid currentColor;
  border-right-color: transparent;
  border-radius: 50%;
  animation: spinner-border 0.75s linear infinite; }

.spinner-border-sm {
  width: 1rem;
  height: 1rem;
  border-width: 0.2em; }

@keyframes spinner-grow {
  0% {
    transform: scale(0); }
  50% {
    opacity: 1;
    transform: none; } }

.spinner-grow {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  vertical-align: text-bottom;
  background-color: currentColor;
  border-radius: 50%;
  opacity: 0;
  animation: spinner-grow 0.75s linear infinite; }

.spinner-grow-sm {
  width: 1rem;
  height: 1rem; }

.clearfix::after {
  display: block;
  clear: both;
  content: ""; }

.link-primary {
  color: #0d6efd; }
  .link-primary:hover, .link-primary:focus {
    color: #024dbc; }

.link-secondary {
  color: #6c757d; }
  .link-secondary:hover, .link-secondary:focus {
    color: #494f54; }

.link-success {
  color: #198754; }
  .link-success:hover, .link-success:focus {
    color: #0d462c; }

.link-info {
  color: #0dcaf0; }
  .link-info:hover, .link-info:focus {
    color: #54dbf6; }

.link-warning {
  color: #ffc107; }
  .link-warning:hover, .link-warning:focus {
    color: #ffd454; }

.link-danger {
  color: #dc3545; }
  .link-danger:hover, .link-danger:focus {
    color: #a71d2a; }

.link-light {
  color: #f8f9fa; }
  .link-light:hover, .link-light:focus {
    color: white; }

.link-dark {
  color: #343a40; }
  .link-dark:hover, .link-dark:focus {
    color: #121416; }

.ratio {
  position: relative;
  width: 100%; }
  .ratio::before {
    display: block;
    padding-top: var(--aspect-ratio);
    content: ""; }
  .ratio > * {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%; }

.ratio-1x1 {
  --aspect-ratio: 100%; }

.ratio-4x3 {
  --aspect-ratio: calc(3 / 4 * 100%); }

.ratio-16x9 {
  --aspect-ratio: calc(9 / 16 * 100%); }

.ratio-21x9 {
  --aspect-ratio: calc(9 / 21 * 100%); }

.ratio-3x2 {
  --aspect-ratio: calc(2 / 3 * 100%); }

.fixed-top {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030; }

.fixed-bottom {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1030; }

.sticky-top {
  position: sticky;
  top: 0;
  z-index: 1020; }

@media (min-width: 576px) {
  .sticky-sm-top {
    position: sticky;
    top: 0;
    z-index: 1020; } }

@media (min-width: 768px) {
  .sticky-md-top {
    position: sticky;
    top: 0;
    z-index: 1020; } }

@media (min-width: 992px) {
  .sticky-lg-top {
    position: sticky;
    top: 0;
    z-index: 1020; } }

@media (min-width: 1200px) {
  .sticky-xl-top {
    position: sticky;
    top: 0;
    z-index: 1020; } }

@media (min-width: 1400px) {
  .sticky-xxl-top {
    position: sticky;
    top: 0;
    z-index: 1020; } }

.visually-hidden,
.visually-hidden-focusable:not(:focus) {
  position: absolute !important;
  width: 1px !important;
  height: 1px !important;
  padding: 0 !important;
  margin: -1px !important;
  overflow: hidden !important;
  clip: rect(0, 0, 0, 0) !important;
  white-space: nowrap !important;
  border: 0 !important; }

.stretched-link::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  content: ""; }

.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap; }

.align-baseline {
  vertical-align: baseline !important; }

.align-top {
  vertical-align: top !important; }

.align-middle {
  vertical-align: middle !important; }

.align-bottom {
  vertical-align: bottom !important; }

.align-text-bottom {
  vertical-align: text-bottom !important; }

.align-text-top {
  vertical-align: text-top !important; }

.float-left {
  float: left !important; }

.float-right {
  float: right !important; }

.float-none {
  float: none !important; }

.overflow-auto {
  overflow: auto !important; }

.overflow-hidden {
  overflow: hidden !important; }

.d-inline {
  display: inline !important; }

.d-inline-block {
  display: inline-block !important; }

.d-block {
  display: block !important; }

.d-table {
  display: table !important; }

.d-table-row {
  display: table-row !important; }

.d-table-cell {
  display: table-cell !important; }

.d-flex {
  display: flex !important; }

.d-inline-flex {
  display: inline-flex !important; }

.d-none {
  display: none !important; }

.shadow {
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important; }

.shadow-sm {
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important; }

.shadow-lg {
  box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175) !important; }

.shadow-none {
  box-shadow: none !important; }

.position-static {
  position: static !important; }

.position-relative {
  position: relative !important; }

.position-absolute {
  position: absolute !important; }

.position-fixed {
  position: fixed !important; }

.position-sticky {
  position: sticky !important; }

.top-0 {
  top: 0 !important; }

.top-50 {
  top: 50% !important; }

.top-100 {
  top: 100% !important; }

.bottom-0 {
  bottom: 0 !important; }

.bottom-50 {
  bottom: 50% !important; }

.bottom-100 {
  bottom: 100% !important; }

.left-0 {
  left: 0 !important; }

.left-50 {
  left: 50% !important; }

.left-100 {
  left: 100% !important; }

.right-0 {
  right: 0 !important; }

.right-50 {
  right: 50% !important; }

.right-100 {
  right: 100% !important; }

.translate-middle {
  transform: translateX(-50%) translateY(-50%) !important; }

.border {
  border: 1px solid #dee2e6 !important; }

.border-0 {
  border: 0 !important; }

.border-top {
  border-top: 1px solid #dee2e6 !important; }

.border-top-0 {
  border-top: 0 !important; }

.border-right {
  border-right: 1px solid #dee2e6 !important; }

.border-right-0 {
  border-right: 0 !important; }

.border-bottom {
  border-bottom: 1px solid #dee2e6 !important; }

.border-bottom-0 {
  border-bottom: 0 !important; }

.border-left {
  border-left: 1px solid #dee2e6 !important; }

.border-left-0 {
  border-left: 0 !important; }

.border-primary {
  border-color: #0d6efd !important; }

.border-secondary {
  border-color: #6c757d !important; }

.border-success {
  border-color: #198754 !important; }

.border-info {
  border-color: #0dcaf0 !important; }

.border-warning {
  border-color: #ffc107 !important; }

.border-danger {
  border-color: #dc3545 !important; }

.border-light {
  border-color: #f8f9fa !important; }

.border-dark {
  border-color: #343a40 !important; }

.border-white {
  border-color: #fff !important; }

.border-0 {
  border-width: 0 !important; }

.border-1 {
  border-width: 1px !important; }

.border-2 {
  border-width: 2px !important; }

.border-3 {
  border-width: 3px !important; }

.border-4 {
  border-width: 4px !important; }

.border-5 {
  border-width: 5px !important; }

.w-25 {
  width: 25% !important; }

.w-50 {
  width: 50% !important; }

.w-75 {
  width: 75% !important; }

.w-100 {
  width: 100% !important; }

.w-auto {
  width: auto !important; }

.mw-100 {
  max-width: 100% !important; }

.vw-100 {
  width: 100vw !important; }

.min-vw-100 {
  min-width: 100vw !important; }

.h-25 {
  height: 25% !important; }

.h-50 {
  height: 50% !important; }

.h-75 {
  height: 75% !important; }

.h-100 {
  height: 100% !important; }

.h-auto {
  height: auto !important; }

.mh-100 {
  max-height: 100% !important; }

.vh-100 {
  height: 100vh !important; }

.min-vh-100 {
  min-height: 100vh !important; }

.flex-fill {
  flex: 1 1 auto !important; }

.flex-row {
  flex-direction: row !important; }

.flex-column {
  flex-direction: column !important; }

.flex-row-reverse {
  flex-direction: row-reverse !important; }

.flex-column-reverse {
  flex-direction: column-reverse !important; }

.flex-grow-0 {
  flex-grow: 0 !important; }

.flex-grow-1 {
  flex-grow: 1 !important; }

.flex-shrink-0 {
  flex-shrink: 0 !important; }

.flex-shrink-1 {
  flex-shrink: 1 !important; }

.flex-wrap {
  flex-wrap: wrap !important; }

.flex-nowrap {
  flex-wrap: nowrap !important; }

.flex-wrap-reverse {
  flex-wrap: wrap-reverse !important; }

.justify-content-start {
  justify-content: flex-start !important; }

.justify-content-end {
  justify-content: flex-end !important; }

.justify-content-center {
  justify-content: center !important; }

.justify-content-between {
  justify-content: space-between !important; }

.justify-content-around {
  justify-content: space-around !important; }

.justify-content-evenly {
  justify-content: space-evenly !important; }

.align-items-start {
  align-items: flex-start !important; }

.align-items-end {
  align-items: flex-end !important; }

.align-items-center {
  align-items: center !important; }

.align-items-baseline {
  align-items: baseline !important; }

.align-items-stretch {
  align-items: stretch !important; }

.align-content-start {
  align-content: flex-start !important; }

.align-content-end {
  align-content: flex-end !important; }

.align-content-center {
  align-content: center !important; }

.align-content-between {
  align-content: space-between !important; }

.align-content-around {
  align-content: space-around !important; }

.align-content-stretch {
  align-content: stretch !important; }

.align-self-auto {
  align-self: auto !important; }

.align-self-start {
  align-self: flex-start !important; }

.align-self-end {
  align-self: flex-end !important; }

.align-self-center {
  align-self: center !important; }

.align-self-baseline {
  align-self: baseline !important; }

.align-self-stretch {
  align-self: stretch !important; }

.order-first {
  order: -1 !important; }

.order-0 {
  order: 0 !important; }

.order-1 {
  order: 1 !important; }

.order-2 {
  order: 2 !important; }

.order-3 {
  order: 3 !important; }

.order-4 {
  order: 4 !important; }

.order-5 {
  order: 5 !important; }

.order-last {
  order: 6 !important; }

.m-0 {
  margin: 0 !important; }

.m-1 {
  margin: 0.25rem !important; }

.m-2 {
  margin: 0.5rem !important; }

.m-3 {
  margin: 1rem !important; }

.m-4 {
  margin: 1.5rem !important; }

.m-5 {
  margin: 3rem !important; }

.m-auto {
  margin: auto !important; }

.mx-0 {
  margin-right: 0 !important;
  margin-left: 0 !important; }

.mx-1 {
  margin-right: 0.25rem !important;
  margin-left: 0.25rem !important; }

.mx-2 {
  margin-right: 0.5rem !important;
  margin-left: 0.5rem !important; }

.mx-3 {
  margin-right: 1rem !important;
  margin-left: 1rem !important; }

.mx-4 {
  margin-right: 1.5rem !important;
  margin-left: 1.5rem !important; }

.mx-5 {
  margin-right: 3rem !important;
  margin-left: 3rem !important; }

.mx-auto {
  margin-right: auto !important;
  margin-left: auto !important; }

.my-0 {
  margin-top: 0 !important;
  margin-bottom: 0 !important; }

.my-1 {
  margin-top: 0.25rem !important;
  margin-bottom: 0.25rem !important; }

.my-2 {
  margin-top: 0.5rem !important;
  margin-bottom: 0.5rem !important; }

.my-3 {
  margin-top: 1rem !important;
  margin-bottom: 1rem !important; }

.my-4 {
  margin-top: 1.5rem !important;
  margin-bottom: 1.5rem !important; }

.my-5 {
  margin-top: 3rem !important;
  margin-bottom: 3rem !important; }

.my-auto {
  margin-top: auto !important;
  margin-bottom: auto !important; }

.mt-0 {
  margin-top: 0 !important; }

.mt-1 {
  margin-top: 0.25rem !important; }

.mt-2 {
  margin-top: 0.5rem !important; }

.mt-3 {
  margin-top: 1rem !important; }

.mt-4 {
  margin-top: 1.5rem !important; }

.mt-5 {
  margin-top: 3rem !important; }

.mt-auto {
  margin-top: auto !important; }

.mr-0 {
  margin-right: 0 !important; }

.mr-1 {
  margin-right: 0.25rem !important; }

.mr-2 {
  margin-right: 0.5rem !important; }

.mr-3 {
  margin-right: 1rem !important; }

.mr-4 {
  margin-right: 1.5rem !important; }

.mr-5 {
  margin-right: 3rem !important; }

.mr-auto {
  margin-right: auto !important; }

.mb-0 {
  margin-bottom: 0 !important; }

.mb-1 {
  margin-bottom: 0.25rem !important; }

.mb-2 {
  margin-bottom: 0.5rem !important; }

.mb-3 {
  margin-bottom: 1rem !important; }

.mb-4 {
  margin-bottom: 1.5rem !important; }

.mb-5 {
  margin-bottom: 3rem !important; }

.mb-auto {
  margin-bottom: auto !important; }

.ml-0 {
  margin-left: 0 !important; }

.ml-1 {
  margin-left: 0.25rem !important; }

.ml-2 {
  margin-left: 0.5rem !important; }

.ml-3 {
  margin-left: 1rem !important; }

.ml-4 {
  margin-left: 1.5rem !important; }

.ml-5 {
  margin-left: 3rem !important; }

.ml-auto {
  margin-left: auto !important; }

.p-0 {
  padding: 0 !important; }

.p-1 {
  padding: 0.25rem !important; }

.p-2 {
  padding: 0.5rem !important; }

.p-3 {
  padding: 1rem !important; }

.p-4 {
  padding: 1.5rem !important; }

.p-5 {
  padding: 3rem !important; }

.px-0 {
  padding-right: 0 !important;
  padding-left: 0 !important; }

.px-1 {
  padding-right: 0.25rem !important;
  padding-left: 0.25rem !important; }

.px-2 {
  padding-right: 0.5rem !important;
  padding-left: 0.5rem !important; }

.px-3 {
  padding-right: 1rem !important;
  padding-left: 1rem !important; }

.px-4 {
  padding-right: 1.5rem !important;
  padding-left: 1.5rem !important; }

.px-5 {
  padding-right: 3rem !important;
  padding-left: 3rem !important; }

.py-0 {
  padding-top: 0 !important;
  padding-bottom: 0 !important; }

.py-1 {
  padding-top: 0.25rem !important;
  padding-bottom: 0.25rem !important; }

.py-2 {
  padding-top: 0.5rem !important;
  padding-bottom: 0.5rem !important; }

.py-3 {
  padding-top: 1rem !important;
  padding-bottom: 1rem !important; }

.py-4 {
  padding-top: 1.5rem !important;
  padding-bottom: 1.5rem !important; }

.py-5 {
  padding-top: 3rem !important;
  padding-bottom: 3rem !important; }

.pt-0 {
  padding-top: 0 !important; }

.pt-1 {
  padding-top: 0.25rem !important; }

.pt-2 {
  padding-top: 0.5rem !important; }

.pt-3 {
  padding-top: 1rem !important; }

.pt-4 {
  padding-top: 1.5rem !important; }

.pt-5 {
  padding-top: 3rem !important; }

.pr-0 {
  padding-right: 0 !important; }

.pr-1 {
  padding-right: 0.25rem !important; }

.pr-2 {
  padding-right: 0.5rem !important; }

.pr-3 {
  padding-right: 1rem !important; }

.pr-4 {
  padding-right: 1.5rem !important; }

.pr-5 {
  padding-right: 3rem !important; }

.pb-0 {
  padding-bottom: 0 !important; }

.pb-1 {
  padding-bottom: 0.25rem !important; }

.pb-2 {
  padding-bottom: 0.5rem !important; }

.pb-3 {
  padding-bottom: 1rem !important; }

.pb-4 {
  padding-bottom: 1.5rem !important; }

.pb-5 {
  padding-bottom: 3rem !important; }

.pl-0 {
  padding-left: 0 !important; }

.pl-1 {
  padding-left: 0.25rem !important; }

.pl-2 {
  padding-left: 0.5rem !important; }

.pl-3 {
  padding-left: 1rem !important; }

.pl-4 {
  padding-left: 1.5rem !important; }

.pl-5 {
  padding-left: 3rem !important; }

.font-weight-light {
  font-weight: 300 !important; }

.font-weight-lighter {
  font-weight: lighter !important; }

.font-weight-normal {
  font-weight: 400 !important; }

.font-weight-bold {
  font-weight: 700 !important; }

.font-weight-bolder {
  font-weight: bolder !important; }

.text-lowercase {
  text-transform: lowercase !important; }

.text-uppercase {
  text-transform: uppercase !important; }

.text-capitalize {
  text-transform: capitalize !important; }

.text-left {
  text-align: left !important; }

.text-right {
  text-align: right !important; }

.text-center {
  text-align: center !important; }

.text-primary {
  color: #0d6efd !important; }

.text-secondary {
  color: #6c757d !important; }

.text-success {
  color: #198754 !important; }

.text-info {
  color: #0dcaf0 !important; }

.text-warning {
  color: #ffc107 !important; }

.text-danger {
  color: #dc3545 !important; }

.text-light {
  color: #f8f9fa !important; }

.text-dark {
  color: #343a40 !important; }

.text-white {
  color: #fff !important; }

.text-body {
  color: #212529 !important; }

.text-muted {
  color: #6c757d !important; }

.text-black-50 {
  color: rgba(0, 0, 0, 0.5) !important; }

.text-white-50 {
  color: rgba(255, 255, 255, 0.5) !important; }

.text-reset {
  color: inherit !important; }

.lh-1 {
  line-height: 1 !important; }

.lh-sm {
  line-height: 1.25 !important; }

.lh-base {
  line-height: 1.5 !important; }

.lh-lg {
  line-height: 2 !important; }

.bg-primary {
  background-color: #0d6efd !important; }

.bg-secondary {
  background-color: #6c757d !important; }

.bg-success {
  background-color: #198754 !important; }

.bg-info {
  background-color: #0dcaf0 !important; }

.bg-warning {
  background-color: #ffc107 !important; }

.bg-danger {
  background-color: #dc3545 !important; }

.bg-light {
  background-color: #f8f9fa !important; }

.bg-dark {
  background-color: #343a40 !important; }

.bg-body {
  background-color: #fff !important; }

.bg-white {
  background-color: #fff !important; }

.bg-transparent {
  background-color: transparent !important; }

.bg-gradient {
  background-image: var(--bs-gradient) !important; }

.text-wrap {
  white-space: normal !important; }

.text-nowrap {
  white-space: nowrap !important; }

.text-decoration-none {
  text-decoration: none !important; }

.text-decoration-underline {
  text-decoration: underline !important; }

.text-decoration-line-through {
  text-decoration: line-through !important; }

.font-italic {
  font-style: italic !important; }

.font-normal {
  font-style: normal !important; }

.text-break {
  word-wrap: break-word !important;
  word-break: break-word !important; }

.font-monospace {
  font-family: var(--bs-font-monospace) !important; }

.user-select-all {
  user-select: all !important; }

.user-select-auto {
  user-select: auto !important; }

.user-select-none {
  user-select: none !important; }

.pe-none {
  pointer-events: none !important; }

.pe-auto {
  pointer-events: auto !important; }

.rounded {
  border-radius: 0.25rem !important; }

.rounded-sm {
  border-radius: 0.2rem !important; }

.rounded-lg {
  border-radius: 0.3rem !important; }

.rounded-circle {
  border-radius: 50% !important; }

.rounded-pill {
  border-radius: 50rem !important; }

.rounded-0 {
  border-radius: 0 !important; }

.rounded-top {
  border-top-left-radius: 0.25rem !important;
  border-top-right-radius: 0.25rem !important; }

.rounded-right {
  border-top-right-radius: 0.25rem !important;
  border-bottom-right-radius: 0.25rem !important; }

.rounded-bottom {
  border-bottom-right-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important; }

.rounded-left {
  border-bottom-left-radius: 0.25rem !important;
  border-top-left-radius: 0.25rem !important; }

.visible {
  visibility: visible !important; }

.invisible {
  visibility: hidden !important; }

@media (min-width: 576px) {
  .float-sm-left {
    float: left !important; }
  .float-sm-right {
    float: right !important; }
  .float-sm-none {
    float: none !important; }
  .d-sm-inline {
    display: inline !important; }
  .d-sm-inline-block {
    display: inline-block !important; }
  .d-sm-block {
    display: block !important; }
  .d-sm-table {
    display: table !important; }
  .d-sm-table-row {
    display: table-row !important; }
  .d-sm-table-cell {
    display: table-cell !important; }
  .d-sm-flex {
    display: flex !important; }
  .d-sm-inline-flex {
    display: inline-flex !important; }
  .d-sm-none {
    display: none !important; }
  .flex-sm-fill {
    flex: 1 1 auto !important; }
  .flex-sm-row {
    flex-direction: row !important; }
  .flex-sm-column {
    flex-direction: column !important; }
  .flex-sm-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-sm-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-sm-grow-0 {
    flex-grow: 0 !important; }
  .flex-sm-grow-1 {
    flex-grow: 1 !important; }
  .flex-sm-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-sm-shrink-1 {
    flex-shrink: 1 !important; }
  .flex-sm-wrap {
    flex-wrap: wrap !important; }
  .flex-sm-nowrap {
    flex-wrap: nowrap !important; }
  .flex-sm-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .justify-content-sm-start {
    justify-content: flex-start !important; }
  .justify-content-sm-end {
    justify-content: flex-end !important; }
  .justify-content-sm-center {
    justify-content: center !important; }
  .justify-content-sm-between {
    justify-content: space-between !important; }
  .justify-content-sm-around {
    justify-content: space-around !important; }
  .justify-content-sm-evenly {
    justify-content: space-evenly !important; }
  .align-items-sm-start {
    align-items: flex-start !important; }
  .align-items-sm-end {
    align-items: flex-end !important; }
  .align-items-sm-center {
    align-items: center !important; }
  .align-items-sm-baseline {
    align-items: baseline !important; }
  .align-items-sm-stretch {
    align-items: stretch !important; }
  .align-content-sm-start {
    align-content: flex-start !important; }
  .align-content-sm-end {
    align-content: flex-end !important; }
  .align-content-sm-center {
    align-content: center !important; }
  .align-content-sm-between {
    align-content: space-between !important; }
  .align-content-sm-around {
    align-content: space-around !important; }
  .align-content-sm-stretch {
    align-content: stretch !important; }
  .align-self-sm-auto {
    align-self: auto !important; }
  .align-self-sm-start {
    align-self: flex-start !important; }
  .align-self-sm-end {
    align-self: flex-end !important; }
  .align-self-sm-center {
    align-self: center !important; }
  .align-self-sm-baseline {
    align-self: baseline !important; }
  .align-self-sm-stretch {
    align-self: stretch !important; }
  .order-sm-first {
    order: -1 !important; }
  .order-sm-0 {
    order: 0 !important; }
  .order-sm-1 {
    order: 1 !important; }
  .order-sm-2 {
    order: 2 !important; }
  .order-sm-3 {
    order: 3 !important; }
  .order-sm-4 {
    order: 4 !important; }
  .order-sm-5 {
    order: 5 !important; }
  .order-sm-last {
    order: 6 !important; }
  .m-sm-0 {
    margin: 0 !important; }
  .m-sm-1 {
    margin: 0.25rem !important; }
  .m-sm-2 {
    margin: 0.5rem !important; }
  .m-sm-3 {
    margin: 1rem !important; }
  .m-sm-4 {
    margin: 1.5rem !important; }
  .m-sm-5 {
    margin: 3rem !important; }
  .m-sm-auto {
    margin: auto !important; }
  .mx-sm-0 {
    margin-right: 0 !important;
    margin-left: 0 !important; }
  .mx-sm-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important; }
  .mx-sm-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important; }
  .mx-sm-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important; }
  .mx-sm-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important; }
  .mx-sm-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important; }
  .mx-sm-auto {
    margin-right: auto !important;
    margin-left: auto !important; }
  .my-sm-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important; }
  .my-sm-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important; }
  .my-sm-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important; }
  .my-sm-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important; }
  .my-sm-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important; }
  .my-sm-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important; }
  .my-sm-auto {
    margin-top: auto !important;
    margin-bottom: auto !important; }
  .mt-sm-0 {
    margin-top: 0 !important; }
  .mt-sm-1 {
    margin-top: 0.25rem !important; }
  .mt-sm-2 {
    margin-top: 0.5rem !important; }
  .mt-sm-3 {
    margin-top: 1rem !important; }
  .mt-sm-4 {
    margin-top: 1.5rem !important; }
  .mt-sm-5 {
    margin-top: 3rem !important; }
  .mt-sm-auto {
    margin-top: auto !important; }
  .mr-sm-0 {
    margin-right: 0 !important; }
  .mr-sm-1 {
    margin-right: 0.25rem !important; }
  .mr-sm-2 {
    margin-right: 0.5rem !important; }
  .mr-sm-3 {
    margin-right: 1rem !important; }
  .mr-sm-4 {
    margin-right: 1.5rem !important; }
  .mr-sm-5 {
    margin-right: 3rem !important; }
  .mr-sm-auto {
    margin-right: auto !important; }
  .mb-sm-0 {
    margin-bottom: 0 !important; }
  .mb-sm-1 {
    margin-bottom: 0.25rem !important; }
  .mb-sm-2 {
    margin-bottom: 0.5rem !important; }
  .mb-sm-3 {
    margin-bottom: 1rem !important; }
  .mb-sm-4 {
    margin-bottom: 1.5rem !important; }
  .mb-sm-5 {
    margin-bottom: 3rem !important; }
  .mb-sm-auto {
    margin-bottom: auto !important; }
  .ml-sm-0 {
    margin-left: 0 !important; }
  .ml-sm-1 {
    margin-left: 0.25rem !important; }
  .ml-sm-2 {
    margin-left: 0.5rem !important; }
  .ml-sm-3 {
    margin-left: 1rem !important; }
  .ml-sm-4 {
    margin-left: 1.5rem !important; }
  .ml-sm-5 {
    margin-left: 3rem !important; }
  .ml-sm-auto {
    margin-left: auto !important; }
  .p-sm-0 {
    padding: 0 !important; }
  .p-sm-1 {
    padding: 0.25rem !important; }
  .p-sm-2 {
    padding: 0.5rem !important; }
  .p-sm-3 {
    padding: 1rem !important; }
  .p-sm-4 {
    padding: 1.5rem !important; }
  .p-sm-5 {
    padding: 3rem !important; }
  .px-sm-0 {
    padding-right: 0 !important;
    padding-left: 0 !important; }
  .px-sm-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important; }
  .px-sm-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important; }
  .px-sm-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important; }
  .px-sm-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important; }
  .px-sm-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important; }
  .py-sm-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important; }
  .py-sm-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important; }
  .py-sm-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important; }
  .py-sm-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important; }
  .py-sm-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important; }
  .py-sm-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important; }
  .pt-sm-0 {
    padding-top: 0 !important; }
  .pt-sm-1 {
    padding-top: 0.25rem !important; }
  .pt-sm-2 {
    padding-top: 0.5rem !important; }
  .pt-sm-3 {
    padding-top: 1rem !important; }
  .pt-sm-4 {
    padding-top: 1.5rem !important; }
  .pt-sm-5 {
    padding-top: 3rem !important; }
  .pr-sm-0 {
    padding-right: 0 !important; }
  .pr-sm-1 {
    padding-right: 0.25rem !important; }
  .pr-sm-2 {
    padding-right: 0.5rem !important; }
  .pr-sm-3 {
    padding-right: 1rem !important; }
  .pr-sm-4 {
    padding-right: 1.5rem !important; }
  .pr-sm-5 {
    padding-right: 3rem !important; }
  .pb-sm-0 {
    padding-bottom: 0 !important; }
  .pb-sm-1 {
    padding-bottom: 0.25rem !important; }
  .pb-sm-2 {
    padding-bottom: 0.5rem !important; }
  .pb-sm-3 {
    padding-bottom: 1rem !important; }
  .pb-sm-4 {
    padding-bottom: 1.5rem !important; }
  .pb-sm-5 {
    padding-bottom: 3rem !important; }
  .pl-sm-0 {
    padding-left: 0 !important; }
  .pl-sm-1 {
    padding-left: 0.25rem !important; }
  .pl-sm-2 {
    padding-left: 0.5rem !important; }
  .pl-sm-3 {
    padding-left: 1rem !important; }
  .pl-sm-4 {
    padding-left: 1.5rem !important; }
  .pl-sm-5 {
    padding-left: 3rem !important; }
  .text-sm-left {
    text-align: left !important; }
  .text-sm-right {
    text-align: right !important; }
  .text-sm-center {
    text-align: center !important; } }

@media (min-width: 768px) {
  .float-md-left {
    float: left !important; }
  .float-md-right {
    float: right !important; }
  .float-md-none {
    float: none !important; }
  .d-md-inline {
    display: inline !important; }
  .d-md-inline-block {
    display: inline-block !important; }
  .d-md-block {
    display: block !important; }
  .d-md-table {
    display: table !important; }
  .d-md-table-row {
    display: table-row !important; }
  .d-md-table-cell {
    display: table-cell !important; }
  .d-md-flex {
    display: flex !important; }
  .d-md-inline-flex {
    display: inline-flex !important; }
  .d-md-none {
    display: none !important; }
  .flex-md-fill {
    flex: 1 1 auto !important; }
  .flex-md-row {
    flex-direction: row !important; }
  .flex-md-column {
    flex-direction: column !important; }
  .flex-md-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-md-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-md-grow-0 {
    flex-grow: 0 !important; }
  .flex-md-grow-1 {
    flex-grow: 1 !important; }
  .flex-md-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-md-shrink-1 {
    flex-shrink: 1 !important; }
  .flex-md-wrap {
    flex-wrap: wrap !important; }
  .flex-md-nowrap {
    flex-wrap: nowrap !important; }
  .flex-md-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .justify-content-md-start {
    justify-content: flex-start !important; }
  .justify-content-md-end {
    justify-content: flex-end !important; }
  .justify-content-md-center {
    justify-content: center !important; }
  .justify-content-md-between {
    justify-content: space-between !important; }
  .justify-content-md-around {
    justify-content: space-around !important; }
  .justify-content-md-evenly {
    justify-content: space-evenly !important; }
  .align-items-md-start {
    align-items: flex-start !important; }
  .align-items-md-end {
    align-items: flex-end !important; }
  .align-items-md-center {
    align-items: center !important; }
  .align-items-md-baseline {
    align-items: baseline !important; }
  .align-items-md-stretch {
    align-items: stretch !important; }
  .align-content-md-start {
    align-content: flex-start !important; }
  .align-content-md-end {
    align-content: flex-end !important; }
  .align-content-md-center {
    align-content: center !important; }
  .align-content-md-between {
    align-content: space-between !important; }
  .align-content-md-around {
    align-content: space-around !important; }
  .align-content-md-stretch {
    align-content: stretch !important; }
  .align-self-md-auto {
    align-self: auto !important; }
  .align-self-md-start {
    align-self: flex-start !important; }
  .align-self-md-end {
    align-self: flex-end !important; }
  .align-self-md-center {
    align-self: center !important; }
  .align-self-md-baseline {
    align-self: baseline !important; }
  .align-self-md-stretch {
    align-self: stretch !important; }
  .order-md-first {
    order: -1 !important; }
  .order-md-0 {
    order: 0 !important; }
  .order-md-1 {
    order: 1 !important; }
  .order-md-2 {
    order: 2 !important; }
  .order-md-3 {
    order: 3 !important; }
  .order-md-4 {
    order: 4 !important; }
  .order-md-5 {
    order: 5 !important; }
  .order-md-last {
    order: 6 !important; }
  .m-md-0 {
    margin: 0 !important; }
  .m-md-1 {
    margin: 0.25rem !important; }
  .m-md-2 {
    margin: 0.5rem !important; }
  .m-md-3 {
    margin: 1rem !important; }
  .m-md-4 {
    margin: 1.5rem !important; }
  .m-md-5 {
    margin: 3rem !important; }
  .m-md-auto {
    margin: auto !important; }
  .mx-md-0 {
    margin-right: 0 !important;
    margin-left: 0 !important; }
  .mx-md-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important; }
  .mx-md-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important; }
  .mx-md-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important; }
  .mx-md-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important; }
  .mx-md-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important; }
  .mx-md-auto {
    margin-right: auto !important;
    margin-left: auto !important; }
  .my-md-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important; }
  .my-md-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important; }
  .my-md-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important; }
  .my-md-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important; }
  .my-md-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important; }
  .my-md-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important; }
  .my-md-auto {
    margin-top: auto !important;
    margin-bottom: auto !important; }
  .mt-md-0 {
    margin-top: 0 !important; }
  .mt-md-1 {
    margin-top: 0.25rem !important; }
  .mt-md-2 {
    margin-top: 0.5rem !important; }
  .mt-md-3 {
    margin-top: 1rem !important; }
  .mt-md-4 {
    margin-top: 1.5rem !important; }
  .mt-md-5 {
    margin-top: 3rem !important; }
  .mt-md-auto {
    margin-top: auto !important; }
  .mr-md-0 {
    margin-right: 0 !important; }
  .mr-md-1 {
    margin-right: 0.25rem !important; }
  .mr-md-2 {
    margin-right: 0.5rem !important; }
  .mr-md-3 {
    margin-right: 1rem !important; }
  .mr-md-4 {
    margin-right: 1.5rem !important; }
  .mr-md-5 {
    margin-right: 3rem !important; }
  .mr-md-auto {
    margin-right: auto !important; }
  .mb-md-0 {
    margin-bottom: 0 !important; }
  .mb-md-1 {
    margin-bottom: 0.25rem !important; }
  .mb-md-2 {
    margin-bottom: 0.5rem !important; }
  .mb-md-3 {
    margin-bottom: 1rem !important; }
  .mb-md-4 {
    margin-bottom: 1.5rem !important; }
  .mb-md-5 {
    margin-bottom: 3rem !important; }
  .mb-md-auto {
    margin-bottom: auto !important; }
  .ml-md-0 {
    margin-left: 0 !important; }
  .ml-md-1 {
    margin-left: 0.25rem !important; }
  .ml-md-2 {
    margin-left: 0.5rem !important; }
  .ml-md-3 {
    margin-left: 1rem !important; }
  .ml-md-4 {
    margin-left: 1.5rem !important; }
  .ml-md-5 {
    margin-left: 3rem !important; }
  .ml-md-auto {
    margin-left: auto !important; }
  .p-md-0 {
    padding: 0 !important; }
  .p-md-1 {
    padding: 0.25rem !important; }
  .p-md-2 {
    padding: 0.5rem !important; }
  .p-md-3 {
    padding: 1rem !important; }
  .p-md-4 {
    padding: 1.5rem !important; }
  .p-md-5 {
    padding: 3rem !important; }
  .px-md-0 {
    padding-right: 0 !important;
    padding-left: 0 !important; }
  .px-md-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important; }
  .px-md-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important; }
  .px-md-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important; }
  .px-md-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important; }
  .px-md-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important; }
  .py-md-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important; }
  .py-md-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important; }
  .py-md-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important; }
  .py-md-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important; }
  .py-md-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important; }
  .py-md-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important; }
  .pt-md-0 {
    padding-top: 0 !important; }
  .pt-md-1 {
    padding-top: 0.25rem !important; }
  .pt-md-2 {
    padding-top: 0.5rem !important; }
  .pt-md-3 {
    padding-top: 1rem !important; }
  .pt-md-4 {
    padding-top: 1.5rem !important; }
  .pt-md-5 {
    padding-top: 3rem !important; }
  .pr-md-0 {
    padding-right: 0 !important; }
  .pr-md-1 {
    padding-right: 0.25rem !important; }
  .pr-md-2 {
    padding-right: 0.5rem !important; }
  .pr-md-3 {
    padding-right: 1rem !important; }
  .pr-md-4 {
    padding-right: 1.5rem !important; }
  .pr-md-5 {
    padding-right: 3rem !important; }
  .pb-md-0 {
    padding-bottom: 0 !important; }
  .pb-md-1 {
    padding-bottom: 0.25rem !important; }
  .pb-md-2 {
    padding-bottom: 0.5rem !important; }
  .pb-md-3 {
    padding-bottom: 1rem !important; }
  .pb-md-4 {
    padding-bottom: 1.5rem !important; }
  .pb-md-5 {
    padding-bottom: 3rem !important; }
  .pl-md-0 {
    padding-left: 0 !important; }
  .pl-md-1 {
    padding-left: 0.25rem !important; }
  .pl-md-2 {
    padding-left: 0.5rem !important; }
  .pl-md-3 {
    padding-left: 1rem !important; }
  .pl-md-4 {
    padding-left: 1.5rem !important; }
  .pl-md-5 {
    padding-left: 3rem !important; }
  .text-md-left {
    text-align: left !important; }
  .text-md-right {
    text-align: right !important; }
  .text-md-center {
    text-align: center !important; } }

@media (min-width: 992px) {
  .float-lg-left {
    float: left !important; }
  .float-lg-right {
    float: right !important; }
  .float-lg-none {
    float: none !important; }
  .d-lg-inline {
    display: inline !important; }
  .d-lg-inline-block {
    display: inline-block !important; }
  .d-lg-block {
    display: block !important; }
  .d-lg-table {
    display: table !important; }
  .d-lg-table-row {
    display: table-row !important; }
  .d-lg-table-cell {
    display: table-cell !important; }
  .d-lg-flex {
    display: flex !important; }
  .d-lg-inline-flex {
    display: inline-flex !important; }
  .d-lg-none {
    display: none !important; }
  .flex-lg-fill {
    flex: 1 1 auto !important; }
  .flex-lg-row {
    flex-direction: row !important; }
  .flex-lg-column {
    flex-direction: column !important; }
  .flex-lg-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-lg-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-lg-grow-0 {
    flex-grow: 0 !important; }
  .flex-lg-grow-1 {
    flex-grow: 1 !important; }
  .flex-lg-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-lg-shrink-1 {
    flex-shrink: 1 !important; }
  .flex-lg-wrap {
    flex-wrap: wrap !important; }
  .flex-lg-nowrap {
    flex-wrap: nowrap !important; }
  .flex-lg-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .justify-content-lg-start {
    justify-content: flex-start !important; }
  .justify-content-lg-end {
    justify-content: flex-end !important; }
  .justify-content-lg-center {
    justify-content: center !important; }
  .justify-content-lg-between {
    justify-content: space-between !important; }
  .justify-content-lg-around {
    justify-content: space-around !important; }
  .justify-content-lg-evenly {
    justify-content: space-evenly !important; }
  .align-items-lg-start {
    align-items: flex-start !important; }
  .align-items-lg-end {
    align-items: flex-end !important; }
  .align-items-lg-center {
    align-items: center !important; }
  .align-items-lg-baseline {
    align-items: baseline !important; }
  .align-items-lg-stretch {
    align-items: stretch !important; }
  .align-content-lg-start {
    align-content: flex-start !important; }
  .align-content-lg-end {
    align-content: flex-end !important; }
  .align-content-lg-center {
    align-content: center !important; }
  .align-content-lg-between {
    align-content: space-between !important; }
  .align-content-lg-around {
    align-content: space-around !important; }
  .align-content-lg-stretch {
    align-content: stretch !important; }
  .align-self-lg-auto {
    align-self: auto !important; }
  .align-self-lg-start {
    align-self: flex-start !important; }
  .align-self-lg-end {
    align-self: flex-end !important; }
  .align-self-lg-center {
    align-self: center !important; }
  .align-self-lg-baseline {
    align-self: baseline !important; }
  .align-self-lg-stretch {
    align-self: stretch !important; }
  .order-lg-first {
    order: -1 !important; }
  .order-lg-0 {
    order: 0 !important; }
  .order-lg-1 {
    order: 1 !important; }
  .order-lg-2 {
    order: 2 !important; }
  .order-lg-3 {
    order: 3 !important; }
  .order-lg-4 {
    order: 4 !important; }
  .order-lg-5 {
    order: 5 !important; }
  .order-lg-last {
    order: 6 !important; }
  .m-lg-0 {
    margin: 0 !important; }
  .m-lg-1 {
    margin: 0.25rem !important; }
  .m-lg-2 {
    margin: 0.5rem !important; }
  .m-lg-3 {
    margin: 1rem !important; }
  .m-lg-4 {
    margin: 1.5rem !important; }
  .m-lg-5 {
    margin: 3rem !important; }
  .m-lg-auto {
    margin: auto !important; }
  .mx-lg-0 {
    margin-right: 0 !important;
    margin-left: 0 !important; }
  .mx-lg-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important; }
  .mx-lg-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important; }
  .mx-lg-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important; }
  .mx-lg-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important; }
  .mx-lg-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important; }
  .mx-lg-auto {
    margin-right: auto !important;
    margin-left: auto !important; }
  .my-lg-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important; }
  .my-lg-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important; }
  .my-lg-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important; }
  .my-lg-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important; }
  .my-lg-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important; }
  .my-lg-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important; }
  .my-lg-auto {
    margin-top: auto !important;
    margin-bottom: auto !important; }
  .mt-lg-0 {
    margin-top: 0 !important; }
  .mt-lg-1 {
    margin-top: 0.25rem !important; }
  .mt-lg-2 {
    margin-top: 0.5rem !important; }
  .mt-lg-3 {
    margin-top: 1rem !important; }
  .mt-lg-4 {
    margin-top: 1.5rem !important; }
  .mt-lg-5 {
    margin-top: 3rem !important; }
  .mt-lg-auto {
    margin-top: auto !important; }
  .mr-lg-0 {
    margin-right: 0 !important; }
  .mr-lg-1 {
    margin-right: 0.25rem !important; }
  .mr-lg-2 {
    margin-right: 0.5rem !important; }
  .mr-lg-3 {
    margin-right: 1rem !important; }
  .mr-lg-4 {
    margin-right: 1.5rem !important; }
  .mr-lg-5 {
    margin-right: 3rem !important; }
  .mr-lg-auto {
    margin-right: auto !important; }
  .mb-lg-0 {
    margin-bottom: 0 !important; }
  .mb-lg-1 {
    margin-bottom: 0.25rem !important; }
  .mb-lg-2 {
    margin-bottom: 0.5rem !important; }
  .mb-lg-3 {
    margin-bottom: 1rem !important; }
  .mb-lg-4 {
    margin-bottom: 1.5rem !important; }
  .mb-lg-5 {
    margin-bottom: 3rem !important; }
  .mb-lg-auto {
    margin-bottom: auto !important; }
  .ml-lg-0 {
    margin-left: 0 !important; }
  .ml-lg-1 {
    margin-left: 0.25rem !important; }
  .ml-lg-2 {
    margin-left: 0.5rem !important; }
  .ml-lg-3 {
    margin-left: 1rem !important; }
  .ml-lg-4 {
    margin-left: 1.5rem !important; }
  .ml-lg-5 {
    margin-left: 3rem !important; }
  .ml-lg-auto {
    margin-left: auto !important; }
  .p-lg-0 {
    padding: 0 !important; }
  .p-lg-1 {
    padding: 0.25rem !important; }
  .p-lg-2 {
    padding: 0.5rem !important; }
  .p-lg-3 {
    padding: 1rem !important; }
  .p-lg-4 {
    padding: 1.5rem !important; }
  .p-lg-5 {
    padding: 3rem !important; }
  .px-lg-0 {
    padding-right: 0 !important;
    padding-left: 0 !important; }
  .px-lg-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important; }
  .px-lg-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important; }
  .px-lg-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important; }
  .px-lg-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important; }
  .px-lg-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important; }
  .py-lg-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important; }
  .py-lg-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important; }
  .py-lg-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important; }
  .py-lg-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important; }
  .py-lg-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important; }
  .py-lg-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important; }
  .pt-lg-0 {
    padding-top: 0 !important; }
  .pt-lg-1 {
    padding-top: 0.25rem !important; }
  .pt-lg-2 {
    padding-top: 0.5rem !important; }
  .pt-lg-3 {
    padding-top: 1rem !important; }
  .pt-lg-4 {
    padding-top: 1.5rem !important; }
  .pt-lg-5 {
    padding-top: 3rem !important; }
  .pr-lg-0 {
    padding-right: 0 !important; }
  .pr-lg-1 {
    padding-right: 0.25rem !important; }
  .pr-lg-2 {
    padding-right: 0.5rem !important; }
  .pr-lg-3 {
    padding-right: 1rem !important; }
  .pr-lg-4 {
    padding-right: 1.5rem !important; }
  .pr-lg-5 {
    padding-right: 3rem !important; }
  .pb-lg-0 {
    padding-bottom: 0 !important; }
  .pb-lg-1 {
    padding-bottom: 0.25rem !important; }
  .pb-lg-2 {
    padding-bottom: 0.5rem !important; }
  .pb-lg-3 {
    padding-bottom: 1rem !important; }
  .pb-lg-4 {
    padding-bottom: 1.5rem !important; }
  .pb-lg-5 {
    padding-bottom: 3rem !important; }
  .pl-lg-0 {
    padding-left: 0 !important; }
  .pl-lg-1 {
    padding-left: 0.25rem !important; }
  .pl-lg-2 {
    padding-left: 0.5rem !important; }
  .pl-lg-3 {
    padding-left: 1rem !important; }
  .pl-lg-4 {
    padding-left: 1.5rem !important; }
  .pl-lg-5 {
    padding-left: 3rem !important; }
  .text-lg-left {
    text-align: left !important; }
  .text-lg-right {
    text-align: right !important; }
  .text-lg-center {
    text-align: center !important; } }

@media (min-width: 1200px) {
  .float-xl-left {
    float: left !important; }
  .float-xl-right {
    float: right !important; }
  .float-xl-none {
    float: none !important; }
  .d-xl-inline {
    display: inline !important; }
  .d-xl-inline-block {
    display: inline-block !important; }
  .d-xl-block {
    display: block !important; }
  .d-xl-table {
    display: table !important; }
  .d-xl-table-row {
    display: table-row !important; }
  .d-xl-table-cell {
    display: table-cell !important; }
  .d-xl-flex {
    display: flex !important; }
  .d-xl-inline-flex {
    display: inline-flex !important; }
  .d-xl-none {
    display: none !important; }
  .flex-xl-fill {
    flex: 1 1 auto !important; }
  .flex-xl-row {
    flex-direction: row !important; }
  .flex-xl-column {
    flex-direction: column !important; }
  .flex-xl-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-xl-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-xl-grow-0 {
    flex-grow: 0 !important; }
  .flex-xl-grow-1 {
    flex-grow: 1 !important; }
  .flex-xl-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-xl-shrink-1 {
    flex-shrink: 1 !important; }
  .flex-xl-wrap {
    flex-wrap: wrap !important; }
  .flex-xl-nowrap {
    flex-wrap: nowrap !important; }
  .flex-xl-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .justify-content-xl-start {
    justify-content: flex-start !important; }
  .justify-content-xl-end {
    justify-content: flex-end !important; }
  .justify-content-xl-center {
    justify-content: center !important; }
  .justify-content-xl-between {
    justify-content: space-between !important; }
  .justify-content-xl-around {
    justify-content: space-around !important; }
  .justify-content-xl-evenly {
    justify-content: space-evenly !important; }
  .align-items-xl-start {
    align-items: flex-start !important; }
  .align-items-xl-end {
    align-items: flex-end !important; }
  .align-items-xl-center {
    align-items: center !important; }
  .align-items-xl-baseline {
    align-items: baseline !important; }
  .align-items-xl-stretch {
    align-items: stretch !important; }
  .align-content-xl-start {
    align-content: flex-start !important; }
  .align-content-xl-end {
    align-content: flex-end !important; }
  .align-content-xl-center {
    align-content: center !important; }
  .align-content-xl-between {
    align-content: space-between !important; }
  .align-content-xl-around {
    align-content: space-around !important; }
  .align-content-xl-stretch {
    align-content: stretch !important; }
  .align-self-xl-auto {
    align-self: auto !important; }
  .align-self-xl-start {
    align-self: flex-start !important; }
  .align-self-xl-end {
    align-self: flex-end !important; }
  .align-self-xl-center {
    align-self: center !important; }
  .align-self-xl-baseline {
    align-self: baseline !important; }
  .align-self-xl-stretch {
    align-self: stretch !important; }
  .order-xl-first {
    order: -1 !important; }
  .order-xl-0 {
    order: 0 !important; }
  .order-xl-1 {
    order: 1 !important; }
  .order-xl-2 {
    order: 2 !important; }
  .order-xl-3 {
    order: 3 !important; }
  .order-xl-4 {
    order: 4 !important; }
  .order-xl-5 {
    order: 5 !important; }
  .order-xl-last {
    order: 6 !important; }
  .m-xl-0 {
    margin: 0 !important; }
  .m-xl-1 {
    margin: 0.25rem !important; }
  .m-xl-2 {
    margin: 0.5rem !important; }
  .m-xl-3 {
    margin: 1rem !important; }
  .m-xl-4 {
    margin: 1.5rem !important; }
  .m-xl-5 {
    margin: 3rem !important; }
  .m-xl-auto {
    margin: auto !important; }
  .mx-xl-0 {
    margin-right: 0 !important;
    margin-left: 0 !important; }
  .mx-xl-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important; }
  .mx-xl-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important; }
  .mx-xl-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important; }
  .mx-xl-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important; }
  .mx-xl-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important; }
  .mx-xl-auto {
    margin-right: auto !important;
    margin-left: auto !important; }
  .my-xl-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important; }
  .my-xl-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important; }
  .my-xl-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important; }
  .my-xl-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important; }
  .my-xl-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important; }
  .my-xl-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important; }
  .my-xl-auto {
    margin-top: auto !important;
    margin-bottom: auto !important; }
  .mt-xl-0 {
    margin-top: 0 !important; }
  .mt-xl-1 {
    margin-top: 0.25rem !important; }
  .mt-xl-2 {
    margin-top: 0.5rem !important; }
  .mt-xl-3 {
    margin-top: 1rem !important; }
  .mt-xl-4 {
    margin-top: 1.5rem !important; }
  .mt-xl-5 {
    margin-top: 3rem !important; }
  .mt-xl-auto {
    margin-top: auto !important; }
  .mr-xl-0 {
    margin-right: 0 !important; }
  .mr-xl-1 {
    margin-right: 0.25rem !important; }
  .mr-xl-2 {
    margin-right: 0.5rem !important; }
  .mr-xl-3 {
    margin-right: 1rem !important; }
  .mr-xl-4 {
    margin-right: 1.5rem !important; }
  .mr-xl-5 {
    margin-right: 3rem !important; }
  .mr-xl-auto {
    margin-right: auto !important; }
  .mb-xl-0 {
    margin-bottom: 0 !important; }
  .mb-xl-1 {
    margin-bottom: 0.25rem !important; }
  .mb-xl-2 {
    margin-bottom: 0.5rem !important; }
  .mb-xl-3 {
    margin-bottom: 1rem !important; }
  .mb-xl-4 {
    margin-bottom: 1.5rem !important; }
  .mb-xl-5 {
    margin-bottom: 3rem !important; }
  .mb-xl-auto {
    margin-bottom: auto !important; }
  .ml-xl-0 {
    margin-left: 0 !important; }
  .ml-xl-1 {
    margin-left: 0.25rem !important; }
  .ml-xl-2 {
    margin-left: 0.5rem !important; }
  .ml-xl-3 {
    margin-left: 1rem !important; }
  .ml-xl-4 {
    margin-left: 1.5rem !important; }
  .ml-xl-5 {
    margin-left: 3rem !important; }
  .ml-xl-auto {
    margin-left: auto !important; }
  .p-xl-0 {
    padding: 0 !important; }
  .p-xl-1 {
    padding: 0.25rem !important; }
  .p-xl-2 {
    padding: 0.5rem !important; }
  .p-xl-3 {
    padding: 1rem !important; }
  .p-xl-4 {
    padding: 1.5rem !important; }
  .p-xl-5 {
    padding: 3rem !important; }
  .px-xl-0 {
    padding-right: 0 !important;
    padding-left: 0 !important; }
  .px-xl-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important; }
  .px-xl-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important; }
  .px-xl-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important; }
  .px-xl-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important; }
  .px-xl-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important; }
  .py-xl-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important; }
  .py-xl-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important; }
  .py-xl-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important; }
  .py-xl-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important; }
  .py-xl-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important; }
  .py-xl-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important; }
  .pt-xl-0 {
    padding-top: 0 !important; }
  .pt-xl-1 {
    padding-top: 0.25rem !important; }
  .pt-xl-2 {
    padding-top: 0.5rem !important; }
  .pt-xl-3 {
    padding-top: 1rem !important; }
  .pt-xl-4 {
    padding-top: 1.5rem !important; }
  .pt-xl-5 {
    padding-top: 3rem !important; }
  .pr-xl-0 {
    padding-right: 0 !important; }
  .pr-xl-1 {
    padding-right: 0.25rem !important; }
  .pr-xl-2 {
    padding-right: 0.5rem !important; }
  .pr-xl-3 {
    padding-right: 1rem !important; }
  .pr-xl-4 {
    padding-right: 1.5rem !important; }
  .pr-xl-5 {
    padding-right: 3rem !important; }
  .pb-xl-0 {
    padding-bottom: 0 !important; }
  .pb-xl-1 {
    padding-bottom: 0.25rem !important; }
  .pb-xl-2 {
    padding-bottom: 0.5rem !important; }
  .pb-xl-3 {
    padding-bottom: 1rem !important; }
  .pb-xl-4 {
    padding-bottom: 1.5rem !important; }
  .pb-xl-5 {
    padding-bottom: 3rem !important; }
  .pl-xl-0 {
    padding-left: 0 !important; }
  .pl-xl-1 {
    padding-left: 0.25rem !important; }
  .pl-xl-2 {
    padding-left: 0.5rem !important; }
  .pl-xl-3 {
    padding-left: 1rem !important; }
  .pl-xl-4 {
    padding-left: 1.5rem !important; }
  .pl-xl-5 {
    padding-left: 3rem !important; }
  .text-xl-left {
    text-align: left !important; }
  .text-xl-right {
    text-align: right !important; }
  .text-xl-center {
    text-align: center !important; } }

@media (min-width: 1400px) {
  .float-xxl-left {
    float: left !important; }
  .float-xxl-right {
    float: right !important; }
  .float-xxl-none {
    float: none !important; }
  .d-xxl-inline {
    display: inline !important; }
  .d-xxl-inline-block {
    display: inline-block !important; }
  .d-xxl-block {
    display: block !important; }
  .d-xxl-table {
    display: table !important; }
  .d-xxl-table-row {
    display: table-row !important; }
  .d-xxl-table-cell {
    display: table-cell !important; }
  .d-xxl-flex {
    display: flex !important; }
  .d-xxl-inline-flex {
    display: inline-flex !important; }
  .d-xxl-none {
    display: none !important; }
  .flex-xxl-fill {
    flex: 1 1 auto !important; }
  .flex-xxl-row {
    flex-direction: row !important; }
  .flex-xxl-column {
    flex-direction: column !important; }
  .flex-xxl-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-xxl-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-xxl-grow-0 {
    flex-grow: 0 !important; }
  .flex-xxl-grow-1 {
    flex-grow: 1 !important; }
  .flex-xxl-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-xxl-shrink-1 {
    flex-shrink: 1 !important; }
  .flex-xxl-wrap {
    flex-wrap: wrap !important; }
  .flex-xxl-nowrap {
    flex-wrap: nowrap !important; }
  .flex-xxl-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .justify-content-xxl-start {
    justify-content: flex-start !important; }
  .justify-content-xxl-end {
    justify-content: flex-end !important; }
  .justify-content-xxl-center {
    justify-content: center !important; }
  .justify-content-xxl-between {
    justify-content: space-between !important; }
  .justify-content-xxl-around {
    justify-content: space-around !important; }
  .justify-content-xxl-evenly {
    justify-content: space-evenly !important; }
  .align-items-xxl-start {
    align-items: flex-start !important; }
  .align-items-xxl-end {
    align-items: flex-end !important; }
  .align-items-xxl-center {
    align-items: center !important; }
  .align-items-xxl-baseline {
    align-items: baseline !important; }
  .align-items-xxl-stretch {
    align-items: stretch !important; }
  .align-content-xxl-start {
    align-content: flex-start !important; }
  .align-content-xxl-end {
    align-content: flex-end !important; }
  .align-content-xxl-center {
    align-content: center !important; }
  .align-content-xxl-between {
    align-content: space-between !important; }
  .align-content-xxl-around {
    align-content: space-around !important; }
  .align-content-xxl-stretch {
    align-content: stretch !important; }
  .align-self-xxl-auto {
    align-self: auto !important; }
  .align-self-xxl-start {
    align-self: flex-start !important; }
  .align-self-xxl-end {
    align-self: flex-end !important; }
  .align-self-xxl-center {
    align-self: center !important; }
  .align-self-xxl-baseline {
    align-self: baseline !important; }
  .align-self-xxl-stretch {
    align-self: stretch !important; }
  .order-xxl-first {
    order: -1 !important; }
  .order-xxl-0 {
    order: 0 !important; }
  .order-xxl-1 {
    order: 1 !important; }
  .order-xxl-2 {
    order: 2 !important; }
  .order-xxl-3 {
    order: 3 !important; }
  .order-xxl-4 {
    order: 4 !important; }
  .order-xxl-5 {
    order: 5 !important; }
  .order-xxl-last {
    order: 6 !important; }
  .m-xxl-0 {
    margin: 0 !important; }
  .m-xxl-1 {
    margin: 0.25rem !important; }
  .m-xxl-2 {
    margin: 0.5rem !important; }
  .m-xxl-3 {
    margin: 1rem !important; }
  .m-xxl-4 {
    margin: 1.5rem !important; }
  .m-xxl-5 {
    margin: 3rem !important; }
  .m-xxl-auto {
    margin: auto !important; }
  .mx-xxl-0 {
    margin-right: 0 !important;
    margin-left: 0 !important; }
  .mx-xxl-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important; }
  .mx-xxl-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important; }
  .mx-xxl-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important; }
  .mx-xxl-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important; }
  .mx-xxl-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important; }
  .mx-xxl-auto {
    margin-right: auto !important;
    margin-left: auto !important; }
  .my-xxl-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important; }
  .my-xxl-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important; }
  .my-xxl-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important; }
  .my-xxl-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important; }
  .my-xxl-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important; }
  .my-xxl-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important; }
  .my-xxl-auto {
    margin-top: auto !important;
    margin-bottom: auto !important; }
  .mt-xxl-0 {
    margin-top: 0 !important; }
  .mt-xxl-1 {
    margin-top: 0.25rem !important; }
  .mt-xxl-2 {
    margin-top: 0.5rem !important; }
  .mt-xxl-3 {
    margin-top: 1rem !important; }
  .mt-xxl-4 {
    margin-top: 1.5rem !important; }
  .mt-xxl-5 {
    margin-top: 3rem !important; }
  .mt-xxl-auto {
    margin-top: auto !important; }
  .mr-xxl-0 {
    margin-right: 0 !important; }
  .mr-xxl-1 {
    margin-right: 0.25rem !important; }
  .mr-xxl-2 {
    margin-right: 0.5rem !important; }
  .mr-xxl-3 {
    margin-right: 1rem !important; }
  .mr-xxl-4 {
    margin-right: 1.5rem !important; }
  .mr-xxl-5 {
    margin-right: 3rem !important; }
  .mr-xxl-auto {
    margin-right: auto !important; }
  .mb-xxl-0 {
    margin-bottom: 0 !important; }
  .mb-xxl-1 {
    margin-bottom: 0.25rem !important; }
  .mb-xxl-2 {
    margin-bottom: 0.5rem !important; }
  .mb-xxl-3 {
    margin-bottom: 1rem !important; }
  .mb-xxl-4 {
    margin-bottom: 1.5rem !important; }
  .mb-xxl-5 {
    margin-bottom: 3rem !important; }
  .mb-xxl-auto {
    margin-bottom: auto !important; }
  .ml-xxl-0 {
    margin-left: 0 !important; }
  .ml-xxl-1 {
    margin-left: 0.25rem !important; }
  .ml-xxl-2 {
    margin-left: 0.5rem !important; }
  .ml-xxl-3 {
    margin-left: 1rem !important; }
  .ml-xxl-4 {
    margin-left: 1.5rem !important; }
  .ml-xxl-5 {
    margin-left: 3rem !important; }
  .ml-xxl-auto {
    margin-left: auto !important; }
  .p-xxl-0 {
    padding: 0 !important; }
  .p-xxl-1 {
    padding: 0.25rem !important; }
  .p-xxl-2 {
    padding: 0.5rem !important; }
  .p-xxl-3 {
    padding: 1rem !important; }
  .p-xxl-4 {
    padding: 1.5rem !important; }
  .p-xxl-5 {
    padding: 3rem !important; }
  .px-xxl-0 {
    padding-right: 0 !important;
    padding-left: 0 !important; }
  .px-xxl-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important; }
  .px-xxl-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important; }
  .px-xxl-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important; }
  .px-xxl-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important; }
  .px-xxl-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important; }
  .py-xxl-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important; }
  .py-xxl-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important; }
  .py-xxl-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important; }
  .py-xxl-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important; }
  .py-xxl-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important; }
  .py-xxl-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important; }
  .pt-xxl-0 {
    padding-top: 0 !important; }
  .pt-xxl-1 {
    padding-top: 0.25rem !important; }
  .pt-xxl-2 {
    padding-top: 0.5rem !important; }
  .pt-xxl-3 {
    padding-top: 1rem !important; }
  .pt-xxl-4 {
    padding-top: 1.5rem !important; }
  .pt-xxl-5 {
    padding-top: 3rem !important; }
  .pr-xxl-0 {
    padding-right: 0 !important; }
  .pr-xxl-1 {
    padding-right: 0.25rem !important; }
  .pr-xxl-2 {
    padding-right: 0.5rem !important; }
  .pr-xxl-3 {
    padding-right: 1rem !important; }
  .pr-xxl-4 {
    padding-right: 1.5rem !important; }
  .pr-xxl-5 {
    padding-right: 3rem !important; }
  .pb-xxl-0 {
    padding-bottom: 0 !important; }
  .pb-xxl-1 {
    padding-bottom: 0.25rem !important; }
  .pb-xxl-2 {
    padding-bottom: 0.5rem !important; }
  .pb-xxl-3 {
    padding-bottom: 1rem !important; }
  .pb-xxl-4 {
    padding-bottom: 1.5rem !important; }
  .pb-xxl-5 {
    padding-bottom: 3rem !important; }
  .pl-xxl-0 {
    padding-left: 0 !important; }
  .pl-xxl-1 {
    padding-left: 0.25rem !important; }
  .pl-xxl-2 {
    padding-left: 0.5rem !important; }
  .pl-xxl-3 {
    padding-left: 1rem !important; }
  .pl-xxl-4 {
    padding-left: 1.5rem !important; }
  .pl-xxl-5 {
    padding-left: 3rem !important; }
  .text-xxl-left {
    text-align: left !important; }
  .text-xxl-right {
    text-align: right !important; }
  .text-xxl-center {
    text-align: center !important; } }

@media print {
  .d-print-inline {
    display: inline !important; }
  .d-print-inline-block {
    display: inline-block !important; }
  .d-print-block {
    display: block !important; }
  .d-print-table {
    display: table !important; }
  .d-print-table-row {
    display: table-row !important; }
  .d-print-table-cell {
    display: table-cell !important; }
  .d-print-flex {
    display: flex !important; }
  .d-print-inline-flex {
    display: inline-flex !important; }
  .d-print-none {
    display: none !important; } }

/* full gradient */
/* gradient with a solid color on top */
/* grid container, if no need for grid-auto-rows? value is "unset" */
/* diagonal background on top from element where it is in with white background color  */
/* diagonal background on top from element where it is in with variable background color  */
/* transparent labels */
/* for animation .svg in background */
/* specification for element with animation as background image, height en position of element */
/* for ul lists */
/* form styling */
/* filter basic */
/* filter menu divider */
/* filter menu form-select  */
/* filter menu arrow transition on click  */
/* basis for zooming background image on mouse over */
body {
  font-family: "Manrope", sans-serif;
  color: #122d3f; }

a {
  color: #122d3f;
  text-decoration: none;
  transition: color 0.2s ease-in; }
  a:hover {
    color: #fc0039; }

h1, .h1 {
  font-size: 2.875rem;
  line-height: 3.125rem; }
  h1.large, .large.h1 {
    font-size: 3.25rem;
    line-height: 3.75rem; }
  h1.xlarge, .xlarge.h1 {
    font-size: 4.75rem;
    line-height: 5.25rem; }
    @media (max-width: 1199.98px) {
      h1.xlarge, .xlarge.h1 {
        font-size: 4rem;
        line-height: 4.5rem; } }
    @media (max-width: 991.98px) {
      h1.xlarge, .xlarge.h1 {
        font-size: 3.5rem;
        line-height: 4rem; } }
    @media (max-width: 767.98px) {
      h1.xlarge, .xlarge.h1 {
        font-size: 3rem;
        line-height: 3.5rem; } }

h2, .h2 {
  font-size: 1.625rem;
  line-height: 2.125rem; }

h3, .h3 {
  font-size: 1.25rem;
  line-height: 1.75rem; }

h4, .h4 {
  font-size: 2.375rem;
  line-height: 3.125rem; }

h5, .h5 {
  font-size: 2rem;
  line-height: 3.125rem; }

h6, .h6 {
  font-size: 1rem;
  line-height: 2.125rem; }

h1, .h1, h2, .h2, h3, .h3, h4, .h4, h5, .h5, h6, .h6 {
  font-weight: 700; }

p {
  /* main p font size */
  font-size: 1.25rem;
  line-height: 2.125rem;
  font-weight: 400;
  margin-bottom: 0;
  /* styling for p in "card" */
  /* styling for p used in introduction text */
  /* small p fonts */
  /* extra small p fonts */ }
  p.card-quote {
    font-weight: 700;
    line-height: 1.75rem; }
  p.introduction-text {
    font-size: 1.375rem;
    font-weight: 600; }
  p.small {
    font-size: 1rem; }
  p.extra-small {
    font-size: 0.8125rem; }

/* responsive font size */
@media (max-width: 992px) {
  html {
    font-size: 90%; } }

@media (max-width: 768px) {
  html {
    font-size: 85%; } }

@media (max-width: 576px) {
  html {
    font-size: 80%; } }

.button-basic {
  display: inline-block;
  background-color: #122d3f;
  font-size: 1rem;
  font-weight: 700;
  color: #ffffff;
  line-height: 1.75rem;
  border-radius: 1.875rem;
  padding: 0.59375rem 1.875rem;
  transition: all 0.2s ease-out;
  /* .dark, .light or .red button */ }
  .button-basic.dark.forward:after, .button-basic.light.forward:after, .button-basic.red.forward:after {
    padding-left: 1.15rem; }
  .button-basic.dark.back:before, .button-basic.light.back:before, .button-basic.red.back:before {
    padding-right: 1.15rem; }
  .button-basic.dark.down:after, .button-basic.light.down:after, .button-basic.red.down:after {
    padding-left: 1.15rem; }
  .button-basic.dark {
    background-color: #122d3f;
    color: #ffffff;
    border: 1px solid #122d3f; }
    .button-basic.dark.forward:after {
      content: url("/static/images/icons/arrow-light.svg"); }
    .button-basic.dark.forward:hover:after {
      content: url("/static/images/icons/arrow-dark.svg"); }
    .button-basic.dark.back:before {
      content: url("/static/images/icons/arrow-light-back.svg"); }
    .button-basic.dark.back:hover:before {
      content: url("/static/images/icons/arrow-dark-back.svg"); }
    .button-basic.dark.down:after {
      content: url("/static/images/icons/arrow-button-down-light.svg"); }
    .button-basic.dark:hover {
      background-color: #ffffff;
      color: #122d3f;
      border: 1px solid #122d3f; }
  .button-basic.light {
    background-color: #ffffff;
    color: #122d3f;
    border: 1px solid #ffffff; }
    .button-basic.light.forward:after {
      content: url("/static/images/icons/arrow-dark.svg"); }
    .button-basic.light.forward:hover:after {
      content: url("/static/images/icons/arrow-light.svg"); }
    .button-basic.light.back:before {
      content: url("/static/images/icons/arrow-dark-back.svg"); }
    .button-basic.light.back:hover:before {
      content: url("/static/images/icons/arrow-light-back.svg"); }
    .button-basic.light:hover {
      background-color: #122d3f;
      color: #ffffff;
      border: 1px solid #ffffff; }
  .button-basic.red {
    background-color: #fc0039;
    color: #ffffff;
    border: 1px solid #fc0039; }
    .button-basic.red.forward:after {
      content: url("/static/images/icons/arrow-light.svg"); }
    .button-basic.red.forward:hover:after {
      content: url("/static/images/icons/arrow-red.svg"); }
    .button-basic.red.back:before {
      content: url("/static/images/icons/arrow-light-back.svg"); }
    .button-basic.red.back:hover:before {
      content: url("/static/images/icons/arrow-red-back.svg"); }
    .button-basic.red.down:after {
      content: url("/static/images/icons/arrow-button-down-light.svg"); }
    .button-basic.red.down:hover:after {
      content: url("/static/images/icons/arrow-down-red.svg"); }
    .button-basic.red:hover {
      background-color: #ffffff;
      color: #fc0039;
      border: 1px solid #fc0039; }

/* labels in header */
.header-labels {
  padding-bottom: 1.375rem;
  display: flex;
  justify-content: center; }
  .header-labels .project-label {
    background-color: #122d3f;
    padding: 0.5rem 1rem;
    margin-right: 1rem; }
    .header-labels .project-label p {
      margin-bottom: 0;
      text-transform: uppercase;
      font-weight: 500;
      font-size: 0.8125rem;
      color: #ffffff; }
    .header-labels .project-label:last-child {
      margin-right: 0; }

/* last section in bottom of content */
.call-part-bottom {
  padding-bottom: 7.75rem;
  background-size: initial;
  background-repeat: no-repeat; }
  .call-part-bottom h4, .call-part-bottom .h4 {
    margin-bottom: 1.875rem;
    font-weight: 700; }
  .call-part-bottom p {
    margin-bottom: 2.6875rem; }
  .call-part-bottom .button-basic:first-of-type {
    margin-right: 2.875rem; }
  .call-part-bottom.call-part-multiple-triangles {
    background-image: url("/static/images/background-vacancies.svg");
    background-position: 30vw 0; }
    @media (min-width: 1400px) {
      .call-part-bottom.call-part-multiple-triangles {
        background-position: 40vw 0; } }
    @media (max-width: 1199.98px) {
      .call-part-bottom.call-part-multiple-triangles {
        background-position: 20vw 0; } }
  .call-part-bottom.call-part-triangle-background {
    position: relative; }
    .call-part-bottom.call-part-triangle-background h4, .call-part-bottom.call-part-triangle-background .h4 {
      margin-top: 10.6875rem; }
    .call-part-bottom.call-part-triangle-background .animation-vacancy-one {
      background-image: url("/static/images/icons/triangle-grey-left-bottom.svg");
      background-position-x: right;
      background-position-y: top;
      background-size: initial;
      background-repeat: no-repeat;
      position: absolute;
      width: 100%;
      left: 0;
      min-height: 271px;
      top: unset;
      left: calc(-271px);
      bottom: 271px; }
    .call-part-bottom.call-part-triangle-background .animation-vacancy-two {
      background-image: url("/static/images/icons/triangle-grey-left-top.svg");
      background-position-x: right;
      background-position-y: top;
      background-size: initial;
      background-repeat: no-repeat;
      position: absolute;
      width: 100%;
      left: 0;
      min-height: 271px;
      top: unset;
      left: calc(0);
      bottom: 0; }
  @media (max-width: 575.98px) {
    .call-part-bottom .button-basic {
      margin-bottom: 1.5rem; }
      .call-part-bottom .button-basic:last-of-type {
        margin-bottom: 0; } }

/* add to items that needs to be layed over the background animations */
.z-index-layer {
  position: relative;
  z-index: 100; }

.vacancy-item {
  padding: 1.8125rem 3.125rem 2.5rem 2.3125rem;
  background: #f7f7f7;
  background: linear-gradient(0deg, #f7f7f7 0%, #ebebeb 50%, #f7f7f7 100%);
  margin-bottom: 2.75rem;
  display: block;
  position: relative; }
  .vacancy-item:not(.open) {
    cursor: pointer; }
  .vacancy-item .red-triangle {
    position: absolute;
    top: 0;
    right: 0;
    display: none; }
  .vacancy-item .vacancy-header {
    display: flex;
    align-items: center; }
    .vacancy-item .vacancy-header h3, .vacancy-item .vacancy-header .h3 {
      font-size: 1.375rem; }
    .vacancy-item .vacancy-header span {
      font-size: 0.875rem;
      text-transform: uppercase;
      vertical-align: center; }
      .vacancy-item .vacancy-header span:last-of-type {
        padding-left: 0.7375rem;
        opacity: 0.36; }
      .vacancy-item .vacancy-header span .vacancy-label {
        display: inline; }
      .vacancy-item .vacancy-header span .vacancy-label-hover {
        display: none; }
    .vacancy-item .vacancy-header .vacancy-add-icon {
      display: block;
      padding: 1rem 0 1rem 2rem;
      transition: all 0.5s ease-out; }
      .vacancy-item .vacancy-header .vacancy-add-icon img {
        display: block; }
        .vacancy-item .vacancy-header .vacancy-add-icon img:last-of-type {
          display: none; }
  .vacancy-item .vacancy-content {
    display: none; }
    .vacancy-item .vacancy-content .horizontal-line {
      padding-top: 2.5rem;
      margin-bottom: 2.1875rem;
      border-bottom: 1px solid #122d3f;
      opacity: 0.2; }
    .vacancy-item .vacancy-content p {
      margin-bottom: 2.1875rem; }
    .vacancy-item .vacancy-content .button-basic.light:hover {
      border: 1px solid #122d3f; }
  .vacancy-item:last-of-type {
    margin-bottom: 7.9125rem; }
  .vacancy-item.open .red-triangle {
    display: block; }
  .vacancy-item.open .vacancy-header .vacancy-add-icon {
    display: none; }
  .vacancy-item.open .vacancy-content {
    display: block; }
  .vacancy-item:not(.open):hover {
    color: #fc0039; }
    .vacancy-item:not(.open):hover .vacancy-header span .vacancy-label {
      display: none; }
    .vacancy-item:not(.open):hover .vacancy-header span .vacancy-label-hover {
      display: inline; }
    .vacancy-item:not(.open):hover .vacancy-header .vacancy-add-icon img {
      display: block; }
      .vacancy-item:not(.open):hover .vacancy-header .vacancy-add-icon img:first-of-type {
        display: none; }

.background-on-scroll nav {
  background-color: transparent;
  box-shadow: none; }
  .background-on-scroll nav p {
    text-shadow: 0px 2px 7px rgba(18, 45, 63, 0.5); }
  .background-on-scroll nav .menu-hamburger .menu-icon .line {
    box-shadow: 0px 0px 7px rgba(35, 71, 95, 0.5); }

.background-animation {
  transition: all 0.1s ease; }
  .background-animation.animation-tablet-display {
    display: none; }
  .background-animation.animation-desktop-display {
    display: block; }
  @media (max-width: 767.98px) {
    .background-animation.animation-tablet-display {
      display: block; }
    .background-animation.animation-desktop-display {
      display: none; } }

/*--- Filters ---*/
.drop-down-container {
  height: 3rem;
  min-width: 15rem; }
  .drop-down-container .scrollable {
    overflow-y: auto;
    overflow-x: hidden; }
  .drop-down-container .full-height {
    height: calc(100vh - 6.2rem); }
  .drop-down-container .drop-down-start {
    display: block; }
    .drop-down-container .drop-down-start .form-select {
      padding-left: 1.5rem;
      padding-top: 0.2rem; }
  .drop-down-container .drop-down-open {
    display: none; }
  .drop-down-container .drop-down-selected {
    display: none; }
  .drop-down-container .drop-down-list {
    transition: max-height 0.3s;
    overflow: hidden;
    position: relative;
    z-index: 2;
    border-bottom-left-radius: 2rem;
    border-bottom-right-radius: 2rem;
    width: 100%;
    max-height: 0; }
    @media (max-height: 500px) {
      .drop-down-container .drop-down-list {
        height: unset; } }
    .drop-down-container .drop-down-list ul {
      list-style-type: none;
      padding: 0 0.7rem;
      padding: 0 1.5rem; }
      .drop-down-container .drop-down-list ul li {
        line-height: 1.7rem;
        text-transform: uppercase; }
        .drop-down-container .drop-down-list ul li a {
          color: #122d3f;
          line-height: 2rem;
          transition: color 0.15s ease-in; }
          .drop-down-container .drop-down-list ul li a:hover {
            color: #fc0039; }
      .drop-down-container .drop-down-list ul .active {
        display: inline-block;
        color: #fc0039; }
      .drop-down-container .drop-down-list ul .explain-list {
        text-transform: uppercase;
        font-weight: 100;
        color: #fc0039;
        font-size: 0.8rem;
        margin-bottom: 0.2rem; }
    .drop-down-container .drop-down-list.news-list {
      background-color: #f6f6f6; }
      .drop-down-container .drop-down-list.news-list ul li {
        border-bottom: 1px solid #122d3f; }
        .drop-down-container .drop-down-list.news-list ul li:first-of-type {
          border-top: 1px solid #122d3f; }
        .drop-down-container .drop-down-list.news-list ul li:last-of-type {
          border-bottom: none; }
      .drop-down-container .drop-down-list.news-list ul li a:not(.active) {
        color: #122d3f; }
        .drop-down-container .drop-down-list.news-list ul li a:not(.active):hover {
          color: #fc0039; }
    .drop-down-container .drop-down-list.jobs-list {
      background-color: #122d3f; }
      .drop-down-container .drop-down-list.jobs-list ul li {
        border-bottom: 1px solid #ffffff; }
        .drop-down-container .drop-down-list.jobs-list ul li:first-of-type {
          border-top: 1px solid #ffffff; }
        .drop-down-container .drop-down-list.jobs-list ul li:last-of-type {
          border-bottom: none; }
      .drop-down-container .drop-down-list.jobs-list ul li a:not(.active) {
        color: #ffffff; }
        .drop-down-container .drop-down-list.jobs-list ul li a:not(.active):hover {
          color: #fc0039; }
    @media (max-width: 767.98px) {
      .drop-down-container .drop-down-list ul li {
        line-height: 2.5rem; } }
  .drop-down-container a {
    text-decoration: none;
    color: #122d3f; }
  .drop-down-container.filter-menu-open .drop-down-list {
    min-height: auto;
    max-height: max-content; }
    @media (max-height: 500px) {
      .drop-down-container.filter-menu-open .drop-down-list {
        max-height: max-content; } }
  .drop-down-container.filter-menu-open .arrow {
    transform: rotate(180deg); }
  .drop-down-container.filter-menu-open .drop-down-list {
    display: block; }
  .drop-down-container.filter-menu-open .drop-down-start .form-select {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0; }
  .drop-down-container.filter-menu-selected .arrow {
    transform: rotate(270deg); }
  @media (max-width: 767.98px) {
    .drop-down-container a {
      font-size: 1.3rem; } }

.drop-down-list-selected .drop-down-selected {
  display: block; }

.drop-down-list-selected .drop-down-start {
  display: none; }

.drop-down-list-selected .drop-down-open {
  display: none; }

.drop-down-list-selected .drop-down-list {
  display: none; }

.noscroll {
  overflow: hidden; }

.services {
  background-color: #f5f6f7;
  position: relative;
  z-index: 100; }
  .services .service-item {
    background-color: #122d3f;
    background-size: cover;
    background-position: center;
    margin: 5.5625rem 0;
    display: block;
    position: relative;
    transition: all 0.4s ease-in-out;
    overflow: hidden; }
    .services .service-item::before {
      content: "";
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      background: inherit;
      transition: inherit; }
    .services .service-item:hover::before {
      transform: scale(1.1); }
    .services .service-item .mouse-over-surface {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      height: 100%;
      width: 100%;
      background-color: #122d3f;
      opacity: 0;
      transition: all 0.5s ease-out; }
    .services .service-item:hover .mouse-over-surface {
      height: 100%;
      opacity: 0.7; }
    .services .service-item .gradient-background-layer {
      background: #122d3f;
      background: linear-gradient(180deg, #122d3f 0%, rgba(255, 255, 255, 0) 100%);
      position: relative; }
    .services .service-item h5, .services .service-item .h5 {
      padding: 2rem 0;
      margin-bottom: 0;
      text-align: center;
      color: #ffffff;
      line-height: 2.125rem; }
  @media (max-width: 575.98px) {
    .services {
      padding: 5.625rem 0; }
      .services .service-item {
        margin-top: 0;
        margin-bottom: 1.875rem; }
      .services .col-12:last-of-type .service-item {
        margin-bottom: 0; } }

@keyframes open-menu-list {
  0% {
    clip-path: polygon(0% 0%, 100% 0%, 100% 0%); }
  30% {
    clip-path: polygon(0% 0%, 100% 0%, 100% 60%); }
  100% {
    clip-path: polygon(0% 0%, 100% 0%, 100% 130%); } }

@keyframes open-menu-list-tablet {
  0% {
    clip-path: polygon(0% 0%, 100% 0%, 100% 0%, 100% 0%); }
  30% {
    clip-path: polygon(0% 0%, 100% 0%, 100% 60%, 100% 60%); }
  100% {
    clip-path: polygon(0% 0%, 100% 0%, 100% 110%, 70% 110%); } }

@keyframes open-menu-list-mobile {
  0% {
    clip-path: polygon(0% 0%, 100% 0%, 100% 0%, 0% 0%); }
  30% {
    clip-path: polygon(0% 0%, 100% 0%, 100% 60%, 0% 60%); }
  100% {
    clip-path: polygon(0% 0%, 100% 0%, 100% 100%, 0% 100%); } }

@keyframes close-menu-list {
  0% {
    clip-path: polygon(0% 0%, 100% 0%, 100% 130%); }
  30% {
    clip-path: polygon(0% 0%, 100% 0%, 100% 40%); }
  100% {
    clip-path: polygon(0% 0%, 100% 0%, 100% 0%); } }

@keyframes close-menu-list-mobile {
  0% {
    clip-path: polygon(0% 0%, 100% 0%, 100% 100%, 0% 100%); }
  30% {
    clip-path: polygon(0% 0%, 100% 0%, 100% 40%, 0% 40%); }
  100% {
    clip-path: polygon(0% 0%, 100% 0%, 100% 0%, 0% 0%); } }

nav {
  background-color: #122d3f;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem 2rem;
  position: fixed;
  top: 0;
  color: #ffffff;
  width: 100%;
  z-index: 100000;
  box-shadow: 0px 10px 20px rgba(13, 22, 37, 0.16);
  transition: all 0.3s ease-out;
  transition-delay: 0.5s; }
  nav.background-dark {
    background-color: #122d3f !important;
    transition-delay: 0s; }
  nav a {
    color: #ffffff;
    text-decoration: none; }
  nav .logo-header img {
    max-height: 2rem;
    max-height: 2.4rem; }
  nav .logo-header-white {
    display: none; }
    nav .logo-header-white img {
      max-height: 2rem;
      max-height: 2.4rem; }
  nav .search-bar {
    display: flex;
    flex-direction: row;
    background-color: #fff;
    padding: 0;
    border-radius: 2rem;
    border: 1px solid #122d3f;
    margin-bottom: 0;
    display: none; }
    nav .search-bar input[type="text"] {
      flex-grow: 1;
      background-color: #fff;
      color: #122d3f;
      font-size: 1rem;
      border: none;
      border-radius: 1.1rem 0px 0px 1.1rem;
      padding: 0.2rem 1rem;
      outline: none; }
    nav .search-bar button {
      background-color: #fff;
      border: none;
      border-radius: 0px 1.5rem 1.5rem 0px;
      padding: 0.2rem 0.5rem; }
    nav .search-bar ::placeholder {
      opacity: 1; }
    nav .search-bar.selected {
      display: flex; }
  nav .search-bar[style*='display: block'] {
    display: flex !important; }
  nav .easy-autocomplete-container {
    color: #122d3f; }
  nav .search {
    display: flex;
    align-items: center; }
    nav .search p {
      display: none; }
    nav .search .magnify-icon {
      margin-left: 1.25rem;
      -webkit-filter: drop-shadow(0px 0px 7px rgba(13, 22, 37, 0.5));
      filter: drop-shadow(0px 0px 7px rgba(13, 22, 37, 0.5)); }
      nav .search .magnify-icon rect {
        fill: #ffffff;
        transition: all 0.2s ease-out; }
      nav .search .magnify-icon g {
        stroke: #ffffff;
        transition: all 0.2s ease-out; }
    nav .search:hover .magnify-icon rect {
      fill: #fc0039; }
    nav .search:hover .magnify-icon g {
      stroke: #fc0039; }
  nav .menu-hamburger {
    margin-left: 1.5rem;
    cursor: pointer;
    transition: color 0.2s ease-out; }
    nav .menu-hamburger p.open {
      display: block; }
    nav .menu-hamburger p.close {
      display: none; }
    nav .menu-hamburger .menu-icon {
      display: flex;
      width: 21px;
      height: 15px;
      position: relative;
      cursor: pointer;
      margin-left: 1rem;
      -webkit-transform: rotate(0deg);
      -moz-transform: rotate(0deg);
      -o-transform: rotate(0deg);
      transform: rotate(0deg);
      -webkit-transition: 0.5s ease-in-out;
      -moz-transition: 0.5s ease-in-out;
      -o-transition: 0.5s ease-in-out;
      transition: 0.5s ease-in-out; }
      nav .menu-hamburger .menu-icon .line {
        display: block;
        position: absolute;
        height: 2px;
        width: 100%;
        background: #fff;
        background-color: white;
        border-radius: 8px;
        opacity: 1;
        left: 0;
        -webkit-transform: rotate(0deg);
        -moz-transform: rotate(0deg);
        -o-transform: rotate(0deg);
        transform: rotate(0deg);
        -webkit-transition: 0.25s ease-in-out;
        -moz-transition: 0.25s ease-in-out;
        -o-transition: 0.25s ease-in-out;
        transition: 0.25s ease-in-out; }
        nav .menu-hamburger .menu-icon .line:nth-child(1) {
          top: 0px; }
        nav .menu-hamburger .menu-icon .line:nth-child(2) {
          top: 6.5px; }
        nav .menu-hamburger .menu-icon .line:nth-child(3) {
          top: 13px; }
    nav .menu-hamburger:hover {
      color: #fc0039; }
      nav .menu-hamburger:hover .menu-icon .line {
        background: #fc0039;
        background-color: #fc0039; }
    nav .menu-hamburger.open p.open {
      display: none; }
    nav .menu-hamburger.open p.close {
      display: block; }
    nav .menu-hamburger.open .menu-icon .line:nth-child(1) {
      top: 18px;
      width: 0%;
      left: 50%; }
    nav .menu-hamburger.open .menu-icon .line:nth-child(2) {
      -webkit-transform: rotate(45deg);
      -moz-transform: rotate(45deg);
      -o-transform: rotate(45deg);
      transform: rotate(45deg);
      height: 3px; }
    nav .menu-hamburger.open .menu-icon .line:nth-child(3) {
      top: 6.5px;
      -webkit-transform: rotate(-45deg);
      -moz-transform: rotate(-45deg);
      -o-transform: rotate(-45deg);
      transform: rotate(-45deg);
      height: 3px; }
  @media (max-width: 575.98px) {
    nav .navbar-right-side {
      width: calc(100% - 4rem);
      position: absolute;
      left: 2rem;
      justify-content: flex-end; }
      nav .navbar-right-side .search-bar {
        width: calc(100%); }
        nav .navbar-right-side .search-bar input[type="text"] {
          width: calc(100% - 31px); }
      nav .navbar-right-side .search p,
      nav .navbar-right-side .menu-hamburger p {
        display: none; }
      nav .navbar-right-side .search.open p.close,
      nav .navbar-right-side .menu-hamburger.open p.close {
        display: none; }
    nav .logo-header img,
    nav .logo-header-white img {
      max-height: 2rem; } }

/* menu list when opened */
.menu-items {
  position: fixed;
  top: 4.3rem;
  color: #fff;
  background-color: #122d3f;
  width: 100%;
  text-align: end;
  height: 121vh;
  clip-path: polygon(30% 0%, 100% 0%, 100% 0%);
  padding-top: 3.3125rem;
  padding-right: 2rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  z-index: 10000;
  max-height: 100vh; }
  .menu-items a {
    color: #fff; }
  .menu-items .menu-list ul {
    list-style: none;
    font-size: 1.375rem;
    line-height: 2.125rem;
    padding-top: 0; }
    .menu-items .menu-list ul li.submenu-title {
      cursor: pointer;
      position: relative;
      padding-right: 1.55625rem; }
      .menu-items .menu-list ul li.submenu-title p {
        font-size: 1.375rem; }
      .menu-items .menu-list ul li.submenu-title:after {
        background-image: url("/static/images/icons/triangle-menu-list.svg");
        background-size: 15px 15px;
        content: "";
        width: 15px;
        height: 15px;
        display: inline-block;
        position: absolute;
        top: 0.6rem;
        right: 0.2rem;
        transform: rotate(0deg);
        transition: all 0.2s ease-out; }
      .menu-items .menu-list ul li.submenu-title.open:after {
        top: 0.4rem;
        transform: rotate(45deg); }
      .menu-items .menu-list ul li.submenu-title.open ul {
        display: block; }
      .menu-items .menu-list ul li.submenu-title:hover {
        color: #fc0039; }
      .menu-items .menu-list ul li.submenu-title.open:after {
        top: 0.4rem;
        transform: rotate(45deg); }
    .menu-items .menu-list ul li.menu-item {
      padding-right: 1.55625rem; }
    .menu-items .menu-list ul li ul.submenu-list {
      display: none;
      animation-duration: 0.6s; }
      .menu-items .menu-list ul li ul.submenu-list li a {
        font-size: 1rem; }
      .menu-items .menu-list ul li ul.submenu-list.open {
        display: block; }
  .menu-items .menu-list a:hover {
    color: #fc0039; }
  .menu-items .menu-links {
    display: flex;
    flex-direction: column;
    font-size: 1.25rem;
    line-height: 1.75rem;
    padding-bottom: calc(15vh + 7rem); }
    .menu-items .menu-links a:last-of-type {
      padding-bottom: 2.1875rem; }

.menu-close-animation {
  animation: close-menu-list 0.6s ease-out; }
  @media (max-width: 575.98px) {
    .menu-close-animation {
      animation: close-menu-list-mobile 0.6s ease-out; } }

.menu-open {
  animation: open-menu-list 0.6s ease-out;
  animation-fill-mode: forwards; }

@media (max-width: 991.98px) {
  .menu-items .menu-list ul li.submenu-title:after {
    width: 14px;
    top: 0.5rem; }
  .menu-items .menu-list ul li.submenu-title.open:after {
    top: 0.3rem;
    transform: rotate(45deg); }
  .menu-open {
    animation: open-menu-list-tablet 0.6s ease-out;
    animation-fill-mode: forwards; }
  .menu-items .menu-links {
    padding-bottom: calc(8vh + 7rem); } }

@media (max-width: 767.98px) {
  .menu-items .menu-list ul li.submenu-title:after {
    width: 14px;
    top: 0.45rem; }
  .menu-items .menu-list ul li.submenu-title.open:after {
    top: 0.2rem;
    transform: rotate(45deg); } }

@media (max-width: 575.98px) {
  .menu-items {
    top: 4rem;
    top: 0;
    overflow: hidden;
    padding-top: 7.3125rem; }
    .menu-items .menu-list ul li.submenu-title {
      padding-bottom: 0.3rem; }
      .menu-items .menu-list ul li.submenu-title:after {
        width: 13px;
        top: 0.4rem; }
      .menu-items .menu-list ul li.submenu-title.open:after {
        top: 0.18rem;
        transform: rotate(45deg); }
      .menu-items .menu-list ul li.submenu-title ul {
        padding-top: 0.3rem; }
        .menu-items .menu-list ul li.submenu-title ul .submenu-item {
          padding-bottom: 0.3rem; }
    .menu-items .menu-list ul li.menu-item {
      padding-bottom: 0.3rem; }
    .menu-items .menu-links {
      padding-bottom: calc(6vh + 7rem); }
  .menu-open {
    animation: open-menu-list-mobile 0.8s ease-out;
    animation-fill-mode: forwards; } }

@media all and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .menu-items {
    display: none !important;
    text-align: right; }
    .menu-items.menu-open {
      display: flex !important; } }

/* when menu icon is clicked and nav menu is displayed */
body.overflow-hidden {
  overflow: hidden; }

/* this class is add when click on hamburger menu with transparent background */
body.navbar-open-menu nav {
  background-color: #122d3f;
  box-shadow: none;
  transition-delay: 0s; }
  body.navbar-open-menu nav .logo-header {
    display: block !important; }
  body.navbar-open-menu nav .logo-header-white {
    display: none !important; }

header {
  position: relative;
  z-index: 100;
  padding-top: 4rem;
  /* custom header used in jobs.html and seo.html */
  /* header with blurred part in bottom of image */
  /* header with only breadcrumb and/or title/text */ }
  header.no-image {
    padding-top: 0; }
    @media (max-width: 575.98px) {
      header.no-image {
        margin-top: -0.5rem; } }
  header .header-content {
    max-height: fit-content;
    display: flex;
    align-items: center;
    width: 100%;
    background-size: cover;
    background-position: center;
    /* image slider in header (used in projects.html) */
    /* used in home.html template: header size hull height of browser window */
    /* only a quote in the header with background (used in about.html and contact.html) */
    /* header with title and labels ( used in project.html) */
    /* custom header used in current-projects.html */
    /* custom header used in current-project.html */ }
    header .header-content.projects-header {
      align-items: flex-start; }
    header .header-content .carousel {
      width: 100%;
      margin-top: 0;
      min-height: calc(100vh - 5rem); }
      header .header-content .carousel .carousel-indicators {
        /* padding is 2.4375rem - 1rem padding from button .carousel-control-next en prev */
        padding-bottom: 1.4375rem;
        align-items: center;
        -webkit-appearance: none; }
        header .header-content .carousel .carousel-indicators button.indicator {
          padding: 0.5rem;
          border-radius: 1rem;
          border: none;
          background-color: rgba(255, 255, 255, 0.65);
          margin-left: 1.6875rem;
          box-shadow: 0px 0px 5px rgba(13, 22, 37, 0.5);
          -webkit-appearance: none;
          -moz-appearance: none;
          appearance: none; }
        header .header-content .carousel .carousel-indicators .indicator.active {
          background-color: #fc0039; }
        header .header-content .carousel .carousel-indicators .carousel-control-prev,
        header .header-content .carousel .carousel-indicators .carousel-control-next {
          position: relative;
          width: auto;
          background-color: transparent;
          opacity: 1;
          border: none;
          padding: 1rem; }
          header .header-content .carousel .carousel-indicators .carousel-control-prev .nav-arrow,
          header .header-content .carousel .carousel-indicators .carousel-control-next .nav-arrow {
            height: 1rem;
            width: auto; }
          header .header-content .carousel .carousel-indicators .carousel-control-prev svg,
          header .header-content .carousel .carousel-indicators .carousel-control-next svg {
            -webkit-filter: drop-shadow(0px 0px 7px rgba(13, 22, 37, 0.5));
            filter: drop-shadow(0px 0px 7px rgba(13, 22, 37, 0.5)); }
            header .header-content .carousel .carousel-indicators .carousel-control-prev svg path,
            header .header-content .carousel .carousel-indicators .carousel-control-prev svg line,
            header .header-content .carousel .carousel-indicators .carousel-control-next svg path,
            header .header-content .carousel .carousel-indicators .carousel-control-next svg line {
              stroke: #ffffff;
              transition: all 0.2s ease-out; }
          header .header-content .carousel .carousel-indicators .carousel-control-prev:hover svg path,
          header .header-content .carousel .carousel-indicators .carousel-control-prev:hover svg line,
          header .header-content .carousel .carousel-indicators .carousel-control-next:hover svg path,
          header .header-content .carousel .carousel-indicators .carousel-control-next:hover svg line {
            stroke: #fc0039; }
        header .header-content .carousel .carousel-indicators .carousel-control-next {
          margin-left: 1.6875rem; }
      header .header-content .carousel .carousel-inner {
        min-height: calc(100vh -5rem);
        max-height: calc(100vh -5rem);
        display: flex; }
        header .header-content .carousel .carousel-inner .carousel-item {
          min-height: calc(100vh -5rem);
          max-height: calc(100vh -5rem);
          display: flex;
          background-color: #122d3f; }
          header .header-content .carousel .carousel-inner .carousel-item .carousel-caption {
            text-align: start;
            top: 0;
            bottom: 0;
            flex-direction: column;
            align-items: flex-start;
            justify-content: center;
            left: 0;
            width: 100%; }
            header .header-content .carousel .carousel-inner .carousel-item .carousel-caption h1, header .header-content .carousel .carousel-inner .carousel-item .carousel-caption .h1 {
              text-shadow: 0px 0px 8px rgba(0, 0, 0, 0.2);
              margin-bottom: 3.5625rem;
              padding-right: 20rem; }
            header .header-content .carousel .carousel-inner .carousel-item .carousel-caption .button-basic.red:hover {
              border: 1px solid #ffffff; }
          header .header-content .carousel .carousel-inner .carousel-item .background-image {
            object-fit: cover;
            width: 100%;
            min-width: 100%;
            width: 100%; }
    header .header-content.background-image {
      background: url("/data/images/home/image_mediumhome_header2.jpg"); }
      @media (max-width: 575.98px) {
        header .header-content.background-image {
          height: 100vh; } }
      @media (min-width: 992px) {
        header .header-content.background-image {
          background: url("/data/images/home/image_largehome_header2.jpg");
          background-size: cover;
          background-position: center; } }
    header .header-content.header-full-height {
      /* video in header home */ }
      header .header-content.header-full-height h1, header .header-content.header-full-height .h1 {
        color: #ffffff;
        text-align: center;
        text-shadow: 0 0 8px rgba(0, 0, 0, 0.2); }
      header .header-content.header-full-height .iframe-container {
        width: 100vw;
        height: 100%;
        overflow: hidden;
        position: relative; }
        header .header-content.header-full-height .iframe-container video {
          position: relative;
          width: 100vw;
          display: block;
          height: auto;
          z-index: 0; }
          header .header-content.header-full-height .iframe-container video .ytp-chrome-top,
          header .header-content.header-full-height .iframe-container video .ytp-show-cards-title {
            display: none; }
      header .header-content.header-full-height .overlay {
        position: absolute;
        top: 50%;
        -webkit-transform: translateY(-50%);
        transform: translateY(-50%);
        z-index: 1;
        min-width: 100%; }
    header .header-content.quote-in-header {
      padding: 4.4rem;
      display: flex;
      flex-direction: column; }
      header .header-content.quote-in-header .breadcrumbs {
        padding-bottom: 2.8125rem; }
      header .header-content.quote-in-header .breadcrumb-container .step-back-link {
        display: none; }
      header .header-content.quote-in-header .quote {
        display: flex;
        align-items: center;
        flex: 1; }
        header .header-content.quote-in-header .quote H1 {
          text-align: center; }
    header .header-content.project-item-header {
      align-items: flex-end;
      margin-bottom: 0;
      min-height: calc(100vh - 10rem); }
      header .header-content.project-item-header .project-specifics {
        margin-bottom: -3.0625rem;
        color: #fff; }
        header .header-content.project-item-header .project-specifics h1, header .header-content.project-item-header .project-specifics .h1 {
          text-align: center;
          margin-bottom: 2.3125rem; }
        header .header-content.project-item-header .project-specifics .project-properties {
          background-color: #122d3f; }
          header .header-content.project-item-header .project-specifics .project-properties .property-item {
            display: flex;
            align-items: flex-end;
            justify-content: center;
            padding-top: 1.625rem;
            padding-bottom: 2rem; }
            header .header-content.project-item-header .project-specifics .project-properties .property-item img {
              margin-right: 1rem; }
            header .header-content.project-item-header .project-specifics .project-properties .property-item p {
              font-size: 1.25rem;
              line-height: 1.75rem;
              margin-bottom: 0;
              font-weight: 700; }
              header .header-content.project-item-header .project-specifics .project-properties .property-item p:last-of-type {
                font-weight: 300; }
        header .header-content.project-item-header .project-specifics .header-labels .project-label {
          background-color: rgba(246, 246, 246, 0.8); }
          header .header-content.project-item-header .project-specifics .header-labels .project-label p {
            color: #122d3f; }
    header .header-content.current-projects-header {
      display: flex;
      flex-direction: column;
      min-height: unset;
      max-height: unset; }
      @media (max-width: 575.98px) {
        header .header-content.current-projects-header {
          margin-top: 0; } }
      header .header-content.current-projects-header .breadcrumbs {
        padding-bottom: 5.3125rem; }
      header .header-content.current-projects-header .breadcrumb-container .step-back-link {
        display: none; }
      header .header-content.current-projects-header .quote {
        text-align: center;
        margin-bottom: 3.8125rem; }
        header .header-content.current-projects-header .quote h1, header .header-content.current-projects-header .quote .h1 {
          margin-bottom: 3rem; }
    header .header-content.search-results-page {
      background-image: url("/static/images/background-about.svg");
      background-size: cover; }
      header .header-content.search-results-page .quote h1, header .header-content.search-results-page .quote .h1 {
        margin-top: 5.3125rem; }
      header .header-content.search-results-page .quote .search-bar {
        display: flex;
        flex-direction: row;
        background-color: #fff;
        padding: 0;
        border-radius: 2rem;
        border: 1px solid #122d3f;
        margin-bottom: 0;
        max-width: 43rem;
        margin-bottom: 3.89rem;
        margin-left: auto;
        margin-right: auto;
        border-color: #ffffff; }
        header .header-content.search-results-page .quote .search-bar input[type="text"] {
          flex-grow: 1;
          background-color: #fff;
          color: #122d3f;
          font-size: 1rem;
          border: none;
          border-radius: 1.1rem 0px 0px 1.1rem;
          padding: 0.2rem 1rem;
          outline: none; }
        header .header-content.search-results-page .quote .search-bar button {
          background-color: #fff;
          border: none;
          border-radius: 0px 1.5rem 1.5rem 0px;
          padding: 0.2rem 0.5rem; }
        header .header-content.search-results-page .quote .search-bar ::placeholder {
          opacity: 1; }
        header .header-content.search-results-page .quote .search-bar.selected {
          display: flex; }
    header .header-content.current-project-header {
      display: flex;
      flex-direction: column; }
      header .header-content.current-project-header .breadcrumbs,
      header .header-content.current-project-header .step-back-link {
        padding-bottom: 5.3125rem; }
      @media (max-width: 575.98px) {
        header .header-content.current-project-header .breadcrumbs {
          padding-bottom: 0.5rem; }
        header .header-content.current-project-header .step-back-link {
          padding-bottom: 2rem;
          margin-bottom: 1rem; } }
      header .header-content.current-project-header .c-project-header-content {
        width: 100%; }
        header .header-content.current-project-header .c-project-header-content h1, header .header-content.current-project-header .c-project-header-content .h1 {
          text-align: center;
          margin-bottom: 3rem; }
        header .header-content.current-project-header .c-project-header-content .header-labels {
          margin-bottom: 0rem; }
      header .header-content.current-project-header #timeline-caption {
        opacity: 0;
        transition: all 2s ease-out;
        display: block;
        text-align: center;
        /* 0% width is (0% - 0rem), 50% width is (50% - 3rem), 100% width is (100% - 6rem) */
        margin-bottom: 0.9rem; }
        header .header-content.current-project-header #timeline-caption p {
          margin-bottom: 0;
          line-height: 1.25rem; }
          header .header-content.current-project-header #timeline-caption p:last-of-type {
            margin-bottom: 0.8625rem; }
        header .header-content.current-project-header #timeline-caption.start p {
          text-align: left;
          margin-left: 3rem; }
        header .header-content.current-project-header #timeline-caption.end p {
          text-align: right;
          margin-left: -3rem; }
        header .header-content.current-project-header #timeline-caption div {
          width: 6rem;
          margin-left: -3rem; }
      header .header-content.current-project-header .timeline-container {
        position: relative;
        padding-top: 1rem;
        display: flex;
        margin-bottom: 6.875rem; }
        header .header-content.current-project-header .timeline-container .timeline-item {
          position: relative; }
          header .header-content.current-project-header .timeline-container .timeline-item .timeline-bar-container {
            width: 100%;
            border-left: 1px solid #707070;
            height: 2.8rem;
            position: relative;
            z-index: 2; }
            header .header-content.current-project-header .timeline-container .timeline-item .timeline-bar-container .timeline-bar {
              border-radius: 1rem;
              position: absolute;
              top: -1rem;
              left: -0.5px;
              width: calc(100% + 1px);
              z-index: 4; }
              header .header-content.current-project-header .timeline-container .timeline-item .timeline-bar-container .timeline-bar p {
                padding: 0.4375rem 0.4375rem;
                line-height: 1.25rem; }
              header .header-content.current-project-header .timeline-container .timeline-item .timeline-bar-container .timeline-bar.preparation {
                color: #ffffff;
                background-color: #fc0039; }
              header .header-content.current-project-header .timeline-container .timeline-item .timeline-bar-container .timeline-bar.performance {
                color: #ffffff;
                background-color: #122d3f; }
              header .header-content.current-project-header .timeline-container .timeline-item .timeline-bar-container .timeline-bar.completion {
                color: #122d3f;
                background-color: #d8d8d8; }
            header .header-content.current-project-header .timeline-container .timeline-item .timeline-bar-container .time-line-date {
              position: absolute;
              bottom: -1.5rem;
              width: 7rem; }
              @media (max-width: 767.98px) {
                header .header-content.current-project-header .timeline-container .timeline-item .timeline-bar-container .time-line-date {
                  width: 15rem; } }
              header .header-content.current-project-header .timeline-container .timeline-item .timeline-bar-container .time-line-date p {
                line-height: 1.25rem;
                text-align: center; }
              header .header-content.current-project-header .timeline-container .timeline-item .timeline-bar-container .time-line-date.start-date {
                left: -3.5rem; }
              header .header-content.current-project-header .timeline-container .timeline-item .timeline-bar-container .time-line-date.end-date {
                right: -3.5rem;
                left: auto; }
          header .header-content.current-project-header .timeline-container .timeline-item:nth-child(1) .timeline-bar-container .timeline-bar {
            width: calc(100% + 1.5px);
            left: -1px; }
          header .header-content.current-project-header .timeline-container .timeline-item:nth-child(3) .timeline-bar-container {
            border-right: 1px solid rgba(112, 112, 112, 0.29); }
            header .header-content.current-project-header .timeline-container .timeline-item:nth-child(3) .timeline-bar-container.finished {
              border-right: 1px solid #707070; }
            header .header-content.current-project-header .timeline-container .timeline-item:nth-child(3) .timeline-bar-container .timeline-bar {
              width: calc(100% + 1.5px); }
          header .header-content.current-project-header .timeline-container .timeline-item .vertical-time-lines {
            position: absolute;
            top: 0;
            left: 0px;
            height: 2.8rem;
            width: 100%;
            display: flex;
            justify-content: space-evenly; }
            header .header-content.current-project-header .timeline-container .timeline-item .vertical-time-lines span {
              opacity: 1;
              display: inline-block;
              height: 100%;
              width: 1px;
              background-color: #707070;
              opacity: 0.29; }
      @media (max-width: 991.98px) {
        header .header-content.current-project-header .timeline-container .timeline-item .timeline-bar-container .timeline-bar p {
          padding-left: 0.4375rem;
          line-height: 1.25rem;
          font-size: 0.9rem; } }
      @media (max-width: 767.98px) {
        header .header-content.current-project-header #timeline-caption {
          display: none; }
        header .header-content.current-project-header .timeline-container {
          padding-top: 0;
          flex-direction: column; }
          header .header-content.current-project-header .timeline-container .timeline-item .timeline-bar-container {
            border: none;
            margin-top: 2.5rem;
            height: 2.5rem; }
            header .header-content.current-project-header .timeline-container .timeline-item .timeline-bar-container .timeline-bar {
              top: 0;
              border-radius: 0; }
              header .header-content.current-project-header .timeline-container .timeline-item .timeline-bar-container .timeline-bar p {
                color: #122d3f;
                padding-left: 0;
                font-size: 1.25rem; }
              header .header-content.current-project-header .timeline-container .timeline-item .timeline-bar-container .timeline-bar.preparation, header .header-content.current-project-header .timeline-container .timeline-item .timeline-bar-container .timeline-bar.performance, header .header-content.current-project-header .timeline-container .timeline-item .timeline-bar-container .timeline-bar.completion {
                height: 100%;
                background-color: transparent; }
              header .header-content.current-project-header .timeline-container .timeline-item .timeline-bar-container .timeline-bar.preparation {
                border-bottom: 4px solid #fc0039; }
              header .header-content.current-project-header .timeline-container .timeline-item .timeline-bar-container .timeline-bar.performance {
                border-bottom: 4px solid #122d3f; }
              header .header-content.current-project-header .timeline-container .timeline-item .timeline-bar-container .timeline-bar.completion {
                border-bottom: 4px solid #d8d8d8; }
            header .header-content.current-project-header .timeline-container .timeline-item .timeline-bar-container .time-line-date {
              bottom: -1.5rem; }
              header .header-content.current-project-header .timeline-container .timeline-item .timeline-bar-container .time-line-date p {
                text-align: start; }
              header .header-content.current-project-header .timeline-container .timeline-item .timeline-bar-container .time-line-date.start-date {
                left: 0; }
              header .header-content.current-project-header .timeline-container .timeline-item .timeline-bar-container .time-line-date.end-date {
                right: -7rem; }
                header .header-content.current-project-header .timeline-container .timeline-item .timeline-bar-container .time-line-date.end-date p {
                  text-align: start; }
          header .header-content.current-project-header .timeline-container .timeline-item:nth-child(3) .timeline-bar-container {
            border: none; }
            header .header-content.current-project-header .timeline-container .timeline-item:nth-child(3) .timeline-bar-container.finished {
              border: none; }
          header .header-content.current-project-header .timeline-container .timeline-item .vertical-time-lines {
            display: none; } }
      @media (max-width: 575.98px) {
        header .header-content.current-project-header .timeline-container .timeline-item .timeline-bar-container .timeline-bar p {
          width: 90vw; } }
  @media (max-width: 991.98px) {
    header .header-content .carousel .carousel-inner .carousel-item .carousel-caption h1, header .header-content .carousel .carousel-inner .carousel-item .carousel-caption .h1 {
      padding-right: 10rem; } }
  @media (max-width: 767.98px) {
    header .header-content .carousel .carousel-inner .carousel-item .carousel-caption h1, header .header-content .carousel .carousel-inner .carousel-item .carousel-caption .h1 {
      padding-right: 0; }
    header .header-content.project-item-header .project-specifics .project-properties .property-item {
      justify-content: flex-start; }
      header .header-content.project-item-header .project-specifics .project-properties .property-item img {
        margin-left: 1rem; }
    header .header-content.current-project-header .breadcrumbs {
      padding-bottom: 2.8125rem; } }
  @media (max-width: 575.98px) {
    header .header-content {
      min-height: calc(100vh-5rem); }
      header .header-content .carousel {
        min-height: calc(100vh-5rem); }
        header .header-content .carousel .carousel-inner {
          min-height: calc(100vh-5rem);
          max-height: calc(100vh-5rem); }
          header .header-content .carousel .carousel-inner .carousel-item {
            min-height: calc(100vh-5rem);
            max-height: calc(100vh-5rem); }
            header .header-content .carousel .carousel-inner .carousel-item .carousel-caption h1, header .header-content .carousel .carousel-inner .carousel-item .carousel-caption .h1 {
              word-wrap: anywhere; }
      header .header-content.current-projects-header .breadcrumbs {
        padding-bottom: 1rem; }
      header .header-content.quote-in-header {
        padding: 4.4rem 0; }
      header .header-content.current-project-header {
        min-height: auto; }
        header .header-content.current-project-header .step-back-link {
          margin-top: 0; }
        header .header-content.current-project-header .breadcrumbs {
          padding-bottom: 0.5rem; } }
  header .header-with-blurred-image {
    position: relative;
    clip-path: inset(0% 0% 2rem 0%);
    margin-bottom: -7.5rem; }
    header .header-with-blurred-image .background-basic {
      position: relative;
      overflow: hidden; }
      header .header-with-blurred-image .background-basic img.background-image {
        width: 130%;
        margin-left: -15%;
        max-height: calc(100vh -5.5rem);
        min-height: calc(100vh -5.5rem);
        object-fit: cover; }
    header .header-with-blurred-image.full-height-header {
      margin-bottom: -2rem; }
      header .header-with-blurred-image.full-height-header .background-basic img.background-image {
        max-height: calc(100vh - 5rem);
        min-height: calc(100vh -5rem); }
    header .header-with-blurred-image .content {
      position: absolute;
      bottom: 0;
      overflow: hidden;
      width: 100%;
      padding-top: 3rem;
      padding-bottom: 3rem;
      color: #fff; }
      @media (max-width: 575.98px) {
        header .header-with-blurred-image .content {
          padding-bottom: 2rem;
          padding-top: 1rem; }
          header .header-with-blurred-image .content h1, header .header-with-blurred-image .content .h1 {
            font-size: 2.25rem;
            line-height: 2.5rem; } }
      header .header-with-blurred-image .content .background-blurred {
        position: absolute;
        bottom: 0;
        width: 100%;
        height: 100%;
        overflow: hidden; }
        header .header-with-blurred-image .content .background-blurred img {
          filter: blur(20px);
          width: 130%;
          margin-left: -15%;
          position: absolute;
          bottom: 0; }
      header .header-with-blurred-image .content .title-text-and-button {
        display: flex;
        align-items: flex-end;
        margin-bottom: 1.96rem; }
        header .header-with-blurred-image .content .title-text-and-button h1, header .header-with-blurred-image .content .title-text-and-button .h1 {
          color: #ffffff;
          text-shadow: 0 0 8px rgba(0, 0, 0, 0.2);
          word-break: break-word; }
      header .header-with-blurred-image .content .news-labels {
        display: flex;
        flex-wrap: wrap; }
        header .header-with-blurred-image .content .news-labels .label-item {
          padding: 0 0;
          position: relative;
          bottom: 0;
          width: max-content;
          overflow: hidden;
          padding-right: 1rem;
          padding-bottom: 1rem; }
          header .header-with-blurred-image .content .news-labels .label-item .label-text {
            background: rgba(246, 246, 246, 0.8);
            overflow: hidden;
            background-position: bottom left;
            padding: 0.34375rem 1rem;
            transition: all 0.4s ease-in-out; }
            header .header-with-blurred-image .content .news-labels .label-item .label-text p {
              color: #122d3f;
              font-weight: 500;
              transition: color 0.4s ease-in-out; }
      header .header-with-blurred-image .content h1, header .header-with-blurred-image .content .h1 {
        margin-bottom: 0; }
        header .header-with-blurred-image .content h1.jobs-title, header .header-with-blurred-image .content .jobs-title.h1 {
          margin-bottom: 6rem; }
      header .header-with-blurred-image .content p {
        text-transform: uppercase; }
  header .header-with-blurred-image-carousel {
    clip-path: inset(0% 0% 2rem 0%);
    min-height: calc(100vh - 5rem);
    margin-bottom: -2rem; }
    header .header-with-blurred-image-carousel .content {
      position: absolute;
      position: relative;
      overflow: hidden;
      width: 100%;
      color: #fff; }
      header .header-with-blurred-image-carousel .content .title-text-and-button {
        display: flex;
        align-items: flex-end;
        margin-bottom: 1.96rem; }
      header .header-with-blurred-image-carousel .content .news-labels {
        display: flex;
        flex-wrap: wrap; }
        header .header-with-blurred-image-carousel .content .news-labels .label-item {
          padding: 0 0;
          position: relative;
          bottom: 0;
          width: max-content;
          overflow: hidden;
          padding-right: 1rem;
          padding-bottom: 1rem; }
          header .header-with-blurred-image-carousel .content .news-labels .label-item .label-text {
            background: rgba(246, 246, 246, 0.8);
            overflow: hidden;
            background-position: bottom left;
            padding: 0.34375rem 1rem;
            transition: all 0.4s ease-in-out; }
            header .header-with-blurred-image-carousel .content .news-labels .label-item .label-text p {
              color: #122d3f;
              font-weight: 500;
              transition: color 0.4s ease-in-out; }
      header .header-with-blurred-image-carousel .content h1, header .header-with-blurred-image-carousel .content .h1 {
        margin-bottom: 0; }
      header .header-with-blurred-image-carousel .content p {
        text-transform: uppercase; }
      header .header-with-blurred-image-carousel .content .carousel {
        width: 100%;
        margin-top: 0;
        min-height: calc(100vh - 5rem);
        /* content inside the carousel, text and labels */
        /* 4 tabs in the bottom of the carousel */ }
        header .header-with-blurred-image-carousel .content .carousel .carousel-inner {
          min-height: calc(100vh - 5rem);
          max-height: calc(100vh - 5rem); }
          header .header-with-blurred-image-carousel .content .carousel .carousel-inner .carousel-item {
            min-height: calc(100vh - 5rem);
            max-height: calc(100vh - 5rem);
            display: flex;
            background-color: #122d3f; }
            header .header-with-blurred-image-carousel .content .carousel .carousel-inner .carousel-item .background-basic {
              position: relative;
              width: 100%;
              margin-bottom: -2rem;
              overflow: hidden; }
              header .header-with-blurred-image-carousel .content .carousel .carousel-inner .carousel-item .background-basic img.background-image {
                width: 130%;
                margin-left: -15%;
                min-height: calc(100vh -5rem);
                max-height: calc(100vh -5rem);
                object-fit: cover; }
            header .header-with-blurred-image-carousel .content .carousel .carousel-inner .carousel-item .carousel-caption {
              text-align: start;
              padding-right: 0;
              padding-left: 0;
              padding-bottom: 5.625rem;
              padding-bottom: 4.58125rem;
              bottom: 0;
              min-width: 100%;
              position: absolute;
              overflow: hidden;
              left: 0; }
              header .header-with-blurred-image-carousel .content .carousel .carousel-inner .carousel-item .carousel-caption .background-blurred {
                position: absolute;
                bottom: 0;
                width: 100%;
                height: 100%;
                margin-bottom: -2rem;
                overflow: hidden; }
                header .header-with-blurred-image-carousel .content .carousel .carousel-inner .carousel-item .carousel-caption .background-blurred img {
                  filter: blur(20px);
                  width: 130%;
                  height: calc(100vh -5rem);
                  margin-left: -15%;
                  position: absolute;
                  bottom: 0; }
              header .header-with-blurred-image-carousel .content .carousel .carousel-inner .carousel-item .carousel-caption p.date-content {
                padding-top: 2.18125rem; }
              header .header-with-blurred-image-carousel .content .carousel .carousel-inner .carousel-item .carousel-caption h1, header .header-with-blurred-image-carousel .content .carousel .carousel-inner .carousel-item .carousel-caption .h1,
              header .header-with-blurred-image-carousel .content .carousel .carousel-inner .carousel-item .carousel-caption .caption-title {
                display: block;
                font-size: 3.25rem;
                font-weight: 700;
                text-shadow: 0px 0px 8px rgba(0, 0, 0, 0.2);
                margin-bottom: 2rem;
                line-height: 3.75rem;
                overflow-wrap: break-word;
                color: #ffffff; }
                @media (max-width: 575.98px) {
                  header .header-with-blurred-image-carousel .content .carousel .carousel-inner .carousel-item .carousel-caption h1, header .header-with-blurred-image-carousel .content .carousel .carousel-inner .carousel-item .carousel-caption .h1,
                  header .header-with-blurred-image-carousel .content .carousel .carousel-inner .carousel-item .carousel-caption .caption-title {
                    margin-bottom: 0;
                    font-size: 2.25rem;
                    line-height: 2.5rem; } }
              header .header-with-blurred-image-carousel .content .carousel .carousel-inner .carousel-item .carousel-caption .news-labels {
                display: flex;
                flex-wrap: wrap; }
                header .header-with-blurred-image-carousel .content .carousel .carousel-inner .carousel-item .carousel-caption .news-labels .label-item {
                  padding: 0 0;
                  position: relative;
                  bottom: 0;
                  width: max-content;
                  overflow: hidden;
                  padding-right: 1rem;
                  padding-bottom: 1rem; }
                  header .header-with-blurred-image-carousel .content .carousel .carousel-inner .carousel-item .carousel-caption .news-labels .label-item .label-text {
                    background: rgba(246, 246, 246, 0.8);
                    overflow: hidden;
                    background-position: bottom left;
                    padding: 0.34375rem 1rem;
                    transition: all 0.4s ease-in-out; }
                    header .header-with-blurred-image-carousel .content .carousel .carousel-inner .carousel-item .carousel-caption .news-labels .label-item .label-text p {
                      color: #122d3f;
                      font-weight: 500;
                      transition: color 0.4s ease-in-out; }
            header .header-with-blurred-image-carousel .content .carousel .carousel-inner .carousel-item .background-image {
              object-fit: cover;
              min-width: 130%; }
        header .header-with-blurred-image-carousel .content .carousel .carousel-indicators {
          padding-bottom: 1.96875rem;
          margin: 0;
          position: relative;
          margin-top: -3.78125rem; }
          header .header-with-blurred-image-carousel .content .carousel .carousel-indicators .col-md-3:nth-child(5) .indicator {
            border-right: none; }
          @media (min-width: 768px) {
            header .header-with-blurred-image-carousel .content .carousel .carousel-indicators button.indicator {
              width: 100%;
              height: 100%;
              border: none;
              border-right: 1px solid #f6f6f6;
              background-color: #122d3f;
              color: #f6f6f6;
              display: flex;
              padding: 1rem;
              padding-top: 1.1875rem;
              padding-bottom: 1.5625rem;
              min-height: 6.8125rem;
              position: relative;
              transition: all 0.5s ease-out;
              transition-delay: 0.1s;
              text-align: start; }
              header .header-with-blurred-image-carousel .content .carousel .carousel-indicators button.indicator:before {
                content: "";
                position: absolute;
                top: 0;
                bottom: 0;
                left: 0;
                width: 0%;
                height: 100%;
                border-bottom: 0.25rem solid #122d3f;
                transition: all 0.5s ease-out; }
              header .header-with-blurred-image-carousel .content .carousel .carousel-indicators button.indicator:hover {
                background-color: #f6f6f6;
                color: #122d3f; }
                header .header-with-blurred-image-carousel .content .carousel .carousel-indicators button.indicator:hover:before {
                  width: 100%; }
            header .header-with-blurred-image-carousel .content .carousel .carousel-indicators .indicator.active {
              background-color: #f6f6f6;
              color: #122d3f; } }
  @media (min-width: 768px) and (min-width: 768px) {
    header .header-with-blurred-image-carousel .content .carousel .carousel-indicators .indicator.active:before {
      width: 100%;
      border-bottom: 0.25rem solid #122d3f; } }
          header .header-with-blurred-image-carousel .content .carousel .carousel-indicators .carousel-control-prev,
          header .header-with-blurred-image-carousel .content .carousel .carousel-indicators .carousel-control-next {
            position: relative;
            width: auto;
            background-color: transparent;
            opacity: 1;
            border: none;
            padding: 1rem; }
            header .header-with-blurred-image-carousel .content .carousel .carousel-indicators .carousel-control-prev .nav-arrow,
            header .header-with-blurred-image-carousel .content .carousel .carousel-indicators .carousel-control-next .nav-arrow {
              height: 1rem;
              width: auto; }
            header .header-with-blurred-image-carousel .content .carousel .carousel-indicators .carousel-control-prev svg,
            header .header-with-blurred-image-carousel .content .carousel .carousel-indicators .carousel-control-next svg {
              -webkit-filter: drop-shadow(0px 0px 7px rgba(13, 22, 37, 0.5));
              filter: drop-shadow(0px 0px 7px rgba(13, 22, 37, 0.5)); }
              header .header-with-blurred-image-carousel .content .carousel .carousel-indicators .carousel-control-prev svg path,
              header .header-with-blurred-image-carousel .content .carousel .carousel-indicators .carousel-control-prev svg line,
              header .header-with-blurred-image-carousel .content .carousel .carousel-indicators .carousel-control-next svg path,
              header .header-with-blurred-image-carousel .content .carousel .carousel-indicators .carousel-control-next svg line {
                stroke: #ffffff;
                transition: all 0.2s ease-out; }
            header .header-with-blurred-image-carousel .content .carousel .carousel-indicators .carousel-control-prev:hover svg path,
            header .header-with-blurred-image-carousel .content .carousel .carousel-indicators .carousel-control-prev:hover svg line,
            header .header-with-blurred-image-carousel .content .carousel .carousel-indicators .carousel-control-next:hover svg path,
            header .header-with-blurred-image-carousel .content .carousel .carousel-indicators .carousel-control-next:hover svg line {
              stroke: #fc0039; }
            @media (min-width: 768px) {
              header .header-with-blurred-image-carousel .content .carousel .carousel-indicators .carousel-control-prev,
              header .header-with-blurred-image-carousel .content .carousel .carousel-indicators .carousel-control-next {
                display: none; } }
          header .header-with-blurred-image-carousel .content .carousel .carousel-indicators .carousel-control-next {
            margin-left: 1.6875rem; }
          @media (max-width: 767.98px) {
            header .header-with-blurred-image-carousel .content .carousel .carousel-indicators button.indicator {
              padding: 0.5rem;
              border-radius: 1rem;
              border: none;
              background-color: rgba(255, 255, 255, 0.65);
              margin-left: 1.6875rem;
              box-shadow: 0px 0px 5px rgba(13, 22, 37, 0.5); }
              header .header-with-blurred-image-carousel .content .carousel .carousel-indicators button.indicator span {
                display: none; }
            header .header-with-blurred-image-carousel .content .carousel .carousel-indicators .indicator.active {
              background-color: #fc0039; } }
        @media (min-width: 992px) {
          header .header-with-blurred-image-carousel .content .carousel {
            min-height: calc(100vh - 5rem); }
            header .header-with-blurred-image-carousel .content .carousel .carousel-inner {
              min-height: calc(100vh - 5rem);
              max-height: calc(100vh - 5rem); }
              header .header-with-blurred-image-carousel .content .carousel .carousel-inner .carousel-item {
                min-height: calc(100vh - 5rem);
                max-height: calc(100vh - 5rem); } }
        @media (max-width: 767.98px) {
          header .header-with-blurred-image-carousel .content .carousel .mobile-display-indicators {
            align-items: center;
            justify-content: center; }
            header .header-with-blurred-image-carousel .content .carousel .mobile-display-indicators .col-md-3 {
              display: flex;
              align-items: center; }
          header .header-with-blurred-image-carousel .content .carousel .carousel-inner {
            min-height: calc(100vh-5rem);
            min-height: calc(var(--vh, 1vh) * 100 -5rem); }
            header .header-with-blurred-image-carousel .content .carousel .carousel-inner .carousel-item {
              min-height: calc(100vh-5rem);
              min-height: calc(var(--vh, 1vh) * 100-5rem); }
              header .header-with-blurred-image-carousel .content .carousel .carousel-inner .carousel-item .background-basic img.background-image {
                min-height: calc(100vh -5rem);
                max-height: calc(100vh -5rem); }
          header .header-with-blurred-image-carousel .content .carousel .carousel-indicators {
            padding-bottom: 2.969rem; } }
        @media (max-width: 575.98px) {
          header .header-with-blurred-image-carousel .content .carousel .carousel-inner {
            min-height: calc(100vh-5rem);
            min-height: calc(var(--vh, 1vh) * 100 -5rem); }
            header .header-with-blurred-image-carousel .content .carousel .carousel-inner .carousel-item {
              min-height: calc(100vh-5rem);
              min-height: calc(var(--vh, 1vh) * 100-5rem); }
              header .header-with-blurred-image-carousel .content .carousel .carousel-inner .carousel-item .background-basic img.background-image {
                min-height: calc(100vh -5rem);
                max-height: calc(100vh -5rem); }
          header .header-with-blurred-image-carousel .content .carousel .carousel-indicators {
            padding-bottom: 2.969rem; } }
  header .text-header {
    margin-top: 4.4rem;
    background-color: #f6f6f6;
    text-align: center;
    display: flex;
    flex-direction: column; }
    header .text-header.light-background-with-margin {
      background-color: #ffffff;
      position: relative; }
      header .text-header.light-background-with-margin #animation-certificates-one {
        background-image: url("/static/images/icons/triangle-grey-small-left-bottom.svg");
        background-position-x: left;
        background-position-y: top;
        background-size: initial;
        background-repeat: no-repeat;
        position: absolute;
        width: 100%;
        left: 0;
        min-height: 236.7px;
        top: calc(-4rem);
        left: -5%; }
      header .text-header.light-background-with-margin #animation-certificates-two {
        background-image: url("/static/images/icons/triangle-grey-small-left-top.svg");
        background-position-x: left;
        background-position-y: top;
        background-size: initial;
        background-repeat: no-repeat;
        position: absolute;
        width: 100%;
        left: 0;
        min-height: 236.7px;
        top: calc(-4rem + 236.7px);
        left: calc(-5% + 236.7px); }
    header .text-header.full-height {
      min-height: calc(100vh - 4.4rem); }
    header .text-header .title {
      flex: 1; }
      header .text-header .title h1, header .text-header .title .h1 {
        margin-bottom: 2.5rem; }
      header .text-header .title span {
        display: inline-block;
        margin-bottom: 4.4rem;
        vertical-align: middle; }
        header .text-header .title span:last-of-type {
          padding-left: 0.8rem;
          opacity: 0.36;
          text-transform: uppercase; }
    header .text-header .introduction-paragraph {
      background-color: #ffffff;
      text-align: start; }
      header .text-header .introduction-paragraph a.button-basic {
        display: block;
        width: max-content;
        margin-top: -1.5rem;
        margin-left: auto;
        margin-right: auto;
        margin-bottom: 5.3125rem; }
      header .text-header .introduction-paragraph p {
        margin-bottom: 2.75rem; }
    header .text-header.news-item-header .step-back-link {
      padding-top: 2.6875rem;
      padding-bottom: 2.0625rem; }

.project_list {
  color: blue; }

.menu_open .project_list {
  color: red; }

.breadcrumb-container .breadcrumbs {
  list-style: none;
  display: flex;
  font-size: 1rem;
  padding-top: 2.8125rem;
  padding-bottom: 6rem;
  padding-left: 0; }
  .breadcrumb-container .breadcrumbs li {
    padding-right: 0.5rem; }
  .breadcrumb-container .breadcrumbs a {
    font-weight: 300; }
    .breadcrumb-container .breadcrumbs a:after {
      content: "/";
      padding-left: 0.5rem; }

.breadcrumb-container .step-back-link {
  display: block;
  padding: 2.8125rem 0;
  text-align: end; }
  .breadcrumb-container .step-back-link:before {
    content: url("/static/images/icons/arrow-dark-back.svg");
    padding-right: 0.375rem; }

@media (max-width: 575.98px) {
  .breadcrumb-container .breadcrumbs {
    padding-bottom: 1rem; }
  .breadcrumb-container .step-back-link {
    padding-top: 0;
    text-align: start; } }

footer {
  background: #0d1625;
  background: linear-gradient(90deg, #0d1625 0%, #122d3f 100%);
  color: #ffffff;
  z-index: 2;
  position: relative; }
  footer .footer-image {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0; }
    footer .footer-image img {
      display: block;
      margin-left: auto;
      max-height: 100%;
      margin-right: 0; }
  footer a,
  footer p {
    color: #ffffff;
    line-height: 1.875rem; }
  footer h6, footer .h6 {
    font-weight: 300;
    text-transform: uppercase;
    color: #74aac2;
    margin-bottom: 1.5625rem; }
  footer .footer-nav {
    padding-top: 4rem;
    padding-bottom: 3rem; }
    footer .footer-nav .routeplanner-link {
      text-decoration: underline; }
    footer .footer-nav .adres-data p,
    footer .footer-nav .adres-data a {
      font-size: 1rem; }
    footer .footer-nav .adres-data a {
      text-decoration: underline; }
  footer .horizontal-line {
    border-bottom: 1px solid #ffffff; }
  footer .footer-credentials {
    padding: 1.5rem 0; }
    footer .footer-credentials a,
    footer .footer-credentials p {
      font-size: 0.8rem;
      font-weight: 300; }
    footer .footer-credentials ul {
      list-style: none;
      padding-left: 0;
      margin-bottom: 0;
      display: flex; }
      footer .footer-credentials ul li {
        padding-right: 1rem; }
    footer .footer-credentials .social-link {
      display: flex;
      margin-left: 1rem; }
  @media (min-width: 768px) and (max-width: 991.98px) {
    footer .footer-credentials p {
      margin-top: 1rem; } }
  @media (max-width: 767.98px) {
    footer .footer-credentials ul {
      margin-bottom: 1rem; } }
  @media (max-width: 575.98px) {
    footer .footer-image {
      display: none; }
    footer h6, footer .h6 {
      margin-top: 1.5625rem;
      margin-bottom: 0; }
    footer .col-12:first-of-type h6, footer .col-12:first-of-type .h6 {
      margin-top: 0; }
    footer .footer-credentials ul {
      flex-direction: column;
      margin-bottom: 1rem; }
    footer .footer-credentials .social-link:first-of-type {
      margin-left: 0; } }

main.home-page .introduction {
  background-color: rgba(18, 45, 63, 0.03);
  position: relative;
  overflow: hidden; }
  main.home-page .introduction #animation-home-introduction-one {
    background-image: url("/static/images/icons/triangle-white-left-bottom.svg");
    background-position-x: calc(100% -236.7px);
    background-position-y: 0px;
    background-size: initial;
    background-repeat: no-repeat;
    position: absolute;
    width: 100%;
    left: 0;
    min-height: 236.7px;
    top: 10px;
    left: 10%;
    margin-left: auto;
    margin-right: auto;
    background-size: 236.7px;
    transition: all ease; }
  main.home-page .introduction #animation-home-introduction-two {
    background-image: url("/static/images/icons/triangle-white-left-top.svg");
    background-position-x: calc(100%);
    background-position-y: 0px;
    background-size: initial;
    background-repeat: no-repeat;
    position: absolute;
    width: 100%;
    left: 0;
    min-height: 236.7px;
    top: 246.7px;
    left: 10%;
    background-size: 236.7px;
    transition: all ease; }
  main.home-page .introduction #animation-home-project-three {
    background-image: url("/static/images/icons/trinagle-current-project.svg");
    background-position-x: calc(0);
    background-position-y: 0px;
    background-size: initial;
    background-repeat: no-repeat;
    position: absolute;
    width: 100%;
    left: 0;
    min-height: 393px;
    bottom: 470px;
    left: 7%;
    background-size: 393px;
    transition: all ease; }
    @media (max-width: 767.98px) {
      main.home-page .introduction #animation-home-project-three {
        bottom: 300px; } }
    @media (max-width: 575.98px) {
      main.home-page .introduction #animation-home-project-three {
        bottom: 350px; } }
    @media (min-width: 1400px) {
      main.home-page .introduction #animation-home-project-three {
        background-size: 593px; } }
  main.home-page .introduction .introduction-content {
    padding-top: 8rem;
    padding-bottom: 8.5625rem; }
    main.home-page .introduction .introduction-content h1, main.home-page .introduction .introduction-content .h1 {
      margin-bottom: 2.8125rem;
      font-weight: 800; }
  main.home-page .introduction .core-values {
    background-image: url("/static/images/background-core-values.svg");
    background-color: #fff;
    background-repeat: no-repeat;
    background-size: 100%;
    padding-bottom: 4.48125rem; }
    main.home-page .introduction .core-values a {
      color: #ffffff; }
    main.home-page .introduction .core-values h1, main.home-page .introduction .core-values .h1 {
      margin-bottom: 2.8125rem;
      font-weight: 800; }
    main.home-page .introduction .core-values .grid-container {
      display: grid;
      height: auto;
      grid-template-columns: 1fr 1fr;
      grid-template-rows: 1fr 1fr 1fr;
      grid-auto-rows: unset;
      column-gap: 1.875rem;
      row-gap: 1.875rem;
      grid-row-gap: 1.875rem;
      -moz-column-gap: 1.875rem; }
      main.home-page .introduction .core-values .grid-container .item-one,
      main.home-page .introduction .core-values .grid-container .item-two,
      main.home-page .introduction .core-values .grid-container .item-three,
      main.home-page .introduction .core-values .grid-container .item-four {
        display: grid;
        min-height: 250px; }
        main.home-page .introduction .core-values .grid-container .item-one .image-background,
        main.home-page .introduction .core-values .grid-container .item-two .image-background,
        main.home-page .introduction .core-values .grid-container .item-three .image-background,
        main.home-page .introduction .core-values .grid-container .item-four .image-background {
          background-size: cover;
          background-position: center; }
          main.home-page .introduction .core-values .grid-container .item-one .image-background .gradient-background-layer,
          main.home-page .introduction .core-values .grid-container .item-two .image-background .gradient-background-layer,
          main.home-page .introduction .core-values .grid-container .item-three .image-background .gradient-background-layer,
          main.home-page .introduction .core-values .grid-container .item-four .image-background .gradient-background-layer {
            background: #122d3f;
            background: linear-gradient(180deg, #122d3f 0%, #122d3f 15%, rgba(18, 45, 63, 0) 80%);
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            align-items: flex-start;
            height: 100%;
            padding: 1.5rem 1.75rem;
            position: absolute;
            top: 0;
            width: 100%; }
        main.home-page .introduction .core-values .grid-container .item-one .color-background,
        main.home-page .introduction .core-values .grid-container .item-two .color-background,
        main.home-page .introduction .core-values .grid-container .item-three .color-background,
        main.home-page .introduction .core-values .grid-container .item-four .color-background {
          background-size: cover;
          background-position: 100% 100%;
          padding: 1.5rem 1.75rem;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          align-items: flex-start;
          position: relative; }
          main.home-page .introduction .core-values .grid-container .item-one .color-background img,
          main.home-page .introduction .core-values .grid-container .item-two .color-background img,
          main.home-page .introduction .core-values .grid-container .item-three .color-background img,
          main.home-page .introduction .core-values .grid-container .item-four .color-background img {
            position: absolute;
            bottom: 0;
            right: 0; }
        main.home-page .introduction .core-values .grid-container .item-one h2, main.home-page .introduction .core-values .grid-container .item-one .h2,
        main.home-page .introduction .core-values .grid-container .item-two h2,
        main.home-page .introduction .core-values .grid-container .item-two .h2,
        main.home-page .introduction .core-values .grid-container .item-three h2,
        main.home-page .introduction .core-values .grid-container .item-three .h2,
        main.home-page .introduction .core-values .grid-container .item-four h2,
        main.home-page .introduction .core-values .grid-container .item-four .h2 {
          line-height: 2.125rem;
          font-weight: 700; }
        main.home-page .introduction .core-values .grid-container .item-one .image-header,
        main.home-page .introduction .core-values .grid-container .item-two .image-header,
        main.home-page .introduction .core-values .grid-container .item-three .image-header,
        main.home-page .introduction .core-values .grid-container .item-four .image-header {
          flex: 1;
          min-height: 7rem;
          background-color: #6f6f6f;
          background-size: cover;
          background-repeat: no-repeat;
          background-position: center;
          position: relative;
          transition: all 0.4s ease-in-out;
          overflow: hidden; }
          main.home-page .introduction .core-values .grid-container .item-one .image-header::before,
          main.home-page .introduction .core-values .grid-container .item-two .image-header::before,
          main.home-page .introduction .core-values .grid-container .item-three .image-header::before,
          main.home-page .introduction .core-values .grid-container .item-four .image-header::before {
            content: "";
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            background: inherit;
            transition: inherit; }
          main.home-page .introduction .core-values .grid-container .item-one .image-header img,
          main.home-page .introduction .core-values .grid-container .item-two .image-header img,
          main.home-page .introduction .core-values .grid-container .item-three .image-header img,
          main.home-page .introduction .core-values .grid-container .item-four .image-header img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            opacity: 0; }
        main.home-page .introduction .core-values .grid-container .item-one:hover .image-header::before,
        main.home-page .introduction .core-values .grid-container .item-two:hover .image-header::before,
        main.home-page .introduction .core-values .grid-container .item-three:hover .image-header::before,
        main.home-page .introduction .core-values .grid-container .item-four:hover .image-header::before {
          transform: scale(1.1); }
        main.home-page .introduction .core-values .grid-container .item-one .button-basic.dark.forward:hover,
        main.home-page .introduction .core-values .grid-container .item-one .button-basic.red.forward:hover,
        main.home-page .introduction .core-values .grid-container .item-two .button-basic.dark.forward:hover,
        main.home-page .introduction .core-values .grid-container .item-two .button-basic.red.forward:hover,
        main.home-page .introduction .core-values .grid-container .item-three .button-basic.dark.forward:hover,
        main.home-page .introduction .core-values .grid-container .item-three .button-basic.red.forward:hover,
        main.home-page .introduction .core-values .grid-container .item-four .button-basic.dark.forward:hover,
        main.home-page .introduction .core-values .grid-container .item-four .button-basic.red.forward:hover {
          border: 1px solid #ffffff; }
      main.home-page .introduction .core-values .grid-container .item-one {
        grid-column: 1 / span 1;
        grid-row: 1 / span 2; }
        main.home-page .introduction .core-values .grid-container .item-one .image-background {
          background-color: #fc0039;
          background-image: url("/data/images/home/imagesmallhome_block_left_upper2.jpg"); }
          @media (min-width: 1200px) {
            main.home-page .introduction .core-values .grid-container .item-one .image-background {
              background-image: url("/data/images/home/imagehome_block_left_upper2.jpg"); } }
        main.home-page .introduction .core-values .grid-container .item-one .color-background {
          background-color: #fc0039; }
      main.home-page .introduction .core-values .grid-container .item-two {
        grid-column: 2 / span 1;
        grid-row: 1 / span 1; }
        main.home-page .introduction .core-values .grid-container .item-two .color-background {
          background-color: #fc0039; }
      main.home-page .introduction .core-values .grid-container .item-three {
        grid-column: 1 / span 1;
        grid-row: 3 / span 1; }
        main.home-page .introduction .core-values .grid-container .item-three .color-background {
          background-color: #122d3f; }
      main.home-page .introduction .core-values .grid-container .item-four {
        grid-column: 2 / span 1;
        grid-row: 2 / span 2; }
        main.home-page .introduction .core-values .grid-container .item-four .image-background {
          background-color: #fc0039;
          background-image: url("/data/images/home/imagesmallhome_block_right_lower2.jpg"); }
          @media (min-width: 1200px) {
            main.home-page .introduction .core-values .grid-container .item-four .image-background {
              background-image: url("/data/images/home/imagehome_block_right_lower2.jpg"); } }
        main.home-page .introduction .core-values .grid-container .item-four .color-background {
          background-color: #fc0039; }
  main.home-page .introduction .current-project {
    background-color: #fff; }
    main.home-page .introduction .current-project .background-basic {
      position: relative;
      margin-bottom: -2rem;
      overflow: hidden; }
      main.home-page .introduction .current-project .background-basic img.background-image {
        width: 130%;
        margin-left: -15%;
        clip-path: polygon(100% 0%, 100% 100%, 0% 100%, 0% 33.5vw);
        max-height: 40rem; }
    main.home-page .introduction .current-project .content {
      position: absolute;
      bottom: 0;
      overflow: hidden;
      width: 100%;
      padding-top: 1.5rem;
      padding-bottom: 2.5rem;
      color: #fff; }
      main.home-page .introduction .current-project .content.image-title {
        bottom: 8rem; }
        @media (min-width: 768px) {
          main.home-page .introduction .current-project .content.image-title {
            bottom: 8.5rem; } }
        @media (min-width: 992px) {
          main.home-page .introduction .current-project .content.image-title {
            bottom: 9rem; } }
        @media (min-width: 1200px) {
          main.home-page .introduction .current-project .content.image-title {
            bottom: 9.5rem; } }
        @media (min-width: 1400px) {
          main.home-page .introduction .current-project .content.image-title {
            bottom: 10rem; } }
        main.home-page .introduction .current-project .content.image-title h1, main.home-page .introduction .current-project .content.image-title .h1 {
          text-shadow: 0 0 8px rgba(0, 0, 0, 0.2); }
      main.home-page .introduction .current-project .content .background-blurred {
        position: absolute;
        bottom: 0;
        width: 100%;
        height: 100%;
        overflow: hidden; }
        main.home-page .introduction .current-project .content .background-blurred img {
          filter: blur(20px);
          width: 130%;
          margin-left: -15%;
          position: absolute;
          bottom: 0; }
      main.home-page .introduction .current-project .content .row h1, main.home-page .introduction .current-project .content .row .h1,
      main.home-page .introduction .current-project .content .row .col-8,
      main.home-page .introduction .current-project .content .row .button-basic {
        z-index: 9999; }
      main.home-page .introduction .current-project .content h1, main.home-page .introduction .current-project .content .h1 {
        margin-bottom: 0;
        font-weight: 700; }
      main.home-page .introduction .current-project .content .text {
        padding-top: 2.1875rem; }
        main.home-page .introduction .current-project .content .text p {
          font-size: 1rem;
          line-height: 1.75rem; }
      main.home-page .introduction .current-project .content .buttons {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-end; }
        main.home-page .introduction .current-project .content .buttons .button-basic:first-of-type {
          margin-bottom: 1rem; }
        main.home-page .introduction .current-project .content .buttons .button-basic.dark.forward:hover {
          border: 1px solid #ffffff; }

main.home-page .news h1, main.home-page .news .h1 {
  padding-bottom: 2rem; }

main.home-page .current-projects-container {
  background-color: rgba(18, 45, 63, 0.03); }
  main.home-page .current-projects-container .introduction-current-projects {
    padding-top: 5.34rem;
    padding-bottom: 3.26rem; }
    main.home-page .current-projects-container .introduction-current-projects h1, main.home-page .current-projects-container .introduction-current-projects .h1,
    main.home-page .current-projects-container .introduction-current-projects p {
      text-align: center; }
    main.home-page .current-projects-container .introduction-current-projects h1, main.home-page .current-projects-container .introduction-current-projects .h1 {
      margin-bottom: 1.6875rem; }
  main.home-page .current-projects-container .map-with-locations {
    background-color: transparent;
    padding-bottom: 7.3rem; }
    @media (max-width: 575.98px) {
      main.home-page .current-projects-container .map-with-locations {
        padding-top: 5rem; } }

main.home-page .call-part-multiple-triangles {
  padding-top: 8.3rem; }
  @media (max-width: 575.98px) {
    main.home-page .call-part-multiple-triangles {
      padding-top: 4rem;
      padding-bottom: 6rem; } }

@media (min-width: 992px) {
  main.home-page .introduction .current-project .background-basic img.background-image {
    max-height: 45rem;
    object-fit: cover; } }

@media (min-width: 1200px) {
  main.home-page .introduction .current-project .background-basic img.background-image {
    max-height: 50rem;
    object-fit: cover; } }

@media (min-width: 1400px) {
  main.home-page .introduction .current-project .background-basic img.background-image {
    max-height: 60rem;
    object-fit: cover; } }

@media (min-width: 2500px) {
  main.home-page .introduction .current-project .background-basic img.background-image {
    max-height: 75rem;
    object-fit: cover; } }

@media (max-width: 991.98px) {
  main.home-page .core-values .grid-container .item-one,
  main.home-page .core-values .grid-container .item-two,
  main.home-page .core-values .grid-container .item-three,
  main.home-page .core-values .grid-container .item-four {
    min-height: 200px; }
  main.home-page .introduction .current-project .content .buttons {
    flex-direction: row;
    flex-wrap: wrap; } }

@media (max-width: 767.98px) {
  main.home-page .introduction .core-values .grid-container {
    grid-template-columns: 1fr;
    grid-template-rows: auto;
    grid-auto-rows: 1fr; }
    main.home-page .introduction .core-values .grid-container .item-one,
    main.home-page .introduction .core-values .grid-container .item-two,
    main.home-page .introduction .core-values .grid-container .item-three,
    main.home-page .introduction .core-values .grid-container .item-four {
      grid-column: 1 / span 1; }
    main.home-page .introduction .core-values .grid-container .item-one {
      grid-row: 1 / span 3; }
    main.home-page .introduction .core-values .grid-container .item-two {
      grid-row: 4 / span 1; }
    main.home-page .introduction .core-values .grid-container .item-three {
      grid-row: 7 / span 1; }
    main.home-page .introduction .core-values .grid-container .item-four {
      grid-row: 5 / span 2; } }

@media (max-width: 575.98px) {
  main.home-page .introduction .core-values .grid-container .item-one,
  main.home-page .introduction .core-values .grid-container .item-two,
  main.home-page .introduction .core-values .grid-container .item-three,
  main.home-page .introduction .core-values .grid-container .item-four {
    grid-column: 1 / span 1; }
  main.home-page .introduction .core-values .grid-container .item-one {
    grid-row: 1 / span 1; }
  main.home-page .introduction .core-values .grid-container .item-two {
    grid-row: 2 / span 1; }
  main.home-page .introduction .core-values .grid-container .item-three {
    grid-row: 4 / span 1; }
  main.home-page .introduction .core-values .grid-container .item-four {
    grid-row: 3 / span 1; } }

@media (max-width: 767.98px) {
  main.home-page .introduction .current-project .content .background-buttons-mobile {
    z-index: 2;
    height: calc(100% -2rem);
    width: 100%;
    background: white;
    background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0) 50%, white 88%, white 100%); } }

@media (max-width: 575.98px) {
  main.home-page .introduction .current-project .background-basic img.background-image {
    min-height: 35rem;
    object-fit: cover; }
  main.home-page .introduction .current-project .content .background-buttons-mobile {
    z-index: 2;
    height: calc(100% -2rem);
    width: 100%;
    background: white;
    background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0) 50%, white 88%, white 100%); } }

@media (max-width: 440px) {
  main.home-page .introduction .current-project .content {
    padding-bottom: 2.375rem; } }

/* main content for projects */
main.projects-page {
  background-color: rgba(18, 45, 63, 0.03);
  background-color: #fff; }
  main.projects-page .breadcrumb-container .step-back-link {
    display: none; }
  main.projects-page .project-introduction {
    text-align: center; }
    main.projects-page .project-introduction h1, main.projects-page .project-introduction .h1 {
      margin-bottom: 3rem; }
    main.projects-page .project-introduction p {
      margin-bottom: 5.1875rem; }

.projects-overview,
.related-projects-overview {
  position: relative;
  background-color: rgba(18, 45, 63, 0.03);
  padding-bottom: 6.9375rem; }
  .projects-overview .clipped-background,
  .related-projects-overview .clipped-background {
    position: absolute;
    top: 0;
    width: 100%;
    background-color: #ffffff;
    -webkit-clip-path: polygon(0 100%, 0 0, 100% 0);
    clip-path: polygon(0 100%, 0 0, 100% 0);
    min-height: 26vw; }
  .projects-overview .filter-wrapper,
  .related-projects-overview .filter-wrapper {
    background-color: #ffffff; }
  .projects-overview .search-and-filter,
  .related-projects-overview .search-and-filter {
    display: block;
    width: max-content;
    margin: 0 auto;
    z-index: 2;
    position: relative;
    padding-bottom: 3rem; }
    .projects-overview .search-and-filter .filter,
    .related-projects-overview .search-and-filter .filter {
      font-size: 1.25rem;
      opacity: 1;
      transition: all 1s ease-out; }
      .projects-overview .search-and-filter .filter img.filter-icon,
      .related-projects-overview .search-and-filter .filter img.filter-icon {
        margin-right: 0.8875rem; }
      .projects-overview .search-and-filter .filter svg,
      .related-projects-overview .search-and-filter .filter svg {
        margin-right: 0.8875rem; }
        .projects-overview .search-and-filter .filter svg line,
        .projects-overview .search-and-filter .filter svg g,
        .related-projects-overview .search-and-filter .filter svg line,
        .related-projects-overview .search-and-filter .filter svg g {
          transition: all 0.5s ease-out; }
      .projects-overview .search-and-filter .filter.open,
      .related-projects-overview .search-and-filter .filter.open {
        opacity: 0.5; }
      .projects-overview .search-and-filter .filter:hover,
      .related-projects-overview .search-and-filter .filter:hover {
        opacity: 0.5; }
        .projects-overview .search-and-filter .filter:hover svg line,
        .projects-overview .search-and-filter .filter:hover svg g,
        .related-projects-overview .search-and-filter .filter:hover svg line,
        .related-projects-overview .search-and-filter .filter:hover svg g {
          stroke: #fc0039; }
  .projects-overview .checkboxes,
  .related-projects-overview .checkboxes {
    display: block;
    width: max-content;
    max-width: 100%;
    margin-left: auto;
    margin-right: auto;
    overflow: hidden;
    max-height: 0;
    opacity: 0;
    transition: 0.5s all ease-in-out; }
    .projects-overview .checkboxes input[type="checkbox"],
    .related-projects-overview .checkboxes input[type="checkbox"] {
      width: 30px;
      height: 30px; }
    .projects-overview .checkboxes label,
    .related-projects-overview .checkboxes label {
      margin-left: 1rem;
      font-size: 1.375rem;
      margin-right: 3rem; }
      @media (max-width: 575.98px) {
        .projects-overview .checkboxes label,
        .related-projects-overview .checkboxes label {
          margin-left: 0.5rem;
          font-size: 1.25rem;
          margin-right: 1.5rem; } }
    .projects-overview .checkboxes .form-check,
    .related-projects-overview .checkboxes .form-check {
      width: max-content; }
    .projects-overview .checkboxes.show,
    .related-projects-overview .checkboxes.show {
      max-height: 10rem;
      opacity: 1; }
      @media (max-width: 575.98px) {
        .projects-overview .checkboxes.show,
        .related-projects-overview .checkboxes.show {
          max-height: 15rem; } }
    @media (max-width: 575.98px) {
      .projects-overview .checkboxes .form-check,
      .related-projects-overview .checkboxes .form-check {
        width: 100%; }
      .projects-overview .checkboxes input[type="checkbox"],
      .related-projects-overview .checkboxes input[type="checkbox"] {
        width: 18px;
        height: 18px; } }
  .projects-overview .projects,
  .related-projects-overview .projects {
    position: relative;
    z-index: 2; }
    .projects-overview .projects .grid-layout,
    .related-projects-overview .projects .grid-layout {
      display: grid;
      grid-template-columns: repeat(auto-fill, minmax(13%, 1fr));
      grid-gap: 1.875rem;
      grid-auto-rows: repeat(auto-fill, minmax(1.2345rem, 17fr));
      grid-auto-flow: dense;
      padding-bottom: 1.875rem; }
      @media (max-width: 767.98px) {
        .projects-overview .projects .grid-layout,
        .related-projects-overview .projects .grid-layout {
          grid-template-columns: repeat(auto-fill, minmax(18%, 1fr)); } }
    .projects-overview .projects .project-item,
    .related-projects-overview .projects .project-item {
      background-color: #122d3f;
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center;
      display: flex;
      align-items: flex-end;
      grid-row-end: span 17;
      grid-column-end: span 2;
      position: relative;
      transition: all 0.4s ease-in-out;
      overflow: hidden; }
      .projects-overview .projects .project-item::before,
      .related-projects-overview .projects .project-item::before {
        content: "";
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background: inherit;
        transition: inherit; }
      .projects-overview .projects .project-item .title,
      .related-projects-overview .projects .project-item .title {
        background-color: #fff;
        padding: 1.125rem 1.375rem;
        flex-grow: 1;
        position: relative;
        z-index: 1000; }
        .projects-overview .projects .project-item .title h3, .projects-overview .projects .project-item .title .h3,
        .related-projects-overview .projects .project-item .title h3,
        .related-projects-overview .projects .project-item .title .h3 {
          font-weight: 700;
          line-height: 1.75rem; }
      .projects-overview .projects .project-item .arrow-box,
      .related-projects-overview .projects .project-item .arrow-box {
        padding: 0.875rem;
        padding-top: 1rem;
        background-color: #fc0039;
        transition: all 0.2s ease-out;
        position: relative;
        z-index: 1000; }
      .projects-overview .projects .project-item:hover::before,
      .related-projects-overview .projects .project-item:hover::before {
        transform: scale(1.1); }
      .projects-overview .projects .project-item:hover .arrow-box,
      .related-projects-overview .projects .project-item:hover .arrow-box {
        background-color: #122d3f; }
    .projects-overview .projects .grid1items .project-item,
    .related-projects-overview .projects .grid1items .project-item {
      grid-column-end: span 6; }
    .projects-overview .projects .grid3items .project-item,
    .related-projects-overview .projects .grid3items .project-item {
      grid-row-end: span 11;
      grid-column-end: span 2; }
    .projects-overview .projects .grid4items .project-item,
    .related-projects-overview .projects .grid4items .project-item {
      grid-row-end: span 11; }
      .projects-overview .projects .grid4items .project-item:nth-child(4),
      .related-projects-overview .projects .grid4items .project-item:nth-child(4) {
        grid-row-end: span 17;
        grid-column-end: span 6; }
    .projects-overview .projects .grid2items .project-item,
    .projects-overview .projects .grid5items .project-item,
    .projects-overview .projects .grid6items .project-item,
    .projects-overview .projects .grid7items .project-item,
    .projects-overview .projects .grid8items .project-item,
    .projects-overview .projects .grid9items .project-item,
    .projects-overview .projects .grid10items .project-item,
    .related-projects-overview .projects .grid2items .project-item,
    .related-projects-overview .projects .grid5items .project-item,
    .related-projects-overview .projects .grid6items .project-item,
    .related-projects-overview .projects .grid7items .project-item,
    .related-projects-overview .projects .grid8items .project-item,
    .related-projects-overview .projects .grid9items .project-item,
    .related-projects-overview .projects .grid10items .project-item {
      grid-column-end: span 3; }
      .projects-overview .projects .grid2items .project-item:nth-child(3), .projects-overview .projects .grid2items .project-item:nth-child(4), .projects-overview .projects .grid2items .project-item:nth-child(5),
      .projects-overview .projects .grid5items .project-item:nth-child(3),
      .projects-overview .projects .grid5items .project-item:nth-child(4),
      .projects-overview .projects .grid5items .project-item:nth-child(5),
      .projects-overview .projects .grid6items .project-item:nth-child(3),
      .projects-overview .projects .grid6items .project-item:nth-child(4),
      .projects-overview .projects .grid6items .project-item:nth-child(5),
      .projects-overview .projects .grid7items .project-item:nth-child(3),
      .projects-overview .projects .grid7items .project-item:nth-child(4),
      .projects-overview .projects .grid7items .project-item:nth-child(5),
      .projects-overview .projects .grid8items .project-item:nth-child(3),
      .projects-overview .projects .grid8items .project-item:nth-child(4),
      .projects-overview .projects .grid8items .project-item:nth-child(5),
      .projects-overview .projects .grid9items .project-item:nth-child(3),
      .projects-overview .projects .grid9items .project-item:nth-child(4),
      .projects-overview .projects .grid9items .project-item:nth-child(5),
      .projects-overview .projects .grid10items .project-item:nth-child(3),
      .projects-overview .projects .grid10items .project-item:nth-child(4),
      .projects-overview .projects .grid10items .project-item:nth-child(5),
      .related-projects-overview .projects .grid2items .project-item:nth-child(3),
      .related-projects-overview .projects .grid2items .project-item:nth-child(4),
      .related-projects-overview .projects .grid2items .project-item:nth-child(5),
      .related-projects-overview .projects .grid5items .project-item:nth-child(3),
      .related-projects-overview .projects .grid5items .project-item:nth-child(4),
      .related-projects-overview .projects .grid5items .project-item:nth-child(5),
      .related-projects-overview .projects .grid6items .project-item:nth-child(3),
      .related-projects-overview .projects .grid6items .project-item:nth-child(4),
      .related-projects-overview .projects .grid6items .project-item:nth-child(5),
      .related-projects-overview .projects .grid7items .project-item:nth-child(3),
      .related-projects-overview .projects .grid7items .project-item:nth-child(4),
      .related-projects-overview .projects .grid7items .project-item:nth-child(5),
      .related-projects-overview .projects .grid8items .project-item:nth-child(3),
      .related-projects-overview .projects .grid8items .project-item:nth-child(4),
      .related-projects-overview .projects .grid8items .project-item:nth-child(5),
      .related-projects-overview .projects .grid9items .project-item:nth-child(3),
      .related-projects-overview .projects .grid9items .project-item:nth-child(4),
      .related-projects-overview .projects .grid9items .project-item:nth-child(5),
      .related-projects-overview .projects .grid10items .project-item:nth-child(3),
      .related-projects-overview .projects .grid10items .project-item:nth-child(4),
      .related-projects-overview .projects .grid10items .project-item:nth-child(5) {
        grid-row-end: span 11;
        grid-column-end: span 2; }
      .projects-overview .projects .grid2items .project-item:nth-child(6), .projects-overview .projects .grid2items .project-item:nth-child(10),
      .projects-overview .projects .grid5items .project-item:nth-child(6),
      .projects-overview .projects .grid5items .project-item:nth-child(10),
      .projects-overview .projects .grid6items .project-item:nth-child(6),
      .projects-overview .projects .grid6items .project-item:nth-child(10),
      .projects-overview .projects .grid7items .project-item:nth-child(6),
      .projects-overview .projects .grid7items .project-item:nth-child(10),
      .projects-overview .projects .grid8items .project-item:nth-child(6),
      .projects-overview .projects .grid8items .project-item:nth-child(10),
      .projects-overview .projects .grid9items .project-item:nth-child(6),
      .projects-overview .projects .grid9items .project-item:nth-child(10),
      .projects-overview .projects .grid10items .project-item:nth-child(6),
      .projects-overview .projects .grid10items .project-item:nth-child(10),
      .related-projects-overview .projects .grid2items .project-item:nth-child(6),
      .related-projects-overview .projects .grid2items .project-item:nth-child(10),
      .related-projects-overview .projects .grid5items .project-item:nth-child(6),
      .related-projects-overview .projects .grid5items .project-item:nth-child(10),
      .related-projects-overview .projects .grid6items .project-item:nth-child(6),
      .related-projects-overview .projects .grid6items .project-item:nth-child(10),
      .related-projects-overview .projects .grid7items .project-item:nth-child(6),
      .related-projects-overview .projects .grid7items .project-item:nth-child(10),
      .related-projects-overview .projects .grid8items .project-item:nth-child(6),
      .related-projects-overview .projects .grid8items .project-item:nth-child(10),
      .related-projects-overview .projects .grid9items .project-item:nth-child(6),
      .related-projects-overview .projects .grid9items .project-item:nth-child(10),
      .related-projects-overview .projects .grid10items .project-item:nth-child(6),
      .related-projects-overview .projects .grid10items .project-item:nth-child(10) {
        grid-column-end: span 6; }
      .projects-overview .projects .grid2items .project-item:nth-child(7), .projects-overview .projects .grid2items .project-item:nth-child(8), .projects-overview .projects .grid2items .project-item:nth-child(9),
      .projects-overview .projects .grid5items .project-item:nth-child(7),
      .projects-overview .projects .grid5items .project-item:nth-child(8),
      .projects-overview .projects .grid5items .project-item:nth-child(9),
      .projects-overview .projects .grid6items .project-item:nth-child(7),
      .projects-overview .projects .grid6items .project-item:nth-child(8),
      .projects-overview .projects .grid6items .project-item:nth-child(9),
      .projects-overview .projects .grid7items .project-item:nth-child(7),
      .projects-overview .projects .grid7items .project-item:nth-child(8),
      .projects-overview .projects .grid7items .project-item:nth-child(9),
      .projects-overview .projects .grid8items .project-item:nth-child(7),
      .projects-overview .projects .grid8items .project-item:nth-child(8),
      .projects-overview .projects .grid8items .project-item:nth-child(9),
      .projects-overview .projects .grid9items .project-item:nth-child(7),
      .projects-overview .projects .grid9items .project-item:nth-child(8),
      .projects-overview .projects .grid9items .project-item:nth-child(9),
      .projects-overview .projects .grid10items .project-item:nth-child(7),
      .projects-overview .projects .grid10items .project-item:nth-child(8),
      .projects-overview .projects .grid10items .project-item:nth-child(9),
      .related-projects-overview .projects .grid2items .project-item:nth-child(7),
      .related-projects-overview .projects .grid2items .project-item:nth-child(8),
      .related-projects-overview .projects .grid2items .project-item:nth-child(9),
      .related-projects-overview .projects .grid5items .project-item:nth-child(7),
      .related-projects-overview .projects .grid5items .project-item:nth-child(8),
      .related-projects-overview .projects .grid5items .project-item:nth-child(9),
      .related-projects-overview .projects .grid6items .project-item:nth-child(7),
      .related-projects-overview .projects .grid6items .project-item:nth-child(8),
      .related-projects-overview .projects .grid6items .project-item:nth-child(9),
      .related-projects-overview .projects .grid7items .project-item:nth-child(7),
      .related-projects-overview .projects .grid7items .project-item:nth-child(8),
      .related-projects-overview .projects .grid7items .project-item:nth-child(9),
      .related-projects-overview .projects .grid8items .project-item:nth-child(7),
      .related-projects-overview .projects .grid8items .project-item:nth-child(8),
      .related-projects-overview .projects .grid8items .project-item:nth-child(9),
      .related-projects-overview .projects .grid9items .project-item:nth-child(7),
      .related-projects-overview .projects .grid9items .project-item:nth-child(8),
      .related-projects-overview .projects .grid9items .project-item:nth-child(9),
      .related-projects-overview .projects .grid10items .project-item:nth-child(7),
      .related-projects-overview .projects .grid10items .project-item:nth-child(8),
      .related-projects-overview .projects .grid10items .project-item:nth-child(9) {
        grid-row-end: span 11;
        grid-column-end: span 2; }
    .projects-overview .projects .grid7items .project-item:nth-child(6), .projects-overview .projects .grid7items .project-item:nth-child(7),
    .related-projects-overview .projects .grid7items .project-item:nth-child(6),
    .related-projects-overview .projects .grid7items .project-item:nth-child(7) {
      grid-row-end: span 17;
      grid-column-end: span 3; }
    .projects-overview .projects .grid8items .project-item:nth-child(n + 7),
    .related-projects-overview .projects .grid8items .project-item:nth-child(n + 7) {
      grid-row-end: span 17;
      grid-column-end: span 3; }
    @media (max-width: 767.98px) {
      .projects-overview .projects .grid1items .project-item:nth-child(n),
      .projects-overview .projects .grid2items .project-item:nth-child(n),
      .projects-overview .projects .grid3items .project-item:nth-child(n),
      .projects-overview .projects .grid4items .project-item:nth-child(n),
      .projects-overview .projects .grid5items .project-item:nth-child(n),
      .projects-overview .projects .grid6items .project-item:nth-child(n),
      .projects-overview .projects .grid7items .project-item:nth-child(n),
      .projects-overview .projects .grid8items .project-item:nth-child(n),
      .projects-overview .projects .grid9items .project-item:nth-child(n),
      .projects-overview .projects .grid10items .project-item:nth-child(n),
      .related-projects-overview .projects .grid1items .project-item:nth-child(n),
      .related-projects-overview .projects .grid2items .project-item:nth-child(n),
      .related-projects-overview .projects .grid3items .project-item:nth-child(n),
      .related-projects-overview .projects .grid4items .project-item:nth-child(n),
      .related-projects-overview .projects .grid5items .project-item:nth-child(n),
      .related-projects-overview .projects .grid6items .project-item:nth-child(n),
      .related-projects-overview .projects .grid7items .project-item:nth-child(n),
      .related-projects-overview .projects .grid8items .project-item:nth-child(n),
      .related-projects-overview .projects .grid9items .project-item:nth-child(n),
      .related-projects-overview .projects .grid10items .project-item:nth-child(n) {
        grid-column-end: span 4;
        grid-row-end: span 11; } }
  @media (max-width: 575.98px) {
    .projects-overview .search-and-filter .search-bar,
    .related-projects-overview .search-and-filter .search-bar {
      margin-right: 1rem;
      max-width: 17rem; } }

/* main content project */
main.project-page {
  background-color: #f6f6f6; }
  main.project-page .breadcrumb-background {
    background-color: #fff; }
    main.project-page .breadcrumb-background .breadcrumb-container {
      margin-top: 3rem; }
  main.project-page .project-introduction {
    padding-bottom: 4.5rem;
    background-color: #fff; }
    main.project-page .project-introduction p {
      text-align: center; }
  main.project-page .project-content {
    background-color: #fff;
    position: relative;
    overflow: hidden; }
    main.project-page .project-content #animation-project-one {
      background-image: url("/static/images/icons/triangle-grey-left-bottom.svg");
      background-position-x: calc(105% - 271px);
      background-position-y: 0px;
      background-size: initial;
      background-repeat: no-repeat;
      position: absolute;
      width: 100%;
      left: 0;
      min-height: 271px;
      top: 70px; }
    main.project-page .project-content #animation-project-two {
      background-image: url("/static/images/icons/triangle-grey-left-top.svg");
      background-position-x: 105%;
      background-position-y: 0px;
      background-size: initial;
      background-repeat: no-repeat;
      position: absolute;
      width: 100%;
      left: 0;
      min-height: 271px;
      top: calc(70px + 271px); }
    main.project-page .project-content #animation-project-three {
      background-image: url("/static/images/icons/triangle-grey-top.svg");
      background-position-x: 0;
      background-position-y: 0px;
      background-size: initial;
      background-repeat: no-repeat;
      position: absolute;
      width: 100%;
      left: 0;
      min-height: 191.7px;
      bottom: calc(20% + 383.3px); }
    main.project-page .project-content #animation-project-four {
      background-image: url("/static/images/icons/triangle-grey-left.svg");
      background-position-x: 0;
      background-position-y: 0px;
      background-size: initial;
      background-repeat: no-repeat;
      position: absolute;
      width: 100%;
      left: 0;
      min-height: 383.3px;
      bottom: 20%;
      left: 191.6px; }
    main.project-page .project-content .project-description {
      position: relative;
      z-index: 2; }
      main.project-page .project-content .project-description h5, main.project-page .project-content .project-description .h5 {
        font-weight: 700;
        margin-bottom: 1.5rem; }
      main.project-page .project-content .project-description .text-container {
        margin-bottom: 5.5rem; }
      main.project-page .project-content .project-description .image-container {
        padding-bottom: 5.5rem; }
        main.project-page .project-content .project-description .image-container img {
          width: 100%; }
      main.project-page .project-content .project-description:nth-child(2) {
        background-position: 0% 150%; }
      main.project-page .project-content .project-description img {
        max-width: 100% !important; }
    main.project-page .project-content .clipped-background {
      position: absolute;
      bottom: -1px;
      width: 100%;
      background-color: #f6f6f6;
      -webkit-clip-path: polygon(100% 0%, 100% 100%, 0 100%);
      clip-path: polygon(100% 0, 100% 100%, 0% 100%);
      min-height: 40.7vw; }
    main.project-page .project-content .related-projects-title {
      position: relative;
      z-index: 2;
      text-align: center;
      font-weight: 700;
      margin-bottom: 0;
      margin-top: 5.125rem; }
  main.project-page .related-projects-overview {
    padding-top: 3.6875rem;
    padding-bottom: 2.375rem;
    background-color: #f6f6f6; }
  main.project-page a.button-basic.dark.back {
    margin-bottom: 5rem; }

/* main content for about */
main.about-page {
  position: relative;
  overflow: hidden; }
  main.about-page .flag-icon {
    text-align: center;
    margin-top: 12rem;
    margin-bottom: -1px; }
  main.about-page #animation-about-one {
    background-image: url("/static/images/icons/triangle-grey-top.svg");
    background-position-x: left;
    background-position-y: top;
    background-size: initial;
    background-repeat: no-repeat;
    position: absolute;
    width: 100%;
    left: 0;
    min-height: 575px;
    top: 800px;
    left: 5%; }
  main.about-page #animation-about-two {
    background-image: url("/static/images/icons/triangle-grey-left.svg");
    background-position-x: left;
    background-position-y: top;
    background-size: initial;
    background-repeat: no-repeat;
    position: absolute;
    width: 100%;
    left: 0;
    min-height: 384px;
    top: 992px;
    left: calc(5% + 191px); }
  main.about-page #animation-about-three {
    background-image: url("/static/images/icons/triangle-grey-right.svg");
    background-position-x: right;
    background-position-y: top;
    background-size: initial;
    background-repeat: no-repeat;
    position: absolute;
    width: 100%;
    left: 0;
    min-height: 384px;
    top: 3000px;
    left: -5%; }
  main.about-page #animation-about-four {
    background-image: url("/static/images/icons/triangle-grey-top.svg");
    background-position-x: right;
    background-position-y: top;
    background-size: initial;
    background-repeat: no-repeat;
    position: absolute;
    width: 100%;
    left: 0;
    min-height: 384px;
    top: 3191.5px;
    left: calc(-5% - 192px); }
  main.about-page .content-about {
    position: relative;
    padding-top: 6.375rem;
    z-index: 2; }
    main.about-page .content-about .image-and-article {
      position: relative;
      padding-bottom: 13rem; }
      main.about-page .content-about .image-and-article img {
        object-fit: cover;
        z-index: 2; }
      main.about-page .content-about .image-and-article p {
        font-size: 1.25rem;
        line-height: 2.125rem;
        margin-bottom: 0;
        position: relative;
        z-index: 2; }
        main.about-page .content-about .image-and-article p:first-of-type {
          color: #6f6f6f; }
      main.about-page .content-about .image-and-article h3, main.about-page .content-about .image-and-article .h3 {
        font-weight: 700;
        margin-bottom: 0;
        line-height: 2.125rem; }
    main.about-page .content-about .subject-promotion {
      position: relative;
      z-index: 2;
      padding-bottom: 13rem; }
      main.about-page .content-about .subject-promotion .subject-background {
        background-size: cover;
        width: 100%;
        min-height: calc((100vw) / 1.7);
        max-height: auto; }
        main.about-page .content-about .subject-promotion .subject-background .subject-content {
          padding: 2rem 3.125rem;
          background: #122d3f;
          background: linear-gradient(180deg, #122d3f 0%, #122d3f 15%, rgba(18, 45, 63, 0) 80%);
          color: #ffffff; }
    main.about-page .content-about .vertical-line {
      position: absolute;
      top: 0;
      height: 100%;
      left: calc(50% - 4px); }
      main.about-page .content-about .vertical-line .line-blue {
        display: block;
        position: absolute;
        height: 100%;
        width: 1px;
        background-color: #122d3f;
        opacity: 1;
        left: 0; }
  main.about-page .dotted-vertical {
    position: relative;
    height: 9.5rem; }
    main.about-page .dotted-vertical .dotted-line {
      position: absolute;
      top: 0;
      left: calc(50% - 4px);
      background-image: linear-gradient(#707070 50%, rgba(255, 255, 255, 0) 0%);
      background-size: 1px 10px;
      height: 100%;
      width: 1px;
      background-position: top; }
  main.about-page .item-box {
    display: flex;
    flex-direction: column;
    background-color: #122d3f;
    background-size: cover;
    background-position: top;
    margin-top: 5.53125rem;
    margin-bottom: 1.4rem;
    position: relative;
    transition: all 0.4s ease-in-out;
    overflow: hidden; }
    main.about-page .item-box::before {
      content: "";
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      background: inherit;
      transition: inherit; }
    main.about-page .item-box h2, main.about-page .item-box .h2 {
      background-color: #122d3f;
      color: #ffffff;
      padding: 1.375rem 2.3125rem;
      margin-bottom: 0; }
      main.about-page .item-box h2:after, main.about-page .item-box .h2:after {
        content: url("/static/images/icons/arrow-light.svg");
        padding-left: 0.5rem; }
    main.about-page .item-box .triangle {
      background: #122d3f;
      background: linear-gradient(180deg, #122d3f 0%, rgba(255, 255, 255, 0) 100%);
      display: flex;
      align-items: flex-end;
      justify-content: flex-end;
      background-position: bottom;
      flex: 1; }
    main.about-page .item-box:hover::before {
      transform: scale(1.1); }
  @media (max-width: 767.98px) {
    main.about-page .content-about .subject-promotion .subject-background .subject-content a.button-basic.light.forward {
      margin-top: 2rem; }
    main.about-page .content-about .subject-promotion .subject-background .subject-content .button-basic.light:hover {
      border: 1px solid #122d3f; } }
  @media (max-width: 575.98px) {
    main.about-page .item-box:last-of-type {
      margin-top: 0; }
    main.about-page .content-about .subject-promotion .subject-background .subject-content {
      padding: 2rem 2rem; }
    main.about-page .content-about .image-and-article {
      position: relative;
      z-index: 5; }
      main.about-page .content-about .image-and-article p, main.about-page .content-about .image-and-article h3, main.about-page .content-about .image-and-article .h3 {
        background-color: #ffffff; } }

main.current-projects-page .search-bar,
main.home-page .search-bar {
  display: flex;
  flex-direction: row;
  background-color: #fff;
  padding: 0;
  border-radius: 2rem;
  border: 1px solid #122d3f;
  margin-bottom: 0;
  max-width: 43rem;
  margin-bottom: 3.89rem;
  margin-left: auto;
  margin-right: auto; }
  main.current-projects-page .search-bar input[type="text"],
  main.home-page .search-bar input[type="text"] {
    flex-grow: 1;
    background-color: #fff;
    color: #122d3f;
    font-size: 1rem;
    border: none;
    border-radius: 1.1rem 0px 0px 1.1rem;
    padding: 0.2rem 1rem;
    outline: none; }
  main.current-projects-page .search-bar button,
  main.home-page .search-bar button {
    background-color: #fff;
    border: none;
    border-radius: 0px 1.5rem 1.5rem 0px;
    padding: 0.2rem 0.5rem; }
  main.current-projects-page .search-bar ::placeholder,
  main.home-page .search-bar ::placeholder {
    opacity: 1; }
  main.current-projects-page .search-bar.selected,
  main.home-page .search-bar.selected {
    display: flex; }

main.current-projects-page .map-with-locations,
main.home-page .map-with-locations {
  position: relative;
  background-color: rgba(18, 45, 63, 0.03);
  padding-bottom: 10rem; }
  main.current-projects-page .map-with-locations .clipped-background,
  main.home-page .map-with-locations .clipped-background {
    position: absolute;
    top: 0;
    width: 100%;
    background-color: #ffffff;
    -webkit-clip-path: polygon(0 100%, 0 0, 100% 0);
    clip-path: polygon(0 100%, 0 0, 100% 0);
    min-height: 26vw; }
  @media (max-width: 575.98px) {
    main.current-projects-page .map-with-locations,
    main.home-page .map-with-locations {
      padding-bottom: 5rem; } }
  main.current-projects-page .map-with-locations .google-maps-container,
  main.home-page .map-with-locations .google-maps-container {
    position: relative;
    box-shadow: 0px 0px 24px 7px rgba(18, 45, 63, 0.24); }
    main.current-projects-page .map-with-locations .google-maps-container img,
    main.home-page .map-with-locations .google-maps-container img {
      object-fit: cover; }
    @media (max-width: 575.98px) {
      main.current-projects-page .map-with-locations .google-maps-container img[src*="/static/images/icons/location-flag-red.svg"],
      main.home-page .map-with-locations .google-maps-container img[src*="/static/images/icons/location-flag-red.svg"] {
        height: 45px !important;
        object-fit: contain; } }

@media (max-width: 575.98px) and (max-width: 575.98px) {
  main.current-projects-page .map-with-locations .google-maps-container .ratio-16x9,
  main.home-page .map-with-locations .google-maps-container .ratio-16x9 {
    --aspect-ratio: calc(100%*16/9); } }
    main.current-projects-page .map-with-locations .google-maps-container iframe,
    main.home-page .map-with-locations .google-maps-container iframe {
      width: 100%;
      margin-left: 0%; }
    main.current-projects-page .map-with-locations .google-maps-container .popup-location,
    main.home-page .map-with-locations .google-maps-container .popup-location {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      width: 37%;
      opacity: 0;
      background-color: #122d3f;
      padding-top: 1.1825rem;
      color: #ffffff;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      transition: all 0.4s ease-out; }
      @media (min-width: 768px) {
        main.current-projects-page .map-with-locations .google-maps-container .popup-location,
        main.home-page .map-with-locations .google-maps-container .popup-location {
          margin-left: -37%; } }
      main.current-projects-page .map-with-locations .google-maps-container .popup-location .content,
      main.home-page .map-with-locations .google-maps-container .popup-location .content {
        padding: 0 2.25rem; }
        main.current-projects-page .map-with-locations .google-maps-container .popup-location .content h2, main.current-projects-page .map-with-locations .google-maps-container .popup-location .content .h2,
        main.home-page .map-with-locations .google-maps-container .popup-location .content h2,
        main.home-page .map-with-locations .google-maps-container .popup-location .content .h2 {
          padding-bottom: 1.375rem; }
        main.current-projects-page .map-with-locations .google-maps-container .popup-location .content .details p,
        main.current-projects-page .map-with-locations .google-maps-container .popup-location .content .details span,
        main.home-page .map-with-locations .google-maps-container .popup-location .content .details p,
        main.home-page .map-with-locations .google-maps-container .popup-location .content .details span {
          line-height: 1.75rem;
          font-weight: 300; }
          main.current-projects-page .map-with-locations .google-maps-container .popup-location .content .details p:before,
          main.current-projects-page .map-with-locations .google-maps-container .popup-location .content .details span:before,
          main.home-page .map-with-locations .google-maps-container .popup-location .content .details p:before,
          main.home-page .map-with-locations .google-maps-container .popup-location .content .details span:before {
            padding-right: 0.6375rem; }
        main.current-projects-page .map-with-locations .google-maps-container .popup-location .content .details p,
        main.home-page .map-with-locations .google-maps-container .popup-location .content .details p {
          margin-bottom: 0.6375rem; }
          main.current-projects-page .map-with-locations .google-maps-container .popup-location .content .details p.location:before,
          main.home-page .map-with-locations .google-maps-container .popup-location .content .details p.location:before {
            content: url("/static/images/icons/location-marker-icon.svg"); }
          main.current-projects-page .map-with-locations .google-maps-container .popup-location .content .details p.date:before,
          main.home-page .map-with-locations .google-maps-container .popup-location .content .details p.date:before {
            content: url("/static/images/icons/location-time-icon.svg"); }
      main.current-projects-page .map-with-locations .google-maps-container .popup-location a,
      main.home-page .map-with-locations .google-maps-container .popup-location a {
        display: block;
        width: 100%;
        text-align: end;
        color: #ffffff;
        position: relative; }
        main.current-projects-page .map-with-locations .google-maps-container .popup-location a img,
        main.home-page .map-with-locations .google-maps-container .popup-location a img {
          width: 3rem;
          background-color: #fc0039;
          padding: 1rem;
          margin-left: 1.625rem; }
    @media (min-width: 768px) {
      main.current-projects-page .map-with-locations .google-maps-container.popup-open .popup-location,
      main.home-page .map-with-locations .google-maps-container.popup-open .popup-location {
        margin-left: 0;
        opacity: 1; } }
    @media (max-width: 767.98px) {
      main.current-projects-page .map-with-locations .google-maps-container.popup-open .popup-location,
      main.home-page .map-with-locations .google-maps-container.popup-open .popup-location {
        height: 100%;
        padding-top: 1.825rem;
        opacity: 1; } }

@media (max-width: 767.98px) {
  main.current-projects-page .map-with-locations .google-maps-container .popup-location,
  main.home-page .map-with-locations .google-maps-container .popup-location {
    position: relative;
    width: 100%;
    height: 0;
    padding-top: 0;
    opacity: 0; } }

@media (max-width: 575.98px) {
  main.current-projects-page .search-bar,
  main.home-page .search-bar {
    margin-left: 1.5rem;
    margin-right: 1.5rem; } }

main.current-projects-page .call-part-triangle-background h4, main.current-projects-page .call-part-triangle-background .h4 {
  margin-top: 3.2rem; }

main.current-project-page .current-project-image {
  position: relative;
  margin-bottom: 4.5rem;
  overflow: hidden; }
  main.current-project-page .current-project-image img {
    position: relative;
    width: 100%;
    z-index: 2; }
  main.current-project-page .current-project-image #animation-current-project-one {
    background-image: url("/static/images/icons/triangle-grey-left-bottom.svg");
    background-position-x: right;
    background-position-y: top;
    background-size: initial;
    background-repeat: no-repeat;
    position: absolute;
    width: 100%;
    left: 0;
    bottom: calc(-271px);
    min-height: 271px;
    left: unset;
    right: 261px; }
  main.current-project-page .current-project-image #animation-current-project-two {
    background-image: url("/static/images/icons/triangle-grey-left-top.svg");
    background-position-x: right;
    background-position-y: top;
    background-size: initial;
    background-repeat: no-repeat;
    position: absolute;
    width: 100%;
    left: 0;
    bottom: calc(-542px);
    min-height: 271px;
    left: unset;
    right: -10px; }

main.current-project-page article {
  position: relative;
  z-index: 2;
  margin-bottom: 10.5rem; }
  main.current-project-page article h5, main.current-project-page article .h5 {
    font-weight: 700;
    margin-bottom: 1.4365rem; }
  main.current-project-page article p {
    line-height: 2.125rem;
    margin-bottom: 3.75rem; }
    main.current-project-page article p:first-of-type {
      margin-bottom: 6.6525rem; }
    main.current-project-page article p:last-of-type {
      margin-bottom: 0; }
  main.current-project-page article ul {
    list-style: none;
    padding-left: 3.3875rem;
    margin: 3.75rem 0; }
    main.current-project-page article ul li {
      font-size: 1.25rem;
      line-height: 2.5rem;
      padding-left: 1.65rem;
      position: relative;
      margin-bottom: 0; }
      main.current-project-page article ul li:before {
        content: url("/static/images/icons/triangle-li-dark.svg");
        position: absolute;
        top: 0;
        left: 0; }

main.contact-page {
  margin-top: 2rem;
  overflow: hidden; }
  main.contact-page h5, main.contact-page .h5 {
    margin-bottom: 3.0625rem; }
  main.contact-page .help-grid-container {
    display: grid;
    height: auto;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr 1fr;
    grid-auto-rows: unset;
    column-gap: 1.875rem;
    row-gap: 1.875rem;
    grid-row-gap: 1.875rem;
    -moz-column-gap: 1.875rem;
    margin-bottom: 5.875rem; }
    main.contact-page .help-grid-container .help-item {
      display: grid;
      background-color: #122d3f;
      background-size: cover;
      background-position: center;
      min-height: 21rem; }
      main.contact-page .help-grid-container .help-item .item-content {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: flex-start;
        color: #ffffff; }
        main.contact-page .help-grid-container .help-item .item-content h2, main.contact-page .help-grid-container .help-item .item-content .h2,
        main.contact-page .help-grid-container .help-item .item-content .gradient-background {
          padding: 1.375rem 2.3125rem; }
        main.contact-page .help-grid-container .help-item .item-content h2, main.contact-page .help-grid-container .help-item .item-content .h2 {
          background-color: #122d3f;
          font-weight: 700;
          line-height: 2.125rem;
          width: 100%;
          margin-bottom: 0; }
        main.contact-page .help-grid-container .help-item .item-content .gradient-background {
          background: #122d3f;
          background: linear-gradient(180deg, #122d3f 0%, rgba(255, 255, 255, 0) 80%);
          flex: 1;
          width: 100%;
          position: relative; }
          main.contact-page .help-grid-container .help-item .item-content .gradient-background p {
            position: absolute;
            bottom: 1.375rem;
            margin-right: 2.3125rem; }
          main.contact-page .help-grid-container .help-item .item-content .gradient-background .button-basic.light:hover {
            border: 1px solid #122d3f; }
      main.contact-page .help-grid-container .help-item:nth-child(1) {
        grid-column: 1 / span 1;
        grid-row: 1 / span 1; }
      main.contact-page .help-grid-container .help-item:nth-child(2) {
        grid-column: 2 / span 1;
        grid-row: 1 / span 2; }
      main.contact-page .help-grid-container .help-item:nth-child(3) {
        grid-column: 1 / span 1;
        grid-row: 2 / span 1; }
    main.contact-page .help-grid-container .help-item-1 {
      background-image: url("/data/images/contact/smallhelp_block_left_top11.jpg"); }
      @media (min-width: 1200px) {
        main.contact-page .help-grid-container .help-item-1 {
          background-image: url("/data/images/contact/retinahelp_block_left_top11.jpg"); } }
    main.contact-page .help-grid-container .help-item-2 {
      background-image: url("/data/images/contact/smallhelp_block_right_desktop11.jpg"); }
      @media (min-width: 1200px) {
        main.contact-page .help-grid-container .help-item-2 {
          background-image: url("/data/images/contact/retinahelp_block_right_desktop11.jpg"); } }
      @media (max-width: 767.98px) {
        main.contact-page .help-grid-container .help-item-2 {
          background-image: url("/data/images/contact/mobilehelp_block_right_mobile11.jpg"); } }
    main.contact-page .help-grid-container .help-item-3 {
      background-image: url("/data/images/contact/smallhelp_block_left_bottom11.jpg"); }
      @media (min-width: 1200px) {
        main.contact-page .help-grid-container .help-item-3 {
          background-image: url("/data/images/contact/retinahelp_block_left_bottom11.jpg"); } }
  main.contact-page .discription-form h1, main.contact-page .discription-form .h1 {
    margin-bottom: 1.8125rem; }
  main.contact-page .discription-form p {
    margin-bottom: 4.25rem; }
  main.contact-page .contact-form {
    margin-bottom: 10.625rem; }
    main.contact-page .contact-form ::placeholder {
      color: #122d3f;
      opacity: 1; }
    main.contact-page .contact-form input[type="text"],
    main.contact-page .contact-form input[type="email"],
    main.contact-page .contact-form input[type="number"],
    main.contact-page .contact-form textarea {
      width: 100%;
      background-color: #ffffff;
      color: #122d3f;
      border: 1px solid #122d3f;
      border-radius: 1rem;
      padding-left: 1rem;
      margin-bottom: 1.75rem;
      line-height: 2.125rem;
      resize: none;
      outline: none;
      appearance: none;
      -webkit-appearance: none; }
    main.contact-page .contact-form input::-webkit-outer-spin-button,
    main.contact-page .contact-form input::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0; }
    main.contact-page .contact-form input[type="number"] {
      -moz-appearance: textfield; }
    main.contact-page .contact-form .form-check {
      margin-left: 1rem;
      margin-bottom: 3.5rem; }
      main.contact-page .contact-form .form-check label,
      main.contact-page .contact-form .form-check input {
        display: inline-block;
        vertical-align: middle; }
      main.contact-page .contact-form .form-check label {
        margin-left: 1.3125rem;
        line-height: 1.5625rem;
        font-size: 1rem; }
      main.contact-page .contact-form .form-check .form-check-input {
        float: unset;
        vertical-align: middle; }
      main.contact-page .contact-form .form-check a {
        color: #ffffff; }
    main.contact-page .contact-form a.button-basic {
      display: block;
      width: max-content;
      margin-left: auto;
      margin-right: 0; }
    main.contact-page .contact-form .messages {
      margin-top: -1.75rem;
      height: 1.75rem; }
      main.contact-page .contact-form .messages p.help-block {
        height: 100%;
        font-weight: 500;
        font-size: x-small;
        line-height: 1rem; }
        main.contact-page .contact-form .messages p.help-block.error {
          color: #fc0039; }
  main.contact-page .contact-details {
    margin-bottom: 15.7rem;
    position: relative; }
    main.contact-page .contact-details .details-content {
      z-index: 10;
      position: relative; }
      main.contact-page .contact-details .details-content h4, main.contact-page .contact-details .details-content .h4 {
        margin-bottom: 1.875rem; }
      main.contact-page .contact-details .details-content p {
        margin-bottom: 4.875rem; }
      main.contact-page .contact-details .details-content img {
        display: block;
        width: 100%; }
    main.contact-page .contact-details #animation-contact-one {
      background-image: url("/static/images/icons/triangle-grey-left-bottom.svg");
      background-position-x: right;
      background-position-y: top;
      background-size: initial;
      background-repeat: no-repeat;
      position: absolute;
      width: 100%;
      left: 0;
      min-height: 271px;
      top: unset;
      left: calc(5% -271px);
      bottom: 191px; }
    main.contact-page .contact-details #animation-contact-two {
      background-image: url("/static/images/icons/triangle-grey-left-top.svg");
      background-position-x: right;
      background-position-y: top;
      background-size: initial;
      background-repeat: no-repeat;
      position: absolute;
      width: 100%;
      left: 0;
      min-height: 271px;
      top: unset;
      left: 5%;
      bottom: -80px; }
  @media (max-width: 767.98px) {
    main.contact-page .help-grid-container {
      display: grid;
      height: auto;
      grid-template-columns: 1fr;
      grid-template-rows: auto;
      grid-auto-rows: 1fr;
      column-gap: 1.875rem;
      row-gap: 1.875rem;
      grid-row-gap: 1.875rem;
      -moz-column-gap: 1.875rem; }
      main.contact-page .help-grid-container .help-item:nth-child(1) {
        grid-column: 1 / span 1;
        grid-row: 1 / span 1; }
      main.contact-page .help-grid-container .help-item:nth-child(2) {
        grid-column: 1 / span 1;
        grid-row: 2 / span 1; }
      main.contact-page .help-grid-container .help-item:nth-child(3) {
        grid-column: 1 / span 1;
        grid-row: 3 / span 1; }
    main.contact-page .contact-details .details-content h4, main.contact-page .contact-details .details-content .h4 {
      margin-top: 1.875rem; } }
  main.contact-page .hidden {
    display: none; }

main.jobs-page {
  margin-top: -6.75rem;
  position: relative;
  overflow: hidden; }
  main.jobs-page #animation-jobs-one {
    background-image: url("/static/images/icons/triangle-grey-top.svg");
    background-position-x: right;
    background-position-y: top;
    background-size: initial;
    background-repeat: no-repeat;
    position: absolute;
    width: 100%;
    left: 0;
    min-height: 192px;
    top: 281.5px;
    left: calc(5% - 192px); }
  main.jobs-page #animation-jobs-two {
    background-image: url("/static/images/icons/triangle-grey-right.svg");
    background-position-x: right;
    background-position-y: top;
    background-size: initial;
    background-repeat: no-repeat;
    position: absolute;
    width: 100%;
    left: 0;
    min-height: 383px;
    top: 90px;
    left: 5%; }
  main.jobs-page #animation-jobs-three {
    background-image: url("/static/images/icons/triangle-grey-left-bottom.svg");
    background-position-x: left;
    background-position-y: top;
    background-size: initial;
    background-repeat: no-repeat;
    position: absolute;
    width: 100%;
    left: 0;
    min-height: 271px;
    top: unset;
    left: -10%;
    bottom: 580px; }
  main.jobs-page #animation-jobs-four {
    background-image: url("/static/images/icons/triangle-grey-left-top.svg");
    background-position-x: left;
    background-position-y: top;
    background-size: initial;
    background-repeat: no-repeat;
    position: absolute;
    width: 100%;
    left: 0;
    min-height: 271px;
    top: unset;
    left: calc(-10% + 271px);
    bottom: 309px; }
  main.jobs-page .introduction-quote {
    position: relative;
    background-color: #122d3f;
    color: #ffffff;
    padding: 2.4375rem 2.75rem; }
  main.jobs-page .breadcrumbs {
    padding-bottom: 3rem; }
  main.jobs-page .breadcrumb-container .step-back-link {
    display: none; }
  main.jobs-page .title-and-filter {
    display: flex;
    justify-content: space-between;
    margin-bottom: 1.875rem;
    margin-bottom: 2.125rem;
    align-items: baseline; }
    main.jobs-page .title-and-filter p.small {
      margin-right: 1rem; }
    main.jobs-page .title-and-filter label {
      width: 100%;
      max-width: fit-content;
      margin-right: 1.25rem; }
    main.jobs-page .title-and-filter select {
      text-transform: uppercase;
      max-width: fit-content; }
      main.jobs-page .title-and-filter select option {
        padding: 1rem 2rem;
        margin-right: 1rem; }
    @media (max-width: 575.98px) {
      main.jobs-page .title-and-filter {
        flex-direction: column; }
        main.jobs-page .title-and-filter h5, main.jobs-page .title-and-filter .h5 {
          margin-bottom: 2rem; } }
  main.jobs-page.filter-menu-open .drop-down-list {
    min-height: calc(100vh - 8.15rem); }
    @media (max-height: 500px) {
      main.jobs-page.filter-menu-open .drop-down-list {
        max-height: max-content; } }
  main.jobs-page.filter-menu-open .arrow {
    transform: rotate(180deg); }
  main.jobs-page.filter-menu-open .drop-down-list {
    display: block; }
  main.jobs-page.filter-menu-selected .arrow {
    transform: rotate(270deg); }
  main.jobs-page .job-filter {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 1.875rem;
    margin-bottom: 2.125rem;
    align-items: baseline; }
    main.jobs-page .job-filter p.small {
      margin-right: 1rem; }
    main.jobs-page .job-filter label {
      width: 100%;
      max-width: fit-content;
      margin-right: 1.25rem; }
    main.jobs-page .job-filter select {
      text-transform: uppercase;
      max-width: fit-content; }
      main.jobs-page .job-filter select option {
        padding: 1rem 2rem;
        margin-right: 1rem; }
    @media (max-width: 575.98px) {
      main.jobs-page .job-filter {
        flex-direction: column; }
        main.jobs-page .job-filter h5, main.jobs-page .job-filter .h5 {
          margin-bottom: 2rem; } }
    main.jobs-page .job-filter .form-select {
      background-color: #122d3f;
      color: #ffffff;
      background-image: none;
      border: 1px solid #122d3f;
      position: relative;
      font-size: 1rem;
      transition: none; }
      main.jobs-page .job-filter .form-select:after {
        content: url("/static/images/icons/triangle-light-down.svg");
        position: absolute;
        top: 0.24rem;
        right: 0.56rem;
        width: 2rem;
        transform: rotate(0deg);
        transition: all 0.2s ease-out; }
      main.jobs-page .job-filter .form-select:hover {
        color: #fc0039; }
    main.jobs-page .job-filter .container {
      padding-right: 0; }
  @media (max-width: 767.98px) {
    main.jobs-page .job-filter .form-select {
      font-size: 1.3rem; } }
  @media (max-width: 575.98px) {
    main.jobs-page .job-filter {
      flex-direction: row; } }
  main.jobs-page .filter-menu-open .form-select:after {
    top: 0rem;
    right: -0.5rem;
    right: 0.5rem;
    width: 2rem;
    transform: rotate(-45deg); }

aside {
  position: relative; }
  aside .van-baarsen-image {
    object-fit: cover; }
  aside .general-job-information {
    margin-bottom: 20rem; }
  aside .clipped-background {
    position: absolute;
    top: 0;
    width: 100%;
    background-color: #f6f6f6;
    -webkit-clip-path: polygon(0% 52vw, 100% 12vw, 100% 100%, 0 100%);
    clip-path: polygon(0% 52vw, 100% 12vw, 100% 100%, 0 100%);
    height: 100%; }

.main-job {
  position: relative;
  overflow: hidden; }
  .main-job #animation-job-item-one {
    background-image: url("/static/images/icons/triangle-grey-left-bottom.svg");
    background-position-x: left;
    background-position-y: top;
    background-size: initial;
    background-repeat: no-repeat;
    position: absolute;
    width: 100%;
    left: 0;
    min-height: 542px;
    top: 300px;
    left: -10%; }
  .main-job #animation-job-item-two {
    background-image: url("/static/images/icons/triangle-grey-left-top.svg");
    background-position-x: left;
    background-position-y: top;
    background-size: initial;
    background-repeat: no-repeat;
    position: absolute;
    width: 100%;
    left: 0;
    min-height: 542px;
    top: 571px;
    left: calc(-10% +271px); }
  .main-job #animation-job-item-three {
    background-image: url("/static/images/icons/triangle-grey-left-bottom.svg");
    background-position-x: right;
    background-position-y: bottom;
    background-size: initial;
    background-repeat: no-repeat;
    position: absolute;
    width: 100%;
    left: 0;
    min-height: 271px;
    top: unset;
    left: -271px;
    bottom: 500px; }
  .main-job #animation-job-item-four {
    background-image: url("/static/images/icons/triangle-grey-left-top.svg");
    background-position-x: right;
    background-position-y: bottom;
    background-size: initial;
    background-repeat: no-repeat;
    position: absolute;
    width: 100%;
    left: 0;
    min-height: 271px;
    top: unset;
    left: 0;
    bottom: 229px; }
  .main-job .job-description {
    margin-top: 2.75rem;
    margin-bottom: 5.6125rem; }
    .main-job .job-description h5, .main-job .job-description .h5 {
      margin-bottom: 1.4375rem;
      font-weight: 700; }
    .main-job .job-description p {
      margin-bottom: 1.7rem; }
    .main-job .job-description .list-with-background {
      background-color: #f6f6f6;
      padding: 2.25rem 2.625rem;
      margin-bottom: 3.75rem; }
      .main-job .job-description .list-with-background ul {
        list-style: none;
        padding-left: 0;
        margin-bottom: 0; }
        .main-job .job-description .list-with-background ul li {
          font-size: 1.25rem;
          line-height: 2.125rem;
          padding-left: 1.65rem;
          position: relative;
          margin-bottom: 2rem; }
          .main-job .job-description .list-with-background ul li:before {
            content: url("/static/images/icons/triangle-li-dark.svg");
            position: absolute;
            top: 0;
            left: 0; }
    .main-job .job-description ul {
      list-style: none;
      padding-left: 0;
      margin-bottom: 3.75rem; }
      .main-job .job-description ul li {
        font-size: 1.25rem;
        line-height: 2.125rem;
        padding-left: 1.65rem;
        position: relative;
        margin-bottom: 1.25rem; }
        .main-job .job-description ul li:before {
          content: url("/static/images/icons/triangle-li-dark.svg");
          position: absolute;
          top: 0;
          left: 0; }

.apply-for-job {
  background-color: #122d3f;
  color: #ffffff;
  padding-top: 4.875rem;
  padding-bottom: 6.75rem;
  margin-bottom: 6rem; }
  .apply-for-job h5, .apply-for-job .h5 {
    margin-bottom: 1.4375rem;
    font-weight: 700; }
  .apply-for-job .apply-form {
    margin-top: 3.125rem; }
    .apply-for-job .apply-form ::placeholder {
      color: #ffffff;
      opacity: 1; }
    .apply-for-job .apply-form input[type="text"],
    .apply-for-job .apply-form input[type="email"],
    .apply-for-job .apply-form input[type="number"],
    .apply-for-job .apply-form textarea {
      width: 100%;
      background-color: #122d3f;
      color: #ffffff;
      border: 1px solid #ffffff;
      border-radius: 1rem;
      padding-left: 1rem;
      margin-bottom: 1.75rem;
      line-height: 2.125rem;
      resize: none;
      outline: none;
      appearance: none;
      -webkit-appearance: none; }
    .apply-for-job .apply-form input::-webkit-outer-spin-button,
    .apply-for-job .apply-form input::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0; }
    .apply-for-job .apply-form input[type="number"] {
      -moz-appearance: textfield; }
    .apply-for-job .apply-form .form-check {
      margin-left: 1rem;
      margin-bottom: 3.5rem; }
      .apply-for-job .apply-form .form-check label,
      .apply-for-job .apply-form .form-check input {
        display: inline-block;
        vertical-align: middle; }
      .apply-for-job .apply-form .form-check label {
        margin-left: 1.3125rem;
        line-height: 1.5625rem;
        font-size: 1rem; }
      .apply-for-job .apply-form .form-check .form-check-input {
        float: unset;
        vertical-align: middle; }
      .apply-for-job .apply-form .form-check a {
        color: #ffffff; }
    .apply-for-job .apply-form a.button-basic {
      display: block;
      width: max-content;
      margin-left: 0;
      margin-right: auto; }
    .apply-for-job .apply-form p {
      padding-left: 1rem;
      margin-bottom: 1.25rem; }
    .apply-for-job .apply-form h3, .apply-for-job .apply-form .h3 {
      padding-left: 1rem;
      font-weight: 700;
      margin-bottom: 0;
      line-height: 2.125rem; }
    .apply-for-job .apply-form a.button-basic {
      margin-bottom: 2.4375rem; }
      .apply-for-job .apply-form a.button-basic:last-of-type {
        margin-bottom: 0; }
    .apply-for-job .apply-form .messages {
      margin-top: -1.75rem;
      height: 1.75rem; }
      .apply-for-job .apply-form .messages p.help-block {
        height: 100%;
        font-weight: 500;
        font-size: x-small;
        line-height: 1rem; }
        .apply-for-job .apply-form .messages p.help-block.error {
          color: #fff; }
      .apply-for-job .apply-form .messages.checkbox {
        margin-top: 0; }
  .apply-for-job .hidden {
    display: none; }

.more-vacancies {
  margin-bottom: 0; }
  .more-vacancies h5, .more-vacancies .h5 {
    font-weight: 700;
    margin-bottom: 2.625rem; }
  .more-vacancies .vacancy-item:last-of-type {
    margin-bottom: 3.5625rem; }
  .more-vacancies a.button-basic.dark.forward {
    margin-bottom: 4.75rem; }

.news-navigation {
  margin-top: 4rem;
  z-index: 200;
  position: relative; }
  @media (max-width: 575.98px) {
    .news-navigation {
      margin-top: 0; } }
  .news-navigation .news-tabs::-webkit-scrollbar {
    width: 6px;
    height: 6px; }
  .news-navigation .news-tabs::-webkit-scrollbar-track {
    background-color: #f6f6f6; }
  .news-navigation .news-tabs::-webkit-scrollbar-thumb {
    background-color: #122d3f; }
  .news-navigation .news-tabs::-webkit-scrollbar-corner {
    border-radius: none; }
  .news-navigation .news-tabs {
    list-style: none;
    display: flex;
    width: 100%;
    padding: 0;
    overflow-x: scroll;
    scrollbar-color: #122d3f #f6f6f6;
    scrollbar-width: auto; }
    .news-navigation .news-tabs .news-category {
      display: block;
      background-color: #122d3f;
      color: #ffffff;
      line-height: 1.5rem;
      height: 100%;
      padding: 1.25rem 1.68rem;
      transition: all 0.5s ease-out;
      border-right: 1px solid #f6f6f6; }
      .news-navigation .news-tabs .news-category.active {
        background-color: #f6f6f6;
        color: #122d3f; }
      .news-navigation .news-tabs .news-category li {
        display: block;
        width: 100%; }
      .news-navigation .news-tabs .news-category:hover {
        background-color: #f6f6f6;
        color: #122d3f; }
  .news-navigation .breadcrumbs {
    padding-bottom: 1.5625rem; }
  .news-navigation .breadcrumb-container .step-back-link {
    display: none; }
  .news-navigation .news-filter {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 1.875rem; }
    .news-navigation .news-filter label {
      width: 100%; }
    .news-navigation .news-filter select {
      text-transform: uppercase; }
      .news-navigation .news-filter select option {
        padding: 1rem 2rem;
        margin-right: 1rem; }

main.news-overview-page {
  margin-bottom: 7.4375rem;
  position: relative;
  overflow: hidden; }
  main.news-overview-page #animation-news-one {
    background-image: url("/static/images/animation-background-news-one.svg");
    background-position-x: left;
    background-position-y: top;
    background-size: initial;
    background-repeat: no-repeat;
    position: absolute;
    width: 100%;
    left: 0;
    min-height: 237px;
    top: 450px;
    left: -10%; }
  main.news-overview-page #animation-news-two {
    background-image: url("/static/images/icons/triangle-grey-top.svg");
    background-position-x: right;
    background-position-y: bottom;
    background-size: initial;
    background-repeat: no-repeat;
    position: absolute;
    width: 100%;
    left: 0;
    min-height: 192px;
    top: unset;
    left: calc(10% - 192px);
    bottom: 150px; }
  main.news-overview-page #animation-news-three {
    background-image: url("/static/images/icons/triangle-grey-right.svg");
    background-position-x: right;
    background-position-y: bottom;
    background-size: initial;
    background-repeat: no-repeat;
    position: absolute;
    width: 100%;
    left: 0;
    min-height: 383px;
    top: unset;
    left: 10%;
    bottom: 150px; }
  main.news-overview-page h1, main.news-overview-page .h1 {
    text-align: center;
    margin-bottom: 1.8125rem; }
  main.news-overview-page .news-filter {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 1.875rem;
    margin-bottom: 2.125rem;
    align-items: baseline; }
    main.news-overview-page .news-filter p.small {
      margin-right: 1rem; }
    main.news-overview-page .news-filter label {
      width: 100%;
      max-width: fit-content;
      margin-right: 1.25rem; }
    main.news-overview-page .news-filter select {
      text-transform: uppercase;
      max-width: fit-content; }
      main.news-overview-page .news-filter select option {
        padding: 1rem 2rem;
        margin-right: 1rem; }
    @media (max-width: 575.98px) {
      main.news-overview-page .news-filter {
        flex-direction: column; }
        main.news-overview-page .news-filter h5, main.news-overview-page .news-filter .h5 {
          margin-bottom: 2rem; } }
    main.news-overview-page .news-filter .form-select {
      background-color: #f6f6f6;
      color: #122d3f;
      background-image: none;
      border: 1px solid #f6f6f6;
      position: relative;
      font-size: 1rem;
      transition: none; }
      main.news-overview-page .news-filter .form-select:after {
        content: url("/static/images/icons/triangle-dark-down.svg");
        position: absolute;
        top: 0.24rem;
        right: 0.56rem;
        width: 2rem;
        transform: rotate(0deg);
        transition: all 0.2s ease-out; }
      main.news-overview-page .news-filter .form-select:hover {
        color: #fc0039; }
  main.news-overview-page a.button-basic.dark.down {
    display: block;
    width: max-content;
    margin-left: auto;
    margin-right: auto; }
  @media (max-width: 767.98px) {
    main.news-overview-page .news-filter .form-select {
      font-size: 1.3rem; } }
  @media (max-width: 575.98px) {
    main.news-overview-page .news-filter {
      flex-direction: row; } }
  main.news-overview-page .filter-menu-open .form-select:after {
    top: 0rem;
    right: -0.5rem;
    right: 0.5rem;
    width: 2rem;
    transform: rotate(-45deg); }

.news {
  padding-bottom: 6.3125rem; }
  .news h1, .news .h1 {
    text-align: center;
    padding-top: 7.125rem;
    padding-bottom: 6.3125rem; }
  .news .news-items .fill-height-controller {
    display: flex;
    flex-direction: column;
    height: 100%; }
    .news .news-items .fill-height-controller.padding-right {
      padding-right: 3px; }
    .news .news-items .fill-height-controller.padding-left {
      padding-left: 3px; }
    .news .news-items .fill-height-controller .news-item {
      background-color: #f6f6f6;
      color: #122d3f;
      flex: 1;
      display: flex;
      flex-direction: column;
      margin-bottom: 1.875rem; }
      .news .news-items .fill-height-controller .news-item p {
        text-transform: uppercase;
        font-weight: 500; }
      .news .news-items .fill-height-controller .news-item .image-header {
        flex: 1;
        min-height: 7rem;
        background-color: #6f6f6f;
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
        position: relative;
        transition: all 0.4s ease-in-out;
        overflow: hidden; }
        .news .news-items .fill-height-controller .news-item .image-header::before {
          content: "";
          position: absolute;
          top: 0;
          right: 0;
          bottom: 0;
          left: 0;
          background: inherit;
          transition: inherit; }
        .news .news-items .fill-height-controller .news-item .image-header img {
          width: 100%;
          height: 100%;
          object-fit: cover;
          opacity: 0; }
        .news .news-items .fill-height-controller .news-item .image-header .news-label {
          padding: 1.5rem 1.75rem;
          position: absolute;
          bottom: 0;
          width: max-content;
          overflow: hidden;
          width: auto; }
          .news .news-items .fill-height-controller .news-item .image-header .news-label .label-text {
            background: rgba(246, 246, 246, 0.8);
            overflow: hidden;
            background-position: bottom left;
            padding: 0.34375rem 1rem;
            transition: all 0.4s ease-in-out; }
            .news .news-items .fill-height-controller .news-item .image-header .news-label .label-text p {
              color: #122d3f;
              font-weight: 500;
              transition: color 0.4s ease-in-out; }
      .news .news-items .fill-height-controller .news-item .news-content {
        padding: 1.5rem 1.75rem; }
        .news .news-items .fill-height-controller .news-item .news-content h3, .news .news-items .fill-height-controller .news-item .news-content .h3 {
          font-weight: 700;
          line-height: 1.75rem; }
          .news .news-items .fill-height-controller .news-item .news-content h3 img, .news .news-items .fill-height-controller .news-item .news-content .h3 img {
            margin-bottom: 0;
            width: max-content; }
      .news .news-items .fill-height-controller .news-item:hover .image-header::before {
        transform: scale(1.1); }
      .news .news-items .fill-height-controller .news-item:hover .image-header .news-label .label-text {
        background-color: #f6f6f6; }
    .news .news-items .fill-height-controller .news-item:last-of-type {
      margin-bottom: 0; }
    .news .news-items .fill-height-controller .more-news-box {
      background-color: #122d3f;
      background-size: cover;
      background-position: center;
      min-height: 18rem;
      flex: 1;
      display: flex;
      flex-direction: column;
      position: relative;
      transition: all 0.4s ease-in-out;
      overflow: hidden; }
      .news .news-items .fill-height-controller .more-news-box::before {
        content: "";
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background: inherit;
        transition: inherit; }
      .news .news-items .fill-height-controller .more-news-box .title-header {
        position: relative;
        background-color: #122d3f;
        padding: 1.5rem; }
        .news .news-items .fill-height-controller .more-news-box .title-header h2, .news .news-items .fill-height-controller .more-news-box .title-header .h2 {
          color: #fff;
          font-weight: 700; }
      .news .news-items .fill-height-controller .more-news-box .gradient-overlay {
        position: relative;
        background: linear-gradient(180deg, #122d3f, rgba(255, 255, 255, 0) 50%);
        flex: 1; }
        .news .news-items .fill-height-controller .more-news-box .gradient-overlay .triangle-icon {
          position: absolute;
          bottom: 0;
          right: 0; }
      .news .news-items .fill-height-controller .more-news-box:hover::before {
        transform: scale(1.1); }
  @media (max-width: 575.98px) {
    .news .news-items .fill-height-controller.padding-right {
      padding-right: 0; }
    .news .news-items .fill-height-controller.padding-left {
      padding-left: 0; }
    .news .news-items .fill-height-controller .news-item:last-of-type {
      margin-bottom: 1.875rem; } }

.news-item-header .breadcrumb-container .breadcrumbs {
  display: none; }

main.news-item-page {
  position: relative; }
  main.news-item-page .clipped-diagonal-background {
    position: absolute;
    top: 0;
    width: 100%;
    background-color: #f6f6f6;
    -webkit-clip-path: polygon(0% 0%, 100% 0%, 100% 25vw, 0 52vw);
    clip-path: polygon(0% 0%, 100% 0%, 100% 25vw, 0 52vw);
    height: 100%; }
  main.news-item-page h1, main.news-item-page .h1 {
    margin-bottom: 2.8125rem; }
  main.news-item-page img {
    width: 100%;
    margin-bottom: 4.75rem; }
  main.news-item-page .labels-wrapper {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 2.375rem; }
    main.news-item-page .labels-wrapper .news-label {
      background-color: #122d3f;
      padding: 0.5rem 1rem;
      width: max-content;
      margin-right: 1rem;
      margin-bottom: 1rem; }
      main.news-item-page .labels-wrapper .news-label p {
        margin-bottom: 0;
        text-transform: uppercase;
        font-weight: 500;
        font-size: 0.8125rem;
        color: #ffffff; }
  main.news-item-page .news-article p {
    margin-bottom: 3.75rem; }
    main.news-item-page .news-article p:last-of-type {
      margin-bottom: 6.25rem; }
  main.news-item-page .more-news {
    margin-bottom: 7.5rem; }
    main.news-item-page .more-news h1, main.news-item-page .more-news .h1 {
      text-align: center;
      margin-bottom: 6rem; }

main.seo-page {
  padding-top: 5.4375rem;
  position: relative;
  overflow: hidden; }
  main.seo-page .clipped-diagonal-background {
    position: absolute;
    top: 0;
    width: 100%;
    background-color: #f6f6f6;
    -webkit-clip-path: polygon(0% 0%, 100% 0%, 100% 25vw, 0 52vw);
    clip-path: polygon(0% 0%, 100% 0%, 100% 25vw, 0 52vw);
    height: 100%; }
  main.seo-page #animation-service-one {
    background-image: url("/static/images/icons/triangle-grey-xxl-top-left.svg");
    background-position-x: right;
    background-position-y: top;
    background-size: initial;
    background-repeat: no-repeat;
    position: absolute;
    width: 100%;
    left: 0;
    min-height: 393.4px;
    top: calc(1400px);
    left: -5%; }
  main.seo-page #animation-service-two {
    background-image: url("/static/images/icons/triangle-grey-xxl-top-left.svg");
    background-position-x: left;
    background-position-y: top;
    background-size: initial;
    background-repeat: no-repeat;
    position: absolute;
    width: 100%;
    left: 0;
    min-height: 393.4px;
    top: calc(1800px);
    left: 0; }
  main.seo-page #animation-service-three {
    background-image: url("/static/images/icons/triangle-grey-xxl-bottom-right.svg");
    background-position-x: right;
    background-position-y: top;
    background-size: initial;
    background-repeat: no-repeat;
    position: absolute;
    width: 100%;
    left: 0;
    min-height: 393.4px;
    top: unset;
    left: -5%;
    bottom: 1300px; }
  main.seo-page #animation-service-four {
    background-image: url("/static/images/icons/triangle-grey-xxl-bottom-left.svg");
    background-position-x: left;
    background-position-y: top;
    background-size: initial;
    background-repeat: no-repeat;
    position: absolute;
    width: 100%;
    left: 0;
    min-height: 393.4px;
    top: unset;
    left: 10%;
    bottom: 100px; }
  main.seo-page p {
    margin-bottom: 3.125rem; }
    main.seo-page p:first-of-type {
      font-size: 1.375rem;
      font-weight: 700; }
  main.seo-page .image-and-text,
  main.seo-page .image-only {
    margin-bottom: 3.125rem; }
    main.seo-page .image-and-text img,
    main.seo-page .image-only img {
      width: 100%; }
    main.seo-page .image-and-text p:first-of-type,
    main.seo-page .image-only p:first-of-type {
      font-size: 1.25rem;
      font-weight: 400; }
  main.seo-page .innovative-projects {
    position: relative;
    overflow: hidden; }
    main.seo-page .innovative-projects h5, main.seo-page .innovative-projects .h5 {
      text-align: center;
      font-weight: 700;
      margin-bottom: 4.1875rem; }
    main.seo-page .innovative-projects .grid-container {
      display: grid;
      height: auto;
      grid-template-columns: 1fr 1fr;
      grid-template-rows: auto;
      grid-auto-rows: 1fr;
      column-gap: 1.875rem;
      row-gap: 1.875rem;
      grid-row-gap: 1.875rem;
      -moz-column-gap: 1.875rem;
      margin-bottom: 14.26rem; }
      main.seo-page .innovative-projects .grid-container .innovative-item {
        display: grid;
        grid-column: auto;
        grid-row: auto;
        background-color: #fc0039;
        background-size: cover;
        background-repeat: no-repeat;
        position: relative;
        color: #ffffff;
        padding: 1.5rem 2.5rem;
        min-height: 25rem; }
        main.seo-page .innovative-projects .grid-container .innovative-item .background-gradient {
          background: #122d3f;
          background: linear-gradient(180deg, #122d3f 0%, #122d3f 15%, rgba(18, 45, 63, 0) 80%);
          position: absolute;
          top: 0;
          width: 100%;
          height: 100%; }
        main.seo-page .innovative-projects .grid-container .innovative-item .item-content {
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          height: 100%; }
          main.seo-page .innovative-projects .grid-container .innovative-item .item-content h2, main.seo-page .innovative-projects .grid-container .innovative-item .item-content .h2 {
            margin-bottom: 2rem; }
          main.seo-page .innovative-projects .grid-container .innovative-item .item-content .item-label {
            background-color: #122d3f;
            padding: 0.5rem 1rem;
            width: max-content; }
            main.seo-page .innovative-projects .grid-container .innovative-item .item-content .item-label p {
              margin-bottom: 0;
              text-transform: uppercase;
              font-weight: 500;
              font-size: 0.8125rem;
              color: #ffffff; }
    @media (max-width: 767.98px) {
      main.seo-page .innovative-projects .grid-container .innovative-item {
        padding: 0.5rem 1.5rem;
        min-height: 21rem; } }
    @media (max-width: 575.98px) {
      main.seo-page .innovative-projects .grid-container {
        display: grid;
        height: auto;
        grid-template-columns: 1fr;
        grid-template-rows: auto;
        grid-auto-rows: 1fr;
        column-gap: 1.875rem;
        row-gap: 1.875rem;
        grid-row-gap: 1.875rem;
        -moz-column-gap: 1.875rem; }
        main.seo-page .innovative-projects .grid-container .innovative-item {
          min-height: 18rem; } }

.certificates-header {
  overflow: hidden; }
  .certificates-header .breadcrumb-container .step-back-link {
    display: none; }

main.certificate-page {
  position: relative;
  overflow: hidden; }
  main.certificate-page #animation-certificates-three {
    background-image: url("/static/images/icons/triangle-grey-small-right-bottom.svg");
    background-position-x: right;
    background-position-y: top;
    background-size: initial;
    background-repeat: no-repeat;
    position: absolute;
    width: 100%;
    left: 0;
    min-height: 236.7px;
    top: calc(250px);
    left: calc(5%); }
  main.certificate-page .certificate {
    margin-bottom: 5.19375rem; }
    main.certificate-page .certificate h1, main.certificate-page .certificate .h1 {
      text-align: center;
      font-weight: 700;
      margin-bottom: 2.7rem; }
    main.certificate-page .certificate .introduction-text {
      margin-bottom: 4.1875rem;
      padding: 0 4.375rem;
      text-align: center; }
  main.certificate-page .sustainability {
    margin-bottom: 8rem; }
    main.certificate-page .sustainability h5, main.certificate-page .sustainability .h5 {
      text-align: center;
      font-weight: 700;
      margin-bottom: 2.5rem; }
    main.certificate-page .sustainability .introduction-text {
      text-align: center;
      margin-bottom: 3.925rem; }
  main.certificate-page .download-bar {
    background: #f7f7f7;
    background: linear-gradient(0deg, #f7f7f7 0%, #ebebeb 50%, #f7f7f7 100%);
    padding: 2.875rem 2.5rem 3.375rem 2.5rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 0.875rem; }
    main.certificate-page .download-bar .download-title {
      padding-right: 5rem; }
      main.certificate-page .download-bar .download-title h3, main.certificate-page .download-bar .download-title .h3 {
        font-size: 1.375rem;
        font-weight: 700;
        margin-bottom: 0;
        line-height: 2.125rem;
        word-break: break-word; }
      main.certificate-page .download-bar .download-title p.introduction-text {
        margin-bottom: 0;
        font-weight: 400;
        text-align: start; }
    main.certificate-page .download-bar .icon-signs {
      display: flex;
      align-items: flex-end; }
      main.certificate-page .download-bar .icon-signs span {
        margin-right: 0.675rem; }
        main.certificate-page .download-bar .icon-signs span:last-of-type {
          text-transform: uppercase;
          font-size: 1.375rem;
          font-weight: 700;
          opacity: 0.2;
          margin-right: 0; }
    main.certificate-page .download-bar:last-of-type {
      margin-bottom: 0; }
  @media (max-width: 767.98px) {
    main.certificate-page .certificate .introduction-text {
      padding: 0; } }
  @media (max-width: 575.98px) {
    main.certificate-page .download-bar .download-title {
      padding-right: 2rem; } }

main.search-results .search-result-list {
  margin: 2rem 0; }
  main.search-results .search-result-list .search-result-item {
    background-color: #ffffff;
    padding: 1.5rem; }
    main.search-results .search-result-list .search-result-item a {
      font-size: 1.25rem;
      display: block; }
    main.search-results .search-result-list .search-result-item:nth-child(even) {
      background-color: #f6f6f6; }

.easy-autocomplete {
  flex-grow: 1;
  text-align: left; }
  .easy-autocomplete input {
    width: 100%;
    box-shadow: none; }

.logos {
  background-color: #f5f6f7;
  position: relative;
  z-index: 100; }
  .logos .logo-item {
    background-size: cover;
    background-position: center;
    margin: 5.5625rem 0;
    display: block;
    height: 14rem; }
    .logos .logo-item .logo-container {
      padding: 1rem;
      background-color: #fff;
      width: 100%;
      height: 100%; }
      .logos .logo-item .logo-container img {
        max-width: 100%;
        max-height: 100%;
        margin-top: auto;
        margin-bottom: auto; }
  @media (max-width: 575.98px) {
    .logos {
      padding: 5.625rem 0; }
      .logos .logo-item {
        margin-top: 0;
        margin-bottom: 1.875rem; }
      .logos .col-12:last-of-type .service-item {
        margin-bottom: 0; } }
