main.contact-page {
    margin-top: 2rem;
    overflow: hidden;
    h5 {
        margin-bottom: 3.0625rem;
    }

    .help-grid-container {
        @include grid-container(1fr 1fr, 1fr 1fr, unset);
        margin-bottom: 5.875rem;
        .help-item {
            display: grid;
            background-color: $primary;
            background-size: cover;
            background-position: center;
            min-height: 21rem;

            .item-content {
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                align-items: flex-start;

                color: $secondary;

                h2,
                .gradient-background {
                    padding: 1.375rem 2.3125rem;
                }
                h2 {
                    background-color: $primary;
                    font-weight: 700;
                    line-height: $line-height-big;
                    width: 100%;
                    margin-bottom: 0;
                }
                .gradient-background {
                    @include gradient(80%);
                    flex: 1;
                    width: 100%;
                    position: relative;
                    p {
                        position: absolute;
                        bottom: 1.375rem;

                        margin-right: 2.3125rem;
                    }
                    .button-basic.light {
                        &:hover {
                            border: 1px solid $primary;
                        }
                    }
                }
            }

            &:nth-child(1) {
                grid-column: 1 / span 1;
                grid-row: 1 / span 1;
            }
            &:nth-child(2) {
                grid-column: 2 / span 1;
                grid-row: 1 / span 2;
            }
            &:nth-child(3) {
                grid-column: 1 / span 1;
                grid-row: 2 / span 1;
            }
        }
        .help-item-1 {
            background-image: url('/data/images/contact/smallhelp_block_left_top11.jpg');
            @include media-breakpoint-up(xl) {
                background-image: url('/data/images/contact/retinahelp_block_left_top11.jpg');
            }
        }

        .help-item-2 {
            background-image: url('/data/images/contact/smallhelp_block_right_desktop11.jpg');
            @include media-breakpoint-up(xl) {
                background-image: url('/data/images/contact/retinahelp_block_right_desktop11.jpg');
            }

            @include media-breakpoint-down(md) {
                background-image: url('/data/images/contact/mobilehelp_block_right_mobile11.jpg');
            }
        }

        .help-item-3 {
            background-image: url('/data/images/contact/smallhelp_block_left_bottom11.jpg');
            @include media-breakpoint-up(xl) {
                background-image: url('/data/images/contact/retinahelp_block_left_bottom11.jpg');
            }        }

    }

    .discription-form {
        h1 {
            margin-bottom: 1.8125rem;
        }
        p {
            margin-bottom: 4.25rem;
        }
    }
    .contact-form {
        margin-bottom: 10.625rem;

        @include form($primary, $secondary, $primary, auto, 0);

        .messages {
            margin-top: -1.75rem;
            height: 1.75rem;
            p.help-block {
                height: 100%;
                font-weight: 500;
                font-size: x-small;
                line-height: 1rem;
                &.error {
                    color: $warning;
                }
            }
        }
    }

    .contact-details {
        margin-bottom: 15.7rem;
        position: relative;
        .details-content {
            z-index: 10;
            position: relative;
            h4 {
                margin-bottom: 1.875rem;
            }
            p {
                margin-bottom: 4.875rem;
            }
            img {
                display: block;
                width: 100%;
            }
        }

        #animation-contact-one {
            @include animation-background(
                url("/static/images/icons/triangle-grey-left-bottom.svg"),
                right,
                top
            );
            @include animation-container-element(271px, unset, calc(5% -271px));
            bottom: 191px;
        }
        #animation-contact-two {
            @include animation-background(
                url("/static/images/icons/triangle-grey-left-top.svg"),
                right,
                top
            );
            @include animation-container-element(271px, unset, 5%);
            bottom: -80px;
        }
    }
    @include media-breakpoint-down(md) {
        .help-grid-container {
            @include grid-container(1fr, auto, 1fr);
            .help-item {
                &:nth-child(1) {
                    grid-column: 1 / span 1;
                    grid-row: 1 / span 1;
                }
                &:nth-child(2) {
                    grid-column: 1 / span 1;
                    grid-row: 2 / span 1;
                }
                &:nth-child(3) {
                    grid-column: 1 / span 1;
                    grid-row: 3 / span 1;
                }
            }
        }
        .contact-details .details-content {
            h4 {
                margin-top: 1.875rem;
            }
        }
    }

    .hidden {
        display: none;
    }
}
